/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal } from "../../../../libs/react-mpk/components";
import { TableWrapper } from "../../../../libs/react-mpk/wrapper";
import t from 'counterpart'
import { inject, observer } from "mobx-react";
import { http, number, toast } from '../../../../libs/react-mpk/services'
import ListLogImportDetail from './ListLogImportDetail'
import moment from "moment";





const ListLogImport = ({ visible = false, onRequestClose = null, title = 'Daftar Log Import', tipeForm, filename, url, ...props }) => {
    const [ready, setReady] = useState(false)
    const [showDetail, setShowDetail] = useState(false)
    const [selected, setSelected] = useState(null)
    useEffect(async () => {
        if (visible) {
            // if (apikeyId) {
            //     const users_apikey = await getApiKeyUsers({ 'apiKeyId.equals': apikeyId })
            //     setDatas(users_apikey.data)
            // }
            // if (sertelId) {
            //     const users_sertel = await getSertelUsers({ 'sertelId.equals': sertelId })
            //     setDatas(users_sertel.data)
            // }
            setReady(true)
        }
    }, [visible])


    return ready && (
        <>
            <Modal.Info
                baseId='mpk-modal'
                title={title}
                // children              = {null} 
                // footer                = {null} 
                // noFooter              = {false}
                // disableFocusOnMount   = {false}
                visible={visible}
                onRequestClose={() => {
                    onRequestClose()
                }}
            >
                <TableWrapper
                    baseId="mod-table-users-list"
                    // title="Tabel Review Data"
                    // className={className}
                    showActionColumn={true}
                    defaultSize={5}
                    showCommandbar={false}
                    fit={false}
                    loading={false}
                    useFilter={false}
                    useCriteria={false}
                    columns={[
                        {
                            label: 'Jumlah Error Validasi',
                            searchable: true,
                            sortable: false,
                            key: 'countError',
                            render: item => (
                                <TableWrapper.Status
                                    type={item.countError > 0 ? TableWrapper.Status.types.ERROR : TableWrapper.Status.types.SUCCESS}
                                >
                                    {item.countError}
                                </TableWrapper.Status>
                            )
                        },
                        {
                            label: 'Tanggal dibuat',
                            searchable: true,
                            sortable: false,
                            key: 'createdBy',
                            render: item => {
                                let date = new Date(parseInt(item.createdAt.$date.$numberLong))
                                return (
                                    <div>{moment(date).format('lll')}</div>
                                )
                            }
                        },
                        {
                            label: 'Proses Data',
                            searchable: true,
                            sortable: false,
                            key: 'status',
                            render: item => (
                                <div>{item.status}</div>
                            )
                        },
                        {
                            label: 'Filename',
                            searchable: true,
                            sortable: false,
                            key: 'filename',
                            render: item => (
                                <div>{item.filename}</div>
                            )
                        },

                    ]}
                    itemActions={[
                        new TableWrapper.action('Detail Error Message', 'mdi mdi-email-alert', (item) => {
                            setSelected(item)
                            setShowDetail(true)
                            console.log("🚀 ~ file: ListLogImport.js ~ line 75 ~ newTableWrapper.action ~ item", item)
                        },
                            true),
                    ]}
                    onFetchData={async (query) => {
                        query['field'] = 'tipeForm'
                        query['query'] = tipeForm
                        query['column'] = 'createdAt'
                        query['order'] = 'DESC'
                        delete query.keyword
                        delete query.columnCriteria
                        delete query.sortBy
                        delete query.start
                        delete query.end
                        delete query.startDate
                        delete query.endDate
                        delete query.sortCriteria
                        let res = await http.get(`/submission/${filename}/log_import`, query)
                        return res
                        // await onFetchData(query)
                    }}
                />
            </Modal.Info>
            <ListLogImportDetail
                visible={showDetail}
                onRequestClose={() => setShowDetail(false)}
                title={'Detail Log Import'}
                tipeForm={tipeForm}
                filename={filename}
                item={selected}
                url={`/submission/${filename}/log_import`}
                getLabel={(e) => props.getLabel(e)}
            />
        </>
    );
};

export default inject("envStore")(observer(ListLogImport));
