import { makeAutoObservable } from 'mobx';
import t from 'counterpart';
import { merge } from 'lodash';
import {toast} from '../services'

const defaultConfirmation = () => ( {
  id                  : 'mpk-modal-confirmation',
  visible             : false,
  title               : '',
  children            : '',
  onSubmit            : null,
  dialogStyle         : null,
  submitLabel         : null,
  cancelLabel         : null
})

const defaultInformation = () => ({
  id                  :'mpk-modal-information',
  visible             : false,
  title               : '',
  children            : '',
  dialogStyle         : null
})

class ModalStore{
  confirmation = defaultConfirmation()
  information = defaultInformation()

  closeConfirm(isCancel){
    this.confirmation.visible = false
    if(this.confirmation.onClose)
      this.confirmation.onClose(isCancel) 
  }

  showConfirm({
    title       = t.translate('mpk.confirm.title'),
    children    = t.translate('mpk.config.message'),
    onSubmit    = () => {},
    dialogStyle = null,
    ...other
  }){
    this.confirmation = merge(defaultConfirmation(), {
      visible : true,
      title, children, dialogStyle,
      ...other
    })
    this.confirmation.onSubmit = (callback) => {
      onSubmit((response, asError=false, autoClose=true) => {
        if(response) {
          if(asError) {
            if(typeof response === 'string') toast.error(response)
            else toast.errorRequest(response);
          } else if(response && typeof response === 'string') toast.success(response)
        }
        if(autoClose)this.confirmation.visible = false
        callback()
      })
    }
    
  }

  closeInfo(){
    this.information.visible = false
  }

  showInfo({
    title       = t.translate('mpk.column.info'),
    children    = '',
    dialogStyle = null,
    onRequestClose = () => {},
  }){
    this.information = merge(defaultInformation(), {
      visible: true,
      title, children, dialogStyle,
      onRequestClose
    })
  }

  constructor(){
    makeAutoObservable(this)
  }
}

export default ModalStore;