import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const recon = (submitting=true) => {
    return new Promise((resolve) => {
        let detailLampVPemegangSaham = storage.get(storage.keys.SPT_1771_DetailLampVPemegangSaham)
    
        let update = {
            dJmlMdlStr: 0,
            dJmlMdlStrPenuh: 0,
            dJmlDividen: 0,
        }

        for(let item of detailLampVPemegangSaham.list){
            update.dJmlMdlStr += item.JmlMdlRp || 0
            update.dJmlMdlStrPenuh += item.dPctMdlStr || 0
            update.dJmlDividen += item.Dividen || 0
        }

        update.dJmlMdlStrPenuh = Math.round(update.dJmlMdlStrPenuh > 100 ? 100 : update.dJmlMdlStrPenuh)

        if(submitting) storage.update(storage.keys.SPT_1771_HeaderLampV, update)
        resolve()
    })
}

const { inputTypes } = DataForm

const skey = storage.keys.SPT_1771_HeaderLampV
const columns = {
    dJmlMdlStr: 'dJmlMdlStr',
    dJmlMdlStrPenuh: 'dJmlMdlStrPenuh',
    dJmlDividen: 'dJmlDividen',
}

const generate = async () => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })())

    return data
}

const HeaderLampV = ({
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    const handleUpdateData = (data) => {
        return data
    }

    useEffect(async () => {
        let __data = storage.get(skey)
        let defData = handleUpdateData(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-headerLampV"
            className="mpk-margin-C"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={Object.keys(columns).map(key=>({
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: getLabel(key, 'HeaderLampV'),
                isNumericString:true,
                decimalSeparator: ',',
                thousandSeparator:'.',
                isNumericString: true,
                readOnly: true,
                decimalScale: '2',
                key
            }))}
            onSubmit={(data, callback) => {
                callback(t.translate('sentence.savedItem'), false)
                props.onNextTab()
            }}
            submitLabel={t.translate('column.next')}
        />
    )
}

HeaderLampV.generate = generate
HeaderLampV.columns = columns
HeaderLampV.recon = recon

export default HeaderLampV
