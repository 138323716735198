import { makeAutoObservable } from 'mobx';

class TemporaryStore {
  properties = {}

  constructor(){
    makeAutoObservable(this)
  }

  setProperties(key, value){
    this.properties[key] = value;
  }
}

export default TemporaryStore;