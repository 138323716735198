import React from 'react'
import ills from '../../assets/illustrasi-line-dash-lamp.svg';
import t from 'counterpart'

const NotFoundData = ({
    headline    = t.translate('mpk.sentence.notFoundDataHeadline'),
    description = t.translate('mpk.sentence.notFoundDataDescription'),
    className   = '',
    showImage   = true,
    ...props
}) => {
    return (
        <div 
            className={`mpk-flex align-center justify-center direction-column mpk-full full-width full-height mpk-animation slide-in mpk-padding-N padding-all ${className}`}
            {...props}
        >
            {showImage && <img src={ills}/>}
            {headline && (
                <div className="mpk-font size-L weight-B color-P1 mpk-margin-N margin-top">
                    {headline}
                </div>
            )}
            {description && (
                <div className="mpk-font size-NS weight-L color-D2 mpk-margin-XS margin-top">
                    {description}
                </div>
            )}
            
        </div>
    )
}

export default NotFoundData
