class dashboardDefinition{
  constructor(
    label       = 'string', 
    summaries   = [], 
    charts      = []
  ){
    this.label = label
    this.summaries = summaries
    this.charts = charts
  }
}

export default dashboardDefinition