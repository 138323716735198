import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { rules, storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import D from './D'

const { inputTypes } = DataForm
const recon = (
    data = storage.get(storage.keys.SPT_1771_Induk),
    HeaderLampIII = storage.get(storage.keys.SPT_1771_HeaderLampIII),
    HeaderLampKhus7A = storage.get(storage.keys.SPT_1771_HeaderLampKhus7A),
) => {
    return new Promise(resolve => {
        data.dC8a = HeaderLampIII ? HeaderLampIII.JmlPs222326 : 0
        data.dC8b = HeaderLampKhus7A ? HeaderLampKhus7A.JmlKreHit : 0
        data.dJmlC8 = data.dC8a + data.dC8b
        data.dJmlC9 = data.dB6 - data.dC7 - data.dJmlC8
        data.dJmlC10 = data.dC10a + data.dC10b
        
        if(data.dJmlC9 < 0) data.optC9 = '2'
        else if(data.dJmlC9 > 0) data.optC9 = '1'
        else data.optC9 = '0'
        
        resolve(data)
    })
}

const C = ({
    data,
    handleUpdate,
    getLabel,
    ...props
}) => {
    const headerLampIII = storage.get(storage.keys.SPT_1771_HeaderLampIII)
    const headerLampKhus7A = storage.get(storage.keys.SPT_1771_HeaderLampKhus7A)
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        setDefaultData(data)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-C"
            className="mpk-margin-C"
            style={{
                width: '100%',
                maxWidth: 800,
            }}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                ...[
                    'dC7', 'dC8a', 'dC8b', 'dJmlC8'
                ].map(key => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Induk'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    allowNegative: false,
                    disabled: ['dC8a', 'dC8b','dJmlC8'].indexOf(key) >= 0,
                    key
                })),
                {
                    inputType: inputTypes.SELECT,
                    label: getLabel('optC9', 'Induk'),
                    key: 'optC9',
                    options: props.envStore.env.spt.kreditPajak
                },
                ...[
                    'dJmlC9', 'dC10a', 'dC10b', 'dJmlC10'
                ].map(key => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Induk'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    allowNegative: false,
                    disabled: ['dJmlC9', 'dJmlC10'].indexOf(key) >= 0,
                    key
                })),
            ]}
            onChange={async (data, key, value) => {
                data = await recon(data, headerLampIII, headerLampKhus7A)
                return data
            }}
            onSubmit={async (__data, callback) => {
                const {
                    dC7,
                    dC8a,
                    dC8b,
                    dJmlC8,
                    optC9,
                    dJmlC9,
                    dC10a,
                    dC10b,
                    dC10c,
                    dJmlC10
                } = __data

                let update = await D.recon(__data)
                await handleUpdate({
                    ...update,
                    dC7,
                    dC8a,
                    dC8b,
                    dJmlC8,
                    optC9,
                    dJmlC9,
                    dC10a,
                    dC10b,
                    dC10c,
                    dJmlC10
                })

                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    )
}

C.recon = recon


export default inject('envStore')(observer(C))
