import React, { useState, useEffect } from "react";
import { LoaderInfo } from "../../../libs/react-mpk/components";
import { config as dataFormConfig } from "../../../libs/react-mpk/components/DataForm";
import { inject, observer } from "mobx-react";
import t from "counterpart";
import { storage, utils } from "../../../services";
import renderHTML from "react-render-html";
import { merge } from "lodash";

import Commit from "./Commit";
import Greeting from "./Greeting";
import MPN from "../MPN";
import { toast } from "../../../libs/react-mpk/services";
import { cancel } from "../Submission.service";
import {
  round,
  roundDecimal,
} from "../../../libs/react-mpk/services/number.service";

dataFormConfig.setOnBeforeChange((key, value) => {
  value =
    typeof value === "string"
      ? value.replace(/[;:<>&\\"'`]|[^ -~]/g, "")
      : value;

  if (
    [
      "NPWP",
      "NPWP_TTD",
      "NPWP_KUASA",
      // DJP PERUBAHAN NPWP_16+nitku
      // "NPWPKAP", // NPWP 16 DIKOMEN 15 TIKDA
      // "NPWPAP",
      // "NPWPKKP",
      // "NPWPKP",
      // DJP PERUBAHAN NPWP_16+nitku
      "NPWPSaham",
      "NPWPHit",
    ].indexOf(key) >= 0
  ) {
    value = String(value).substr(0, 15).replace(/-/g, "").replace(/\D/g, "");
  }

  if (["KdPosHit"].indexOf(key) >= 0) value = String(value).replace(/\D/g, "");
  //   // value = value.length === 15 ? utils.formatNpwp(value) : value
  // } else if(['nik', 'ntpn'].indexOf(key) >= 0){
  //   value = String(value).substr(0, 16)
  // } else if(['tahunPerolehan', 'tahunPinjaman'].indexOf(key) >= 0){
  //   let initData = storage.get(storage.keys.SPT_INIT)
  //   value = Number(value) > Number(initData.tahunPajak) ? initData.tahunPajak : value
  // }

  return value;
});

const sptWrapper =
  (options = {}) =>
  (WrappedComponent) => {
    let opt = merge(
      {
        code: "1770S",
        onInit: () => Promise.resolve(),
      },
      options
    );

    const Form = ({ className = "", ...props }) => {
      let initData = storage.get(storage.keys.SPT_INIT);
      const submission = storage.get(storage.keys.SPT_SUBMISSION);
      const [ready, setReady] = useState(false);
      const [sptData, setSptData] = useState(null);
      const [showCommit, setShowCommit] = useState(false);
      const [saveOnly, setSaveOnly] = useState(true);
      const [ssp, setSsp] = useState(null);
      const [billingCode, setBillingCode] = useState(null);

      const { showFieldKey } = props.envStore.env;

      const handleBack = () => {
        let { submission } = props.temporaryStore.properties;
        props.navigationStore.redirectTo(
          `/${
            props.envStore.widget.active
              ? "widget"
              : props.envStore.env.applicationType
          }/submissions`
        );
        props.temporaryStore.setProperties("sptErrorData", null);
        if (submission) {
          props.temporaryStore.setProperties("submission", null);
          storage.clearAll();
        }
      };

      const handleCancel = () => {
        let { submission } = props.temporaryStore.properties;
        props.modalStore.showConfirm({
          title: t.translate(
            "modules.submission.sptForm.confirmation.cancelTitle"
          ),
          children: t.translate(
            "modules.submission.sptForm.confirmation.cancelMessage"
          ),
          onSubmit: async (callback) => {
            try {
              if (submission) await cancel(submission.fileName);
              callback();
              handleBack();
            } catch (error) {
              callback();
              toast.errorRequest(error);
            }
          },
        });
      };

      const handlePembetulan = () => {
        let profile = storage.get(storage.keys[`SPT_${opt.code}_Profile`]);
        if (Number(profile.KD_PEMBETULAN_KE) < 99) {
          const nextPembetulan = String(Number(profile.KD_PEMBETULAN_KE) + 1);
          props.modalStore.showConfirm({
            title: t.translate("modules.submission.sptForm.pembetulan.title"),
            children: t.translate(
              "modules.submission.sptForm.pembetulan.confirmMessage"
            ),
            onSubmit: (callback) => {
              setReady(false);
              props.temporaryStore.setProperties("submission", null);
              toast.success(
                t
                  .translate(
                    "modules.submission.sptForm.pembetulan.dataCreated"
                  )
                  .replace("*pembetulan", nextPembetulan)
              );
              callback();
              storage.remove(storage.keys.SPT_SUBMISSION);
              storage.update(storage.keys[`SPT_${opt.code}_Profile`], {
                KD_PEMBETULAN_KE: String(Number(profile.KD_PEMBETULAN_KE) + 1),
              });
              setTimeout(() => setReady(true));
            },
          });
        } else {
          props.modalStore.showInfo({
            title: t.translate("modules.submission.sptForm.pembetulan.title"),
            children: t.translate("modules.submission.sptForm.pembetulan.deny"),
          });
        }
      };

      const getIcon = (regexKey) => {
        return props.temporaryStore.properties.sptErrorData &&
          Object.keys(props.temporaryStore.properties.sptErrorData)
            .toString()
            .match(regexKey)
          ? "mdi mdi-alert"
          : "mdi mdi-check";
      };

      const getLabel = (key, mod, code = "1771") => {
        return `${showFieldKey ? `[${key}][${mod}] - ` : ""}${t.translate(
          `modules.submission.sptForm.${code}.${mod}.${key}`
        )}`;
      };

      const getErrorMessage = (keys = []) => {
        if (props.temporaryStore.properties.sptErrorData) {
          let message = "";
          for (let key of keys) {
            let m = props.temporaryStore.properties.sptErrorData[key];
            if (m) {
              for (let msg of m) {
                message += `<li>${msg}</li>`;
              }
            }
          }
          return message.length > 0
            ? renderHTML(
                `<ul className="mpk-padding-N padding-left padding-right mpk-margin-NONE margin-all">${message}</ul>`
              )
            : null;
        }
        return null;
      };

      useEffect(async () => {
        // await opt.onInit(props)
        // setReady(true)
        let isContinue = true;

        if (submission) {
          if (
            props.envStore.widget.active ||
            props.authStore.user.isSupport ||
            submission.createdBy === props.authStore.user.username
          )
            props.temporaryStore.setProperties("submission", submission);
          else {
            isContinue = false;
            storage.clearAll();
            handleBack();
          }

          if (submission.errorValidation) {
            let { errors, validator } = submission.errorValidation;
            if (errors) {
              let errorData = {};
              for (let err of errors) {
                let keys = Object.keys(err);
                let key = keys[0];
                errorData[key] = err[key].map((e) => e.error);
              }
              props.temporaryStore.setProperties("sptErrorData", errorData);
            } else if (validator) {
              toast.errorRequest(new Error(String(validator)));
            }
          }

          if (isContinue) {
            await opt.onInit(props, submission);
            setReady(true);
          }
        } else {
          await opt.onInit(props);
          setReady(true);
        }
      }, []);

      return ready ? (
        <>
          <WrappedComponent
            {...{
              submission,
              handleBack,
              handleCancel,
              handlePembetulan,
              getIcon,
              getLabel,
              getErrorMessage,
              setSptData,
              setShowCommit,
              setSaveOnly,
              setBillingCode,
              setSsp,
              isEditable: submission ? submission.audit.isEditable : true,
              allowPembetulan: false, //submission && (submission.isNtte || (submission.djpResponse && submission.djpResponse.message.match(/((?=sudah).*(?=dilaporkan)).*/ig))),
              ...props,
            }}
          />
          <Commit
            sptData={sptData}
            code={opt.code}
            visible={showCommit}
            onRequestClose={() => {
              setShowCommit(false);
              setSaveOnly(true);
            }}
            onBack={handleBack}
            onError={(errorData) => console.log(errorData)}
            saveOnly={saveOnly}
          />
          <MPN
            visible={billingCode || ssp ? true : false}
            onRequestClose={() => {
              setSsp(null);
              setBillingCode(null);
            }}
            billingCode={billingCode}
            ssp={ssp}
            code={opt.code}
          />
          <Greeting code={opt.code} />
        </>
      ) : (
        <LoaderInfo>loading data...</LoaderInfo>
      );
    };

    return inject(
      "navigationStore",
      "envStore",
      "modalStore",
      "authStore",
      "temporaryStore"
    )(observer(Form));
  };

export default sptWrapper;
