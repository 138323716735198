import React, { useState, useEffect } from "react";
import { DataForm } from "../../../../../libs/react-mpk/components";
import { rules, storage } from "../../../../../services";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { round } from "../../../../../libs/react-mpk/services/number.service";
import numeral from "numeral";

const { inputTypes } = DataForm;

const recon = (
  data,
  Profile = storage.get(storage.keys.SPT_1771_Profile),
  LampI = storage.get(storage.keys.SPT_1771_LampI),
  HeaderLampKhus2A = storage.get(storage.keys.SPT_1771_HeaderLampKhus2A)
) => {
  return new Promise((resolve) => {
    const tahunPajak = Number(Profile.THN_PJK);
    const tahunPajakJln = tahunPajak + 1;

    let bruto = LampI ? LampI.d1A + LampI.d1E + LampI.d2 : 0;

    data.dE14b = HeaderLampKhus2A.JmlSisa;
    data.dE14c = data.dE14a - data.dE14b;
    if (data.dE14c < 0) data.dE14c = 0;

    if (bruto > 50 * Math.pow(10, 9) && String(data.optB4) === "1") {
      if (tahunPajakJln >= 2010 && tahunPajakJln <= 2019) {
        data.dE14d = data.dE14c * 0.25;
      } else if (tahunPajakJln === 2020 || tahunPajakJln === 2021) {
        data.dE14d = data.dE14c * 0.22;
      } else {
        data.dE14d = data.dE14c * 0.22;
      }
    } else if (bruto > 50 * Math.pow(10, 9) && String(data.optB4) === "2") {
      if (tahunPajakJln >= 2010 && tahunPajakJln <= 2019) {
        data.dE14d = data.dE14c * 0.2;
      } else if (tahunPajakJln === 2020 || tahunPajakJln === 2021) {
        data.dE14d = data.dE14c * 0.19;
      } else {
        data.dE14d = data.dE14c * 0.19;
      }
    } else if (
      bruto <= 50 * Math.pow(10, 9) &&
      String(data.optB4) === "3" &&
      !data.hitungSendiri
    ) {
      if (bruto <= 48 * Math.pow(10, 8)) {
        if (tahunPajakJln >= 2010 && tahunPajakJln <= 2019) {
          data.dE14d = data.dE14c * (0.25 * 0.5);
        } else if (tahunPajakJln === 2020 || tahunPajakJln === 2021) {
          data.dE14d = data.dE14c * (0.22 * 0.5);
        } else {
          data.dE14d = data.dE14c * (0.22 * 0.5);
        }
      } else {
        if (tahunPajakJln >= 2010 && tahunPajakJln <= 2019) {
          data.dE14d =
            (((48 * Math.pow(10, 8)) / bruto) *
              data.dE14c *
              ((25 * 50) / 100)) /
            100;
          data.dE14d +=
            (data.dE14c - ((48 * Math.pow(10, 8)) / bruto) * data.dE14c) * 0.25;
        } else if (tahunPajakJln === 2020 || tahunPajakJln === 2021) {
          data.dE14d =
            (((48 * Math.pow(10, 8)) / bruto) *
              data.dE14c *
              ((22 * 50) / 100)) /
            100;
          data.dE14d +=
            (data.dE14c - ((48 * Math.pow(10, 8)) / bruto) * data.dE14c) * 0.22;
        } else {
          data.dE14d =
            (((48 * Math.pow(10, 8)) / bruto) *
              data.dE14c *
              ((22 * 50) / 100)) /
            100;
          data.dE14d +=
            (data.dE14c - ((48 * Math.pow(10, 8)) / bruto) * data.dE14c) * 0.22;
        }
      }
    }

    data.dE14d = round(data.dE14d);
    data.dE14f = data.dE14d - data.dE14e;
    if (data.dE14f < 0) data.dE14f = 0;

    data.dE14g = round(data.dE14f / 12, true);
    if (data.dE14g < 0) data.dE14g = 0;

    resolve(data);
  });
};

const E = ({ data, handleUpdate, getLabel, ...props }) => {
  const profile = storage.get(storage.keys.SPT_1771_Profile);
  const lampI = storage.get(storage.keys.SPT_1771_LampI);
  const [defaultData, setDefaultData] = useState();
  const [ready, setReady] = useState(false);

  useEffect(async () => {
    setDefaultData(data);
    setReady(true);
  }, []);

  return (
    ready && (
      <DataForm
        baseId="mod-submission-spt-1771-E"
        className="mpk-margin-C"
        style={{
          width: "100%",
          maxWidth: 800,
        }}
        hintMessage={props.errorMessage}
        asDialog={false}
        defaultData={defaultData}
        definitions={[
          ...["dE14a", "dE14b", "dE14c"].map((key) => ({
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: getLabel(key, "Induk"),
            helpText: key === "dE14e" ? getLabel(`__${key}`, "Induk") : null,
            isNumericString: true,
            decimalSeparator: ",",
            thousandSeparator: ".",
            disabled: ["dE14b", "dE14c"].indexOf(key) >= 0,
            key,
          })),
          // {
          //     inputType: inputTypes.CHECKBOX,
          //     label: getLabel('hitungSendiri', 'Induk'),
          //     key: 'hitungSendiri',
          //     show: d => (String(d.optB4) === '3')
          // },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: getLabel("dE14d", "Induk"),
            isNumericString: true,
            decimalSeparator: ",",
            thousandSeparator: ".",
            disabled: true,
            key: "dE14d",
            show: (d) => !d.hitungSendiri,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: getLabel("dE14d", "Induk"),
            isNumericString: true,
            decimalSeparator: ",",
            thousandSeparator: ".",
            key: "dE14d",
            show: (d) => d.hitungSendiri,
          },
          ...["dE14e", "dE14f", "dE14g"].map((key) => ({
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: getLabel(key, "Induk"),
            helpText: key === "dE14e" ? getLabel(`__${key}`, "Induk") : null,
            isNumericString: true,
            decimalSeparator: ",",
            thousandSeparator: ".",
            disabled: ["dE14f", "dE14g"].indexOf(key) >= 0,
            key,
          })),
        ]}
        onChange={async (data, key, value) => {
          data = await recon(data, profile, lampI);
          return data;
        }}
        onSubmit={async (__data, callback) => {
          const { dE14a, dE14b, dE14c, dE14d, dE14e, dE14f, dE14g } = __data;

          await handleUpdate({
            dE14a,
            dE14b,
            dE14c,
            dE14d,
            dE14e,
            dE14f,
            dE14g,
          });

          callback(t.translate("sentence.savedItem"), false);
        }}
        submitLabel={t.translate("column.save")}
      />
    )
  );
};

E.recon = recon;

export default inject("envStore")(observer(E));
