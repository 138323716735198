import React from 'react'
import { Router, Switch, Route, Redirect } from "react-router-dom"
import Company from './Company.routes'
import Index from './Index.routes'
import { inject, observer } from 'mobx-react'

const Product = ({history, navigationStore}) => {
  if(window.location.pathname === '/product') 
    setTimeout(() => navigationStore.redirectTo('/product/index'), 500)
  return (
    <Router history={history}>
      <Switch>
        <Route
          path='/product/index'
          render={props => (
            <Index {...props}/>
          )}
        />
        <Route
          path='/product/company/:companyId'
          render={props => (
            <Company {...props}/>
          )}
        />
      </Switch>
    </Router>
  )
}

export default inject('navigationStore')(observer(Product))