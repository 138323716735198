import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { rules, storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { round, roundDecimal } from '../../../../../libs/react-mpk/services/number.service'
import HeaderLampV from './HeaderLampV'
import Rekapitulasi from '../Rekapitulasi'

const { inputTypes } = DataForm

const skey = storage.keys.SPT_1771_DetailLampVPemegangSaham
const columns = {
    list:"list",
}

const itemColumns = {
    NPWPSaham:{
        key: 'npwpSaham',
        value: d => {
            return rules.npwpOptional(d)
        }
    },
    Nama:'nama',
    Alamat:'alamat',
    JmlMdlRp:'jmlMdlRp',
    dPctMdlStr:'dPctMdlStr',
    Dividen:'dividen'
}

const generate = async () => {
    let data = await storage.generate(skey, {
        list: []
    })

    return data
}

const DetailLampVPemegangSaham = ({
    getLabel,
    envStore,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    const handleUpdateData = (data, ) => {
        const dJmlMdlStr = data.list.reduce((a, b) => (a+b.JmlMdlRp), 0)
        for(let item of data.list){
            // item.dPctMdlStr = roundDecimal((item.JmlMdlRp*100)/dJmlMdlStr, true, 10)
            // item.dPctMdlStr = roundDecimal(item.dPctMdlStr, true, 100, true)
            // item.dPctMdlStr = Math.round(item.dPctMdlStr)

            // item.dPctMdlStr = roundDecimal((item.JmlMdlRp*100)/dJmlMdlStr, false, 1000, false)
            // item.dPctMdlStr = roundDecimal(item.dPctMdlStr, true, 100, false)

            item.dPctMdlStr = +(Math.round((item.JmlMdlRp*100)/dJmlMdlStr + "e+2")  + "e-2")//roundDecimal(item.dPctMdlStr, true, 100, false)
            item.dPctMdlStr = item.dPctMdlStr ? item.dPctMdlStr:0
        }

        return data
    }

    useEffect(() => {
        let __data = storage.get(skey)
        let defData = handleUpdateData(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-DetailLampVPemegangSaham"
            className="mpk-margin-C"
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            watchDefaultData={false}
            style={{maxWidth: 'unset'}}
            definitions={[
                {
                    inputType: inputTypes.LIST,
                    asTable: true,
                    label: getLabel('__title','DetailLampVPemegangSaham'),
                    key: 'list',
                    defaultData:{
                        NPWPSaham: '',
                        Nama: '',
                        Alamat: '',
                        JmlMdlRp: 0,
                        dPctMdlStr: 0,
                        Dividen: 0,
                    },
                    definitions:[
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('Nama', 'DetailLampVPemegangSaham'),
                            key: 'Nama',
                            validation: (data) => (rules.validateNamaNpwp(data, 'NPWPSaham')),
                            required: true
                        },
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('Alamat', 'DetailLampVPemegangSaham'),
                            key: 'Alamat',
                            validation: 'max:255',
                            required: true
                        },
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('NPWPSaham', 'DetailLampVPemegangSaham'),
                            key: 'NPWPSaham',
                            validation: data => (data.Nama.length > 0 ? 'size:15' : 'min:0'),
                            maxLength: 15,
                            minLength: 15,
                            required: true
                        },
                        {
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            label: getLabel('JmlMdlRp', 'DetailLampVPemegangSaham'),
                            isNumericString:true,
                             decimalSeparator: ',',
                            thousandSeparator:'.',
                            key: 'JmlMdlRp',
                            allowNegative: false
                        },
                        {
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            label: getLabel('dPctMdlStr', 'DetailLampVPemegangSaham'),
                            isNumericString:true,
                            decimalSeparator: ',',
                            thousandSeparator:'.',
                            key: 'dPctMdlStr',
                            allowNegative: false,
                            readOnly: true,
                            decimalScale: '2'
                        },
                        {
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            label: getLabel('Dividen', 'DetailLampVPemegangSaham'),
                            isNumericString:true,
                             decimalSeparator: ',',
                            thousandSeparator:'.',
                            key: 'Dividen',
                            allowNegative: false
                        },
                    ]
                }
            ]}
            onBeforeChange={(key, value) => {
                // if(key === 'NPWPSaham') return rules.npwpOptional(value)
                return value
            }}
            onChange={(data, key, value, parentKey, indexAtParent) => {
                if(parentKey && indexAtParent !== null && (key === 'JmlMdlRp' || key === 'list')){
                    data = handleUpdateData(data)
                }

                return data
            }}
            onSubmit={async (data, callback) => {
                storage.update(skey, data)
                await HeaderLampV.recon()
                await Rekapitulasi.recon()
                callback(t.translate('sentence.savedItem'), false)
            }}
        />
    )
}

DetailLampVPemegangSaham.generate = generate
DetailLampVPemegangSaham.columns = columns
DetailLampVPemegangSaham.itemColumns = itemColumns

export default inject('envStore')(observer(DetailLampVPemegangSaham))