/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal } from "../../../../libs/react-mpk/components";
import { TableWrapper } from "../../../../libs/react-mpk/wrapper";
import t from 'counterpart'
import { inject, observer } from "mobx-react";
import { http, number, toast } from '../../../../libs/react-mpk/services'





const ListLogImportDetail = ({ visible = false, onRequestClose = null, title = 'Daftar Log Import', tipeForm, filename, url, item, ...props }) => {
    const [ready, setReady] = useState(false)
    useEffect(async () => {
        if (visible) {
            // if (apikeyId) {
            //     const users_apikey = await getApiKeyUsers({ 'apiKeyId.equals': apikeyId })
            //     setDatas(users_apikey.data)
            // }
            // if (sertelId) {
            //     const users_sertel = await getSertelUsers({ 'sertelId.equals': sertelId })
            //     setDatas(users_sertel.data)
            // }
            setReady(true)
        }
    }, [visible])


    return ready && (
        <Modal.Info
            baseId='mpk-modal'
            title={title}
            // children              = {null} 
            // footer                = {null} 
            // noFooter              = {false}
            // disableFocusOnMount   = {false}
            visible={visible}
            useCriteria={false}
            onRequestClose={() => {
                onRequestClose()
            }}
        >
            <TableWrapper
                baseId="mod-table-users-list"
                // title="Tabel Review Data"
                // className={className}
                showActionColumn={false}
                defaultSize={5}
                showCommandbar={false}
                fit={false}
                loading={false}
                useFilter={false}
                useCriteria={false}
                columns={[
                    {
                        label: 'ROW',
                        searchable: false,
                        sortable: false,
                        key: 'line',
                        render: item => (
                            <div><b style={{ color: 'red' }}>{item.line}</b></div>
                        )
                    },
                    {
                        label: 'Error Validasi Message',
                        searchable: true,
                        sortable: true,
                        key: 'errorMessage',
                        render: item => (
                            <div style={{ color: 'red' }}>{item.errorMessage}</div>
                        )
                    },
                ]}
                onFetchData={async (query) => {
                    query['column'] = 'createdAt'
                    query['order'] = 'DESC'
                    delete query.keyword
                    delete query.columnCriteria
                    delete query.sortBy
                    delete query.start
                    delete query.end
                    delete query.startDate
                    delete query.endDate
                    delete query.sortCriteria
                    let res = await http.get(`${url}/${item._id.$oid}`, query)
                    return res
                    // await onFetchData(query)
                }}
            />
        </Modal.Info>
    );
};

export default inject("envStore")(observer(ListLogImportDetail));
