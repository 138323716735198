import React from 'react'
import { CompanyCard, Masonry } from '../../libs/react-mpk/components'
import { inject, observer } from 'mobx-react'
import t from 'counterpart'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import httpService from '../../libs/react-mpk/services/http.service'

const Companies = ({ authStore, envStore }) => {
  return (
    <TableWrapper
      title={t.translate('menu.companies')}
      baseId="mod-companies"
      useFilter={authStore.user ? authStore.user.isSupport : false}
      render={(data) => (
        <div className="mpk-padding-N padding-all mpk-animation slide-in mpk-full full-width">
          <Masonry
            className="mpk-full full-width"
            baseId="mod-companies-masonry"
            items={data.map(d => (
              <CompanyCard 
                key={`company-${d.id}`}
                value={d}
                href={`/product/company/${d.id}`}
              />
            ))}
          />
        </div>
      )}
      onFetchData={(query) => {
        return authStore.user.isSupport 
          ? httpService.stream(`${envStore.env.sso.host}/api/user_console/company_support`, query, {}, {
            useDefaultHost: false,
            useDefaultBaseUrl: false,
          })
          : new Promise(resolve => {
            let { companies } = authStore.user
            resolve({
              data: companies,
              headers:{
                'x-pagination-count': companies.length,
              }
            })
          })
      }}
    />
  )
}

export default inject('authStore', 'envStore')(observer(Companies))
