import React, { useState } from 'react'
import { Button, CircularProgress, DropdownMenu, MenuItem } from 'react-md'
import { toast } from '../../../../libs/react-mpk/services'
import { downloadSpt, exportSpt } from '../../Submission.service'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import iziToast from 'izitoast'

const ExportSPT = ({
    className       = '',
    submission,
    idTemplate      = '1',
    kota,
    ...props
}) => {
    const [inProgress, setInProgress] = useState(false)
    return (
        <>
        {submission && submission.referenceNo && submission.audit && !submission.audit.isValidate ? (
            <>
            <Button onClick={()=> {
                  sessionStorage.setItem('returnPath', window.location.pathname)
                props.navigationStore.redirectTo(`/${props.envStore.widget.active ? 'widget' : 'internal'}/submissions-log-export-v2/${submission.fileName}/${submission.rawData.referenceNo}`)
            }} style={{ marginRight: '5px' }} theme='primary' themeType='outline'>Log Export</Button>
            <DropdownMenu
                id="export-dropdown"
                disableDropdownIcon
                theme="primary"
                themeType="outline"
                className={className}
                disabled={inProgress}
                items={[
                    {
                        label: 'Induk',
                        key: 'induk'
                    },
                    {
                        label: 'LampI',
                        key: 'lampiran1',
                        fileName: 'Lampiran I-1771-{fileName}.pdf'
                    },
                    {
                        label:'LampII',
                        key:'lampiran2',
                        fileName: 'Lampiran II-1771-{fileName}.pdf'
                    },
                    {
                        label:'LampIII',
                        key:'lampiran3',
                        fileName: 'Lampiran III-1771-{fileName}.pdf'
                    },
                    {
                        label:'LampIV',
                        key:'lampiran4',
                        fileName: 'Lampiran IV-1771-{fileName}.pdf'
                    },
                    {
                        label:'LampV',
                        key:'lampiran5',
                        fileName: 'Lampiran V-1771-{fileName}.pdf'
                    },
                    {
                        label:'LampVI',
                        key:'lampiran6',
                        fileName: 'Lampiran VI-1771-{fileName}.pdf'
                    },
                    {
                        label: 'LampKhus1A',
                        key:'lampiranKhusus1A',
                        fileName: 'Lampiran 1A-1771-{fileName}.pdf'
                    },
                    {
                        label: 'LampKhus2A',
                        key:'lampiranKhusus2A',
                        fileName: 'Lampiran 2A-1771-{fileName}.pdf'
                    },
                    {
                        label: 'LampKhus3',
                        key:'lampiranKhusus3A',
                        fileName: 'Lampiran 3A-1771-{fileName}.pdf'
                    },
                    {
                        label: 'LampKhus3A1',
                        key:'lampiranKhusus3A1',
                        fileName: 'Lampiran 3A-1-1771-{fileName}.pdf'
                    },
                    {
                        label: 'LampKhus3A2I',
                        key:'lampiranKhusus3A2',
                        fileName: 'Lampiran 3A-2-1771-{fileName}.pdf'
                    },
                    {
                        label:'LampKhus4A',
                        key:'lampiranKhusus4A',
                        fileName: 'Lampiran 4A-1771-{fileName}.pdf'
                    },
                    {
                        label:'LampKhus5A',
                        key:'lampiranKhusus5A',
                        fileName: 'Lampiran 5A-1771-{fileName}.pdf'
                    },
                    {
                        label:'LampKhus6A',
                        key:'lampiranKhusus6A',
                        fileName: 'Lampiran 6A-1771-{fileName}.pdf'
                    },
                    {
                        label:'LampKhus7A',
                        key:'lampiranKhusus7A',
                        fileName: 'Lampiran 7A-1771-{fileName}.pdf'
                    },
                    {
                        label:'LapKeu',
                        key:'lampiranKhusus8A1',
                        fileName: 'Lampiran 8A-1-1771-{fileName}.pdf',
                        idTemplate: '1'
                    },
                    {
                        label:'LapKeu',
                        key:'lampiranKhusus8A3',
                        fileName: 'Lampiran 8A-3-1771-{fileName}.pdf',
                        idTemplate: '3'
                    },
                    {
                        label:'LapKeu',
                        key:'lampiranKhusus8A5',
                        fileName: 'Lampiran 8A-5-1771-{fileName}.pdf',
                        idTemplate: '5'
                    },
                    {
                        label:'LapKeu',
                        key:'lampiranKhusus8A7',
                        fileName: 'Lampiran 8A-7-1771-{fileName}.pdf',
                        idTemplate: '7'
                    },
                    {
                        label:'LapKeu',
                        key:'lampiranKhusus8A9',
                        fileName: 'Lampiran 8A-9-1771-{fileName}.pdf',
                        idTemplate: '9'
                    },
                    {
                        label:'LapKeu',
                        key:'lampiranKhusus8A11',
                        fileName: 'Lampiran 8A-11-1771-{fileName}.pdf',
                        idTemplate: '11'
                    },
                    {
                        label:'LapKeu',
                        key:'lampiranKhusus8A13',
                        fileName: 'Lampiran 8A-13-1771-{fileName}.pdf',
                        idTemplate: '13'
                    },
                    {
                        label:'LapKeu',
                        key:'lampiranKhusus8A15',
                        fileName: 'Lampiran 8A-15-1771-{fileName}.pdf',
                        idTemplate: '15'
                    }
                ].filter(d => (d.idTemplate ? d.idTemplate === idTemplate : true)).map(d => (
                    <MenuItem
                        style={{
                            width: 240
                        }}
                        onClick={async () => {
                            try {
                                setInProgress(true)
                                let res = await exportSpt(submission.fileName, d.key, props.authStore.user.isSupport, kota)
                                if(d.key !== 'induk') {
                                    iziToast.info({
                                        title: 'Export',
                                        message: res.data
                                    })
                                    // await downloadSpt(submission.fileName, res.data, d.fileName.replace(`{fileName}`, submission.fileName))
                                } 
                                setInProgress(false)
                            } catch (error) {
                                setInProgress(false)
                                toast.errorRequest(error)
                            }
                        }}
                    >
                        {t.translate(`modules.submission.sptForm.1771.${d.label}.__title`)}
                    </MenuItem>
                ))}
            >
                {inProgress && <CircularProgress className='mpk-margin-S margin-right'/>}
                {t.translate('column.exportSPT')}
            </DropdownMenu>
            </>
        ) : null}
        </>
    )
}

export default inject('authStore', "envStore", "navigationStore")(observer(ExportSPT))