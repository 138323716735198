import React, {useState, useEffect} from 'react'
import { ExpansionList, ExpansionPanel, usePanels } from 'react-md'
import { Hint } from '../../../../../libs/react-mpk/components'
import DetailLampVIPenyertaanModal from './DetailLampVIPenyertaanModal'
import DetailLampVIPiutangModal from './DetailLampVIPiutangModal'
import DetailLampVIUtangModal from './DetailLampVIUtangModal'
import HeaderLampVI from './HeaderLampVI'

const generate = () => (new Promise(async resolve => {
    await DetailLampVIPenyertaanModal.generate()
    await DetailLampVIUtangModal.generate()
    await DetailLampVIPiutangModal.generate()
    await HeaderLampVI.generate()
    await HeaderLampVI.recon()
    resolve()
}))

const LampVI = ({
    getLabel,
    onNextTab,
    ...props
}) => {
    const [ready, setReady] = useState(false)
    const [panels, onKeyDown] = usePanels({
        idPrefix: 'spt-1771-LampVI',
        count:4,
        defaultExpandedIndex: 0
    })

    useEffect(() => {
        setReady(true)
    }, [])

    const [panel1Props, panel2Props, panel3Props, panel4Props] = panels;
    return ready && (
        <div
            className="mpk-margin-C mpk-animation slide-in"
            style={{
                width: '100%',
                maxWidth: 1024
            }}
        >
            {props.errorMessage && 
                <div className="mpk-paper mpk-padding-N padding-all">
                    <Hint>{props.errorMessage}</Hint>
                </div>
            }
            <ExpansionList onKeyDown={onKeyDown}>
                {[
                    {
                        label: getLabel('__title', 'DetailLampVIPenyertaanModal'),
                        render: <DetailLampVIPenyertaanModal {...{getLabel}}/>,
                        panelProps: panel1Props
                    },
                    {
                        label: getLabel('__title', 'DetailLampVIUtangModal'),
                        render: <DetailLampVIUtangModal {...{getLabel}}/>,
                        panelProps: panel2Props
                    },
                    {
                        label: getLabel('__title', 'DetailLampVIPiutangModal'),
                        render: <DetailLampVIPiutangModal {...{getLabel}}/>,
                        panelProps: panel3Props
                    },
                    {
                        label: getLabel('__title', 'HeaderLampVI'),
                        render: <HeaderLampVI {...{getLabel, onNextTab}}/>,
                        panelProps: panel4Props
                    }
                ].map((p, i) => (
                    <ExpansionPanel 
                        key={`spt-1771-LampVI-panel-${i}`}
                        {...p.panelProps}
                        header={<div className="mpk-font size-M weight-M">{p.label}</div>}
                    >
                        {p.render}
                    </ExpansionPanel>
                ))}
            </ExpansionList>
        </div>
    )
}

LampVI.generate = generate

export default LampVI
