import React, { useState, useEffect, useCallback } from 'react'
import { Flex } from '../components'
import { Commandbar, DataTable, Status, DataFilter, Hint, Link, Pagination } from '../components'
import DataTablev2 from '../components/DataTable/DataTablev2'
import t from 'counterpart'
import { defaultQueryParameter, dataTypes, sorts, eventTypes, inputTypes } from '../config/constant'
import { merge, find, cloneDeep } from 'lodash'
import { toast, http } from '../services'
import { Button, FontIcon } from 'react-md'
import { Dialog, DialogContent, Tabs, Tab } from 'react-md'
import { action } from '../class'

const __defaultQuery = (query) => ({ ...defaultQueryParameter(), ...query })
const getChannelName = baseId => (`${baseId}-table-wrapper-event`)
const TableWrapper = ({
  title = null,
  baseId = 'mpk-table-wrapper',
  className = '',
  limitActionType = 2,
  actionType = 'icon', // icon / button / text
  actions = [],
  backTo = null,
  columns = [],
  itemActions = [],
  defaultData = [],
  defaultSort = sorts.descending,
  defaultSortBy = '',
  defaultSize = 20,
  extraFilter = null,
  showFilter = false,
  render = null,
  hintMessage = null,
  hintShowIcon = true,
  hintIconClassName = null,
  hintMore = null,
  onFetchData = null,
  useCriteria = true,
  useFilter = true,
  useQueryHistory = true,
  useSidebar = true,
  showFilterPeriod = false,
  showCommandbar = true,
  showActionColumn = true,
  showIndex = true,
  topContent = null,
  sideHeader = null,
  sideFooter = null,
  tableHeader = null,
  tableFooter = null,
  selectable = false,
  totalDataKey = null,
  commandbar = {},
  multiColumnFilter = false,
  defaultQuery = {},
  tableActions = false,
}) => {
  const queryName = `${baseId}-query`;
  const [isShowFilter, setIsShowFilter] = useState(showFilter)
  let [query, setQuery] = useState(__defaultQuery({
    size: defaultSize,
    sort: defaultSort,
    sortBy: defaultSortBy
    , ...defaultQuery
  }))
  const [data, setData] = useState(defaultData)
  const [totalData, setTotalData] = useState(0)
  const [loading, setLoading] = useState(true)
  const [mounted, setMounted] = useState(false)
  const [filterColumns, setFilterColumns] = useState([])

  const handleFetchData = async () => {
    if (onFetchData) {
      setLoading(true)
      setData([])
      try {
        // // console.log(useCriteria)
        let res = await onFetchData(useCriteria
          ? (multiColumnFilter
            ? http.mapQueryCriteriaMultiColumn(query)
            : http.mapQueryCriteria(query)
          ) : query
        )
        setData(res.data)
        setTotalData(Number(res.headers[totalDataKey ? totalDataKey : (useCriteria ? 'x-total-count' : 'x-pagination-count')]))
        setLoading(false)
      } catch (error) {
        setLoading(false)
        try {
          // toast.errorRequest(error)
        } catch (e) { }
      }
    }
  }

  const defaultActions = [
    new action(t.translate('mpk.column.reload'), 'mdi mdi-reload', handleFetchData, true, 'right', true),
    new action(t.translate('mpk.column.filterPencarian'), 'mdi mdi-filter', () => setIsShowFilter(!isShowFilter), useFilter, 'right', false),
  ]

  const updateDataItem = useCallback(({ selector, itemData }) => {
    setData(prevData => {
      let oldItem = find(prevData, selector)
      if (oldItem) {
        let newData = [...prevData]
        let idx = newData.indexOf(oldItem)
        newData[idx] = itemData
        return newData
      } else {
        return query.sort === sorts.ascending ? [...prevData, itemData] : [itemData, ...prevData]
      }
    })
  }, [data])

  useEffect(() => {
    if (mounted) {
      handleFetchData()
      localStorage.setItem(queryName, JSON.stringify(query))
    }
  }, [query])

  useEffect(() => {
    const channelName = getChannelName(baseId)
    window.addEventListener(channelName, (e) => {
      const { eventType, eventData } = e.detail
      switch (eventType) {
        case eventTypes.RELOAD:
          handleFetchData()
          break;
        case eventTypes.UPDATE:
          updateDataItem(eventData);
          break;
        default:
          break;
      }
    })

    return function cleanup() {
      window.removeEventListener(channelName, () => { }, false)
    }
  }, [])

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      let newFilterColumns = columns
        .filter(col => (col.searchable === undefined && (col.type === undefined || col.type === dataTypes.STRING) ? true : col.searchable))
        .map(({ key, label, ...col }) => ({
          label,
          value: key || label,
          ...col
        }))
      setFilterColumns(newFilterColumns)
      setTimeout(() => {
        if (newFilterColumns.length > 0) {
          let savedQuery = {}
          if (useQueryHistory) {
            savedQuery = localStorage.getItem(queryName)
            savedQuery = savedQuery ? JSON.parse(savedQuery) : {}
          }

          setQuery({ ...query, ...{ column: newFilterColumns[0].value }, ...savedQuery })
        } else {
          if (render) handleFetchData()
        }
      }, 1000)
    }
  }, [mounted])

  var sidebarPadding = {}
  if (useSidebar) {
    sidebarPadding = { paddingLeft: '14px' }
  }

  return (
    <Flex
      style={sidebarPadding}
      className={`mpk-table-wrapper mpk-full full-height ${className}`}
      direction={Flex.properties.direction.COLUMN}
      fit
    >
      {topContent &&
        <>
          {topContent}
        </>
      }
      {showCommandbar && (
        <Commandbar
          tableActions={tableActions}
          title={title}
          className="flex-none"
          actions={[...actions, ...defaultActions]}
          actionType={actionType}
          limitActionType={limitActionType}
          backTo={backTo}
          {...commandbar}
        />
      )}
      <Flex
        className="mpk-full full-width"
        fit
        style={{ paddingLeft: '16px' }}
      >
        <Flex
          className="mpk-full full-height mpk-padding-N padding-right mpk-animation slide-in"
          direction={Flex.properties.direction.COLUMN}
          fit
        >
          {hintMessage && (
            <div className="mpk-padding-N padding-all mpk-paper mpk-full full-width">
              <Hint
                message={hintMessage}
                showIcon={hintShowIcon}
                iconClassName={hintIconClassName}
                more={hintMore}
              />
            </div>
          )}
          {tableHeader &&
            <>
              {tableHeader}
            </>
          }
          <Flex
            className="mpk-full full-width mpk-position position-relative"
            // style={{background: 'white'}}
            fit
          >
            {render ?
              <Flex fit scrollable className="mpk-full full-width full-height">{render(data)}</Flex> : (
                <Flex
                  fit
                  className="mpk-full full-width full-height"
                  direction={Flex.properties.direction.COLUMN}
                >
                  <div className="flex mpk-full full-width new-table-wrapper">
                    <DataTablev2
                      tableActions={tableActions}
                      actions={[...actions, ...defaultActions]}
                      actionType={actionType}
                      limitActionType={limitActionType}
                      baseId={baseId}
                      columns={columns}
                      data={data}
                      itemActions={itemActions}
                      totalData={totalData}
                      loading={loading}
                      useCriteria={useCriteria}
                      showActionColumn={showActionColumn}
                      showIndex={showIndex}
                      selectable={selectable}
                      query={query}
                      setQuery={setQuery}
                      handleFetchData={handleFetchData}
                    />
                  </div>
                  <div className="pagination-container mpk-padding-S padding-all mpk-flex align-center justify-end">
                    <Pagination
                      baseId={`${baseId}-pagination`}
                      className="flex"
                      page={query.page}
                      size={query.size}
                      totalData={totalData}
                      onChange={(newQuery) => setQuery({ ...query, ...newQuery })}
                    />
                  </div>
                </Flex>
              )}
            {/* {!showCommandbar && (
              <Flex
                fir={false}
                direction={Flex.properties.direction.COLUMN}
                className="mpk-full mpk-padding-S padding-all"
                style={{ background: 'white' }}
              >
                {[...actions, ...defaultActions].map(action => (
                  <Button
                    buttonType="icon"
                    onClick={action.onClick}
                    key={`${baseId}-action-${action.label}`}
                  >
                    <FontIcon iconClassName={action.iconClassName} />
                  </Button>
                ))}
              </Flex>
            )} */}
          </Flex>
          {tableFooter &&
            <div className="mpk-padding-N padding-all mpk-paper mpk-full full-width mpk-margin-N margin-top">
              {tableFooter}
            </div>
          }
        </Flex>
        {(useFilter || sideHeader || sideFooter) && mounted && (sideHeader ? sideHeader : isShowFilter) && (
          <div
            className="mpk-padding-N padding-right padding-bottom flex-none mpk-animation slide-right scrollable mpk-full full-height"
          >

            {useFilter && isShowFilter ? (
              <div style={{ width: 280 }}>
                {sideHeader}
                <DataFilter
                  defaultQuery={query}
                  columns={filterColumns}
                  onSubmit={newQuery => {
                    setQuery({ ...query, ...newQuery })
                  }}
                  showPeriod={showFilterPeriod}
                  useCriteria={useCriteria}
                  extraFilter={extraFilter}
                  multiColumn={multiColumnFilter}
                />
              </div>
            ) : (
              <>
                {(mounted && isShowFilter) &&
                  <Dialog
                    visible={isShowFilter}
                    onRequestClose={() => {
                      setIsShowFilter(false)
                    }}
                  >
                    <DialogContent>
                      {sideHeader}
                    </DialogContent>
                  </Dialog>
                }
                {/* {isShowFilter &&
                <div style={{ width: 280 }}>
                  {sideHeader}
                </div>
              } */}
              </>
            )}
            {sideFooter}
          </div>
        )}
      </Flex>
    </Flex>
  )
}

TableWrapper.action = action
TableWrapper.dataTypes = dataTypes
TableWrapper.inputTypes = inputTypes
TableWrapper.Status = Status
TableWrapper.sorts = sorts
TableWrapper.Link = Link
TableWrapper.getSelected = DataTable.getSelected
TableWrapper.reload = (baseId) => {
  const channelName = getChannelName(baseId)
  window.dispatchEvent(new CustomEvent(channelName, { detail: { eventType: eventTypes.RELOAD } }))
}
TableWrapper.updateDataItem = (baseId, selector, itemData) => {
  const channelName = getChannelName(baseId)
  window.dispatchEvent(new CustomEvent(channelName, {
    detail: {
      eventType: eventTypes.UPDATE,
      eventData: { selector, itemData }
    }
  }))
}

export default TableWrapper
