import React, { useState, useEffect } from "react";
import { DataForm } from "../../../../../libs/react-mpk/components";
import { rules, storage } from "../../../../../services";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { round } from "../../../../../libs/react-mpk/services/number.service";

const { inputTypes } = DataForm;
const recon = (
  data = storage.get(storage.keys.SPT_1771_Induk),
  Profile = storage.get(storage.keys.SPT_1771_Profile),
  LampI = storage.get(storage.keys.SPT_1771_LampI)
) => {
  return new Promise((resolve) => {
    const tahunPajak = Number(Profile.THN_PJK);

    let bruto = LampI ? LampI.d1A + LampI.d1E + LampI.d2 : 0;

    if (bruto > 50 * Math.pow(10, 9)) {
      if (["1", "2"].indexOf(String(data.optB4)) < 0) {
        data.optB4 = "1";
      }
    } else {
      data.optB4 = "3";
    }

    if (bruto > 50 * Math.pow(10, 9) && String(data.optB4) === "1") {
      if (tahunPajak >= 2010 && tahunPajak <= 2019) {
        data.dB4 = data.dA3 * 0.25;
      } else if (tahunPajak === 2020 || tahunPajak === 2021) {
        data.dB4 = data.dA3 * 0.22;
      } else {
        data.dB4 = data.dA3 * 0.22;
      }
    } else if (bruto > 50 * Math.pow(10, 9) && String(data.optB4) === "2") {
      if (tahunPajak >= 2010 && tahunPajak <= 2019) {
        data.dB4 = data.dA3 * 0.2;
      } else if (tahunPajak === 2020 || tahunPajak === 2021) {
        data.dB4 = data.dA3 * 0.19;
      } else {
        data.dB4 = data.dA3 * 0.19;
      }
    } else if (
      bruto <= 50 * Math.pow(10, 9) &&
      String(data.optB4) === "3" &&
      !data.hitungSendiri
    ) {
      if (bruto <= 48 * Math.pow(10, 8)) {
        if (tahunPajak >= 2010 && tahunPajak <= 2019) {
          data.dB4 = data.dA3 * (0.25 * 0.5);
        } else if (tahunPajak === 2020 || tahunPajak === 2021) {
          data.dB4 = data.dA3 * (0.22 * 0.5);
        } else {
          data.dB4 = data.dA3 * (0.22 * 0.5);
        }
      } else {
        if (tahunPajak >= 2010 && tahunPajak <= 2019) {
          data.dB4 =
            (((48 * Math.pow(10, 8)) / bruto) * data.dA3 * ((25 * 50) / 100)) /
            100;
          data.dB4 +=
            (data.dA3 - ((48 * Math.pow(10, 8)) / bruto) * data.dA3) * 0.25;
        } else if (tahunPajak === 2020 || tahunPajak === 2021) {
          data.dB4 =
            (((48 * Math.pow(10, 8)) / bruto) * data.dA3 * ((22 * 50) / 100)) /
            100;
          data.dB4 +=
            (data.dA3 - ((48 * Math.pow(10, 8)) / bruto) * data.dA3) * 0.22;
        } else {
          data.dB4 =
            (((48 * Math.pow(10, 8)) / bruto) * data.dA3 * ((22 * 50) / 100)) /
            100;
          data.dB4 +=
            (data.dA3 - ((48 * Math.pow(10, 8)) / bruto) * data.dA3) * 0.22;
        }
      }
    }

    data.dB4 = Math.round(data.dB4);
    data.dB6 = data.dB4 + data.dB5;
    data.hitungSendiri =
      ["1", "2"].indexOf(String(data.optB4)) >= 0 ? false : data.hitungSendiri;

    resolve(data);
  });
};

const B = ({ data, handleUpdate, getLabel, ...props }) => {
  const LampI = storage.get(storage.keys.SPT_1771_LampI);
  const bruto = LampI.d1A + LampI.d1E + LampI.d2;
  const [defaultData, setDefaultData] = useState();
  const [ready, setReady] = useState(false);

  useEffect(async () => {
    setDefaultData(data);
    setReady(true);
  }, []);

  return (
    ready && (
      <DataForm
        baseId="mod-submission-spt-1771-B"
        className="mpk-margin-C"
        style={{
          width: "100%",
          maxWidth: 800,
        }}
        hintMessage={props.errorMessage}
        asDialog={false}
        defaultData={defaultData}
        definitions={[
          {
            inputType: inputTypes.SELECT,
            label: getLabel("optB4", "Induk"),
            key: "optB4",
            options: props.envStore.env.spt.pphTerutang.filter((d) => {
              return (bruto > 50000000000 ? ["1", "2"] : ["3"]).indexOf(
                String(d.value)
              ) >= 0
                ? true
                : false;
            }),
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: getLabel("hitungSendiri", "Induk"),
            key: "hitungSendiri",
            show: (d) => String(d.optB4) === "3",
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: getLabel("dB4", "Induk"),
            key: "dB4",
            isNumericString: true,
            decimalSeparator: ",",
            thousandSeparator: ".",
            allowNegative: false,
            disabled: true,
            show: (d) => !d.hitungSendiri,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: getLabel("dB4", "Induk"),
            key: "dB4",
            isNumericString: true,
            decimalSeparator: ",",
            thousandSeparator: ".",
            allowNegative: false,
            show: (d) => d.hitungSendiri,
          },
          ...["dB5", "dB6"].map((key) => ({
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: getLabel(key, "Induk"),
            isNumericString: true,
            decimalSeparator: ",",
            thousandSeparator: ".",
            allowNegative: false,
            disabled: ["dB6"].indexOf(key) >= 0,
            key,
          })),
        ]}
        onChange={async (data, key, value) => {
          data.dB6 = data.dB4 + data.dB5;
          if (
            key === "optB4" ||
            (key === "hitungSendiri" && !data.hitungSendiri)
          ) {
            data = await recon(data);
          }
          return data;
        }}
        onSubmit={async (__data, callback) => {
          const { optB4, dB4, dB5, dB6, hitungSendiri } = __data;

          await handleUpdate({
            optB4,
            dB4,
            dB5,
            dB6,
            hitungSendiri,
          });

          callback(t.translate("sentence.savedItem"), false);
        }}
        submitLabel={t.translate("column.save")}
      />
    )
  );
};

B.recon = recon;

export default inject("envStore")(observer(B));
