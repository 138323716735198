import React from 'react'
import Link from './Link'
import { Button, FontIcon } from 'react-md'

const IconButton = ({
    to,
    iconClassName="mdi mdi-arrow-right"
}) => {
    return (
        <Link to={to}>
            <Button
                className="mpk-margin-NS margin-right"
                buttonType="icon"
            >
                <FontIcon iconClassName={iconClassName}/>
            </Button>
        </Link>
    )
}

export default IconButton
