import React, {useState, useEffect} from 'react'
import t from 'counterpart'
import { DataForm, Link } from '../../libs/react-mpk/components'
import { inject, observer } from 'mobx-react'
import { Button, Dialog } from 'react-md'
import { http, toast } from '../../libs/react-mpk/services'
import renderHTML from 'react-render-html'
import './Form.scss'
import { getAggrement } from './Certificate.service'

const Form = ({
  visible        = false,
  onRequestClose = () => {},
  ...props
}) => {
  const { user } = props.authStore
  const { env } = props.envStore
  const [agreement, setAgreement] = useState(null)

  useEffect(async () => {
    try {
      let res = await getAggrement()
      setAgreement(res.data)
    } catch (error) {
      toast.errorRequest(error)
    }
  })
  return (
    <DataForm
      baseId="mod-certificate-form"
      className="mod-certificate-form"
      title={t.translate('modules.certificate.form.title')}
      asDialog={true}
      visible={visible}
      onRequestClose={onRequestClose}
      hintMessage={t.translate('modules.certificate.form.hintText')}
      showCancel={false}
      defaultData={{
        name: user.name,
        email: user.email,
        occupation: 'Orang Pribadi',
        phone: '123123',
        npwp: '123456789012345',
        efin: '1234567890',
        password: 'cikan',
        confirmPassword: 'cikan',
        agree: true
      }}
      definitions={[
        {
          inputType: DataForm.inputTypes.DIVIDER,
          label: t.translate('column.profile')
        },
        {
          inputType: DataForm.inputTypes.INPUT,
          label: t.translate('column.name'),
          key: 'name',
          readOnly: true
        },
        {
          inputType: DataForm.inputTypes.INPUT,
          label: t.translate('column.email'),
          key: 'email',
          readOnly: true
        },
        {
          render: (
            <a href={`${env.appConsole.userAccount.url}/main/account/user`} target="_self">
              <Button
                theme="warn"
                themeType="outline"
                className="mpk-margin-M margin-bottom"
              >
                {t.translate('column.updateProfile')}
              </Button>
            </a>
          )
        },
        {
          inputType: DataForm.inputTypes.DIVIDER,
          label: t.translate('column.certData')
        },
        {
          inputType: DataForm.inputTypes.INPUT,
          label: 'NPWP',
          key: 'npwp',
          required: true,
          validation: 'size:15'
        },
        {
          inputType: DataForm.inputTypes.INPUT,
          label: 'EFIN',
          key: 'efin',
          required: true,
          type: 'password',
          validation: 'digits:10',
          helpText: renderHTML(t.translate('modules.certificate.form.efinHint').replace('*here', `<a href="https://www.pajakku.com/read/5d075b78f9852949fdd34184/Cara-Daftar-EFIN-secara-Online">${t.translate('column.here').toLowerCase()}</a>`))
        },
        {
          inputType: DataForm.inputTypes.INPUT,
          label: t.translate('column.phone'),
          key: 'phone',
          required: true
        },
        {
          inputType: DataForm.inputTypes.INPUT,
          label: t.translate('column.password'),
          key: 'password',
          type: 'password',
          required: true,
          helpText: t.translate('modules.certificate.form.passwordHint')
        },
        {
          inputType: DataForm.inputTypes.INPUT,
          label: t.translate('column.confirmPassword'),
          key: 'confirmPassword',
          type: 'password',
          required: true
        },
        {
          inputType: DataForm.inputTypes.DIVIDER,
          label: t.translate('column.agreement')
        },
        {
          render:(
            <div 
              className="mpk-border border-all thin solid dark mpk-padding-N padding-all agrement mpk-full full-width"
              style={{
                lineHeight: '24px',
                background: '#fef6f4'
              }}
            >
              {agreement && renderHTML(agreement)}
            </div>
          )
        },
        {
          inputType: DataForm.inputTypes.CHECKBOX,
          label: t.translate('modules.certificate.form.agree'),
          key: 'agree'
        },
      ]}
      style={{
        width: '100%',
        maxWidth: 800
      }}
      onBeforeChange={(key, value) => {
        if(['npwp', 'phone', 'efin'].indexOf(key) >= 0) value = value.replace(/\D/g, '');
        return value
      }}
      onSubmit={async (data, callback) => {
        if(data.agree){
          try{
            const res = await http.post(`/npwp/cert-registration`, data)
            callback();
          }catch(error){
            callback(error)
          }
        }else{
          callback(new Error(t.translate('modules.certificate.form.notAgreeAlert')))
        }
      }}
    />
  )
}

export default inject('authStore', 'envStore')(observer(Form))
