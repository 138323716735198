import React from 'react'
import { inject, observer } from 'mobx-react'
import { DropdownMenu, FontIcon, MenuItem } from 'react-md'

const LocaleSwitcher = (props) => {
  const { locale } = props.envStore;
  return locale.options.length > 0 ? (
    <div className="mpk-locale-switcher">
      <DropdownMenu
        id="mpk-locale-switcher-menu"
        items={locale.options.map(d => (
          <MenuItem
            key={`locale-switcher-${d.value}`}
            style={{width: 120}}
            onClick={() => props.envStore.setLocaleCode(d.value)}
          >
            <div className="mpk-flex align-center justify-between mpk-full width">
              <span>{d.label}</span>
              {d.value === locale.code && (
                <FontIcon iconClassName="mdi mdi-check"/>
              )}
            </div>
          </MenuItem>
        ))}
        disableDropdownIcon
      >
        <FontIcon iconClassName="mdi mdi-web"/>
        <span className="mpk-margin-S margin-left">{locale.code}</span>
      </DropdownMenu>
    </div>
  ) : null
}

export default inject('envStore')(observer(LocaleSwitcher));
