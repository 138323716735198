import React, {useState, useEffect} from 'react'
import { storage } from '../../../../services'
import { DataForm, LoaderInfo } from '../../../../libs/react-mpk/components'
import t from 'counterpart'
import LampI from './LampI'
import A from './Induk/A'

const skey = storage.keys.SPT_1771_LampII
const { inputTypes } = DataForm
const columns = {
    d13:"d13",
    d14:"d14",
    d15:"d15",
    d16:"d16",
    d23:"d23",
    d24:"d24",
    d25:"d25",
    d26:"d26",
    d33:"d33",
    d34:"d34",
    d35:"d35",
    d36:"d36",
    d43:"d43",
    d44:"d44",
    d45:"d45",
    d46:"d46",
    d53:"d53",
    d54:"d54",
    d55:"d55",
    d56:"d56",
    d63:"d63",
    d64:"d64",
    d65:"d65",
    d66:"d66",
    d73:"d73",
    d74:"d74",
    d75:"d75",
    d76:"d76",
    d83:"d83",
    d84:"d84",
    d85:"d85",
    d86:"d86",
    d93:"d93",
    d94:"d94",
    d95:"d95",
    d96:"d96",
    d103:"d103",
    d104:"d104",
    d105:"d105",
    d106:"d106",
    d113:"d113",
    d114:"d114",
    d115:"d115",
    d116:"d116",
    d123:"d123",
    d124:"d124",
    d125:"d125",
    d126:"d126",
    d133:"d133",
    d134:"d134",
    d135:"d135",
    d136:"d136",
    dJml3:"dJml3",
    dJml4:"dJml4",
    dJml5:"dJml5",
    dJml6:"dJml6"
}

const generate = async () => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })())

    return data
}

const recon = (
    data = storage.get(skey),
    submitting = true
) => {
    return new Promise(resolve => {
        data.dJml3 = 0
        data.dJml4 = 0
        data.dJml5 = 0
        data.dJml6 =    data.d16 + data.d26 + data.d36 +
                        data.d46 + data.d56 + data.d66 + data.d76 +
                        data.d86 + data.d96 + data.d106 + data.d116 +
                        data.d126 - data.d136

        for(let i = 1 ; i <= 13 ; i++){
            data[`d${i}6`] = Number(data[`d${i}3`]) + Number(data[`d${i}4`]) + Number(data[`d${i}5`])
            data.dJml3 += Number(data[`d${i}3`])*(i === 13 ? -1 : 1)
            data.dJml4 += Number(data[`d${i}4`])*(i === 13 ? -1 : 1)
            data.dJml5 += Number(data[`d${i}5`])*(i === 13 ? -1 : 1)
        }

        if(submitting){
            storage.update(skey, data)
            LampI.recon()
        }

        resolve(data)
    })
}

const LampII = ({
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)
    const [definitions, setDefinitions] = useState([])

    const handleUpdateData = async (data) => {
        data = await recon(data, false)
        return data
    }

    useEffect(async () => {
        getDefinitions()
        let defData = await recon()
        
        setDefaultData(defData)
        setTimeout(() => {
            setReady(true)
        })
    }, [])

    const getDefinitions = () => {
        let result = []
        for(let i = 1 ; i <= 13 ; i++){
            result.push({
                inputType: inputTypes.DIVIDER,
                label: getLabel(`__d${i}`, 'LampII'),
                key:`__d${i}`
            })
            for(let a=3 ; a <= 6 ; a++){
                let key = `d${i}${a}`
                result.push({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(`__group${a}`, 'LampII'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    theme: 'underline',
                    width: '25%',
                    className: A % 6 > 0  ? 'mpk-padding-S padding-right' : '',
                    allowNegative: [
                        'd13','d14','d15',
                        'd23','d24','d25',
                        'd33','d34','d35',
                        'd43','d44','d45',
                        'd53','d54','d45',
                        'd63','d64','d65',
                        'd73','d74','d75',
                        'd83','d84','d85',
                        'd93','d94','d95',
                        'd103','d104','d105',
                        'd113','d114','d115',
                        'd123','d124','d125',
                        'd133','d134','d135',
                    ].indexOf(key) < 0,
                    disabled:[
                        'd16','d26','d36',
                        'd46','d56','d66',
                        'd76','d86','d96',
                        'd106','d116','d126',
                        'd136'
                    ].indexOf(key) >= 0,
                    key
                })
            }
        }

        result.push({
            inputType: inputTypes.DIVIDER,
            label: 'Total',
        })

        for(let i = 3 ; i <= 6 ; i++){
            let key = `dJml${i}`
            result.push({
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: getLabel(key, 'LampII'),
                isNumericString:true,
                decimalSeparator: ',',
                thousandSeparator:'.',
                disabled: true,
                key
            })
        }

        setDefinitions(result)
    }

    return ready ? (
       <DataForm
            baseId="mod-submission-spt1771-LampII"
            className="mpk-margin-C mpk-animation slide-in"
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={definitions}
            style={{maxWidth: 1024}}
            // definitions={Object.keys(columns).map(key => ({
            //     inputType: inputTypes.INPUT_MASK_NUMBER,
            //     label: getLabel(key, 'LampII'),
            //     isNumericString:true,
            //     thousandSeparator: ',',
            //     allowNegative: [
            //         'd13','d14','d15',
            //         'd23','d24','d25',
            //         'd33','d34','d35',
            //         'd43','d44','d45',
            //         'd53','d54','d45',
            //         'd63','d64','d65',
            //         'd73','d74','d75',
            //         'd83','d84','d85',
            //         'd93','d94','d95',
            //         'd103','d104','d105',
            //         'd113','d114','d115',
            //         'd123','d124','d125',
            //         'd133','d134','d135',
            //     ].indexOf(key) < 0,
            //     readOnly:[
            //         'd16','d26','d36',
            //         'd46','d56','d66',
            //         'd76','d86','d96',
            //         'd106','d116','d126',
            //         'd136'
            //     ].indexOf(key) >= 0,
            //     key
            // }))}
            // onBeforeChange={(key, value) => {
            //     let __val = Number(value)
            //     return isNaN(__val) ? '0' : String(__val)
            // }}
            onChange={(data, key, value) => {
                return handleUpdateData(data, key, value)
            }}
            onSubmit={(data, callback) => {
                storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
                LampI.recon()
                props.onNextTab()
            }}
            submitLabel={t.translate('column.next')}
       />
    ) : <LoaderInfo>loading data...</LoaderInfo>
}

LampII.generate = generate
LampII.columns = columns

export default LampII
