import {themes, portals} from './constant';
import {merge} from 'lodash';

class Env {
    theme = themes.PAJAKKU;
    themes = themes;
    portal = portals[themes.PAJAKKU]
    localeFormat = 'en'

    mappingApps = {
        efiling: 'Sobat Lapor',
        ebilling: 'Sobat Setor',
        eppt: 'Sobat Hitung'
    }

    setBrand(theme, channelName) {
        this.theme = theme;
        this.portal = channelName ? merge(portals[theme], {channel: channelName}) : portals[theme];
    }

    setLocaleFormat(localeFormat){
        this.localeFormat = localeFormat
    }
}


let env = new Env();
export default env;