import React, { Component, useState } from "react";
import { TableWrapper, TabsWrapper } from "../../../libs/react-mpk/wrapper";
import moment from "moment";
import { inject, observer } from "mobx-react";
import t from "counterpart";
import { supportGet } from "../Submission.service";
import service from "./LogImport.service";
import LogImportDetail from "./LogImportDetail";

const baseId = "mod-log-import-list";
const statusTypes = TableWrapper.Status.types;

const LogImport = ({ isSupport = false, ...props }) => {
  const [showDetail, setShowDetail] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  var itemActions = [
    {
      label: "Detail",
      iconClassName: "mdi mdi-eye",
      onClick: (item) => {
        setSelectedItem(item);
        setShowDetail(true);
      },
    },
  ];

  return (
    <>
      <TableWrapper
        baseId={baseId}
        title={t.translate("modules.logImport.title")}
        className={`mpk-padding-N padding-left`}
        // hintMessage={t.translate("modules.logImport.hint.message")}
        hintIconClassName="mdi mdi-information"
        defaultData={[]}
        defaultSortBy="createdAt"
        defaultSort={TableWrapper.sorts.descending}
        // defaultQuery={{ column: "category" }}
        columns={[
          {
            label: t.translate("column.category"),
            key: "category",
            searchable: true,
            definition: {
              options: ["UPLOAD SERTEL", "UPLOAD PASSPHRASE", "UPLOAD PDF SPT"],
              inputType: TableWrapper.inputTypes.SELECT,
            },
            render: (item) => item.category,
          },
          {
            label: "Status",
            key: "status",
            searchable: true,
            definition: {
              options: [
                "MERGE FILE",
                "EXTRACT ZIP",
                "SUBMISSION PROCESS",
                "FINISH",
              ],
              inputType: TableWrapper.inputTypes.SELECT,
            },
            render: (item) => {
              return (
                <div>
                  <TableWrapper.Status
                    type={
                      item.status === "FINISH"
                        ? statusTypes.SUCCESS
                        : statusTypes.PROGRESS
                    }
                    // onClick={stat.onClick}
                  >
                    {item.status}
                  </TableWrapper.Status>
                </div>
              );
            },
          },
          // {
          //   label: t.translate("column.errorMessage"),
          //   key: "errorMessage",
          //   searchable: false,
          //   render: (item) =>
          //     item.errorMessage != null ? item.errorMessage : "-",
          // },
          {
            label: t.translate("column.totalData"),
            key: "totalData",
            searchable: false,
            render: (item) => (item.totalData != null ? item.totalData : 0),
          },
          {
            label: t.translate("column.totalDataProcess"),
            key: "totalDataValidating",
            searchable: false,
            render: (item) =>
              item.totalDataValidating != null
                ? item.totalDataValidating
                : item.totalDataUploading,
          },
          {
            label: t.translate("column.totalDataSuccess"),
            key: "totalDataSuccess",
            searchable: false,
            render: (item) =>
              item.totalDataSuccess != null ? item.totalDataSuccess : 0,
          },
          {
            label: t.translate("column.totalDataError"),
            key: "totalDataError",
            searchable: false,
            render: (item) =>
              item.totalDataError != null ? item.totalDataError : 0,
          },
          {
            label: t.translate("column.startAt"),
            key: "startAt",
            searchable: false,
            type: TableWrapper.dataTypes.DATE,
            render: (item) => moment(item.startAt).format("lll"),
          },
          {
            label: t.translate("column.endAt"),
            key: "endAt",
            searchable: false,
            type: TableWrapper.dataTypes.DATE,
            render: (item) =>
              item.endAt ? moment(item.endAt).format("lll") : "-",
          },
          {
            label: t.translate("column.createdAt"),
            key: "createdAt",
            searchable: false,
            type: TableWrapper.dataTypes.DATE,
            render: (item) => moment(item.createdAt).format("lll"),
          },
          {
            label: t.translate("column.createdBy"),
            key: "createdBy",
            searchable: isSupport,
            render: (item) => item.createdBy,
          },
        ]}
        itemActions={itemActions}
        onFetchData={async (query) => {
          return new Promise(async (resolve, reject) => {
            try {
              let res = await service.get(query);
              resolve(res);
            } catch (error) {
              reject(error);
            }
          });
          return service.get(query);
        }}
        useFilter={true}
        useCriteria={false}
        showCommandbar={true}
        showFilterPeriod={false}
        defaultSize={20}
        showActionColumn={true}
      />
      <LogImportDetail
        visible={showDetail}
        item={selectedItem}
        onRequestClose={() => {
          setShowDetail(false);
        }}
        onError={(errorData) => {
          setShowDetail(false);
        }}
        onBack={() => {
          setShowDetail(false);
        }}
      />
    </>
  );
};

export default inject("envStore", "modalStore")(observer(LogImport));
