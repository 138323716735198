import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const skey = storage.keys.SPT_1771_LampKhus3A2II

const { inputTypes } = DataForm

const columns = {
    TTP_NILAI: 'ttpNilai'
}

const generate = async () => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })())

    return data
}

const LampKhus3A2II = ({
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)
    const [definitions, setDefinitions] = useState([])

    const handleUpdateData = (data, key) => {
        return data
    }

    useEffect(async () => {
        let __data = await storage.get(skey)
        let defData = handleUpdateData(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-LampKhus3A2II"
            className="mpk-margin-C mpk-animation slide-in"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            transparent={true}
            definitions={Object.keys(columns).map(key=>({
                inputType: inputTypes.CHECKBOX,
                label: getLabel(key, 'LampKhus3A2II'),
                key
            }))}
            onBeforeChange={(key, value) => (value ? 1 : 0)}
            onSubmit={(data, callback) => {
                callback(t.translate('sentence.savedItem'), false)
                storage.update(skey, data)
                props.onNextTab()
            }}
            submitLabel={t.translate('column.next')}
        />
    ) : <LoaderInfo>Loading data...</LoaderInfo>
}

LampKhus3A2II.generate = generate
LampKhus3A2II.columns = columns

export default LampKhus3A2II
