import React, {useState, useEffect} from 'react'
import { storage } from '../../../../services'
import { DataForm } from '../../../../libs/react-mpk/components'
import t from 'counterpart'
import Induk from './Induk'
import LampKhus6A from './LampKhus6A'
import { inject, observer } from 'mobx-react'
import HeaderLampKhus2A from './LampKhus2A/HeaderLampKhus2A'

const skey = storage.keys.SPT_1771_LampI
const { inputTypes } = DataForm
const columns = {
    d1A: "d1A",
    d1B: "d1B",
    d1C: "d1C",
    d1D: "d1D",
    d1E: "d1E",
    d1F: "d1F",
    d1G: "d1G",
    d1H: "d1H",
    d2: "d2",
    d3: "d3",
    d4: "d4",
    d5a: "d5a",
    d5b: "d5b",
    d5c: "d5c",
    d5d: "d5d",
    d5e: "d5e",
    d5f: "d5f",
    d5g: "d5g",
    d5h: "d5h",
    d5i: "d5i",
    d5j: "d5j",
    d5k: "d5k",
    d5l: "d5l",
    d5m: "d5m",
    d6a: "d6a",
    d6b: "d6b",
    d6c: "d6c",
    d6d: "d6d",
    d6e: "d6e",
    d7a: {
        key:"d7a",
        value: d => (Number(d))
    },
    d7b: "d7b",
    d8: "d8",
}

const generate = async () => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })())

    return data
}

const recon = (
    data = storage.get(skey),
    lampKhus4A = storage.get(storage.keys.SPT_1771_LampKhus4A),
    headerLampKhus1A = storage.get(storage.keys.SPT_1771_HeaderLampKhus1A),
    headerLampKhus7A = storage.get(storage.keys.SPT_1771_HeaderLampKhus7A),
    lampII = storage.get(storage.keys.SPT_1771_LampII),
    submitting = true
) => {
    if(lampKhus4A && headerLampKhus1A && headerLampKhus7A && lampII){
        data.d1B = lampII.dJml3
        data.d1C = lampII.dJml4
        data.d1D = data.d1A - data.d1B - data.d1C
        data.d1G = data.d1E - data.d1F
        data.d1F = lampII.dJml5
        data.d1H = data.d1D + data.d1G
        data.d2 = headerLampKhus7A.JmlNettoRp
        data.d3 = data.d1H + data.d2
        data.d5i = headerLampKhus1A.JmlSelSut < 0 
            ? Math.abs(headerLampKhus1A.JmlSelSut)
            : 0;
        data.d5j = headerLampKhus1A.JmlSelAm < 0 
            ? Math.abs(headerLampKhus1A.JmlSelAm)
            : 0;
        data.d5m =  data.d5a + data.d5b + data.d5c + data.d5d + data.d5e + data.d5f + data.d5g + data.d5h + data.d5i + data.d5j + data.d5k + data.d5l;
        data.d6a = headerLampKhus1A.JmlSelSut > 0 
            ? headerLampKhus1A.JmlSelSut
            : 0;
        data.d6b = headerLampKhus1A.JmlSelAm > 0 
            ? headerLampKhus1A.JmlSelAm
            : 0;
        data.d6e = data.d6a + data.d6b + data.d6c + data.d6d
        data.d7a = lampKhus4A.ThNet
        data.d7b = lampKhus4A.JmlNet
        data.d8 = (data.d3 - data.d4) + (data.d5m - data.d6e - data.d7b)

        // let selSut = headerLampKhus1A.JmlSutFis - headerLampKhus1A.JmlSutKom
        
        if(submitting) {
            storage.update(skey, data)
            HeaderLampKhus2A.recon()
            Induk.recon()
        }
    }
    
    return data
}

const LampI = ({
    getLabel,
    ...props
}) => {
    const lampKhus4A = storage.get(storage.keys.SPT_1771_LampKhus4A)
    const headerLampKhus1A = storage.get(storage.keys.SPT_1771_HeaderLampKhus1A)
    const headerLampKhus7A = storage.get(storage.keys.SPT_1771_HeaderLampKhus7A)
    const lampII = storage.get(storage.keys.SPT_1771_LampII)
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    const handleUpdateData = (data) => {
        data = recon(data, lampKhus4A, headerLampKhus1A, headerLampKhus7A, lampII, false)
        return data
    }

    useEffect(() => {
        let __data = storage.get(skey)
        let defData = handleUpdateData(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return ready && (
       <DataForm
            baseId="mod-submission-spt1771-lampI"
            className="mpk-margin-C"
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                {
                    inputType: inputTypes.DIVIDER,
                    label: getLabel('__subTitleD1', 'LampI')
                },
                ...[
                    'd1A',
                    'd1B',
                    'd1C',
                    'd1D',
                    'd1E',
                    'd1F',
                    'd1G',
                    'd1H',
                    'd2',
                    'd3',
                    'd4',
                ].map(key => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'LampI'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    allowNegative: [
                        'd1A','d1B','d1C','d1E','d1F','d4',
                    ].indexOf(key) < 0,
                    disabled:[
                        'd1B','d1C','d1D','d1F','d1G','d1H','d2','d3'
                    ].indexOf(key) >= 0,
                    key
                })),
                {
                    inputType: inputTypes.DIVIDER,
                    label: getLabel('__subTitleD5', 'LampI')
                },
                ...[
                    'd5a',
                    'd5b',
                    'd5c',
                    'd5d',
                    'd5e',
                    'd5f',
                    'd5g',
                    'd5h',
                    'd5i',
                    'd5j',
                    'd5k',
                    'd5l',
                    'd5m',
                ].map(key => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'LampI'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    allowNegative: [
                        'd5a','d5b','d5c','d5d','d5e','d5f','d5g','d5h','d5k','d5l',
                    ].indexOf(key) < 0,
                    disabled:[
                        'd5i','d5j','d5m',
                    ].indexOf(key) >= 0,
                    key
                })),
                {
                    inputType: inputTypes.DIVIDER,
                    label: getLabel('__subTitleD6', 'LampI')
                },
                ...[
                    'd6a',
                    'd6b',
                    'd6c',
                    'd6d',
                    'd6e',
                    'd7a',
                    'd7b',
                    'd8'
                ].map(key => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'LampI'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    allowNegative: [
                        'd6c','d6d'
                    ].indexOf(key) < 0,
                    disabled:[
                        'd6a','d6b','d6e','d7a','d7b','d8'
                    ].indexOf(key) >= 0,
                    // show: key === 'd7a' && lampKhus4A.Chkd1 ? true : false,
                    key
                }))
            ]}
            onChange={(data, key, value) => {
                return handleUpdateData(data, key, value)
            }}
            onSubmit={async (data, callback) => {
                await recon(data)
                await LampKhus6A.recon(props.temporaryStore.properties.isBut)
                callback(t.translate('sentence.savedItem'), false)
                props.onNextTab()
                
            }}
            submitLabel={t.translate('column.next')}
       />
    )
}

LampI.generate = generate
LampI.columns = columns
LampI.recon = recon

export default inject('temporaryStore')(observer(LampI))
