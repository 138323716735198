import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Neraca5
const columns = {
    ID253: 'id253',
    ID254: 'id254',
    ID255: 'id255',
    ID256: 'id256',
    ID257: 'id257',
    ID258: 'id258',
    ID259: 'id259',
    ID260: 'id260',
    ID261: 'id261',
    ID262: 'id262',
    ID263: 'id263',
    ID264: 'id264',
    ID265: 'id265',
    ID266: 'id266',
    ID267: 'id267',
    ID268: 'id268',
    ID269: 'id269',
    ID270: 'id270',
    ID271: 'id271',
    ID272: 'id272',
    ID273: 'id273',
    ID274: 'id274',
    ID275: 'id275',
    ID276: 'id276',
    ID277: 'id277',
    ID278: 'id278',
    ID279: 'id279',
    ID280: 'id280',
    ID281: 'id281',
    ID282: 'id282',
    ID283: 'id283',
    ID284: 'id284',
    ID285: 'id285',
    ID286: 'id286',
    ID287: 'id287',
    ID288: 'id288',
    ID289: 'id289',
    ID290: 'id290',
    ID291: 'id291',
    ID292: 'id292',
    ID293: 'id293',
    ID294: 'id294',
}

const recon = (
    data = storage.get(skey),
    submitting=true
) => {
    return new Promise(async (resolve) => {
        data.ID273 =    data.ID253 + data.ID254 + data.ID255 + data.ID256 +
                    data.ID257 + data.ID258 + data.ID259 + data.ID260 + data.ID261 +
                    data.ID262 + data.ID263 + data.ID264 + data.ID265 + data.ID266 +
                    data.ID267 + data.ID268 - data.ID269 + data.ID270 + data.ID271 +
                    data.ID272

        data.ID294 =    data.ID274 + data.ID275 + data.ID276 + data.ID277 +
                    data.ID278 + data.ID279 + data.ID280 + data.ID281 + data.ID282 +
                    data.ID283 + data.ID284 + data.ID285 + data.ID286 + data.ID287 +
                    data.ID288 + data.ID289 + data.ID290 + data.ID291 + data.ID292 +
                    data.ID293

        if(submitting) {
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async (isReset) => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })(), isReset)

    return data
}

const Neraca5Aktiva = ({
    getLabel,
    asKewajiban,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-Neraca5"
            className="mpk-margin-C mpk-animation slide-in"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: getLabel('__aktiva', 'Neraca5')
                // },
                ...(asKewajiban ? [] : [
                    'ID253',
                    'ID254',
                    'ID255',
                    'ID256',
                    'ID257',
                    'ID258',
                    'ID259',
                    'ID260',
                    'ID261',
                    'ID262',
                    'ID263',
                    'ID264',
                    'ID265',
                    'ID266',
                    'ID267',
                    'ID268',
                    'ID269',
                    'ID270',
                    'ID271',
                    'ID272',
                    'ID273',
                ]).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Neraca5'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    isNumericString: true,
                    allowNegative: false,
                    width: key != 'ID273' ? '50%' : '100%',
                    className: i%2 && key != 'ID273' ? '' : 'mpk-padding-S padding-right',
                    disabled: key === 'ID273',
                    key
                })),
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: getLabel('__kewajibanDanEkuitas', 'Neraca5')
                // },
                ...(asKewajiban ? [
                    'ID274',
                    'ID275',
                    'ID276',
                    'ID277',
                    'ID278',
                    'ID279',
                    'ID280',
                    'ID281',
                    'ID282',
                    'ID283',
                    'ID284',
                    'ID285',
                    'ID286',
                    'ID287',
                    'ID288',
                    'ID289',
                    'ID290',
                    'ID291',
                    'ID292',
                    'ID293',
                    'ID294',
                ] : []).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Neraca5'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    allowNegative: ['ID291','ID292','ID293'].indexOf(key) >= 0,
                    width: ['ID274','ID275','ID280','ID281','ID286','ID287','ID294'].indexOf(key) < 0 ? '50%' : '100%',
                    className: i%2 && ['ID274','ID275','ID280','ID281','ID286','ID287','ID294'].indexOf(key) < 0  ? '' : 'mpk-padding-S padding-right',
                    disabled: key === 'ID294',
                    key
                }))
            ]}
            onChange={async data => {
                data = await recon(data, false)
                return data
            }}
            onSubmit={async (data, callback) => {
                await storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    ) : <LoaderInfo/>
}

Neraca5Aktiva.generate = generate
Neraca5Aktiva.columns = columns
Neraca5Aktiva.recon = recon

export default Neraca5Aktiva
