import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Labarugi9
const columns = {
    ID517: 'id517',
    ID518: 'id518',
    ID519: 'id519',
    ID520: 'id520',
    ID521: 'id521',
    ID522: 'id522',
    ID523: 'id523',
    ID524: 'id524',
    ID525: 'id525',
    ID526: 'id526',
    ID527: 'id527',
    ID528: 'id528',
    ID529: 'id529',
    ID530: 'id530',
    ID531: 'id531',
    ID532: 'id532',
    ID533: 'id533',
    ID534: 'id534',
    ID535: 'id535',
    ID536: 'id536',
    ID537: 'id537',
    ID538: 'id538',
    ID539: 'id539',
    ID540: 'id540',
    ID541: 'id541'
}

const recon = (
    data = storage.get(skey),
    submitting=true
) => {
    return new Promise(async (resolve) => {
        data.ID520 = data.ID517 + data.ID518 + data.ID519
        data.ID523 = data.ID520 + data.ID521 + data.ID522
        data.ID532 = data.ID524 + data.ID525 + data.ID526 + data.ID527 + data.ID528 + data.ID529 + data.ID530 + data.ID531
        data.ID533 = data.ID523 - data.ID532
        data.ID535 = data.ID533 + data.ID534
        data.ID537 = data.ID535 - data.ID536
        data.ID539 = data.ID537 + data.ID538
        data.ID541 = data.ID539 - data.ID540

         if(submitting) {   
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async (isReset) => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })(), isReset)

    return data
}

const Labarugi9 = ({
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-Labarugi9"
            className="mpk-margin-C mpk-animation slide-in"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={Object.keys(columns).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Labarugi9'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    isNumericString: true,
                    width: [
                        'ID519',
                        'ID520',
                        'ID523',
                        'ID526',
                        'ID531',
                        'ID532',
                        'ID533',
                        'ID534',
                        'ID535',
                        'ID536',
                        'ID537',
                        'ID538',
                        'ID539',
                        'ID540',
                        'ID541',
                    ].indexOf(key) < 0 ? '50%' : '100%',
                    className: [
                        'ID517',
                        'ID521',
                        'ID524',
                        'ID527',
                        'ID529'
                    ].indexOf(key) >= 0  ? 'mpk-padding-S padding-right' : '',
                    disabled: [
                        'ID520',
                        'ID523',
                        'ID532',
                        'ID533',
                        'ID535',
                        'ID537',
                        'ID539',
                        'ID541',
                    ].indexOf(key) >= 0,
                    key
                }))
            }
            onChange={async data => {
                data = await recon(data, false)
                return data
            }}
            onSubmit={async (data, callback) => {
                await storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    ) : <LoaderInfo/>
}

Labarugi9.generate = generate
Labarugi9.columns = columns
Labarugi9.recon = recon

export default Labarugi9
