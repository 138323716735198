import React, {useState, useEffect} from 'react'
import t from 'counterpart'
import { DataForm, LoaderInfo } from '../../../../libs/react-mpk/components'
import { toast } from '../../../../libs/react-mpk/services'
import service, { parseFilename, supportGet } from '../../Submission.service'
import { options } from '../../../../services'
import { inject, observer } from 'mobx-react'

const { inputTypes } = DataForm
const Migration = ({
    visible,
    onRequestClose,
    onSubmit,
    submissionName,
    ...props
}) => {
    const { submission } = props.temporaryStore.properties
    const [submissionListData, setSubmissionListData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const fetchSubmission = async () => {
        try {
            setIsLoading(true)
            let meta = parseFilename(submission.fileName)
            const query = {page: 1, size: 100, keyword: meta.npwp, column: 'fileName'}
            let res = await (props.authStore.user.isSupport ? supportGet(query) : service.get(query))
            setSubmissionListData(res.data.filter(d => {
                let __meta = parseFilename(d.fileName)
                return d.fileName !== submission.fileName && meta.taxYear === __meta.taxYear && meta.npwp === __meta.npwp
            }))
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            toast.errorRequest(error)
        }
    }

    useEffect(() => {
        fetchSubmission()
    }, [])

    return (
        <DataForm
            baseId="dialog-migration"
            title={t.translate('column.migrate')}
            asDialog={true}
            visible={visible}
            onRequestClose={onRequestClose}
            onSubmit={onSubmit}
            style={{minWidth: 320}}
            defaultData={{

            }}
            definitions={[
                {
                    render: <LoaderInfo/>,
                    show: isLoading
                },
                {
                    inputType: inputTypes.SELECT,
                    label: t.translate('column.submission'),
                    key: 'fileName',
                    required: true,
                    options: submissionListData.map(d => {
                        let __meta = parseFilename(d.fileName)
                        return {
                            value: d.fileName,
                            label: (
                                <div>
                                    <div>{`${__meta.taxYear} - ${t.translate('column.pembetulan')} ${__meta.pembetulan}`}</div>

                                </div>
                            )
                        }
                    }),
                    placeholder: t.translate('sentence.selectSubmissionMigration'),
                    show: !isLoading
                }       
            ]}
            {...props}
        />
    )
};

export default inject('temporaryStore', 'authStore')(observer(Migration));
