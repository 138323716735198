import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Dialog, Password } from "react-md";
import Logo from "../Logo/Logo";
import { http, toast } from "../../services";
import { inject, observer } from "mobx-react";
import "./PasswordExpiration.scss";

const SkipResetPassword = true;
const PasswordExpiration = ({ visible, onFinish, ...props }) => {
  const textInputVerifyPassword = useRef(null);
  const textInputPassword = useRef(null);
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const access_token = props.authStore.getAccessToken();
  const { host } = props.envStore.env.sso;

  useEffect(() => {
    if (visible) {
      if (textInputPassword.current) {
        textInputPassword.current.focus();
      }
    }
  }, [visible]);

  const handleRenewPassword = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const payload = {
        password,
        verifyPassword,
      };

      await http.post(
        `${host}/auth/account/update_expired_password`,
        payload,
        {
          withCredentials: true,
          headers: {
            authorization: `Bearer ${access_token}`,
          },
        },
        {
          useDefaultHost: false,
          useDefaultBaseUrl: false,
        }
      );
      props.authStore.setPasswordExpired('DONE');
      toast.success("Berhasil Perbaharui Password!");
      onFinish();
    } catch (error) {
      toast.errorRequest(error);
    }
  };
  const handleSkipPassword = async () => {
    onFinish();
  };

  return (
    <Dialog
      id="password-expiration-dialog"
      visible={visible}
      type="full-page"
      aria-labelledby="simple-full-page-dialog-title"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Logo
          logoStyle={{
            height: 55,
          }}
        />
        <Card
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "1.5em",
            maxWidth: 500,
            marginTop: "1.5em",
          }}
        >
          <div>
            <div
              style={{ fontSize: "24px", alignSelf: "center", margin: "1em 0" }}
            >
              Perbaharui Password (Password Expiration)
            </div>
          </div>
          <p>{"Silahkan masukkan password baru & konfirmasi password"}</p>
          <form
            id="password-expiration-form"
            onSubmit={handleRenewPassword}
            autocomplete={false}
          >
            <Password
              id="application-form-password"
              label="Password"
              style={{ margin: "1em 0" }}
              ref={textInputPassword}
              value={password}
              onChange={(text) => {
                setPassword(text.target.value);
              }}
              required
              theme="underline"
            />
            <Password
              id="application-form-verify-password"
              label="Confirm Password"
              style={{ marginBottom: "1em" }}
              ref={textInputVerifyPassword}
              value={verifyPassword}
              onChange={(text) => {
                setVerifyPassword(text.target.value);
              }}
              required
              theme="underline"
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: "2em",
              }}
            >
              {!SkipResetPassword &&
                <Button
                  themeType="flat"
                  onClick={handleSkipPassword}
                  style={{ fontSize: "12px", marginRight: "0.5em" }}
                >
                  Lewati Tahap Ini
                </Button>
              }
              <Button
                id="password-expiration-form"
                theme="primary"
                themeType="contained"
                type="submit"
              >
                Perbaharui Password
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </Dialog>
  );
};

export default inject(
  "modalStore",
  "authStore",
  "envStore"
)(observer(PasswordExpiration));
