import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Labarugi7
const columns = {
    ID407: 'id407',
    ID408: 'id408',
    ID409: 'id409',
    ID410: 'id410',
    ID411: 'id411',
    ID412: 'id412',
    ID413: 'id413',
    ID414: 'id414',
    ID415: 'id415',
    ID416: 'id416',
    ID417: 'id417',
    ID418: 'id418',
    ID419: 'id419',
    ID420: 'id420',
    ID421: 'id421',
    ID422: 'id422'
}

const recon = (
    data = storage.get(skey),
    submitting=true
) => {
    return new Promise(async (resolve) => {
        data.ID409 = data.ID407 - data.ID408
        data.ID412 = data.ID409 + data.ID410 - data.ID411
        data.ID416 = data.ID412 + data.ID413 - data.ID414 + data.ID415 
        data.ID418 = data.ID416 - data.ID417
        data.ID420 = data.ID418 - data.ID419
        data.ID422 = data.ID420 - data.ID421
        
         if(submitting) {   
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async (isReset) => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })(), isReset)

    return data
}

const Labarugi7 = ({
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-Labarugi7"
            className="mpk-margin-C mpk-animation slide-in"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={Object.keys(columns).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Labarugi7'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    isNumericString: true,
                    width: [
                        'ID409',
                        'ID412',
                        'ID415',
                        'ID416',
                        'ID417',
                        'ID418',
                        'ID419',
                        'ID420',
                        'ID421',
                        'ID422'
                    ].indexOf(key) < 0 ? '50%' : '100%',
                    className: [
                        'ID407',
                        'ID410',
                        'ID413'
                    ].indexOf(key) >= 0  ? 'mpk-padding-S padding-right' : '',
                    disabled: [
                        'ID409',
                        'ID412',
                        'ID416',
                        'ID418',
                        'ID420',
                        'ID422'
                    ].indexOf(key) >= 0,
                    key
                }))
            }
            onChange={async data => {
                data = await recon(data, false)
                return data
            }}
            onSubmit={async (data, callback) => {
                await storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    ) : <LoaderInfo/>
}

Labarugi7.generate = generate
Labarugi7.columns = columns
Labarugi7.recon = recon

export default Labarugi7
