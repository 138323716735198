import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Labarugi15
const columns = {
    ID858: 'id858',
    ID859: 'id859',
    ID860: 'id860',
    ID861: 'id861',
    ID862: 'id862',
    ID863: 'id863',
    ID864: 'id864',
    ID865: 'id865',
    ID866: 'id866',
    ID867: 'id867',
    ID868: 'id868',
    ID869: 'id869',
    ID870: 'id870',
    ID871: 'id871',
    ID872: 'id872',
    ID873: 'id873',
    ID874: 'id874',
    ID875: 'id875'
}

const recon = (
    data = storage.get(skey),
    submitting=true
) => {
    return new Promise(async (resolve) => {
        data.ID863 = data.ID858 + data.ID859 + data.ID860 + data.ID861 + data.ID862
        data.ID872 = data.ID864 + data.ID865 + data.ID866 + data.ID867 + data.ID868 + data.ID869 + data.ID870 + data.ID871
        data.ID875 = data.ID863 - data.ID872 + data.ID873 - data.ID874

         if(submitting) {   
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async (isReset) => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })(), isReset)

    return data
}

const Labarugi15 = ({
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-Labarugi15"
            className="mpk-margin-C mpk-animation slide-in"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={Object.keys(columns).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Labarugi15'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    isNumericString: true,
                    width: [
                        'ID858',
                        'ID859',
                        'ID860',
                        'ID861',
                        'ID864',
                        'ID865',
                        'ID866',
                        'ID867',
                        'ID868',
                        'ID869',
                        'ID870',
                        'ID871',
                        'ID873',
                        'ID874',
                    ].indexOf(key) >= 0 ? '50%' : '100%',
                    className: [
                        'ID858',
                        'ID860',
                        'ID864',
                        'ID866',
                        'ID868',
                        'ID870',
                        'ID873',
                    ].indexOf(key) >= 0  ? 'mpk-padding-S padding-right' : '',
                    disabled: [
                        'ID863',
                        'ID872',
                        'ID875'
                    ].indexOf(key) >= 0,
                    key
                }))
            }
            onChange={async data => {
                data = await recon(data, false)
                return data
            }}
            onSubmit={async (data, callback) => {
                await storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    ) : <LoaderInfo/>
}

Labarugi15.generate = generate
Labarugi15.columns = columns
Labarugi15.recon = recon

export default Labarugi15
