import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Neraca7
const columns = {
    ID371: 'id371',
    ID372: 'id372',
    ID373: 'id373',
    ID374: 'id374',
    ID375: 'id375',
    ID376: 'id376',
    ID377: 'id377',
    ID378: 'id378',
    ID379: 'id379',
    ID380: 'id380',
    ID381: 'id381',
    ID382: 'id382',
    ID383: 'id383',
    ID384: 'id384',
    ID385: 'id385',
    ID386: 'id386',
    ID387: 'id387',
    ID388: 'id388',
    ID389: 'id389',
    ID390: 'id390',
    ID391: 'id391',
    ID392: 'id392',
    ID393: 'id393',
    ID394: 'id394',
    ID395: 'id395',
    ID396: 'id396',
    ID397: 'id397',
    ID398: 'id398',
    ID399: 'id399',
    ID400: 'id400',
    ID401: 'id401',
    ID402: 'id402',
    ID403: 'id403',
    ID404: 'id404',
    ID405: 'id405',
    ID406: 'id406'
}

const recon = (
    data = storage.get(skey),
    submitting=true
) => {
    return new Promise(async (resolve) => {
        data.ID388 =    data.ID371 + data.ID372 + data.ID373 + data.ID374 +
                        data.ID375 + data.ID376 + data.ID377 + data.ID378 + data.ID379 +
                        data.ID380 + data.ID381 + data.ID382 + data.ID383 - data.ID384 +
                        data.ID385 + data.ID386 + data.ID387

        data.ID406 =    data.ID389 + data.ID390 + data.ID391 + data.ID392 +
                        data.ID393 + data.ID394 + data.ID395 + data.ID396 + data.ID397 +
                        data.ID398 + data.ID399 + data.ID400 + data.ID401 + data.ID402 +
                        data.ID403 + data.ID404 + data.ID405

        if(submitting) {
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async (isReset) => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })(), isReset)

    return data
}

const Neraca7 = ({
    getLabel,
    asKewajiban,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-Neraca7"
            className="mpk-margin-C mpk-animation slide-in"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: getLabel('__aktiva', 'Neraca7')
                // },
                ...(asKewajiban ? [] : [
                    'ID371',
                    'ID372',
                    'ID373',
                    'ID374',
                    'ID375',
                    'ID376',
                    'ID377',
                    'ID378',
                    'ID379',
                    'ID380',
                    'ID381',
                    'ID382',
                    'ID383',
                    'ID384',
                    'ID385',
                    'ID386',
                    'ID387',
                    'ID388'
                ]).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Neraca7'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    isNumericString: true,
                    allowNegative: false,
                    width: ['ID387', 'ID388'].indexOf(key) < 0 ? '50%' : '100%',
                    className: [
                        'ID371',
                        'ID373',
                        'ID375',
                        'ID377',
                        'ID379',
                        'ID381',
                        'ID383',
                        'ID385'
                    ].indexOf(key) >= 0 ? 'mpk-padding-S padding-right' : '',
                    disabled: key === 'ID388',
                    key
                })),
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: getLabel('__kewajibanDanEkuitas', 'Neraca7')
                // },
                ...(asKewajiban ? [
                    'ID389',
                    'ID390',
                    'ID391',
                    'ID392',
                    'ID393',
                    'ID394',
                    'ID395',
                    'ID396',
                    'ID397',
                    'ID398',
                    'ID399',
                    'ID400',
                    'ID401',
                    'ID402',
                    'ID403',
                    'ID404',
                    'ID405',
                    'ID406'
                ] : []).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Neraca7'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    isNumericString: true,
                    allowNegative: ['ID403','ID404','ID405'].indexOf(key) >= 0,
                    width: [
                        'ID399',
                        'ID400',
                        'ID405',
                        'ID406'
                    ].indexOf(key) >= 0 ? '100%' : '50%',
                    className: [
                        'ID389',
                        'ID391',
                        'ID393',
                        'ID395',
                        'ID397',
                        'ID401',
                        'ID403',
                    ].indexOf(key) >= 0 ? 'mpk-padding-S padding-right' : '',
                    disabled: key === 'ID406',
                    key
                }))
            ]}
            onChange={async data => {
                data = await recon(data, false)
                return data
            }}
            onSubmit={async (data, callback) => {
                await storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    ) : <LoaderInfo/>
}

Neraca7.generate = generate
Neraca7.columns = columns
Neraca7.recon = recon

export default Neraca7
