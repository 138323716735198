import { http } from "../../libs/react-mpk/services";

export const getAggrement = () => {
  return http.get(
    `/static/legal/cert-registration-aggreement.html`,
    {},
    {},
    { useDefaultBaseUrl: false, useDefaultHost: false }
  );
};

export const getUploadAggrement = () => {
  return http.get(
    `/static/legal/cert-upload-aggreement.html`,
    {},
    {},
    { useDefaultBaseUrl: false, useDefaultHost: false }
  );
};

export const register = () => {
  return http.post();
};

export const upload = (data) => {
  return http.upload(`/npwp/cert-upload/1771`, data.file, {
    password: data.password,
  });
};

export const uploadCertificateBulk = (name, file, data) => {
  return http.upload(`/npwp/cert-registration/cert_zip/upload`, file, data);
};

export const uploadPassBulk = (name, file, data) => {
  return http.upload(`/npwp/cert-registration/pass_zip/upload`, file, data);
};

export const postCommitZip = (data) => {
  return http.post(`/npwp/cert-registration/commit_zip`, data);
};
