import moment from "moment";
import React, { useState, useEffect } from "react";
import { ExpansionList, ExpansionPanel, usePanels } from "react-md";
import {
  DataForm,
  Hint,
  LoaderInfo,
} from "../../../../../libs/react-mpk/components";
import { storage } from "../../../../../services";
import DetailSSP from "../SSP/DetailSSP";
import A from "./A";
import Akuntan from "./Akuntan";
import B from "./B";
import C from "./C";
import Checklist from "./Checklist";
import D from "./D";
import E from "./E";
import F from "./F";
import Footer from "./Footer";
import Konsultan from "./Konsultan";

const skey = storage.keys.SPT_1771_Induk;

const recon = (
  data = storage.get(skey),
  Profile = storage.get(storage.keys.SPT_1771_Profile),
  LampI = storage.get(storage.keys.SPT_1771_LampI),
  HeaderLampIII = storage.get(storage.keys.SPT_1771_HeaderLampIII),
  HeaderLampIV = storage.get(storage.keys.SPT_1771_HeaderLampIV),
  HeaderLampKhus2A = storage.get(storage.keys.SPT_1771_HeaderLampKhus2A),
  HeaderLampKhus7A = storage.get(storage.keys.SPT_1771_HeaderLampKhus7A),
  submitting = true
) => {
  return new Promise(async (resolve, reject) => {
    
    data = await A.recon(data, LampI, HeaderLampKhus2A);
    data = await B.recon(data, Profile, LampI);
    data = await C.recon(data, HeaderLampIII, HeaderLampKhus7A);
    data = await D.recon(data);
    data = await E.recon(data);
    data = await F.recon(data, HeaderLampIV);
    data.FlgTtdSPT = Profile.FlgTtdSPT;
    if (submitting) {
      storage.update(skey, data);
    }

    resolve(data);
  });
};

const columns = {
  FlgAudit: {
    key: "flgAudit",
    reverseValue: (d) => String(d),
  },
  OpAkt: "opAkt",
  NmKAP: "nmKAP",
  NPWPKAP: "npwpKAP",
  NmAP: "nmAP",
  NPWPAP: "npwpAP",
  NmKKP: "nmKKP",
  NPWPKKP: {
    key: "npwpKKP",
    reverseValue: (d) => {
      return d;
    },
  },
  NmKP: "nmKP",
  NPWPKP: "npwpKP",
  dA1: "dA1",
  dA2: "dA2",
  dA3: "dA3",
  optB4: {
    key: "optB4",
    reverseValue: (d) => String(d),
  },
  dB4: "dB4",
  dB5: "dB5",
  dB6: "dB6",
  dC7: "dC7",
  dC8a: "dC8a",
  dC8b: "dC8b",
  dJmlC8: "dJmlC8",
  optC9: "optC9",
  dJmlC9: "dJmlC9",
  dC10a: "dC10a",
  dC10b: "dC10b",
  dC10c: "dC10c",
  dJmlC10: "dJmlC10",
  FlgSptPPh: "flgSptPPh",
  dD11: "dD11",
  TglLunas: "tglLunas",
  OptD13: {
    key: "optD13",
    reverseValue: (d) => String(d),
  },
  OptD13a: {
    key: "optD13a",
    value: (d) => (d ? 1 : 0),
    reverseValue: (d) => (d === 1 ? true : false),
  },
  dE14a: "dE14a",
  dE14b: "dE14b",
  dE14c: "dE14c",
  dE14d: "dE14d",
  dE14e: "dE14e",
  dE14f: "dE14f",
  dE14g: "dE14g",
  dF15a: "dF15a",
  dF15b: "dF15b",
  OptD16: "optD16",
  chkH17a: "chkH17a",
  chkH17b: "chkH17b",
  chkH17c: "chkH17c",
  chkH17d: "chkH17d",
  chkH17e: "chkH17e",
  chkH17f: "chkH17f",
  chkH17g: "chkH17g",
  chkH17h: "chkH17h",
  chkH17i: "chkH17i",
  chkH17j: "chkH17j",
  chkH17k: "chkH17k",
  chkH17l: "chkH17l",
  chkH17m: "chkH17m",
  chkH17n: "chkH17n",
  vHl: "vHl",
  vHm: "vHm",
  vHn: "vHn",
  FlgTtdSPT: {
    key: "flgTtdSPT",
    value: (d) => Number(d),
    reverseValue: (d) => String(d),
  },
  TglLaporSPT: "tglLaporSPT",
  hitungSendiri: {
    key: "hitungSendiri",
    reverseValue: (d) => String(d) === "1",
  },
  __kota: "__kota",
};

const generate = async () => {
  const data = await storage.generate(skey, {
    FlgAudit: "0",
    OpAkt: "0",
    NmKAP: "",
    NPWPKAP: "",
    NmAP: "",
    NPWPAP: "",
    NmKKP: "",
    NPWPKKP: "",
    NmKP: "",
    NPWPKP: "",
    dA1: 0,
    dA2: 0,
    dA3: 0,
    optB4: "1",
    dB4: 0,
    dB5: 0,
    dB6: 0,
    dC7: 0,
    dC8a: 0,
    dC8b: 0,
    dJmlC8: 0,
    optC9: "0",
    dJmlC9: 0,
    dC10a: 0,
    dC10b: 0,
    dC10c: 0,
    dJmlC10: 0,
    FlgSptPPh: "0",
    dD11: 0,
    TglLunas: "",
    OptD13: "",
    OptD13a: "",
    dE14a: 0,
    dE14b: 0,
    dE14c: 0,
    dE14d: 0,
    dE14e: 0,
    dE14f: 0,
    dE14g: 0,
    dF15a: 0,
    dF15b: 0,
    OptD16: "",
    chkH17a: false,
    chkH17b: false,
    chkH17c: false,
    chkH17d: false,
    chkH17e: false,
    chkH17f: false,
    chkH17g: false,
    chkH17h: false,
    chkH17i: false,
    chkH17j: false,
    chkH17k: false,
    chkH17l: false,
    chkH17m: false,
    chkH17n: false,
    vHl: "",
    vHm: "",
    vHn: "",
    FlgTtdSPT: "1",
    TglLaporSPT: moment().format("YYYY-MM-DD"),
    hitungSendiri: false,
    __kota: "",
  });

  return data;
};

const Induk = ({ getLabel, onNextTab, goToIndex, ...props }) => {
  const [defaultData, setDefaultData] = useState();
  console.log("🚀 ~ file: Induk.js ~ line 214 ~ defaultData", defaultData);
  const [ready, setReady] = useState(false);
  const [panels, onKeyDown] = usePanels({
    idPrefix: "spt-1771-Induk",
    count: 9,
    defaultExpandedIndex: 0,
  });
  const [
    panel1Props,
    panel2Props,
    panel3Props,
    panel4Props,
    panel5Props,
    panel6Props,
    panel7Props,
    panel8Props,
    panel9Props,
  ] = panels;

  const handleUpdateData = (data) => {
    setDefaultData((d) => ({ ...d, ...data }));
    props.setKota(data.__kota);
  };

  useEffect(async () => {
    let defData = await recon();

    storage.update(skey, defData);
    setDefaultData(defData);
    setReady(true);
  }, []);

  useEffect(() => {
    storage.update(skey, defaultData);
  }, [defaultData]);

  return (
    ready && (
      <div
        className="mpk-margin-C mpk-animation slide-in"
        style={{
          width: "100%",
          maxWidth: 800,
        }}
      >
        {props.errorMessage && (
          <div className="mpk-paper mpk-padding-N padding-all">
            <Hint>{props.errorMessage}</Hint>
          </div>
        )}
        <ExpansionList
          onKeyDown={onKeyDown}
          className="mpk-margin-S margin-bottom"
        >
          {[
            {
              label: getLabel("__akuntan", "Induk"),
              render: (
                <Akuntan
                  {...{
                    getLabel,
                    data: defaultData,
                    handleUpdate: handleUpdateData,
                  }}
                />
              ),
              panelProps: panel1Props,
            },
            {
              label: getLabel("__konsultan", "Induk"),
              render: (
                <Konsultan
                  {...{
                    getLabel,
                    data: defaultData,
                    handleUpdate: handleUpdateData,
                  }}
                />
              ),
              panelProps: panel2Props,
            },
            {
              label: getLabel("__A", "Induk"),
              render: (
                <A
                  {...{
                    getLabel,
                    data: defaultData,
                    handleUpdate: handleUpdateData,
                  }}
                />
              ),
              panelProps: panel3Props,
            },
            {
              label: getLabel("__B", "Induk"),
              render: (
                <B
                  {...{
                    getLabel,
                    data: defaultData,
                    handleUpdate: handleUpdateData,
                  }}
                />
              ),
              panelProps: panel4Props,
            },
            {
              label: getLabel("__C", "Induk"),
              render: (
                <C
                  {...{
                    getLabel,
                    data: defaultData,
                    handleUpdate: handleUpdateData,
                  }}
                />
              ),
              panelProps: panel5Props,
            },
            {
              label: getLabel("__D", "Induk"),
              render: (
                <D
                  {...{
                    getLabel,
                    data: defaultData,
                    handleUpdate: handleUpdateData,
                    onNextTab,
                    goToIndex,
                    setSsp: props.setSsp,
                  }}
                />
              ),
              panelProps: panel6Props,
            },
            {
              label: getLabel("__E", "Induk"),
              render: (
                <E
                  {...{
                    getLabel,
                    data: defaultData,
                    handleUpdate: handleUpdateData,
                  }}
                />
              ),
              panelProps: panel7Props,
            },
            {
              label: getLabel("__F", "Induk"),
              render: (
                <F
                  {...{
                    getLabel,
                    data: defaultData,
                    handleUpdate: handleUpdateData,
                  }}
                />
              ),
              panelProps: panel8Props,
            },
            {
              label: getLabel("__Checklist", "Induk"),
              render: (
                <Checklist
                  {...{
                    getLabel,
                    data: defaultData,
                    handleUpdate: handleUpdateData,
                    onNextTab,
                  }}
                />
              ),
              panelProps: panel9Props,
            },
          ].map((p, i) => (
            <ExpansionPanel
              key={`spt-1771-Induk-panel-${i}`}
              {...p.panelProps}
              header={<div className="mpk-font size-M weight-M">{p.label}</div>}
            >
              {p.render}
            </ExpansionPanel>
          ))}
        </ExpansionList>
        <Footer
          {...{
            getLabel,
            data: defaultData,
            handleUpdate: handleUpdateData,
            onNextTab,
          }}
        />
      </div>
    )
  );
};

Induk.generate = generate;
Induk.recon = recon;
Induk.columns = columns;

export default Induk;
