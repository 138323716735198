import { merge } from 'lodash'
import { storage } from '.'
import { number } from '../libs/react-mpk/services'
import moment from 'moment'

export const set = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const get = (key) => {
  let item = localStorage.getItem(key)
  return JSON.parse(item)
}

export const remove = (key) => {
  localStorage.removeItem(key)
}

export const update = (key, value) => {
  let item = localStorage.getItem(key)
  if(item) value = Object.assign(JSON.parse(item), value)
  set(key, value)
}

export const generate = (key, value, isReset=false) => {
  return new Promise(resolve => {
    let item = get(key)
    if(item && !isReset) resolve(item);
    else {
      let __item = get(`__${key}`) || {}
      value = {...value, ...__item}
      set(key, value)
      resolve(value)
    }
  })
}

export const clearAll = () => {
  for(let key of Object.keys(keys)){
    storage.remove(keys[key])
    storage.remove(`__${keys[key]}`)
  }
}

export const keys = {
  SPT_SUBMISSION: 'submission-data',

  SPT_1771_Induk: 'SPT_1771_Induk',
  SPT_1771_Profile:'SPT_1771_Profile',
  SPT_1771_Rekapitulasi: 'SPT_1771_Rekapitulasi',
  SPT_1771_LampI: 'SPT_1771_LampI',
  SPT_1771_LampII: 'SPT_1771_LampII',
  SPT_1771_LampKhus4A:'SPT_1771_LampKhus4A',
  SPT_1771_HeaderLampIII:'SPT_1771_HeaderLampIII',
  SPT_1771_DetailLampIIIPPh22:'SPT_1771_DetailLampIIIPPh22',
  SPT_1771_DetailLampIIIPPh23:'SPT_1771_DetailLampIIIPPh23',
  SPT_1771_HeaderLampIV:'SPT_1771_HeaderLampIV',
  SPT_1771_DetailLampIVA:'SPT_1771_DetailLampIVA',
  SPT_1771_DetailLampIVB:'SPT_1771_DetailLampIVB',
  SPT_1771_HeaderLampV:'SPT_1771_HeaderLampV',
  SPT_1771_DetailLampVPemegangSaham: 'SPT_1771_DetailLampVPemegangSaham',
  SPT_1771_DetailLampVPengurus:'SPT_1771_DetailLampVPengurus',
  SPT_1771_HeaderLampVI:'SPT_1771_HeaderLampVI',
  SPT_1771_DetailLampVIPenyertaanModal:'SPT_1771_DetailLampVIPenyertaanModal',
  SPT_1771_DetailLampVIUtangModal:'SPT_1771_DetailLampVIUtangModal',
  SPT_1771_DetailLampVIPiutangModal:'SPT_1771_DetailLampVIPiutangModal',
  SPT_1771_HeaderLampKhus1A:'SPT_1771_HeaderLampKhus1A',
  SPT_1771_DetailLampKhus1A:'SPT_1771_DetailLampKhus1A',
  SPT_1771_DetailLampKhus1A2:'SPT_1771_DetailLampKhus1A2',
  SPT_1771_HeaderLampKhus2A:'SPT_1771_HeaderLampKhus2A',
  SPT_1771_LampKhus3AI:'SPT_1771_LampKhus3AI',
  SPT_1771_LampKhus3AII:'SPT_1771_LampKhus3AII',
  SPT_1771_LampKhus3A1:'SPT_1771_LampKhus3A1',
  SPT_1771_LampKhus3A2I:'SPT_1771_LampKhus3A2I',
  SPT_1771_LampKhus3A2II: 'SPT_1771_LampKhus3A2II',
  SPT_1771_HeaderLampKhus5A:'SPT_1771_HeaderLampKhus5A',
  SPT_1771_DetailLampKhus5A: 'SPT_1771_DetailLampKhus5A',
  SPT_1771_LampKhus6A:'SPT_1771_LampKhus6A',
  SPT_1771_HeaderLampKhus7A:'SPT_1771_HeaderLampKhus7AA',
  SPT_1771_DetailLampKhus7A:'SPT_1771_DetailLampKhus7A',
  SPT_1771_HeaderLapKeu:'SPT_1771_HeaderLapKeu',
  SPT_1771_Neraca1:'SPT_1771_Neraca1',
  SPT_1771_Labarugi1:'SPT_1771_Labarugi1',
  SPT_1771_HubIstimewa1:'SPT_1771_HubIstimewa1',
  SPT_1771_Neraca3:'SPT_1771_Neraca3',
  SPT_1771_Labarugi3:'SPT_1771_Labarugi3',
  SPT_1771_HubIstimewa3:'SPT_1771_HubIstimewa3',
  SPT_1771_Neraca5:'SPT_1771_Neraca5',
  SPT_1771_Labarugi5:'SPT_1771_Labarugi5',
  SPT_1771_HubIstimewa5:'SPT_1771_HubIstimewa5',
  SPT_1771_Neraca7:'SPT_1771_Neraca7',
  SPT_1771_Labarugi7:'SPT_1771_Labarugi7',
  SPT_1771_HubIstimewa7:'SPT_1771_HubIstimewa7',
  SPT_1771_Neraca9:'SPT_1771_Neraca9',
  SPT_1771_Labarugi9:'SPT_1771_Labarugi9',
  SPT_1771_HubIstimewa9:'SPT_1771_HubIstimewa9',
  SPT_1771_Neraca11:'SPT_1771_Neraca11',
  SPT_1771_Labarugi11:'SPT_1771_Labarugi11',
  SPT_1771_HubIstimewa11:'SPT_1771_HubIstimewa11',
  SPT_1771_Neraca13:'SPT_1771_Neraca13',
  SPT_1771_Labarugi13:'SPT_1771_Labarugi13',
  SPT_1771_HubIstimewa13:'SPT_1771_HubIstimewa13',
  SPT_1771_Neraca15:'SPT_1771_Neraca15',
  SPT_1771_Labarugi15:'SPT_1771_Labarugi15',
  SPT_1771_HubIstimewa15:'SPT_1771_HubIstimewa15',
  SPT_1771_HeaderSSP:'SPT_1771_HeaderSSP',
  SPT_1771_DetailSSP:'SPT_1771_DetailSSP'
} 