import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { options, rules, storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import HeaderLampVI from './HeaderLampVI'
import moment from 'moment'

const { inputTypes } = DataForm

const skey = storage.keys.SPT_1771_DetailLampVIPiutangModal
const columns = {
    list:"list",
}

const itemColumns = {
    NPWP: {
        key: 'npwp',
        value: d => {
            return rules.npwpOptional(d)
        }
    },
    Nama: 'nama',
    JmlPinjam: 'jmlPinjam',
    ThPinjam: 'thPinjam',
    BngTh: {
        key:'bngTh',
        value: d => (Number(d)),
        reverseValue: d => (String(d))
    }
}

const generate = async () => {
    let data = await storage.generate(skey, {
        list: []
    })

    return data
}

const DetailLampVIPiutangModal = ({
    getLabel,
    envStore,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    const handleUpdateData = (data) => {
        return data
    }

    useEffect(() => {
        let __data = storage.get(skey)
        let defData = handleUpdateData(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-DetailLampVIPiutangModal"
            className="mpk-margin-C"
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            watchDefaultData={false}
            style={{maxWidth: 'unset'}}
            definitions={[
                {
                    inputType: inputTypes.LIST,
                    asTable: true,
                    label: getLabel('__title','DetailLampVIPiutangModal'),
                    key: 'list',
                    defaultData:{
                        NPWP: '',
                        Nama: '',
                        JmlPinjam: 0,
                        ThPinjam: Number(moment(envStore.env.timestamp).year()),
                        BngTh: 0
                    },
                    definitions:[
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('NPWP', 'DetailLampVIPiutangModal'),
                            key: 'NPWP',
                            required: true
                        },
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('Nama', 'DetailLampVIPiutangModal'),
                            key: 'Nama',
                            validation: (data) => (rules.validateNamaNpwp(data, 'NPWP')),
                            required: true
                        },
                        {
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            label: getLabel('JmlPinjam', 'DetailLampVIPiutangModal'),
                            isNumericString:true,
                            decimalSeparator: ',',
                            thousandSeparator:'.',
                            key: 'JmlPinjam',
                            allowNegative: false
                        },
                        {
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            label: getLabel('ThPinjam', 'DetailLampVIPiutangModal'),
                            isNumericString:true,
                            key: 'ThPinjam',
                            allowNegative: false,
                            validation: rules.getValidationTahunUmum(envStore.env.timestamp)
                        },
                        {
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            label: getLabel('BngTh', 'DetailLampVIPiutangModal'),
                            key: 'BngTh',
                            isNumericString:true,
                            decimalSeparator: ',',
                            thousandSeparator:'.',
                            allowNegative: false,
                            decimalScale: '2',
                            validation: rules.validationTarif
                        }
                    ]
                }
            ]}
            onBeforeChange={(key, value) => {
                // if(key === 'NPWP') return rules.npwpOptional(value)
                return value
            }}
            onSubmit={async (data, callback) => {
                storage.update(skey, data)
                await HeaderLampVI.recon()
                callback(t.translate('sentence.savedItem'), false)
            }}
        />
    )
}

DetailLampVIPiutangModal.generate = generate
DetailLampVIPiutangModal.columns = columns
DetailLampVIPiutangModal.itemColumns = itemColumns

export default inject('envStore')(observer(DetailLampVIPiutangModal))