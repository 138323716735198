import t from 'counterpart'
import moment from 'moment'

export const getPembetulans = () => {
  let result = [];
  for(let pb = 0; pb <= 99 ; pb++){result.push(String(pb))}
  return result
}

export const getMonths = () => {
  const months = ['01','02','03','04','05','06','07','08','09','10','11','12']
  return months.map(m => ({
    value: m,
    label: t.translate(`months.${m}`)
  }))
}

export const getYears = (timeStamp, minYear=2016) => {
  let result = [];
  const currentYear = moment(timeStamp).year()
  for(let year = currentYear; year >= minYear ; year--){
    result.push(year)
  }
  return result
}

export const getTahunBuku = (timeStamp) => {
  const currentYear = moment(timeStamp).year()
  const result = [];
  for(let year = currentYear ; year >= 2015 ; year --){
    result.push({
      value:String(year).substr(2,2),
      label:year 
    })
  }
  return result
}