import React,{useState, useEffect} from 'react'
import { DataForm } from '../../libs/react-mpk/components'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { upload, getUploadAggrement } from './Certificate.service'
import renderHTML from 'react-render-html'
import { toast } from '../../libs/react-mpk/services'
import { Link } from 'react-router-dom'
import { Button, AutoComplete } from 'react-md'

const { inputTypes } = DataForm
const Upload = ({
  visible         = false,
  npwp            = null,
  onFinished      = null,
  onRequestClose  = () => {},
  ...props
}) => {
  const [agreement, setAgreement] = useState(null)
  const handleSubmit = async (data, callback) => {
    if(data.agree){
      try {
        data.npwp = data.file.name.split('.')[0]
        const res = await upload(data)
        
        if(onFinished) onFinished(res.data)
        else props.temporaryStore.setProperties('npwp', res.data)

        setTimeout(() => {
          onRequestClose()
          callback()
        })
      } catch (error) {
        callback(error, true, false)
      }
    }else{
      callback(new Error(t.translate('modules.certificate.form.notAgreeAlert')), true, false)
    }
  }

  useEffect(async () => {
    try {
      if(!agreement){
        let res = await getUploadAggrement()
        setAgreement(res.data)
      }
    } catch (error) {
      toast.errorRequest(error)
    }
  })

  return (
    <DataForm
      baseId="mod-certificate-upload"
      title={t.translate(`modules.certificate.upload.title`)}
      hintMessage={npwp 
        ? t.translate(`modules.certificate.upload.hintTextNpwp`).replace('$npwp', npwp)
        : t.translate(`modules.certificate.upload.hintText`)
      }
      asDialog={true}
      visible={visible}
      onRequestClose={onRequestClose}
      defaultData={{
        file: null,
        password: ''
      }}
      definitions={[
        {
          inputType: inputTypes.FILE_INPUT,
          label: t.translate(`column.selectFile`),
          key: 'file',
          accept: '.p12'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.certificate.upload.certPassword'),
          helpText: t.translate('modules.certificate.upload.passwordHint'),
          key: 'password',
          type: 'password',
          required: true
        },
        {
          inputType: DataForm.inputTypes.DIVIDER,
          label: t.translate('column.agreement')
        },
        {
          render:(
            <div 
              className="mpk-border border-all thin solid dark mpk-padding-N padding-all agrement mpk-full full-width"
              style={{
                lineHeight: '24px',
                background: '#fef6f4'
              }}
            >
              {agreement && renderHTML(agreement)}
            </div>
          )
        },
        {
          inputType: DataForm.inputTypes.CHECKBOX,
          label: t.translate('modules.certificate.form.agree'),
          key: 'agree'
        }
      ]}
      onBeforeChange={(key, value) => {
        if(key === 'file' && value) {
          let name = value.name.split('.')[0]
          if(npwp && name !== npwp){
            toast.error(t.translate('modules.certificate.upload.npwpNotMatch').replace('$npwp', npwp))
            return null
          } else {
            // return value
            // BILA SIMULASI VALIDASI INI DI KOMEN
            // DJP PERUBAHAN NPWP_16+nitku
            if(name.length > 15){
              var npwp = name.slice(0,16)
              let isPusat = npwp.substr(npwp.length - 3, 3) === '000'
              if(isPusat) return value;
              else {
                toast.error(t.translate('modules.certificate.upload.mustHOAlert'))
                return null
              }
            // DJP PERUBAHAN NPWP_16+nitku
            } else {  
              let isPusat = name.substr(name.length - 3, 3) === '000'
              if(isPusat) return value;
              else {
                toast.error(t.translate('modules.certificate.upload.mustHOAlert'))
                return null
              }
            }
          }
        } else {
          return value
        }
      }}
      onSubmit={handleSubmit}
      showCancel={true}
      additionalFooter={(
        props.envStore.env.kbs.map(d => (
          <Button
            theme="primary"
            themeType="outline"
            className="mpk-margin-N margin-right margin-left"
            onClick={() => {
              if(window.kbsWidget) window.kbsWidget[d.type](d.code)
            }}
          >
            {d.label}
          </Button>
        ))
      )}
      {...props}
    />
  )
}

export default inject('temporaryStore', 'envStore')(observer(Upload))
