import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { rules, storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import HeaderLampVI from './HeaderLampVI'

const { inputTypes } = DataForm

const skey = storage.keys.SPT_1771_DetailLampVIPenyertaanModal
const columns = {
    list:"list",
}

const itemColumns = {
    NPWP:{
        key: 'npwp',
        value: d => {
            return rules.npwpOptional(d)
        }
    },
    Nama:'nama',
    Alamat:'alamat',
    JmlPenyMdl:'jmlPenyMdl',
    PctPenyMdl:'pctPenyMdl',
}

const generate = async () => {
    let data = await storage.generate(skey, {
        list: []
    })

    return data
}

const DetailLampVIPenyertaanModal = ({
    getLabel,
    envStore,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    const handleUpdateData = (data) => {
        return data
    }

    useEffect(() => {
        let __data = storage.get(skey)
        let defData = handleUpdateData(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-DetailLampVIPenyertaanModal"
            className="mpk-margin-C"
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            watchDefaultData={false}
            style={{maxWidth: 'unset'}}
            definitions={[
                {
                    inputType: inputTypes.LIST,
                    asTable: true,
                    label: getLabel('__title','DetailLampVIPenyertaanModal'),
                    key: 'list',
                    defaultData:{
                        NPWP: '',
                        Nama: '',
                        Alamat: '',
                        JmlPenyMdl: 0,
                        PctPenyMdl: 0,
                    },
                    definitions:[
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('NPWP', 'DetailLampVIPenyertaanModal'),
                            key: 'NPWP',
                            required: true
                        },
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('Nama', 'DetailLampVIPenyertaanModal'),
                            key: 'Nama',
                            validation: (data) => (rules.validateNamaNpwp(data, 'NPWP')),
                            required: true
                        },
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('Alamat', 'DetailLampVIPenyertaanModal'),
                            key: 'Alamat',
                            validation: 'max:255',
                        },
                        {
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            label: getLabel('JmlPenyMdl', 'DetailLampVIPenyertaanModal'),
                            isNumericString:true,
                            decimalSeparator: ',',
                            thousandSeparator:'.',
                            key: 'JmlPenyMdl',
                            allowNegative: false
                        },
                        {
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            label: getLabel('PctPenyMdl', 'DetailLampVIPenyertaanModal'),
                            key: 'PctPenyMdl',
                            isNumericString:true,
                            decimalSeparator: ',',
                            thousandSeparator:'.',
                            allowNegative: false,
                            decimalScale: '2',
                            validation: rules.validationTarif
                        }
                    ]
                }
            ]}
            onBeforeChange={(key, value) => {
                // if(key === 'NPWP') return rules.npwpOptional(value)
                return value
            }}
            onSubmit={async (data, callback) => {
                storage.update(skey, data)
                await HeaderLampVI.recon()
                callback(t.translate('sentence.savedItem'), false)
            }}
        />
    )
}

DetailLampVIPenyertaanModal.generate = generate
DetailLampVIPenyertaanModal.columns = columns
DetailLampVIPenyertaanModal.itemColumns = itemColumns

export default inject('envStore')(observer(DetailLampVIPenyertaanModal))