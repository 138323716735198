import React, {useState, useEffect} from 'react'
import { ExpansionList, ExpansionPanel, usePanels } from 'react-md'
import { Hint } from '../../../../../libs/react-mpk/components'
import HeaderLampKhus7A from './HeaderLampKhus7A'
import DetailLampKhus7A from './DetailLampKhus7A'

const generate = () => (new Promise(async resolve => {
    await DetailLampKhus7A.generate()
    await HeaderLampKhus7A.generate()
    await HeaderLampKhus7A.recon()
    resolve()
}))

const LampKhus7A = ({
    getLabel,
    onNextTab,
    ...props
}) => {
    const [ready, setReady] = useState(false)
    const [panels, onKeyDown] = usePanels({
        idPrefix: 'spt-1771-LampKhus7A',
        count:2,
        defaultExpandedIndex: 0
    })

    useEffect(() => {
        setReady(true)
    }, [])

    const [panel1Props, panel2Props, panel3Props] = panels;
    return ready && (
        <div
            className="mpk-margin-C mpk-animation slide-in"
            style={{
                width: '100%',
                maxWidth: 1024
            }}
        >
            {props.errorMessage && 
                <div className="mpk-paper mpk-padding-N padding-all">
                    <Hint>{props.errorMessage}</Hint>
                </div>
            }
            <ExpansionList onKeyDown={onKeyDown}>
                {[
                    {
                        label: getLabel('__title', 'DetailLampKhus7A'),
                        render: <DetailLampKhus7A {...{getLabel}}/>,
                        panelProps: panel1Props
                    },
                    {
                        label: getLabel('__title', 'HeaderLampKhus7A'),
                        render: <HeaderLampKhus7A {...{getLabel, onNextTab}}/>,
                        panelProps: panel2Props
                    }
                ].map((p, i) => (
                    <ExpansionPanel 
                        key={`spt-1771-LampKhus7A-panel-${i}`}
                        {...p.panelProps}
                        header={<div className="mpk-font size-M weight-M">{p.label}</div>}
                    >
                        {p.render}
                    </ExpansionPanel>
                ))}
            </ExpansionList>
        </div>
    )
}

LampKhus7A.generate = generate

export default LampKhus7A
