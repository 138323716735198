import React, { useState, useEffect } from "react";
import {
  DataForm,
  DividerLabel,
  LoaderInfo,
} from "../../../../../libs/react-mpk/components";
import { rules, storage } from "../../../../../services";
import t from "counterpart";
import Induk from "../Induk";
import { round } from "../../../../../libs/react-mpk/services/number.service";

const { inputTypes } = DataForm;

const skey = storage.keys.SPT_1771_HeaderLampIV;
const columns = {
  A13: "a13",
  A14: "a14",
  A15: "a15",
  A23: "a23",
  A24: "a24",
  A25: "a25",
  A33: "a33",
  A34: "a34",
  A35: "a35",
  A43: "a43",
  A44: "a44",
  A45: "a45",
  A53: "a53",
  A54: "a54",
  A55: "a55",
  A63: "a63",
  A64: "a64",
  A65: "a65",
  A73: "a73",
  A74: "a74",
  A75: "a75",
  A8a3: "a8a3",
  A8a4: "a8a4",
  A8a5: "a8a5",
  A8b3: "a8b3",
  A8b4: "a8b4",
  A8b5: "a8b5",
  A8c3: "a8c3",
  A8c4: "a8c4",
  A8c5: "a8c5",
  A93: "a93",
  A94: "a94",
  A95: "a95",
  A103: "a103",
  A104: "a104",
  A105: "a105",
  A113: "a113",
  A114: "a114",
  A115: "a115",
  A123: "a123",
  A124: "a124",
  A125: "a125",
  A133: "a133",
  A134: "a134",
  A135: "a135",
  JBA: "jba",
  B13: "b13",
  B23: "b23",
  B33: "b33",
  B43: "b43",
  B53: "b53",
  B63: "b63",
  JBB: "jbb",
};

const generate = async () => {
  let data = await storage.generate(
    skey,
    (() => {
      let result = {};
      for (let k of Object.keys(columns)) {
        result[k] = 0;
      }

      return result;
    })()
  );

  return data;
};

const recon = (
  data = storage.get(skey),
  detailLampIVA = storage.get(storage.keys.SPT_1771_DetailLampIVA),
  detailLampIVB = storage.get(storage.keys.SPT_1771_DetailLampIVB),
  submitting = true
) =>
  new Promise((resolve) => {
    data.JBA = 0;
    for (let i = 1; i <= 13; i++) {
      if (i === 8) {
        for (let c of ["a", "b", "c"]) {
          data[`A${i}${c}5`] = round(
            (data[`A${i}${c}3`] * data[`A${i}${c}4`]) / 100,
            true,
            1
          );
          data.JBA += data[`A${i}${c}5`];
        }
      } else {
        data[`A${i}5`] = round(
          (data[`A${i}3`] * data[`A${i}4`]) / 100,
          true,
          1
        );
        data.JBA += data[`A${i}5`];
      }
    }

    data.JBA += detailLampIVA.list.reduce((a, b) => a + b.PPH, 0);
    data.JBB =
      data.B13 +
      data.B23 +
      data.B33 +
      data.B43 +
      data.B53 +
      data.B63 +
      detailLampIVB.list.reduce((a, b) => a + b.DPP, 0);

    if (submitting) {
      storage.update(skey, data);
      Induk.recon();
    }
    resolve(data);
  });

const HeaderLampIV = ({ getLabel, ...props }) => {
  const detailLampIVA = storage.get(storage.keys.SPT_1771_DetailLampIVA);
  const detailLampIVB = storage.get(storage.keys.SPT_1771_DetailLampIVB);
  const [definitions, setDefinitions] = useState([]);
  const [defaultData, setDefaultData] = useState();
  const [ready, setReady] = useState(false);

  const handleUpdateData = (data) => {
    // data.JBA = 0
    // for(let i = 1 ; i <= 13 ;i++){
    //     if(i === 8){
    //         for(let c of ['a', 'b', 'c']){
    //             data[`A${i}${c}5`] = (data[`A${i}${c}3`]*data[`A${i}${c}4`])/100
    //             data.JBA += data[`A${i}${c}5`]
    //         }
    //     } else {
    //         data[`A${i}5`] = (data[`A${i}3`]*data[`A${i}4`])/100
    //         data.JBA += data[`A${i}5`]
    //     }
    // }

    // data.JBB = data.B13 + data.B23 + data.B33 + data.B43 + data.B53 + data.B63 + (
    //     detailLampIVB.list.reduce((a,b) => (a+b.DPP), 0)
    // )

    return new Promise(async (resolve) => {
      data = await recon(data, detailLampIVA, detailLampIVB, false);
      resolve(data);
    });
  };

  const getDefinitions = () => {
    return new Promise((resolve) => {
      let result = [
        {
          // render: () => (
          //     <div className="mpk-font weight-B mpk-margin-N margin-bottom">
          //         {getLabel('__titleA', 'HeaderLampIV')}
          //     </div>
          // )
          render: (
            <DividerLabel className="mpk-margin-M margin-bottom">
              {getLabel("__titleA", "HeaderLampIV")}
            </DividerLabel>
          ),
        },
      ];

      let genField = (name) => {
        for (let a = 3; a <= 5; a++) {
          let key = `${name}${a}`;
          result.push({
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: getLabel(`__group${a}`, "HeaderLampIV").replace(
              "(Prosentase)",
              "%"
            ),
            isNumericString: true,
            decimalSeparator: ",",
            thousandSeparator: ".",
            width: a === 4 ? "20%" : "25%",
            className:
              a === 4 ? "mpk-padding-S padding-right padding-left" : "",
            decimalScale: a === 4 ? 2 : null,
            allowNegative: false,
            // [
            //   "A1",
            //   "A2",
            //   "A3",
            //   "A4",
            //   "A5",
            //   "A6",
            //   "A7",
            //   "A8a",
            //   "A8b",
            //   "A8c",
            //   "A9",
            //   "A10",
            //   "A11",
            //   "A12",
            //   "A13",
            // ].indexOf(name) < 0,
            readOnly: [].indexOf(key) >= 0,
            validation: a === 4 ? rules.validationTarif : "",
            theme: "underline",
            key,
          });
        }
      };

      for (let i = 1; i <= 13; i++) {
        if (i === 8) {
          for (let c of ["a", "b", "c"]) {
            // result.push({
            //     inputType: inputTypes.DIVIDER,
            //     label: getLabel(`__A${i}${c}`, 'HeaderLampIV'),
            //     key:`__A${i}`
            // })
            result.push({
              render: (
                <div
                  className={`
                                        mpk-margin-N margin-bottom mpk-font size-NS mpk-padding-S padding-right
                                    `}
                  style={{
                    width: "30%",
                    paddingTop: 2,
                  }}
                >
                  {getLabel(`__A${i}${c}`, "HeaderLampIV")}
                </div>
              ),
            });
            genField(`A${i}${c}`);
          }
        } else {
          // result.push({
          //     inputType: inputTypes.DIVIDER,
          //     label: getLabel(`__A${i}`, 'HeaderLampIV'),
          //     key:`__A${i}`
          // })
          result.push({
            render: (
              <div
                className={`
                                    mpk-margin-N margin-bottom mpk-font size-NS mpk-padding-S padding-right
                                `}
                style={{
                  width: "30%",
                  paddingTop: 2,
                }}
              >
                {getLabel(`__A${i}`, "HeaderLampIV")}
              </div>
            ),
          });
          genField(`A${i}`);
        }
      }

      result.push({
        inputType: inputTypes.INPUT_MASK_NUMBER,
        label: getLabel(`JBA`, "HeaderLampIV"),
        isNumericString: true,
        decimalSeparator: ",",
        thousandSeparator: ".",
        key: "JBA",
        disabled: true,
      });

      result.push({
        render: (
          <DividerLabel className="mpk-margin-M margin-bottom margin-top">
            {getLabel("__titleB", "HeaderLampIV")}
          </DividerLabel>
        ),
      });

      for (let a = 1; a <= 6; a++) {
        let key = `B${a}3`;
        result.push({
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: getLabel(a === 6 ? `__B63` : `B${a}3`, "HeaderLampIV"),
          helpText: a === 6 ? getLabel("B63", "HeaderLampIV") : null,
          isNumericString: true,
          allowNegative: false,
          decimalSeparator: ",",
          thousandSeparator: ".",
          theme: "underline",
          key,
        });
      }

      result.push({
        inputType: inputTypes.INPUT_MASK_NUMBER,
        label: getLabel(`JBB`, "HeaderLampIV"),
        isNumericString: true,
        decimalSeparator: ",",
        thousandSeparator: ".",
        key: "JBB",
        disabled: true,
      });

      setDefinitions(result);
      resolve();
    });
  };

  useEffect(async () => {
    await getDefinitions();
    let __data = storage.get(skey);
    let defData = await handleUpdateData(__data);

    storage.update(skey, defData);
    setDefaultData(defData);
    setTimeout(() => setReady(true), 500);
  }, []);

  return ready ? (
    <DataForm
      baseId="mod-submission-spt-1771-HeaderLampIV"
      className="mpk-margin-C mpk-animation slide-in"
      style={{ maxWidth: "unset" }}
      hintMessage={props.errorMessage}
      asDialog={false}
      defaultData={defaultData}
      definitions={definitions}
      onChange={async (data) => {
        data = await handleUpdateData(data);
        return data;
      }}
      onSubmit={(data, callback) => {
        callback(t.translate("sentence.savedItem"), false);
        storage.update(skey, data);
      }}
    />
  ) : (
    <LoaderInfo>Loading data...</LoaderInfo>
  );
};

HeaderLampIV.generate = generate;
HeaderLampIV.columns = columns;
HeaderLampIV.recon = recon;

export default HeaderLampIV;
