/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Children } from "react";
import { DataForm, DividerLabel, Hint } from "../../libs/react-mpk/components";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import { toast } from "../../libs/react-mpk/services";
import { inject, observer } from "mobx-react";
import { ExportXlsx } from "./Submission.service";
import { find } from "lodash";
import { options } from "superagent";
import qs from "query-string";
import iziToast from "izitoast";

const { inputTypes } = DataForm;
const ExportSummary = ({
  visible = false,
  onRequestClose = null,
  ...props
}) => {

  var [data, setData] = useState({
    type: '2'
  })
  var [hint, setHint] = useState(false)
  var [type, setType] = useState()

  useEffect(()=> {
    setType('2')
  }, [])

  return (
    <DataForm
      baseId="exportSummary"
      title={"Export Summary"}
      style={{
        width: 800,
        height: 600,
      }}
      asDialog={true}
      visible={visible}
      onRequestClose={() => {
        onRequestClose();
      }}
      defaultData={data}
      onChange={(form, key, value)=> {
        console.log(value, key)
        if(key == 'type' && value == '1'){
          setType('1')
        } else {
          if(form.type == '1'){
            setType('1')
          } else {
            setHint(false)
            setType('2')
          }
        }
      }}
      hintMessage={hint}
      definitions={[
        {
          id: 'input-gt',
          inputType: inputTypes.SELECT,
          label: "Tipe Export",
          key: "type",
          required: true,
          width: "100%",
          className: "mpk-padding-N padding-right",
          options: [
            {value: '1', label: 'Tanggal Dibuat'},
            {value: '2', label: 'Masa/Tahun'}
          ]
        },
        {
          show: type == '1',
          id: 'input-gt',
          inputType: inputTypes.DATEPICKER,
          label: "Dari Tanggal Dibuat",
          key: "gt",
          required: true,
          width: "50%",
          className: "mpk-padding-N padding-right",
        },
        {
          show: type == '1',
          id: 'input-lt',
          inputType: inputTypes.DATEPICKER,
          label: "Sampai Tanggal Dibuat",
          key: "lt",
          required: true,
          width: "50%",
          className: "mpk-padding-N padding-right",
        },
        {
          show: type == '2',
          id: 'input-year',
          inputType: inputTypes.INPUT,
          type: 'number',
          label: "Tahun",
          key: "year",
          required: true,
          width: "100%",
          className: "mpk-padding-N padding-right",
        },
        {
          show: type == '2',
          id: 'input-rev',
          inputType: inputTypes.INPUT,
          type: 'number',
          label: "Pembetulan",
          key: "rev",
          required: true,
          width: "100%",
          className: "mpk-padding-N padding-right",
        },
        {
          inputType: inputTypes.AUTOCOMPLETE,
          label: "Status",
          key: "status",
          className: "mpk-padding-N padding-right",
          width: "100%",
          required: true,
          validation: "min:3",
          data: [
            {
              children: <div style={{ whiteSpace: "wrap" }}>Semua Status</div>,
              value: "All",
            },
            {
              children: <div style={{ whiteSpace: "wrap" }}>Success</div>,
              value: "Success",
            },
            {
              children: <div style={{ whiteSpace: "wrap" }}>Validasi</div>,
              value: "Validasi",
            },
            {
              children: <div style={{ whiteSpace: "wrap" }}>Ready</div>,
              value: "Ready",
            },
            {
              children: <div style={{ whiteSpace: "wrap" }}>Error</div>,
              value: "Error",
            },
            {
              children: <div style={{ whiteSpace: "wrap" }}>Failed</div>,
              value: "Failed",
            },
          ],
        },
      ]}
      onSubmit={async (data, callback) => {
        try {
          if(data.type == '1'){
            var gt = new Date(data.gt)
            var lt = new Date(data.lt)
            console.log(gt, lt)
            if (gt >= lt) {
              callback(
                "Dari tanggal harus lebih kecil dari Sampai tanggal",
                true,
                false
              );
            } else {
              props.setLoadingDownload(true)
              await ExportXlsx(qs.stringify(data))
              toast.success("Export berhasil !")
              props.setLoadingDownload(false);
              callback("", false, false, false)
              onRequestClose()
            }
          } else {
            props.setLoadingDownload(true)
            await ExportXlsx(qs.stringify(data))
            toast.success("Export berhasil !")
            props.setLoadingDownload(false);
            callback("", false, false, false)
            onRequestClose()
          }
        } catch(e){
          var message = e.message
          window.hahaha = e
          if(e.response && e.response.data && e.response.data.errorMessage && e.response.data.errorMessage.id) message = e.response.data.errorMessage.id
          toast.error(message)
          props.setLoadingDownload(false);
          callback("", false, false, false)
          onRequestClose()
        }
        // try {
        //   let gt = new Date(data.greaterThan);
        //   let lt = new Date(data.lessThan);
        //   if (gt > lt) {
        //     callback(
        //       "Dari tanggal harus lebih kecil dari Sampai tanggal",
        //       true,
        //       false
        //     );
        //   } else {
        //     await ExportXlsx(qs.stringify(data));
        //     callback("Export Summary Berhasil", false);
        //   }
        // } catch (error) {
        //   callback(
        //     "Data tidak ditemukan dalam rentang tanggal tersebut",
        //     true,
        //     false
        //   );
        // }
      }}
    />
  );
};

export default inject("envStore")(observer(ExportSummary));
