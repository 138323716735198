import React from 'react'
import './Label.scss'

const Label = ({
  children,
  className = '',
  ...props
}) => {
  return (
    <div 
      className={`mpk-label mpk-margin-N margin-bottom ${className} mpk-font size-NS`}
      {...props}
    >
      {children}
    </div>
  )
}

export default Label
