import React from 'react';

const properties = {
    direction: {
        COLUMN          : 'direction-column',
        ROW             : 'direction-row',
        COLUMN_REVERSE  : 'direction-column reverse',
        ROW_REVERSE     : 'direction-row reverse'
    },
    align: {
        START           : 'align-start',
        CENTER          : 'align-center',
        END             : 'align-end'
    },
    justify: {
        START           : 'justify-start',
        ARROUND         : 'justify-arround',
        CENTER          : 'justify-center',
        END             : 'justify-end',
        BETWEEN         : 'justify-between'
    }
}

const Flex  = ({
    style       = {},
    className   = '',
    direction   = properties.direction.ROW,
    align       = properties.align.START,
    justify     = properties.justify.START,
    wrap        = false,
    fit         = false,
    scrollable  = false,
    children    = null,
    ...props
}) => (
    <div
        className={`
            mpk-flex ${align} ${direction} ${justify} ${className} 
            ${wrap ? 'wrap' : ''} ${fit ? 'flex' : 'flex-none'}
            ${scrollable ? 'scrollable' : ''}
        `}
        style={style}
        {...props}
    >
        {children}
    </div>
)

Flex.properties = properties;
export default Flex;