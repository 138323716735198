import axios from 'axios'
import oboe from 'oboe'
import qs from 'query-string'
import fileSaver from 'file-saver'

class http {
  constructor(){
    this.methods = {
      GET: 'get',
      POST: 'post',
      PUT: 'put',
      DELETE: 'delete',
      STREAM: 'stream'
    }
    this.requestInterceptor = config => (config);
    this.handleError = err => {
      if(err.response){
        const { data } = err.response;
        err.message = data.message || data.error_description || data.error || data.errmsg || data.errorMessage || data.detail || data;
        err.message = typeof err.message === 'object' ? err.message.id : err.message;
      } else err.message = 'Request err. Check your connection!'
      return err;
    };

    this.host = '';
    this.baseUrl = '';
    this.companyId = null;
    this.subscriptionId = null;
    this.clientId = null;
    this.headerOptions = {}
    this.client = null;
  }
  
  setHost(host=''){this.host = host};
  setBaseUrl(baseUrl=''){this.baseUrl = baseUrl}
  setRequestInterceptor(func){this.requestInterceptor = func};
  setErrorHanlder(func){this.handleError = func};
  setCompanyId(companyId=null){{
    this.headerOptions['X-Company-ID'] = companyId
    this.headerOptions['X-Company'] = companyId
  }};
  setSubscriptionId(subscriptionId=null){this.headerOptions['X-Subscription-ID'] = subscriptionId};
  setClientId(clientId=null){this.headerOptions['X-Client-ID'] = clientId};
  setClient(client=null){this.headerOptions['X-Client'] = client};
  setCustomHeader(key, value){this.headerOptions[key] = value};
  setApplicationType(applicationType){this.headerOptions['X-Application-Type'] = applicationType};

  request = async opt => {
    let { url, method, data, config={headers:{}}, options={}, query={}} = opt;
    let { 
      useDefaultHost     = true,
      useDefaultBaseUrl  = true,
      useDefaultHeader   = true,
      onData             = null,
      keyTotal           = 'x-pagination-count',
      ignoreInterceptor  = false
    } = options;
    
    config = ignoreInterceptor ? config : await this.requestInterceptor(config, url);
    config.headers = config.headers || {}
    url = `${useDefaultHost ? this.host : ''}${useDefaultBaseUrl ? this.baseUrl : ''}${url}`;

    if(url.indexOf("://") != 0){
      url = url
    } else {
      url = `${useDefaultHost ? this.host : ''}${useDefaultBaseUrl ? this.baseUrl : ''}${url}`;
    }
    
    if(useDefaultHeader){
      for(let key of Object.keys(this.headerOptions)){
        if(this.headerOptions[key] && config.headers)
          config.headers[key] = this.headerOptions[key];
      }
    }

    return new Promise((resolve, reject) => {
      let req
      if(method === this.methods.STREAM){
        let total = 0
        let isResolved = false
        let data = []
        let { page=1, size=20 } = query
        let headers;
        oboe({url: `${url}${query ? `?${qs.stringify(query)}` : ''}`, ...config})
          .start((status, _headers) => {
            headers = _headers
            if(status === 200){
              if(onData) resolve({status, headers});
              else {
                total = Number(headers[keyTotal])
                isResolved = true
              }
            }
          })
          .done(item => {
            if(item && typeof item === 'object') {
              if(onData) onData(item);
              else {
                data.push(item)
                let willReceive = this.totalItemWillReceive(page, size, total)
                if(data.length === willReceive) resolve({headers, data})
              }
            }
          })
          .fail(err => {
            let error = {
              headers: {status: err.statusCode},
              response:{
                status: err.statusCode,
              }
            };

            if(err.jsonBody) error.response.data = err.jsonBody;
            reject(this.handleError(error));
          })
      } else {
        switch(method){
          case this.methods.GET:
            config.params = query;
            req = axios[method](url, config);
            break;
          case this.methods.DELETE:
            req = axios[method](url, config);
            break;
          case this.methods.POST:
          case this.methods.PUT:
            req = axios[method](url, data, config);
            break;
          default:
            return null;
        }
        
        req
          .then(response => resolve(response))
          .catch( error => {
            reject(this.handleError(error))
          })
      }
    })
  }

  get = (url, query, config={}, options={}) => {
    return this.request({
      method: this.methods.GET, 
      url, config, options, query
    });
  }

  stream = (url, query={}, config={}, options={}) => {
    return this.request({
      method: this.methods.STREAM, 
      url, config, options, query
    });
  }

  post = (url, data, config={}, options={}) => {
    return this.request({
      method: this.methods.POST, 
      url, data, config, options
    });
  }

  put = (url, data, config={}, options={}) => {
    return this.request({
      method: this.methods.PUT, 
      url, data, config, options
    });
  }

  delete = (url, config={}, options={}) => {
    return this.request({
      method: this.methods.DELETE, 
      url, config, options
    });
  }

  upload = (url, file, data, config={}, options={}) => {
    let opt = Object.assign({
      fileLabel: 'file',
      fileName: null
    }, options)
    let formData = this.fileToFormData(file, data, opt.fileLabel, opt.fileName) 
    return this.request({
      method: this.methods.POST,
      url, data: formData, config, options
    })
  }

  download = async (url, data, config={}, options={}) => {
    config = Object.assign({
      method: 'get',
      responseType: 'arraybuffer',
    }, config)

    options = Object.assign({
      fileType: null, 
      fileName: null
    }, options)
    return new Promise(async (resolve, reject) => {
      try{
        let res = await ( config.method === 'post' ? this.post(url, data, config) : this.get(url, {}, config));
        let fileName = options.fileName || res.headers['content-disposition'].replace(/"/g,'').split('filename=')[1];
        let type = options.fileType || res.headers['content-type'];
        
        const blob = new Blob([res.data], {type: type})
        fileSaver.saveAs(blob, fileName);
        resolve();
      }catch(error){
        reject(error);
      }
    })
  }

  downloadImage = (url, query, config={}, options={}) => {
    config = Object.assign({
      method: 'get',
      responseType: 'blob',
    }, config)

    return this.request({
      method: this.methods.GET, 
      url, config, options, query
    });
  }

  fileToFormData = (file, meta = {}, fileLabel='file', fileName) => {
    const data = new FormData();
    data.append(fileLabel, file, fileName || file.name );
    const keys = Object.keys(meta);
    for(let i = 0 ; i < keys.length ; i++){
      const key = keys[i];
      data.append(key, meta[key]);
    }
    return data;
  }

  objectToFormData = (data={}) => {
    const result = new FormData();
    const keys = Object.keys(data);
    for(let i = 0 ; i < keys.length ; i++){
      const key = keys[i];
      result.append(key, data[key]);
    }
    return result;
  }

  mapQueryCriteria = (query) => {
    let newQuery = {}
    const { page, size, keyword, column, columnCriteria, sort, sortBy, startDate, endDate, ...others  } = query
    if(keyword && keyword !== '' && column) newQuery[`${column}.${columnCriteria}`] = columnCriteria === 'in' ? keyword.split(',').toString() : keyword
    if(size) newQuery.size = size
    if(page) newQuery.page = page - 1
    if(sort && sortBy) newQuery.sort = `${sortBy},${sort}`
    if(startDate) newQuery.startDate = startDate
    if(endDate) newQuery.endDate = endDate

    for(let o of Object.keys(others)){
      if(others[o] !== null && others[o] !== undefined && others[o] !== '__NULL__')
        newQuery[o] = others[o]
    }

    return newQuery
  }

  mapQueryCriteriaMultiColumn = query => {
    let newQuery = {}
    const { page, size, keyword, column, columnCriteria, sort, sortBy, startDate, endDate, ...others } = query
    if(size) newQuery.size = size
    if(page) newQuery.page = page - 1
    if(sort && sortBy) newQuery.sort = `${sortBy},${sort}`
    if(startDate) newQuery.startDate = startDate
    if(endDate) newQuery.endDate = endDate

    for(let o of Object.keys(others).filter(d => (!d.includes('.')))){
      let criteria = others[`${o}.criteria`]
      if(others[o] && others[o] !== '' && ( others[o] !== '--NULL--' || others[o] !== '__NULL__')) 
        newQuery[`${o}.${criteria || 'equals'}`] = others[o]
    }
    
    return newQuery
  }

  totalItemWillReceive = ( page, size, total) => {
    let tPage = total < size ? 1 : Math.ceil(total/size);
    let result = page === tPage ? (total - ((tPage-1)*size)) : size;
    return result;
  }
}

export default new http();