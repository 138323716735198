import React, {useState, useEffect} from 'react'
import { storage } from '../../../../../services'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { http } from '../../../../../libs/react-mpk/services'
import LampI from '../LampI'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_HeaderLampKhus1A
const columns = {
    JmlSutFis:"jmlSutFis",
    JmlSutKom:"jmlSutKom",
    JmlSelSut:"jmlSelSut",
    JmlAmFis:"jmlAmFis",
    JmlAmKom:"jmlAmKom",
    JmlSelAm:"jmlSelAm"
}

const recon = (
    data = storage.get(skey),
    submitting=true
) => {
    return new Promise(async (resolve, reject) => {
        try{
            // data.JmlSutFis = detailLampKhus1A.list.reduce((a, b) => (a + b.SusutFiskal), 0)
            if(submitting){
                let submission = storage.get(storage.keys.SPT_SUBMISSION)
                let resSumPenyusutan = await http.get(`/penyusutan/${submission.fileName}/summary?field=susutFiskal`)
                let resSumAmortisasi = await http.get(`/amortisasi/${submission.fileName}/summary?field=susutFiskal`)
                
                let __sumPenyusutan = resSumPenyusutan.data[0] || {sum: 0}
                let __sumAmortisasi = resSumAmortisasi.data[0] || {sum: 0}

                data.JmlSutFis = typeof __sumPenyusutan.sum === 'object' ? Number(__sumPenyusutan.sum.$long) : __sumPenyusutan.sum
                data.JmlAmFis = typeof __sumAmortisasi.sum === 'object' ? Number(__sumAmortisasi.sum.$long) : __sumAmortisasi.sum
            }
            data.JmlSelSut = data.JmlSutFis - data.JmlSutKom//Math.abs(data.JmlSutFis - data.JmlSutKom)
            data.JmlSelAm = data.JmlAmFis - data.JmlAmKom//Math.abs(data.JmlAmFis - data.JmlAmKom)
            if(submitting) {
                storage.update(skey, data)
                await LampI.recon()
            }
            resolve(data)
        }catch(error){
            reject(error)
        }
    })
}

const generate = async () => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })())

    return data
}

const HeaderLampKhus1A = ({
    getLabel,
    ...props
}) => {
    const { submission } = props.temporaryStore.properties.submission
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    const handleUpdateData = async (data) => {
        return new Promise(async resolve => {
            data = await recon(data, false)
            resolve(data)
        })
    }

    useEffect(async () => {
        // await recon()
        // let __data = storage.get(skey)
        let defData = await recon() //await handleUpdateData(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-HeaderLampKhus1A"
            className="mpk-margin-C"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={Object.keys(columns).map(key=>({
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: getLabel(key, 'HeaderLampKhus1A'),
                isNumericString:true,
                decimalSeparator: ',',
                thousandSeparator:'.',
                isNumericString: true,
                disabled: ['JmlSutFis','JmlSelSut', 'JmlAmFis', 'JmlSelAm'].indexOf(key) >= 0,
                key
            }))}
            onChange={async data => {
                data = await handleUpdateData(data)
                return data
            }}
            onSubmit={(data, callback) => {
                callback(t.translate('sentence.savedItem'), false)
                storage.update(skey, data)
                props.onNextTab()
            }}
            submitLabel={t.translate('column.next')}
        />
    )
}

HeaderLampKhus1A.generate = generate
HeaderLampKhus1A.columns = columns
HeaderLampKhus1A.recon = recon

export default inject('temporaryStore')(observer(HeaderLampKhus1A))
