import md5 from 'fast-md5';
import SplitFile from 'js-split-file';
import {uid} from 'uid';
// import { PDFDocument } from 'pdf-lib';

export const checkSum = file => {
  return new Promise(function(resolve,reject){
    let fr = new FileReader();
    fr.readAsBinaryString(file);
    fr.onload = function(e){
      resolve(md5(e.target.result.toString()))
    }
  })
}

// export const chunk = async (file, chunkSize = 1024, getChecksum = false, randomChecksum = false, expectedChecksum = null, pdfType = "") => {
//   return new Promise(async (resolve, reject) => {
//     if (!file) {
//       reject('file is required');
//       return;
//     }

//     // Read the file as an array buffer
//     let reader = new FileReader();
//     reader.readAsArrayBuffer(file);

//     reader.onload = async function () {
//       let binary = new Uint8Array(reader.result);
//       let result = { blobs: [] };

//       // Calculate the checksum of the original file
//       if (getChecksum) {
//         let binaryString = new TextDecoder().decode(binary);
//         let originalChecksum = md5(binaryString);

//         if (expectedChecksum && originalChecksum !== expectedChecksum) {
//           if(pdfType != ""){
//             reject(`Type PDF ${pdfType} with File ${file.name} is corrupted`);
//           } else {
//             reject(`File ${file.name} is corrupted`);
//           }
//           return;
//         }

//         result.originalChecksum = originalChecksum;

//         if (randomChecksum) {
//           result.checksum = uid(32);
//         } else {
//           result.checksum = originalChecksum;
//         }
//       }

//       // Check if the file is a PDF and if it can be opened
//       // if (file.type === 'application/pdf') {
//       //   try {
//       //     await PDFDocument.load(reader.result);
//       //   } catch (error) {
//       //     if(pdfType != ""){
//       //       reject(`Type PDF ${pdfType} with File ${file.name} corrupted and cannot be opened`);
//       //     } else {
//       //       reject(`PDF file ${file.name} is corrupted and cannot be opened`);
//       //     }
//       //     return;
//       //   }
//       // }

//       // Check if file size is greater than 1MB (1 * 1024 * 1024 bytes)
//       if (file.size <= 1 * 1024 * 1024) {
//         // If file size is less than or equal to 1MB, return the file as a single blob
//         result.blobs.push(file);
//         resolve(result);
//       } else {
//         // If file size is greater than 1MB, perform chunking
//         const splitFile = new SplitFile();
//         const blocks = splitFile.split(binary, file.name, chunkSize);

//         for (let block of blocks) {
//           let piece = splitFile.decodeBlock(block);
//           result.blobs.push(new Blob([piece.binary], { type: file.mimetype }));
//         }

//         resolve(result);
//       }
//     };

//     reader.onerror = () => {
//       if(pdfType != ""){
//         reject(`Type PDF ${pdfType} with File ${file.name} error read file`);
//       } else {
//         reject(`Error reading file ${file.name}`);
//       }
//     };
//   });
// };

export const chunk = (file, chunkSize = 1024, getChecksum = false, randomChecksum = false) => {
  return new Promise((resolve, reject) => {
    if(file){
      let reader = new FileReader();
      let result = {blobs:[]};
      reader.readAsArrayBuffer(file);
      reader.onload = function(){
        let binary = new Uint8Array(reader.result);

        const splitFile = new SplitFile();
        const blocks = splitFile.split(binary, file.name, chunkSize);

        for(let block of blocks){
          let piece = splitFile.decodeBlock(block);
          result.blobs.push(new Blob([piece.binary], {type: file.mimetype}))
        }

        if(getChecksum) {
          if(randomChecksum){
            result.checksum = uid(32);
          }else{
            let binaryString = new TextEncoder().encode(binary);//String.fromCharCode.apply(null, binary);
            binaryString = binaryString.toString();
            result.checksum = md5(binaryString);
          }
        }

        resolve(result);
      }
    }else{
      reject('file is required');
    }
  })
}

export const b64ToBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  let byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, {type: contentType});
  return blob;
}