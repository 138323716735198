import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { sso } from "../libs/react-mpk/hocs";
import { PageWrapper } from "../libs/react-mpk/wrapper";
import { http, toast } from "../libs/react-mpk/services";
import { Submission, Certificate } from "../modules";
import { LoaderInfo } from "../libs/react-mpk/components";
import LogExport from '../modules/Submission/LogExport/LogExport'
import t from "counterpart";

class Internal extends React.Component {
  state = {
    checkingNpwp: true,
    showRegistration: false,
  };

  async componentDidMount() {
    try {
      window.addEventListener("internal-event", (e) => {
        let { eventType } = e.detail;
        switch (eventType) {
          case "show-registration":
            this.setState({ showRegistration: true });
            break;
          default:
            return;
        }
      });

      if (this.props.authStore.user.isSupport) {
        this.setState({
          checkingNpwp: false,
          showRegistration: false,
        });
      } else {
        let res = await http.get(`/npwp/index/1771`);
        let npwp = res.data;

        if (npwp) this.props.temporaryStore.setProperties("npwp", npwp);
        // setTimeout(() =>
        this.setState({ checkingNpwp: false, showRegistration: !npwp });
        // , 500)
      }
    } catch (error) {
      this.setState({ checkingNpwp: false });
      toast.errorRequest(error);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("invernal-event", () => {}, false);
  }

  render() {
    let { history } = this.props;
    let { npwp } = this.props.temporaryStore.properties;
    return this.state.checkingNpwp ? (
      <LoaderInfo>{t.translate("sentence.checkingNpwp")}</LoaderInfo>
    ) : (
      <>
        <PageWrapper
          sidebarCollapsible={false}
          useSidebar={false}
          showNewsTicker={false}
          inverseTheme={true}
        >
          <Router history={history}>
            <Switch>
              <Route
                path="/internal/submissions"
                render={(props) => <Submission.List {...props} />}
              />
              <Route
                path="/internal/submissions-log-export"
                render={(props) => <LogExport {...props} />}
              />
              <Route
                path="/internal/submissions-log-export-v2/:filename/:referenceNo" // <- ini referenceNo
                render={(props) => <LogExport {...props} />}
              />
            </Switch>
            <Switch>
              <Route
                path="/internal/submission/spt-1771/:tab"
                render={(props) => <Submission.SPTForm.SPT1771 {...props} />}
              />
            </Switch>
          </Router>
        </PageWrapper>
        <Certificate.Upload
          visible={this.state.showRegistration}
          onRequestClose={() => {
            if (this.props.temporaryStore.properties.npwp) {
              this.setState({ showRegistration: false });
            } else {
              this.props.modalStore.showConfirm({
                title: t.translate("mpk.column.logout"),
                children: t.translate("mpk.sentence.logoutMessage"),
                onSubmit: () => {
                  this.props.authStore.logout();
                },
              });
            }
          }}
        />
      </>
    );
  }
}

export default sso({
  basePath: "/internal",
})(Internal);
