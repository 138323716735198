import React, { useState, useEffect } from "react";
import {
  DataForm,
  Label,
  LoaderInfo,
  DividerLabel,
} from "../../../../../libs/react-mpk/components";
import { rules, storage } from "../../../../../services";
import t from "counterpart";
import { round } from "../../../../../libs/react-mpk/services/number.service";
import { inject, observer } from "mobx-react";
import LampI from "../LampI";
import HeaderLampKhus2A from "../LampKhus2A";
import moment from "moment";

const skey = storage.keys.SPT_1771_LampKhus4A;

const { inputTypes } = DataForm;

const columns = {
  NoSP: "noSp",
  TglSP: "tglSp",
  NoSK: "noSk",
  TglSK: "tglSk",
  Valas: "valas",
  JmlVal: "jmlVal",
  JmlEQ: "jmlEQ",
  JmlRp: "jmlRp",
  TotRp: "totRp",
  ChkB1: "chkB1",
  ChkB2: "chkB2",
  BidC: "bidC",
  Chkd1: "chkD1",
  Chkd2: "chkD2",
  Chkd3: "chkD3",
  Chkd4: "chkD4",
  ThRugi: {
    key: "thRugi",
    value: (d) => Number(d),
    reverseValue: (d) => String(d),
  },
  d3a: "d3a",
  d3b: "d3b",
  TglKom: "tglKom",
  ThNet: {
    key: "thNet",
    value: (d) => Number(d),
    reverseValue: (d) => String(d),
  },
  JmlNet: "jmlNet",
};

const generate = () => {
  return new Promise(async (resolve) => {
    let profile = storage.get(storage.keys.SPT_1771_Profile);
    let data = await storage.generate(skey, {
      NoSP: "",
      TglSP: "",
      NoSK: "",
      TglSK: "",
      Valas: "USD",
      JmlVal: 0,
      JmlEQ: 0,
      JmlRp: 0,
      TotRp: 0,
      ChkB1: false,
      ChkB2: false,
      BidC: "",
      Chkd1: false,
      Chkd2: false,
      Chkd3: false,
      Chkd4: false,
      ThRugi: "6",
      d3a: 0,
      d3b: 0,
      TglKom: `${profile.THN_PJK}-01-01`,
      ThNet: "",
      JmlNet: 0,
    });

    resolve(data);
  });
};

const LampKhus4A = ({ getLabel, envStore, ...props }) => {
  const profile = storage.get(storage.keys.SPT_1771_Profile);
  const [defaultData, setDefaultData] = useState();
  const [ready, setReady] = useState(false);
  const [definitions, setDefinitions] = useState([]);

  const handleUpdateData = (data, key) => {
    data.TotRp = data.JmlEQ + data.JmlRp;
    if (key === "Chkd4") {
      // data.chkd4 = Number(data.Chkd4);
    }
    if (key === "Chkd3") {
      // data.chkd3 = Number(data.Chkd3);
      data.ThRugi = "6"; //data.Chkd3 ? '6' : '6'
    }

    if (key === "Chkd2") {
      // data.chkd2 = Number(data.Chkd2);
    }
    if (key === "Chkd1") {
      // data.chkd1 = Number(data.Chkd1);
      data.ThNet = data.Chkd1 ? "1" : "";
    }

    if (key === "Chkd1" || key === "d3b")
      data.JmlNet = data.Chkd1 ? round((5 * data.d3b) / 100) : 0;
    return data;
  };

  useEffect(async () => {
    let __data = await storage.get(skey);
    let defData = handleUpdateData(__data);

    storage.update(skey, defData);
    setDefaultData(defData);
    setReady(true);
  }, []);

  return ready ? (
    <DataForm
      baseId="mod-submission-spt-1771-LampKhus4A"
      className="mpk-margin-C mpk-animation slide-in"
      style={{ maxWidth: 1024 }}
      hintMessage={props.errorMessage}
      asDialog={false}
      defaultData={defaultData}
      definitions={[
        {
          render: (
            <DividerLabel>{getLabel("__label1", "LampKhus4A")}</DividerLabel>
          ),
        },
        {
          render: <Label>{getLabel("__labelSP", "LampKhus4A")}</Label>,
        },
        {
          inputType: inputTypes.INPUT,
          label: getLabel("NoSP", "LampKhus4A"),
          key: "NoSP",
          validation: "max:50",
          width: "50%",
          className: "mpk-padding-S padding-right",
        },
        {
          inputType: inputTypes.DATE,
          label: getLabel("TglSP", "LampKhus4A"),
          key: "TglSP",
          required: true,
          show: (d) => d.NoSP !== "",
          width: "50%",
        },
        {
          render: <Label>{getLabel("__labelSK", "LampKhus4A")}</Label>,
        },
        {
          inputType: inputTypes.INPUT,
          label: getLabel("NoSK", "LampKhus4A"),
          key: "NoSK",
          validation: "max:50",
          width: "50%",
          className: "mpk-padding-S padding-right",
        },
        {
          inputType: inputTypes.DATE,
          label: getLabel("TglSK", "LampKhus4A"),
          key: "TglSK",
          required: true,
          show: (d) => d.NoSK !== "",
          width: "50%",
        },
        {
          render: (
            <DividerLabel className="mpk-margin-M margin-top mpk-padding-S padding-bottom">
              {getLabel("__label2", "LampKhus4A")}
            </DividerLabel>
          ),
        },
        {
          inputType: inputTypes.SELECT,
          label: getLabel("Valas", "LampKhus4A"),
          key: "Valas",
          options: envStore.env.spt.kodeValas,
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: getLabel("JmlVal", "LampKhus4A"),
          key: "JmlVal",
          isNumericString: true,
          decimalSeparator: ",",
          thousandSeparator: ".",
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: getLabel("JmlEQ", "LampKhus4A"),
          key: "JmlEQ",
          isNumericString: true,
          decimalSeparator: ",",
          thousandSeparator: ".",
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: getLabel("JmlRp", "LampKhus4A"),
          key: "JmlRp",
          isNumericString: true,
          decimalSeparator: ",",
          thousandSeparator: ".",
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: getLabel("TotRp", "LampKhus4A"),
          key: "TotRp",
          isNumericString: true,
          decimalSeparator: ",",
          thousandSeparator: ".",
          disabled: true,
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: getLabel("ChkB1", "LampKhus4A"),
          key: "ChkB1",
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: getLabel("ChkB2", "LampKhus4A"),
          key: "ChkB2",
        },
        {
          inputType: inputTypes.INPUT,
          label: getLabel("BidC", "LampKhus4A"),
          key: "BidC",
        },
        {
          render: <Label>{getLabel("__labelFasilitas", "LampKhus4A")}</Label>,
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: getLabel("Chkd1", "LampKhus4A"),
          key: "Chkd1",
        },
        {
          inputType: inputTypes.SELECT,
          label: getLabel("ThNet", "LampKhus4A"),
          key: "ThNet",
          options: ["1", "2", "3", "4", "5", "6"],
          show: (d) => d.Chkd1,
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: getLabel("Chkd2", "LampKhus4A"),
          key: "Chkd2",
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: getLabel("Chkd4", "LampKhus4A").replace("*percent", "%"),
          key: "Chkd4",
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: getLabel("Chkd3", "LampKhus4A"),
          key: "Chkd3",
        },
        {
          inputType: inputTypes.SELECT,
          label: getLabel("ThRugi", "LampKhus4A"),
          key: "ThRugi",
          options: ["6", "7", "8", "9", "10"],
          show: (d) => d.Chkd3,
        },
        {
          render: (
            <DividerLabel className="mpk-margin-M margin-top mpk-padding-S padding-bottom">
              {getLabel("__label3", "LampKhus4A")}
            </DividerLabel>
          ),
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: getLabel("d3a", "LampKhus4A"),
          key: "d3a",
          isNumericString: true,
          decimalSeparator: ",",
          thousandSeparator: ".",
          allowNegative: false,
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: getLabel("d3b", "LampKhus4A"),
          key: "d3b",
          isNumericString: true,
          decimalSeparator: ",",
          thousandSeparator: ".",
          allowNegative: false,
        },
        {
          inputType: inputTypes.DATE,
          label: getLabel("TglKom", "LampKhus4A"),
          key: "TglKom",
          required: false,
          className:
            "mpk-margin-M margin-top mpk-border thin solid dark border-top mpk-padding-M padding-top",
          max: `${profile.THN_PJK}-12-31`,
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: getLabel("JmlNet", "LampKhus4A"),
          key: "JmlNet",
          isNumericString: true,
          decimalSeparator: ",",
          thousandSeparator: ".",
          disabled: true,
        },
      ]}
      onChange={(data, key) => {
        return handleUpdateData(data, key);
      }}
      onSubmit={async (data, callback) => {
        storage.update(skey, data);
        await HeaderLampKhus2A.recon();
        LampI.recon();
        callback(t.translate("sentence.savedItem"), false);
        props.onNextTab();
      }}
      submitLabel={t.translate("column.next")}
    />
  ) : (
    <LoaderInfo>Loading data...</LoaderInfo>
  );
};

LampKhus4A.generate = generate;
LampKhus4A.columns = columns;

export default inject("envStore")(observer(LampKhus4A));
