
import React, {useState, useEffect} from 'react'
import { ExpansionList, ExpansionPanel, usePanels } from 'react-md'
import { Hint } from '../../../../../libs/react-mpk/components'
import LampKhus3AI from './LampKhus3AI'
import LampKhus3AII from './LampKhus3AII'
import LampKhus3A1 from './LampKhus3A1'
import LampKhus3A2I from './LampKhus3A2I'
import LampKhus3A2II from './LampKhus3A2II'

const generate = () => (new Promise(async resolve => {
    await LampKhus3A1.generate()
    await LampKhus3A2I.generate()
    await LampKhus3A2II.generate()
    await LampKhus3AI.generate()
    await LampKhus3AII.generate()
    resolve()
}))

const LampKhus3 = ({
    getLabel,
    onNextTab,
    ...props
}) => {
    const [ready, setReady] = useState(false)
    const [panels, onKeyDown] = usePanels({
        idPrefix: 'spt-1771-LampKhus3',
        count:2,
        defaultExpandedIndex: 0
    })
    const [panels2, onKeyDown2] = usePanels({
        idPrefix: 'spt-1771-LampKhus3A',
        count:3,
        defaultExpandedIndex: 0
    })
    const [panels3, onKeyDown3] = usePanels({
        idPrefix: 'spt-1771-LampKhus3A',
        count:2,
        defaultExpandedIndex: 0
    })


    useEffect(() => {
        setReady(true)
    }, [])

    const [panel1Props, panel2Props] = panels;
    const [panel1Props2, panel2Props2, panel3Props2] = panels2
    const [panel1Props3, panel2Props3] = panels3

    return ready && (
        <div
            className="mpk-margin-C mpk-animation slide-in"
            style={{
                width: '100%',
                maxWidth: 1024
            }}
        >
            {props.errorMessage && 
                <div className="mpk-paper mpk-padding-N padding-all">
                    <Hint>{props.errorMessage}</Hint>
                </div>
            }
            <ExpansionList onKeyDown={onKeyDown}>
                {[
                    {
                        label: getLabel('__title', 'LampKhus3'),
                        render: (
                            <ExpansionList onKeyDown={onKeyDown2}>
                                {[
                                    {
                                        label: getLabel('__title', 'LampKhus3AI'),
                                        render: <LampKhus3AI {...{getLabel, onNextTab}}/>,
                                        panelProps: panel1Props2
                                    },
                                    {
                                        label: getLabel('__title', 'LampKhus3AII'),
                                        render: <LampKhus3AII {...{getLabel, onNextTab}}/>,
                                        panelProps: panel2Props2
                                    },
                                    {
                                        label: getLabel('__title', 'LampKhus3A1'),
                                        render: <LampKhus3A1 {...{getLabel, onNextTab}}/>,
                                        panelProps: panel3Props2
                                    },
                                ].map((p, i) => (
                                    <ExpansionPanel 
                                        key={`spt-1771-LampKhus3A-panel-${i}`}
                                        {...p.panelProps}
                                        header={<div className="mpk-font size-M weight-M">{p.label}</div>}
                                        style={{background: '#fafafa'}}
                                    >
                                        {p.render}
                                    </ExpansionPanel>
                                ))}
                            </ExpansionList>
                        ),
                        panelProps: panel1Props
                    },
                    {
                        label: getLabel('___title', 'LampKhus3A2I'),
                        render: (
                            <ExpansionList onKeyDown={onKeyDown3}>
                                {[
                                    {
                                        label: getLabel('__title', 'LampKhus3A2I'),
                                        render: <LampKhus3A2I {...{getLabel, onNextTab}}/>,
                                        panelProps: panel1Props3
                                    },
                                    {
                                        label: getLabel('__title', 'LampKhus3A2II'),
                                        render: <LampKhus3A2II {...{getLabel, onNextTab}}/>,
                                        panelProps: panel2Props3
                                    },
                                ].map((p, i) => (
                                    <ExpansionPanel 
                                        key={`spt-1771-LampKhus3A-panel-${i}`}
                                        {...p.panelProps}
                                        header={<div className="mpk-font size-M weight-M">{p.label}</div>}
                                        style={{background: '#fafafa'}}
                                    >
                                        {p.render}
                                    </ExpansionPanel>
                                ))}
                            </ExpansionList>
                        ),
                        panelProps: panel2Props
                    }
                ].map((p, i) => (
                    <ExpansionPanel 
                        key={`spt-1771-LampKhus3-panel-${i}`}
                        {...p.panelProps}
                        header={<div className="mpk-font size-M weight-M">{p.label}</div>}
                    >
                        {p.render}
                    </ExpansionPanel>
                ))}
            </ExpansionList>
        </div>
    )
}

LampKhus3.generate = generate

export default LampKhus3
