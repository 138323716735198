import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Labarugi13
const columns = {
    ID763: 'id763',
    ID764: 'id764',
    ID765: 'id765',
    ID766: 'id766',
    ID767: 'id767',
    ID768: 'id768',
    ID769: 'id769',
    ID770: 'id770',
    ID771: 'id771',
    ID772: 'id772',
    ID773: 'id773',
    ID774: 'id774',
    ID775: 'id775',
}

const recon = (
    data = storage.get(skey),
    submitting=true
) => {
    return new Promise(async (resolve) => {
        data.ID765 =    data.ID763 - data.ID764
        data.ID772 =    data.ID766 + data.ID767 + data.ID768 +
                        data.ID769 + data.ID770 + data.ID771
        data.ID775 =    data.ID765 - data.ID772 + data.ID773 -
                        data.ID774

         if(submitting) {   
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async (isReset) => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })(), isReset)

    return data
}

const Labarugi13 = ({
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-Labarugi13"
            className="mpk-margin-C mpk-animation slide-in"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={Object.keys(columns).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Labarugi13'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    isNumericString: true,
                    width: [
                        'ID763',
                        'ID764',
                        'ID766',
                        'ID767',
                        'ID768',
                        'ID769',
                        'ID770',
                        'ID771',
                        'ID773',
                        'ID774'
                    ].indexOf(key) >= 0 ? '50%' : '100%',
                    className: [
                        'ID763',
                        'ID766',
                        'ID768',
                        'ID770',
                        'ID773',
                    ].indexOf(key) >= 0  ? 'mpk-padding-S padding-right' : '',
                    disabled: [
                        'ID765',
                        'ID772',
                        'ID775'
                    ].indexOf(key) >= 0,
                    key
                }))
            }
            onChange={async data => {
                data = await recon(data, false)
                return data
            }}
            onSubmit={async (data, callback) => {
                await storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    ) : <LoaderInfo/>
}

Labarugi13.generate = generate
Labarugi13.columns = columns
Labarugi13.recon = recon

export default Labarugi13
