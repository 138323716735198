import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Neraca3
const columns = {
    ID133: 'id133',
    ID134: 'id134',
    ID135: 'id135',
    ID136: 'id136',
    ID137: 'id137',
    ID138: 'id138',
    ID139: 'id139',
    ID140: 'id140',
    ID141: 'id141',
    ID142: 'id142',
    ID143: 'id143',
    ID144: 'id144',
    ID145: 'id145',
    ID146: 'id146',
    ID147: 'id147',
    ID148: 'id148',
    ID149: 'id149',
    ID150: 'id150',
    ID151: 'id151',
    ID152: 'id152',
    ID153: 'id153',
    ID154: 'id154',
    ID155: 'id155',
    ID156: 'id156',
    ID157: 'id157',
    ID158: 'id158',
    ID159: 'id159',
    ID160: 'id160',
    ID161: 'id161',
    ID162: 'id162',
    ID163: 'id163',
    ID164: 'id164',
    ID165: 'id165',
    ID166: 'id166',
    ID167: 'id167',
    ID168: 'id168',
    ID169: 'id169',
    ID170: 'id170',
    ID171: 'id171',
    ID172: 'id172',
    ID173: 'id173',
    ID174: 'id174',
}

const recon = (
    data = storage.get(skey),
    submitting = true
) => {
    return new Promise(async (resolve) => {
        data.ID153 = data.ID133 + data.ID134 + data.ID135 + data.ID136 +
            data.ID137 + data.ID138 + data.ID139 + data.ID140 + data.ID141 +
            data.ID142 + data.ID143 + data.ID144 + data.ID145 + data.ID146 -
            data.ID147 + data.ID148 + data.ID149 + data.ID150 + data.ID151 +
            data.ID152

        data.ID174 = data.ID154 + data.ID155 + data.ID156 + data.ID157 +
            data.ID158 + data.ID159 + data.ID160 + data.ID161 + data.ID162 +
            data.ID163 + data.ID164 + data.ID165 + data.ID166 + data.ID167 +
            data.ID168 + data.ID169 + data.ID170 + data.ID171 + data.ID172 +
            data.ID173

        if (submitting) {
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async (isReset = false) => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for (let k of Object.keys(columns)) {
            result[k] = 0
        }

        return result
    })(), isReset)

    return data
}

const Neraca3Aktiva = ({
    getLabel,
    asKewajiban,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-Neraca3"
            className="mpk-margin-C mpk-animation slide-in"
            style={{ maxWidth: 'unset' }}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: getLabel('__aktiva', 'Neraca3')
                // },
                ...(asKewajiban ? [] : [
                    'ID133',
                    'ID134',
                    'ID135',
                    'ID136',
                    'ID137',
                    'ID138',
                    'ID139',
                    'ID140',
                    'ID141',
                    'ID142',
                    'ID143',
                    'ID144',
                    'ID145',
                    'ID146',
                    'ID147',
                    'ID148',
                    'ID149',
                    'ID150',
                    'ID151',
                    'ID152',
                    'ID153'
                ]).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Neraca3'),
                    isNumericString: true,
                    decimalSeparator: ',',
                    thousandSeparator: '.',
                    isNumericString: true,
                    allowNegative: key === 'ID139' ? true : false,
                    width: key != 'ID153' ? '50%' : '100%',
                    className: i % 2 && key != 'ID153' ? '' : 'mpk-padding-S padding-right',
                    disabled: key === 'ID153',
                    key
                })),
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: getLabel('__kewajibanDanEkuitas', 'Neraca3')
                // },
                ...(asKewajiban ? [
                    'ID154',
                    'ID155',
                    'ID156',
                    'ID157',
                    'ID158',
                    'ID159',
                    'ID160',
                    'ID161',
                    'ID162',
                    'ID163',
                    'ID164',
                    'ID165',
                    'ID166',
                    'ID167',
                    'ID168',
                    'ID169',
                    'ID170',
                    'ID171',
                    'ID172',
                    'ID173',
                    'ID174',
                ] : []).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Neraca3'),
                    isNumericString: true,
                    decimalSeparator: ',',
                    thousandSeparator: '.',
                    allowNegative: ['ID171', 'ID172', 'ID173'].indexOf(key) >= 0,
                    width: ['ID154', 'ID155', 'ID160', 'ID29', 'ID161', 'ID166', 'ID167', 'ID174'].indexOf(key) < 0 ? '50%' : '100%',
                    className: i % 2 && ['ID154', 'ID155', 'ID160', 'ID161', 'ID166', 'ID167', 'ID174'].indexOf(key) < 0 ? '' : 'mpk-padding-S padding-right',
                    disabled: key === 'ID174',
                    key
                }))
            ]}
            onChange={async data => {
                data = await recon(data, false)
                return data
            }}
            onSubmit={async (data, callback) => {
                await storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    ) : <LoaderInfo />
}

Neraca3Aktiva.generate = generate
Neraca3Aktiva.columns = columns
Neraca3Aktiva.recon = recon

export default Neraca3Aktiva
