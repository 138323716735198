import React, { useState, useEffect } from "react";
import { DataForm, LoaderInfo } from "../../../../libs/react-mpk/components";
import t from "counterpart";
import * as service from "../../Submission.service";

import { inject, observer } from "mobx-react";
import { toast } from "../../../../libs/react-mpk/services";
import { find } from "lodash";
import { file, storage } from "../../../../services";
import { uid } from "uid";
import moment from "moment";
import renderHTML from "react-render-html";
import { Upload } from "../../../Certificate";
// import { PDFDocument } from 'pdf-lib';

const { inputTypes } = DataForm;
const Commit = ({
  visible = false,
  onRequestClose = () => {},
  onError = () => {},
  submission = null,
  ...props
}) => {
  const handleSetPdfDefinitions = (code, profile) => {
    profile = profile || storage.get(storage.keys[`SPT_${code}_Profile`]);

    if (profile) {
      switch (code) {
        default:
          return props.envStore.env.pdf[code];
      }
    } else return [];
  };

  const [spt, setSpt] = useState(null);
  const [sptData, setSptData] = useState(null);
  const [code, setCode] = useState("1770");
  const [saveOnly, setSaveOnly] = useState(true);
  const [uploadIdPdf, setUploadIdPdf] = useState([]);
  const [pdfDefinitions, setPdfDefinitions] = useState([]);
  const [committing, setCommitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isWaitingCertData, setIsWaitingCertData] = useState(false);
  const [isShowCertUpload, setIsShowCertUpload] = useState(false);

  const handleSubmitSpt = async (data, callback) => {
    if (!data)
      new Error(t.translate("modules.submission.sptForm.commit.emptySPT"));
    else {
      try {
        props.temporaryStore.setProperties("sptErrorData", null);

        /*
        if(props.envStore.widget.active){
          let { npwp } = sptData.profile
          // let resCert = await service.getNpwp(npwp)
          let resCert = await Promise.resolve({data: null})
          if(!resCert.data){
            setIsWaitingCertData(true)
            await new Promise((resolve, reject) => {
              props.envStore.widgetPost('request-cert', {npwp})
              window.addEventListener('cert-data', e => {
                window.removeEventListener('cert-data', () => {}, false)
                if(e.detail.npwp === npwp) resolve()
                else reject(new Error(t.translate('modules.submission.sptForm.commit.npwpNotMatch').replace('*current', e.detail.npwp).replace('*expect', npwp)))
              })
            })
          }
        }
        */

        const resSpt = await service[`post${code}`](
          sptData,
          props.envStore.widget.active
        );
        const onSaved = () => {
          onRequestClose();
          if (props.onSuccess) props.onSuccess(resSpt.data);
          props.modalStore.showConfirm({
            title: t.translate("modules.submission.sptForm.save.title"),
            children: t.translate(
              "modules.submission.sptForm.save.confirmMessage"
            ),
            onSubmit: (callback) => {
              props.envStore.setWidget({ spt: null });
              props.navigationStore.redirectTo(
                `/${
                  props.envStore.widget.active
                    ? "widget"
                    : props.envStore.env.applicationType
                }/submissions`
              );
              callback();
            },
          });
          setLoading(false);
          callback();
        };

        if (saveOnly) {
          if (props.authStore.user.isSupport) {
            onSaved();
          } else {
            let { npwp } = sptData.profile;
            let resCert = await service.getNpwp(npwp);
            // let resCert = await Promise.resolve({data: null})
            onSaved();
            if (!resCert.data) {
              setIsWaitingCertData(true);
              setIsShowCertUpload(true);
            }
          }
        } else {
          setSpt(resSpt.data.spt);
          setLoading(false);
          callback(null, false, false);
        }
      } catch (error) {
        let isErrorData = false;
        let errorValidator = null;

        if (error.response) {
          let { data } = error.response;
          if (data.validator) {
            errorValidator = data.validator;
          } else {
            if (data && data.errors) {
              isErrorData = true;
              let { errors } = data;
              let errorData = {};
              for (let err of errors) {
                let keys = Object.keys(err);
                let key = keys[0];
                errorData[key] = err[key].map((e) => e.error);
              }
              props.temporaryStore.setProperties("sptErrorData", errorData);
            }
          } //else toast.errorRequest(error)
        }

        callback(
          isErrorData
            ? new Error(t.translate("sentence.errorData"))
            : errorValidator
            ? new Error(String(errorValidator))
            : error
        );

        onRequestClose();
      }
    }
  };

  const handleCommit = async (data, callback) => {
    if (data.agree) {
      console.log(
        "🚀 ~ file: Commit.js ~ line 142 ~ handleCommit ~ data.agree",
        data.agree
      );
      setCommitting(true);
      try {
        const { name } = spt;
        await handleUploadPdf(data);
        setStatusMessage(
          t.translate("modules.submission.sptForm.commit.statusCommit")
        );
        await service.commitSPT(name);
        callback(
          t.translate("modules.submission.sptForm.commit.success"),
          false
        );

        if (props.onSuccess) props.onSuccess();
        if (props.onBack) props.onBack();
        else onRequestClose();
        storage.clearAll();
      } catch (error) {
        // onError(error)
        let isDcIssue2 = String(error.message).match(
          /Anda tidak mempunyai akses untuk mengelola NPWP*/gi
        );
        let isDcIssue = String(error.message).match(
          /sertifikat tidak ditemukan|(sertifikat elektronik.* (sudah habis|upload))/gi
        );
        console.log(isDcIssue, isDcIssue2, error.message);
        if (isDcIssue || isDcIssue2) {
          setIsShowCertUpload(true);
        } else {
          onRequestClose();
        }

        setCommitting(false);
        setStatusMessage("");
        callback(error, true, !isDcIssue);
      }
    } else {
      callback(
        new Error(t.translate("modules.submission.sptForm.notAgreeAlert")),
        true,
        false
      );
    }
  };

  const handleUploadPdf = (data) => {
    var maxSize = 100
    if(submission.uploadSize) maxSize = submission.uploadSize
    return new Promise(async (resolve, reject) => {
      try {
        const { chunkSize } = props.envStore.env;
        const MAX_TOTAL_UPLOAD_SIZE = maxSize * 1024 * 1024; // 100 MB in bytes
        let totalUploadSize = 0;
    
        // PDF validation function
        // const isCorruptPdf = (file) => {
        //   return new Promise((resolve, reject) => {
        //     const reader = new FileReader();
    
        //     reader.onload = function (event) {
        //       const arrayBuffer = event.target.result;
        //       const uint8Array = new Uint8Array(arrayBuffer);
    
        //       // Check PDF signature
        //       const signature = String.fromCharCode.apply(null, uint8Array.slice(0, 5));
        //       if (signature !== '%PDF-') {
        //         reject(new Error(`PDF file is corrupt: ${file.name} (Invalid PDF signature)`));
        //         return;
        //       }
    
        //       // Check EOF marker
        //       const eofMarker = String.fromCharCode.apply(null, uint8Array.slice(-5));
        //       if (eofMarker !== '%%EOF') {
        //         reject(new Error(`PDF file is corrupt: ${file.name} (Invalid PDF EOF marker)`));
        //         return;
        //       }
    
        //       resolve(true);
        //     };
    
        //     reader.onerror = function () {
        //       reject(new Error(`Error reading file: ${file.name}`));
        //     };
    
        //     reader.readAsArrayBuffer(file);
        //   });
        // };
    
        // Validate all PDFs first
        for (let def of pdfDefinitions) {
          if (data[def.pdfType]) {
            let f = data[def.pdfType];
            if (f) {
              totalUploadSize += f.size;
              if (totalUploadSize > MAX_TOTAL_UPLOAD_SIZE) {
                reject(new Error(`Total Ukuran PDF keseluruhan melebihi maximal ${maxSize} MB, Silahkan compress pdfnya atau hubungi support.`));
                return;
              }
    
              try {
                // await isCorruptPdf(f);
              } catch (validationError) {
                reject(validationError);
                return;
              }
            }
          }
        }
    
        // Proceed with uploading PDFs
        for (let def of pdfDefinitions) {
          if (data[def.pdfType]) {
            let f = data[def.pdfType];
            let responseUpload = null;
    
            if (f) {
              setStatusMessage(
                t
                  .translate(
                    `modules.submission.sptForm.commit.statusMessagePdf`
                  )
                  .replace("*name", f.name)
              );
    
              let chunking = await file.chunk(f, chunkSize, true, true, null, def.pdfType);
              let id = `${moment(props.envStore.env.timestamp).format(
                "YYYYMMDDHHmmss"
              )}${uid(2)}`;
              for (let i = 0; i < chunking.blobs.length; i++) {
                let bl = chunking.blobs[i];
                let data = {
                  id,
                  name: f.name,
                  pdfType: def.pdfType,
                  size: f.size,
                  offset: i,
                };
    
                if (responseUpload) data.uploadId = responseUpload.uploadId;
                let res = await service.uploadPDF(spt.name, bl, data);
                if (!responseUpload) {
                  uploadIdPdf.push(res.data.uploadId);
                  setUploadIdPdf(uploadIdPdf);
                }
                responseUpload = responseUpload || res.data;
              }
            }
          }
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  // const handleUploadPdf = (data) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const { chunkSize } = props.envStore.env;
  //       for (let def of pdfDefinitions) {
  //         if (data[def.pdfType]) {
  //           let f = data[def.pdfType];
  //           let responseUpload = null;

  //           if (f) {
  //             setStatusMessage(
  //               t
  //                 .translate(
  //                   `modules.submission.sptForm.commit.statusMessagePdf`
  //                 )
  //                 .replace("*name", f.name)
  //             );
  //             let chunking = await file.chunk(f, chunkSize, true, true, null, def.pdfType);
  //             let id = `${moment(props.envStore.env.timestamp).format(
  //               "YYYYMMDDHHmmss"
  //             )}${uid(2)}`;
  //             for (let i = 0; i < chunking.blobs.length; i++) {
  //               let bl = chunking.blobs[i];
  //               let data = {
  //                 id,
  //                 name: f.name,
  //                 pdfType: def.pdfType,
  //                 size: f.size,
  //                 offset: i,
  //               };

  //               if (responseUpload) data.uploadId = responseUpload.uploadId;
  //               let res = await service.uploadPDF(spt.name, bl, data);
  //               if (!responseUpload) {
  //                 uploadIdPdf.push(res.data.uploadId);
  //                 setUploadIdPdf(uploadIdPdf);
  //               }
  //               responseUpload = responseUpload || res.data;
  //             }
  //           }
  //         }
  //       }
  //       resolve();
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // };

  const checkingRequired = (d) => {
    let isRequired =
      code === "1770S" ? String(sptData.induk.E.flagE16) === "2" : d.required;

    // if(['RK', 'LK'].indexOf(d.pdfType) < 0){
    if (!d.required) {
      if (d.checklist) {
        for (let c of d.checklist) {
          if (sptData.induk[c.key] && sptData.induk[c.key] === "1") {
            isRequired = true;
            break;
          }
        }
      }
      // }
    }

    return isRequired;
  };

  const handlePdfDefaultValue = (pdfType) => {
    let uploadId = find(uploadIdPdf, { pdfType });
    if (uploadId) return uploadId;
    else return null;
  };

  useEffect(async () => {
    if (visible) {
      try {
        if (submission) {
          setLoading(true);
          let res = await service[
            props.authStore.user.isSupport ? "supportGetRawData" : "getRawData"
          ](submission.fileName);
          let { data, code, submissionName, referenceNo, uploadIdPdf } =
            res.data;
          setCode(code);
          setSptData(data);
          setPdfDefinitions(handleSetPdfDefinitions(code, data.profile));
          setSpt({ name: submissionName, referenceNo });
          setUploadIdPdf(uploadIdPdf);
          setSaveOnly(false);
          setCommitting(false);
          setLoading(false);
        } else {
          setCode(props.code);
          setSaveOnly(props.saveOnly);
          setSptData(props.sptData);
          setPdfDefinitions(
            handleSetPdfDefinitions(props.code, props.sptData.profile)
          );
          setUploadIdPdf([]);
          setCommitting(false);
          // setStatusMessage('')
        }
      } catch (error) {
        toast.errorRequest(error);
        onRequestClose();
      }
    } else {
      setTimeout(() => {
        setSpt(null);
        setSptData(null);
        setPdfDefinitions([]);
      }, 500);
    }
  }, [visible]);

  return (
    <>
      <DataForm
        baseId="mod-submission-sptform-commit"
        title={t.translate(
          saveOnly
            ? "modules.submission.sptForm.save.title"
            : `modules.submission.sptForm.commit.title`
        )}
        hintMessage={
          committing
            ? null
            : spt
            ? t.translate("modules.submission.sptForm.commit.pdfHint") + (submission.uploadSize ? `${submission.uploadSize} MB` : '100 MB')
            : ""
        }
        asDialog={true}
        visible={visible}
        onRequestClose={onRequestClose}
        defaultData={{}}
        style={{ width: "100%", maxWidth: 1024 }}
        definitions={
          loading
            ? [
                {
                  render: (
                    <LoaderInfo>
                      {t.translate("sentence.loadingData")}
                    </LoaderInfo>
                  ),
                },
              ]
            : committing
            ? [
                {
                  render: (
                    <div className="mpk-align align-center mpk-font size-L">
                      {statusMessage}
                    </div>
                  ),
                },
              ]
            : spt
            ? [
                ...pdfDefinitions.map((d) => {
                  let defaultValue = handlePdfDefaultValue(d.pdfType);
                  return {
                    inputType: inputTypes.FILE_INPUT,
                    label: d.label,
                    key: d.pdfType,
                    value: defaultValue,
                    required: checkingRequired(d),
                    // helpText: t.translate('modules.submission.sptForm.commit.pdfFileNameInfo').replace('*name', `${spt.name}${d.pdfType}.pdf`),
                    placeholder: d.checklist.map((c, i) =>
                      t.translate(
                        code === "1771"
                          ? `modules.submission.sptForm.1771.pdf.${c.label}`
                          : `modules.submission.sptForm.${code}.Induk.${c.label}`
                      )
                    ),
                    // helpText: d.checklist ? (
                    //   <div className="mpk-padding-N padding-right">
                    //     {/* <p>{t.translate('modules.submission.sptForm.commit.attachmentItemInfo')}</p> */}
                    //     {/* <ul className="mpk-margin-NONE margin-all mpk-padding-N padding-left"> */}
                    //       {d.checklist.map((c, i) => (
                    //         <div key={`commit-pdf-${i}`}>
                    //           {t.translate(code === '1771' ? `modules.submission.sptForm.1771.pdf.${c.label}` : `modules.submission.sptForm.${code}.Induk.${c.label}`)}
                    //         </div>
                    //       ))}
                    //     {/* </ul> */}
                    //   </div>
                    // ) : null,
                    accept: ".pdf",
                    style: { width: "20%" },
                  };
                }),
                {
                  inputType: inputTypes.CHECKBOX,
                  label: t.translate(
                    "modules.submission.sptForm.commit.submitHint"
                  ),
                  className: "mpk-margin-N margin-top",
                  key: "agree",
                },
              ]
            : [
                {
                  render: (
                    <div>
                      {renderHTML(
                        t.translate(`modules.submission.sptForm.save.saveHint`)
                      )}
                    </div>
                  ),
                },
              ]
        }
        onBeforeChange={(key, value) => {
          return new Promise(async (resolve, reject) => {
            try {
              if (value) {
                // const expectedFilename = `${spt.name}${key}.pdf`
                // if(value.name !== expectedFilename){
                //   toast.error(t.translate('modules.submission.sptForm.commit.unexpectedPDFFilename').replace('*name', expectedFilename))
                //   return null
                // } else {
                if (key === "LK" || key === "RK") {
                  let LK = find(pdfDefinitions, { pdfType: "LK" });
                  let RK = find(pdfDefinitions, { pdfType: "RK" });
                  if (LK) LK.required = false;
                  if (RK) RK.required = false;
                  setPdfDefinitions(pdfDefinitions);
                }
                resolve(value);
                // }
              } else {
                let existingUploadId = handlePdfDefaultValue(key);
                if (existingUploadId) {
                  await service.deletePDF(spt.name, existingUploadId.uploadId);
                  setUploadIdPdf(uploadIdPdf.filter((d) => d.pdfType !== key));
                }
                resolve(value);
              }
            } catch (error) {
              toast.errorRequest(error);
            }
          });
        }}
        onChange={(data, key) => {
          if (key === "LK" || key === "RK") {
            let LK = find(pdfDefinitions, { pdfType: "LK" });
            let RK = find(pdfDefinitions, { pdfType: "RK" });
            if (!data.RK && !data.LK) {
              RK.required = true;
              LK.required = true;
            } else {
              if (key === "LK") LK.required = data.RK === null;
              else if (key === "RK") RK.required = data.LK === null;
              setPdfDefinitions(pdfDefinitions);
            }
          }
          return data;
        }}
        onSubmit={spt ? handleCommit : handleSubmitSpt}
        submitLabel={t.translate(
          spt ? "column.next" : saveOnly ? "column.save" : "column.submit"
        )}
      />
      <Upload
        visible={isShowCertUpload}
        onRequestClose={() => setIsShowCertUpload(false)}
        npwp={sptData ? sptData.profile.npwp : null}
        onFinished={(cert) => {
          toast.success(
            t.translate("modules.submission.sptForm.certificateUploaded")
          );
        }}
      />
    </>
  );
};

export default inject(
  "envStore",
  "navigationStore",
  "temporaryStore",
  "modalStore",
  "authStore"
)(observer(Commit));
