import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { rules, storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { roundDecimal } from '../../../../../libs/react-mpk/services/number.service'
import HeaderLampV from './HeaderLampV'

const { inputTypes } = DataForm

const skey = storage.keys.SPT_1771_DetailLampVPengurus
const columns = {
    list:"list",
}

const itemColumns = {
    NPWP:{
        key: 'npwp',
        value: d => {
            return rules.npwpOptional(d)
        }
    },
    Nama:'nama',
    Alamat:'alamat',
    Nm_Jab: 'nmJab'
}

const generate = async () => {
    let data = await storage.generate(skey, {
        list: []
    })

    return data
}

const DetailLampVPengurus = ({
    getLabel,
    envStore,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    const handleUpdateData = (data) => {
        return data
    }

    useEffect(() => {
        let __data = storage.get(skey)
        let defData = handleUpdateData(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-DetailLampVPengurus"
            className="mpk-margin-C"
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            watchDefaultData={false}
            style={{maxWidth: 'unset'}}
            definitions={[
                {
                    inputType: inputTypes.LIST,
                    asTable: true,
                    label: getLabel('__title','DetailLampVPengurus'),
                    key: 'list',
                    defaultData:{
                        NPWP:'',
                        Nama:'',
                        Alamat:'',
                        Nm_Jab: ''
                    },
                    definitions:[
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('Nama', 'DetailLampVPengurus'),
                            key: 'Nama',
                            validation: 'max:50',
                            required: true
                        },
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('Alamat', 'DetailLampVPengurus'),
                            key: 'Alamat',
                            validation: 'max:255',
                            required: true
                        },
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('NPWP', 'DetailLampVPengurus'),
                            key: 'NPWP',
                            validation: data => (data.Nama.length > 0 ? 'size:15' : ''),
                            maxLength: 15,
                            minLength: 15,
                            required: true
                        },
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('Nm_Jab', 'DetailLampVPengurus'),
                            key: 'Nm_Jab',
                            validation: 'max:50',
                            required: true
                        }
                    ]
                }
            ]}
            onBeforeChange={(key, value) => {
                // if(key === 'NPWP') return rules.npwpOptional(value)
                return value
            }}
            onChange={(data, key, value, parentKey, indexAtParent) => {
                if(parentKey && indexAtParent !== null && key === 'JmlMdlRp'){
                    const dJmlMdlStr = data[parentKey].reduce((a, b) => (a+b.JmlMdlRp), 0)
                    for(let item of data[parentKey]){
                        item.dPctMdlStr = roundDecimal((item.JmlMdlRp*100)/dJmlMdlStr, true, 100)
                    }
                }

                return data
            }}
            onSubmit={async (data, callback) => {
                storage.update(skey, data)
                await HeaderLampV.recon()
                callback(t.translate('sentence.savedItem'), false)
            }}
        />
    )
}

DetailLampVPengurus.generate = generate
DetailLampVPengurus.columns = columns
DetailLampVPengurus.itemColumns = itemColumns

export default inject('envStore')(observer(DetailLampVPengurus))