import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Neraca13
const columns = {
    ID729: 'id729',
    ID730: 'id730',
    ID731: 'id731',
    ID732: 'id732',
    ID733: 'id733',
    ID734: 'id734',
    ID735: 'id735',
    ID736: 'id736',
    ID737: 'id737',
    ID738: 'id738',
    ID739: 'id739',
    ID740: 'id740',
    ID741: 'id741',
    ID742: 'id742',
    ID743: 'id743',
    ID744: 'id744',
    ID745: 'id745',
    ID746: 'id746',
    ID747: 'id747',
    ID748: 'id748',
    ID749: 'id749',
    ID750: 'id750',
    ID751: 'id751',
    ID752: 'id752',
    ID753: 'id753',
    ID754: 'id754',
    ID755: 'id755',
    ID756: 'id756',
    ID757: 'id757',
    ID758: 'id758',
    ID759: 'id759',
    ID760: 'id760',
    ID761: 'id761',
    ID762: 'id762'
}

const recon = (
    data = storage.get(skey),
    submitting=true
) => {
    return new Promise(async (resolve) => {
        data.ID754 =    data.ID729 + data.ID730 + data.ID731 + data.ID732 +
                        data.ID733 + data.ID734 + data.ID735 + data.ID736 + data.ID737 +
                        data.ID738 + data.ID739 + data.ID740 + data.ID741 + data.ID742 +
                        data.ID743 + data.ID744 + data.ID745 + data.ID746 + data.ID747 +
                        data.ID748 + data.ID749 + data.ID750 + data.ID751 + data.ID752 +
                        data.ID753 

        data.ID762 =    data.ID755 + data.ID756 + data.ID757 + data.ID758 +
                        data.ID759 + data.ID760 + data.ID761

        if(submitting) {
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async (isReset) => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })(), isReset)

    return data
}

const Neraca13 = ({
    getLabel,
    asKewajiban,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-Neraca13"
            className="mpk-margin-C mpk-animation slide-in"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: getLabel('__aktiva', 'Neraca13')
                // },
                ...(asKewajiban ? [] : [
                    'ID729',
                    'ID730',
                    'ID731',
                    'ID732',
                    'ID733',
                    'ID734',
                    'ID735',
                    'ID736',
                    'ID737',
                    'ID738',
                    'ID739',
                    'ID740',
                    'ID741',
                    'ID742',
                    'ID743',
                    'ID744',
                    'ID745',
                    'ID746',
                    'ID747',
                    'ID748',
                    'ID749',
                    'ID750',
                    'ID751',
                    'ID752',
                    'ID753',
                    'ID754',
                ]).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Neraca13'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    isNumericString: true,
                    allowNegative: false,
                    width: ['ID754','ID753'].indexOf(key) < 0 ? '50%' : '100%',
                    className: i%2 || ['ID754','ID753'].indexOf(key) >= 0 ? '' : 'mpk-padding-S padding-right',
                    disabled: key === 'ID754',
                    key
                })),
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: getLabel('__kewajibanDanEkuitas', 'Neraca13')
                // },
                ...(asKewajiban ? [
                    'ID755',
                    'ID756',
                    'ID757',
                    'ID758',
                    'ID759',
                    'ID760',
                    'ID761',
                    'ID762'
                ] : []).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Neraca13'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    isNumericString: true,
                    allowNegative: ['ID761','ID762'].indexOf(key) >= 0,
                    width: [
                        'ID757',
                        'ID758',
                        'ID759',
                        'ID760'
                    ].indexOf(key) >= 0 ? '50%' : '100%',
                    className: [
                        'ID757',
                        'ID759'
                    ].indexOf(key) >= 0 ? 'mpk-padding-S padding-right' : '',
                    disabled: key === 'ID762',
                    key
                }))
            ]}
            onChange={async data => {
                data = await recon(data, false)
                return data
            }}
            onSubmit={async (data, callback) => {
                await storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    ) : <LoaderInfo/>
}

Neraca13.generate = generate
Neraca13.columns = columns
Neraca13.recon = recon

export default Neraca13
