import React from 'react'
import { DataForm } from '../../../../libs/react-mpk/components'
import t from 'counterpart'
import { FontIcon, Button, TextIconSpacing, } from 'react-md'

const { inputTypes } = DataForm;

const ImportForm = ({
    itemColumns,
    fileName,
    title,
    onSubmit = () => { },
    onRequestClose,
    templateUri,
    mod,
    getLabel,
    isBulk = false,
    onSubmitItem,
    onFinished = () => { },
    ...props
}) => {

    return (
        <DataForm
            baseId="import-form"
            title={title ? title : t.translate('column.import')}
            hintMessage={t.translate('modules.submission.sptForm.importListForm.hint1')}
            asDialog={true}
            onRequestClose={() => {
                onRequestClose()
            }}
            defaultData={{
                file: "",
            }}
            definitions={[
                {
                    render: (
                        // <div className='mpk-flex justify-end'>
                        <a href={templateUri} target="_blank">
                            <Button
                                themeType="outline"
                                theme='primary'
                                className="mpk-margin-N margin-right margin-bottom"
                            >
                                <TextIconSpacing
                                    icon={
                                        <FontIcon iconClassName="mdi mdi-download" />
                                    }
                                >
                                    {t.translate('column.downloadTemplate')}
                                </TextIconSpacing>
                            </Button>
                        </a>
                        // </div>
                    ),
                },
                {
                    inputType: inputTypes.FILE_INPUT,
                    label: "Pilih File",
                    key: "file",
                    accept: ".csv",
                    required: true,

                },
            ]}
            onSubmit={async (data, callback) => {
                try {
                    await onSubmit(data, callback)
                } catch (error) {
                    callback(error, true, false)
                }
            }}
            {...props}
        />
    )
}

export default ImportForm