import React, { useState, useEffect } from 'react'
import { DataForm, LoadingButton } from '../../../../../libs/react-mpk/components'
import { rules, storage, utils, file } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import HeaderLampIII from './HeaderLampIII'
import { TextIconSpacing, Button, FontIcon } from 'react-md'
import ImportListForm from '../../ImportListForm'
import ImportUploadForm from '../../ImportListForm/ImportUploadForm'
import ListLogImport from '../../ImportListForm/ListLogImport'
import moment from 'moment'
import { http, toast } from '../../../../../libs/react-mpk/services'
import Migration from '../../Migration'
import ImportFromSB from './ImportFromSB'
import { downloadSpt } from "../../../Submission.service";


const { inputTypes } = DataForm

const skey = storage.keys.SPT_1771_DetailLampIIIPPh23
const baseId = 'mod-submission-spt-1771-DetailLampIIIPPh23'
const columns = {
    list: "list",
}

const itemColumns = {
    NPWP: {
        key: 'npwp',
        value: d => {
            return rules.npwpOptional(d)
        },
        validation: d => {
            let isValid = d !== undefined && !String(d).match(/[^0-9]/g) && String(d).length === 15
            return {
                isValid,
                message: 'Wajib diisi dan harus 15 digit angka'
            }
        }
    },
    NMWP: {
        key: 'nmwp',
        formatValue: d => String(d),
        validation: (d, obj) => {
            let isValid = d !== undefined && (String(d).length > 0 ? true : false) && !String(d).match(/[;:<>&\\"'`]/g)
            return {
                isValid,
                message: !isValid && `Harus diisi dan tidak boleh mengandung karakter spesial ([;:<>&\\"')`
            }
        }
    },
    Alamat: {
        key: 'alamat',
        formatValue: d => String(d),
        validation: (d, obj) => {
            let isValid = d !== undefined && (String(d).length > 0 ? true : false) && !String(d).match(/[;:<>&\\"'`]/g)
            return {
                isValid,
                message: !isValid && `Harus diisi dan tidak boleh mengandung karakter spesial ([;:<>&\\"')`
            }
        }
    },
    JnsPenghasilan: {
        key: 'jnsPenghasilan',
        validation: (d, obj) => {
            let isValid = [
                'Dividen',
                'Bunga',
                'Royalti',
                'Hadiah dan Penghargaan',
                'Bunga Simpanan yang Dibayarkan oleh Koperasi',
                'Imbalan / Jasa Lainnya',
                'Sewa dan Penghasilan Lain Sehubungan dgn Penggunaan Harta',
            ].indexOf(d) >= 0
            return {
                isValid,
                message: !isValid && 'Isian tidak valid. Silahkan lihat opsi jenis penghasilan pada file template'
            }
        }
    },
    ObjPmt: {
        key: 'objPmt',
        validation: d => {
            let isValid = !String(d).match(/[^0-9]/g) && d % 1 == 0
            return {
                isValid,
                message: !isValid && `Harus berupa angka dan tidak boleh desimal`
            }
        }
    },
    PPhDPT: {
        key: 'pphDPT',
        validation: (d, obj) => {
            let isValid = !String(d).match(/[^0-9]/g) && (obj.objPmt > 0 ? d < obj.ObjPmt : true) && d % 1 == 0
            return {
                isValid,
                message: !isValid && `Harus berupa angka dan tidak boleh lebih besar dari nilai Pemotongan/Objek Potput dan tidak boleh desimal`
            }
        }
    },
    NoBukti: {
        key: 'noBukti',
        validation: (d, obj) => {
            let isValid = String(obj.NPWP).length > 0 ? String(d).length > 0 : true
            return {
                isValid,
                message: !isValid && 'Tidak boleh kosong jika kolom NPWP diisi'
            }
        },
        reverseValue: d => String(d)
    },
    TglBukti: {
        key: 'tglBukti',
        validation: d => {
            let isValid = String(d).length > 0 && moment(d).isValid()
            return {
                isValid,
                message: !isValid && `Tanggal tidak valid. Gunakan format YYYY-MM-DD`
            }
        },
        formatValue: d => moment(d).add(1, 'hours').format('YYYY-MM-DD')
    },
    KodeMAP: {
        key: 'kodeMAP',
        validation: d => {
            let isValid = !String(d).match(/[^0-9]/g) && (String(d).length > 0 ? String(d).length === 6 : true)
            return {
                isValid,
                message: !isValid && `Jika diisi harus 6 digit dan hanya berupa angka`
            }
        },
        reverseValue: d => String(d)
    },
    KodeJenis: {
        key: 'kodeJenis',
        validation: d => {
            let isValid = !String(d).match(/[^0-9]/g) && (String(d).length > 0 ? String(d).length === 3 : true)
            return {
                isValid,
                message: !isValid && `Jika diisi harus 3 digit dan hanya berupa angka`
            }
        },
        reverseValue: d => String(d)
    },
    NTPP: {
        key: 'ntpp',
        validation: d => {
            let isValid = (String(d).length > 0 ? String(d).length === 16 : true)
            return {
                isValid,
                message: !isValid && `Jika diisi harus 16 digit`
            }
        },
        reverseValue: d => String(d)
    }
}

const generate = async () => {
    let data = await storage.generate(skey, {
        list: []
    })

    return data
}

const DetailLampIIIPPh23 = ({
    getLabel,
    envStore,
    ...props
}) => {
    const profile = storage.get(storage.keys.SPT_1771_Profile)
    const { submission } = props.temporaryStore.properties

    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)
    const [showImportForm, setShowImportForm] = useState(false)
    const [showMigration, setShowMigration] = useState(false)
    const [showImportFromSB, setShowImportFromSB] = useState(false)
    const [isExporting, setIsExporting] = useState(false)
    const [showLogImport, setShowLogImport] = useState(false)

    const handleUpdateData = (data) => {
        return data
    }

    useEffect(() => {
        let __data = storage.get(skey)
        let defData = handleUpdateData(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])


    const handleUploadPdf = (dataFile) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { chunkSize } = envStore.env
                let responseUpload = null

                let chunking = await file.chunk(dataFile.file, chunkSize, true, true)
                for (let i = 0; i < chunking.blobs.length; i++) {
                    let bl = chunking.blobs[i]
                    let data = {
                        filename: submission.fileName,
                        size: dataFile.file.size,
                        offset: i,
                    }
                    if (responseUpload) data.uploadId = responseUpload.uploadId
                    let res = await http.upload(`/kpdn/pph23/${submission.fileName}/upload/detail_lamp_3_pph_22`, bl, data, {}, {
                        fileLabel: 'csv'
                    })
                    responseUpload = responseUpload || res.data
                }
                resolve(responseUpload)
            } catch (error) {
                reject(error)
            }
        })
    }

    const handleCommit = async (data, callback) => {
        try {
            let resUpload = await handleUploadPdf(data)
            await http.post(`/kpdn/pph23/${submission.fileName}/commit`, { uploadId: resUpload.uploadId })
            DataForm.reload(baseId)
            callback('Import data Berhasil', false)
        } catch (error) {
            callback(error, true, false)
        }
    }

    return ready && (
        <>
            <DataForm
                baseId={baseId}
                className="mpk-margin-C"
                hintMessage={props.errorMessage}
                asDialog={false}
                defaultData={defaultData}
                watchDefaultData={false}
                style={{ maxWidth: 'unset' }}
                definitions={[
                    {
                        inputType: inputTypes.LIST,
                        asTable: true,
                        label: getLabel('__title', 'DetailLampIIIPPh23'),
                        key: 'list',
                        additionalAction: (
                            <>
                                {(envStore.widget.active || props.authStore.user.isSupport) && (
                                    <>
                                        <Button
                                            className="mpk-margin-S margin-right"
                                            theme="primary"
                                            themeType="outline"
                                            onClick={() => setShowImportForm(true)}
                                        >
                                            <TextIconSpacing
                                                icon={<FontIcon iconClassName="mdi mdi-file-excel" />}
                                            >
                                                {t.translate('column.import')}
                                            </TextIconSpacing>
                                        </Button>
                                        <Button
                                            className="mpk-margin-S margin-right"
                                            theme="primary"
                                            themeType="outline"
                                            onClick={() => setShowLogImport(true)}
                                        >
                                            <TextIconSpacing
                                                icon={<FontIcon iconClassName="mdi mdi-history" />}
                                            >
                                                {t.translate('column.logImport')}
                                            </TextIconSpacing>
                                        </Button>
                                    </>
                                )}

                                <Button
                                    className="mpk-margin-S margin-right"
                                    theme="primary"
                                    themeType="outline"
                                    onClick={() => setShowMigration(true)}
                                >
                                    <TextIconSpacing
                                        icon={<FontIcon iconClassName="mdi mdi-content-copy" />}
                                    >
                                        {t.translate('column.migrate')}
                                    </TextIconSpacing>
                                </Button>

                                {(props.authStore.hasPermission(`GET:/prepop-sb/{submissionName}/{pasal}`) || envStore.widget.active) && (
                                    <Button
                                        className="mpk-margin-S margin-right"
                                        theme="primary"
                                        themeType="outline"
                                        onClick={() => setShowImportFromSB(true)}
                                    >
                                        <TextIconSpacing
                                            icon={<FontIcon iconClassName="mdi mdi-database-export" />}
                                        >
                                            {t.translate('column.importFromSB')}
                                        </TextIconSpacing>
                                    </Button>
                                )}
                                {submission.referenceNo && (
                                    <LoadingButton
                                        className="mpk-margin-S margin-right"
                                        theme="primary"
                                        themeType="outline"
                                        iconClassName="mdi mdi-download"
                                        loading={isExporting}
                                        onClick={async () => {
                                            try {
                                                setIsExporting(true)
                                                let res = await http.get(`/submission/${submission.fileName}/export/csv/12`)
                                                toast.success(res.data)
                                                // await downloadSpt(
                                                //     submission.fileName,
                                                //     res.data,
                                                //     `Lampiran III-1771-${submission.fileName}-CSV.zip`
                                                //   );
                                                setIsExporting(false)
                                            } catch (error) {
                                                toast.errorRequest(error)
                                                setIsExporting(false)
                                            }
                                        }}
                                    >
                                        {t.translate('column.export')}
                                    </LoadingButton>
                                )}
                            </>
                        ),
                        defaultData: {
                            NPWP: '',
                            NMWP: '',
                            JnsPenghasilan: envStore.env.spt.jenisPenghasilanPasal23[0],
                            ObjPmt: 0,
                            PPhDPT: 0,
                            NoBukti: '',
                            TglBukti: '',
                            Alamat: '',
                            kodeMAP: '',
                            KodeJenis: '',
                            NTPP: ''
                        },
                        onFetchData: (query) => {
                            return new Promise(async (resolve, reject) => {
                                try {
                                    let res = await http.get(`/kpdn/pph23/${submission.fileName}`, query)
                                    res.data = utils.mapListItemColumns(res.data, itemColumns, true)
                                    resolve(res)
                                } catch (error) {
                                    reject(error)
                                }
                            })
                        },
                        onSubmit: (data) => {
                            return new Promise(async (resolve, reject) => {
                                try {
                                    let { _id } = data
                                    let __data = utils.mapColumns(data, itemColumns)
                                    if (__data.kodeMAP === '') delete __data.kodeMAP
                                    let res = await http[_id ? 'put' : 'post'](`/kpdn/pph23/${submission.fileName}${_id ? `/${_id.$oid}` : ''}`, __data)
                                    await HeaderLampIII.recon()
                                    resolve(res)
                                } catch (error) {
                                    reject(error)
                                }
                            })
                        },
                        onDelete: data => {
                            return new Promise(async (resolve, reject) => {
                                try {
                                    let { _id } = data
                                    await http.delete(`/kpdn/pph23/${submission.fileName}/${_id.$oid}`)
                                    await HeaderLampIII.recon()
                                    resolve()
                                } catch (error) {
                                    reject(error)
                                }
                            })
                        },
                        onReset: () => {
                            return new Promise(async (resolve, reject) => {
                                try {
                                    await http.delete(`/kpdn/pph23/${submission.fileName}`)
                                    await HeaderLampIII.recon()
                                    resolve()
                                } catch (error) {
                                    reject(error)
                                }
                            })
                        },
                        definitions: [
                            {
                                inputType: inputTypes.INPUT,
                                label: getLabel('NPWP', 'DetailLampIIIPPh23'),
                                key: 'NPWP',
                                validation: (data => {
                                    return data.ObjPmt || data.NPWP.length > 0 ? 'size:15' : 'min:0'
                                }),
                                required: true
                            },
                            {
                                inputType: inputTypes.INPUT,
                                label: getLabel('NMWP', 'DetailLampIIIPPh23'),
                                key: 'NMWP',
                                validation: (data) => (rules.validateNamaNpwp(data, 'NPWP')),
                                required: true
                            },
                            {
                                inputType: inputTypes.INPUT,
                                label: getLabel('Alamat', 'DetailLampIIIPPh23'),
                                key: 'Alamat',
                                validation: data => {
                                    return data.NPWP.length > 0 ? 'required|max:200' : 'max:200'
                                },
                                required: true
                            },
                            {
                                inputType: inputTypes.SELECT,
                                label: getLabel('JnsPenghasilan', 'DetailLampIIIPPh23'),
                                key: 'JnsPenghasilan',
                                options: envStore.env.spt.jenisPenghasilanPasal23,
                                required: true
                            },
                            {
                                inputType: inputTypes.INPUT_MASK_NUMBER,
                                label: getLabel('ObjPmt', 'DetailLampIIIPPh23'),
                                isNumericString: true,
                                decimalSeparator: ',',
                                thousandSeparator: '.',
                                key: 'ObjPmt',
                                allowNegative: false,
                                required: true
                            },
                            {
                                inputType: inputTypes.INPUT_MASK_NUMBER,
                                label: getLabel('PPhDPT', 'DetailLampIIIPPh23'),
                                isNumericString: true,
                                decimalSeparator: ',',
                                thousandSeparator: '.',
                                key: 'PPhDPT',
                                allowNegative: false,
                                validation: (data) => {
                                    return data.ObjPmt > 0 ? `max:${data.ObjPmt}` : 'min:0'
                                },
                                required: true
                            },
                            {
                                inputType: inputTypes.INPUT,
                                label: getLabel('NoBukti', 'DetailLampIIIPPh23'),
                                key: 'NoBukti',
                                validation: data => {
                                    return data.NPWP.length > 0 ? 'required|max:50' : 'max:50'
                                },
                                required: true
                            },
                            {
                                inputType: inputTypes.DATE,
                                label: getLabel('TglBukti', 'DetailLampIIIPPh23'),
                                key: 'TglBukti',
                                validation: 'max:50',
                                max: rules.maxTanggalPajak(profile.THN_PJK, envStore.env.timestamp),
                                required: true,
                            },
                            {
                                inputType: inputTypes.INPUT,
                                label: getLabel('KodeMAP', 'DetailLampIIIPPh23'),
                                key: 'KodeMAP',
                                width: '50%',
                                maxLength: 6,
                                validation: data => {
                                    return data.KodeMAP && data.KodeMAP.length > 0 ? 'digits:6' : 'min:0'
                                }
                            },
                            {
                                inputType: inputTypes.INPUT,
                                label: getLabel('KodeJenis', 'DetailLampIIIPPh23'),
                                key: 'KodeJenis',
                                width: '50%',
                                maxLength: 3,
                                className: 'mpk-padding-S padding-left',
                                validation: data => {
                                    return data.KodeJenis.length > 0 ? 'digits:3' : 'min:0'
                                }
                            },
                            {
                                inputType: inputTypes.INPUT,
                                label: getLabel('NTPP', 'DetailLampIIIPPh23'),
                                key: 'NTPP',
                                validation: data => {
                                    return data.NTPP.length > 0 ? 'size:16' : 'min:0'
                                }
                            },
                        ]
                    }
                ]}
                onBeforeChange={(key, value) => {
                    // if(key === 'NPWP') return rules.npwpOptional(value)
                    if (['KodeMAP', 'KodeJenis', 'NTPP'].indexOf(key) >= 0) return value.replace(/\D/g, '')
                    return value
                }}
            // onSubmit={async (data, callback) => {
            //     storage.update(skey, data)
            //     await HeaderLampIII.recon()
            //     callback(t.translate('sentence.savedItem'), false)
            // }}
            />
            {/* <ImportListForm
                visible={showImportForm}
                onRequestClose={() => setShowImportForm(false)}
                itemColumns={itemColumns}
                onSubmitItem={(obj) => {
                    return new Promise(async (resolve, reject) => {
                        try {
                            let __data = {
                                npwp: obj.NPWP,
                                nmwp: obj.NMWP,
                                alamat: obj.Alamat,
                                jnsPenghasilan: obj.JnsPenghasilan,
                                objPmt: obj.ObjPmt,
                                pphDPT: obj.PPhDPT,
                                noBukti: String(obj.NoBukti),
                                tglBukti: obj.TglBukti,
                                kodeMAP: String(obj.KodeMAP),
                                kodeJenis: String(obj.KodeJenis),
                                ntpp:obj.NTPP
                            }
                            if(__data.kodeMAP === '') delete __data.kodeMAP;
                            let res = await http.post(`/kpdn/pph23/${submission.fileName}`, __data)
                            await HeaderLampIII.recon()
                            resolve()
                        } catch (error) {
                            reject(error)
                        }
                    })
                }}
                onFinished={() => {
                    DataForm.reload(baseId)
                }}
                // onSubmit={(data) => {
                //     DataForm.get(baseId, lastData => {
                //         lastData.list = [...lastData.list, ...data]
                //         DataForm.update(baseId, lastData)
                //     })
                // }}
                templateUri={envStore.env.template.daftarKreditPajakDalamNegeri23}
            /> */}
            <ImportUploadForm
                visible={showImportForm}
                onRequestClose={() => setShowImportForm(false)}
                title={getLabel('Import', 'DetailLampIIIPPh23')}
                fileName={submission.fileName}
                onSubmit={async (data, callback) => {
                    handleCommit(data, callback)
                }}
                onFinished={() => {
                    DataForm.reload(baseId)
                }}
                templateUri={envStore.env.template.daftarKreditPajakDalamNegeri23}
                getLabel={getLabel}
            />
            <ListLogImport
                visible={showLogImport}
                onRequestClose={() => setShowLogImport(false)}
                title={getLabel('LogImport', 'DetailLampIIIPPh23')}
                tipeForm='DetailLamp3PPh23'
                filename={submission.fileName}
                getLabel={(e) => getLabel(e)}
            />
            <Migration
                visible={showMigration}
                onRequestClose={() => setShowMigration(false)}
                onSubmit={async (data, callback) => {
                    try {
                        if (data.fileName) {
                            await http.put(`/kpdn/pph23/${submission.fileName}/migrate/async`, {
                                oldRefNo: data.fileName
                            })
                            callback(t.translate('sentence.migrationMessage'), false)
                        } else {
                            callback(new Error(t.translate('sentence.migrationValidationMessage')), true, false)
                        }
                    } catch (error) {
                        callback(error)
                    }
                }}
            />
            <ImportFromSB
                visible={showImportFromSB}
                onRequestClose={() => setShowImportFromSB(false)}
                pasal="23"
                onFinished={() => {
                    DataForm.reload(baseId)
                }}
            />
        </>
    )
}

DetailLampIIIPPh23.generate = generate
DetailLampIIIPPh23.columns = columns
DetailLampIIIPPh23.itemColumns = itemColumns

export default inject('envStore', 'temporaryStore', 'authStore')(observer(DetailLampIIIPPh23))
