import React, { useState, useEffect } from "react";
import {
  DataForm,
  LoadingButton,
} from "../../../../../libs/react-mpk/components";
import { options, storage, utils, file } from "../../../../../services";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import HeaderLampKhus1A from "./HeaderLampKhus1A";
import { Button, FontIcon, TextIconSpacing } from "react-md";
import ImportListForm from "../../ImportListForm";
import ImportUploadForm from "../../ImportListForm/ImportUploadForm";
import ListLogImport from "../../ImportListForm/ListLogImport";
import { downloadSpt } from "../../../Submission.service";

import { http, number, toast } from "../../../../../libs/react-mpk/services";
import Migration from "../../Migration";

const { inputTypes } = DataForm;

const baseId = "mod-submission-spt-1771-DetailLampKhus1A";
const skey = storage.keys.SPT_1771_DetailLampKhus1A;
const columns = {
  list: "list",
};

const itemColumns = {
  JnsHarta: {
    key: "jnsHarta",
    value: (d) => Number(d),
    reverseValue: (d) => String(d),
    validation: (d) => {
      let isValid = !Number.isNaN(Number(d)) && [1, 2].indexOf(Number(d)) >= 0;
      return {
        isValid,
        message: !isValid && "Harus berupa angka 1 atau 2",
      };
    },
  },
  KelHarta: {
    key: "kelHarta",
    value: (d) => Number(d),
    reverseValue: (d) => String(d),
    validation: (d, obj) => {
      let isValid =
        !Number.isNaN(d) &&
        (Number(obj.JnsHarta) === 1
          ? [1, 2, 3, 4].indexOf(Number(d)) >= 0
          : [5, 6].indexOf(Number(d)) >= 0);
      return {
        isValid,
        message: !isValid && (
          <div>
            Harus berupa angka. <br />
            Jika Jenis Harta = 1, maka Kelompok Harta harus angka 1,2,3, atau 4.{" "}
            <br />
            Jika Jenis Harta = 2, maka Kelompok Harta harus angka 5 atau 6
          </div>
        ),
      };
    },
  },
  JnsKelHarta: {
    key: "jnsKelHarta",
    value: (d) => String(d),
    formatValue: (d) => String(d),
    validation: (d) => {
      let isValid =
        d !== undefined &&
        (String(d).length > 0 ? true : false) &&
        !String(d).match(/[;:<>&\\"'`]/g);
      return {
        isValid,
        message:
          !isValid &&
          `Harus diisi dan tidak boleh mengandung karakter spesial ([;:<>&\\"')`,
      };
    },
  },
  BlnPerolehan: {
    key: "blnPerolehan",
    value: (d) => Number(d),
    reverseValue: (d) => {
      return Number(d) < 10 ? `0${d}` : String(d);
    },
    validation: (d) => {
      let isValid = !Number.isNaN(d) && Number(d) <= 12 && Number(d) > 0;
      return {
        isValid,
        message: !isValid && `Harus berupa angka 1 - 12`,
      };
    },
  },
  ThnPerolehan: {
    key: "thnPerolehan",
    value: (d) => Number(d),
    reverseValue: (d) => String(d),
    validation: (d) => {
      let isValid = !Number.isNaN(d) && d % 1 == 0;
      return {
        isValid,
        message: !isValid && `Harus berupa tahun dan tidak boleh desimal`,
      };
    },
  },
  HrgPerolehan: {
    key: "hrgPerolehan",
    value: (d) => Number(d),
    validation: (d) => {
      let isValid = !Number.isNaN(d) && d % 1 == 0;
      return {
        isValid,
        message: !isValid && `Harus berupa angka dan tidak bileh desimal`,
      };
    },
  },
  NlSisa: {
    key: "nlSisa",
    value: (d) => Number(d),
    validation: (d) => {
      let isValid = !Number.isNaN(d) && d % 1 == 0;
      return {
        isValid,
        message: !isValid && `Harus berupa angka dan tidak boleh desimal`,
      };
    },
  },
  MetSusutKom: {
    key: "metSusutKom",
    validation: (d) => {
      let arr = ["GL", "JAT", "SM", "SMG", "JJJ", "JSP", "ML"];
      let isValid = arr.indexOf(d) >= 0;
      return {
        isValid,
        message: !isValid && `Harus berupa teks ${arr.toString()}`,
      };
    },
  },
  MetSusutFis: {
    key: "metSusutFis",
    validation: (d) => {
      let arr = ["GL", "SM"];
      let isValid = arr.indexOf(d) >= 0;
      return {
        isValid,
        message: !isValid && `Harus berupa teks ${arr.toString()}`,
      };
    },
  },
  SusutFiskal: {
    key: "susutFiskal",
    value: (d) => Number(d),
    validation: (d) => {
      let isValid = !Number.isNaN(d) && d % 1 == 0;
      return {
        isValid,
        message: !isValid && `Harus berupa angka dan tidak boleh desimal`,
      };
    },
  },
  Catatan: {
    key: "catatan",
    formatValue: (d) => String(d).replace(/[;:<>&\\"'`]|[^ -~]/g, ""),
    validation: (d) => {
      let isValid =
        d !== undefined &&
        (String(d).length > 0 ? true : false) &&
        !String(d).match(/[;:<>&\\"'`^]/g);
      return {
        isValid,
        message:
          !isValid &&
          `Harus diisi dan tidak boleh mengandung karakter spesial ([;:<>&\\"')`,
      };
    },
  },
};

const generate = async () => {
  let data = await storage.generate(skey, {
    list: [],
    import: null,
  });

  return data;
};

const DetailLampKhus1A = ({ getLabel, envStore, ...props }) => {
  const profile = storage.get(storage.keys.SPT_1771_Profile);
  const months = options.getMonths();
  const years = options.getYears(
    envStore.env.timestamp,
    envStore.env.spt.minYearAll
  );
  const { submission } = props.temporaryStore.properties;

  const [defaultData, setDefaultData] = useState();
  const [ready, setReady] = useState(false);
  const [showImportForm, setShowImportForm] = useState(false);
  const [showLogImport, setShowLogImport] = useState(false);
  const [showMigration, setShowMigration] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const handleUpdateData = (data) => {
    return data;
  };

  const handleCountPenyusutan = (item) => {
    if (item) {
      let tahunPajak = Number(profile.THN_PJK);
      let { KelHarta, ThnPerolehan, BlnPerolehan, HrgPerolehan, MetSusutFis } =
        item;
      ThnPerolehan = Number(ThnPerolehan);
      KelHarta = Number(KelHarta);

      if (MetSusutFis === "GL") {
        let kelHartaTarifs = [0, 0.25, 0.125, 0.0625, 0.05, 0.05, 0.1];
        let tarif = kelHartaTarifs[KelHarta];
        if (tarif) {
          if (ThnPerolehan > tahunPajak) {
            item.NlSisa = 0;
            item.SusutFiskal = 0;
          } else if (tahunPajak === ThnPerolehan) {
            item.NlSisa = HrgPerolehan;
            item.SusutFiskal = Math.round(HrgPerolehan * tarif);
          } else {
            if (
              (KelHarta == 1 && ThnPerolehan < tahunPajak - 3) ||
              (KelHarta == 2 && ThnPerolehan < tahunPajak - 7) ||
              (KelHarta == 3 && ThnPerolehan < tahunPajak - 15) ||
              (KelHarta == 4 && ThnPerolehan < tahunPajak - 19) ||
              (KelHarta == 5 && ThnPerolehan < tahunPajak - 19) ||
              (KelHarta == 6 && ThnPerolehan < tahunPajak - 9)
            ) {
              item.NlSisa = 0;
              item.SusutFiskal = 0;
            } else {
              item.NlSisa = Math.round(
                HrgPerolehan -
                  (HrgPerolehan *
                    tarif *
                    (12 * (tahunPajak - ThnPerolehan) -
                      Number(BlnPerolehan) +
                      1)) /
                    12
              );
              item.SusutFiskal = Math.round(HrgPerolehan * tarif);
            }
          }
        }
      } else if (MetSusutFis === "SM") {
        let kelHartaTarifs = [0, 0.5, 0.25, 0.125, 0.1];
        let tarif = kelHartaTarifs[Number(KelHarta)];
        if (tarif) {
          if (ThnPerolehan > tahunPajak) {
            item.NlSisa = 0;
            item.SusutFiskal = 0;
          } else if (tahunPajak === ThnPerolehan) {
            item.NlSisa = HrgPerolehan;
            item.SusutFiskal = Math.round(
              ((HrgPerolehan * tarif) / 12) * (12 - (BlnPerolehan - 1))
            );
          } else {
            if (
              (KelHarta == 1 && ThnPerolehan < tahunPajak - 3) ||
              (KelHarta == 2 && ThnPerolehan < tahunPajak - 7) ||
              (KelHarta == 3 && ThnPerolehan < tahunPajak - 15) ||
              (KelHarta == 4 && ThnPerolehan < tahunPajak - 19)
            ) {
              item.NlSisa = 0;
              item.SusutFiskal = 0;
            } else {
              let baseSusut = Math.round(
                ((HrgPerolehan * tarif) / 12) * (12 - (BlnPerolehan - 1))
              );
              item.NlSisa = HrgPerolehan;

              for (let thn = tahunPajak - 1; thn >= ThnPerolehan; thn--) {
                if (thn === tahunPajak - 1)
                  item.NlSisa = Math.round(item.NlSisa - baseSusut);
                else {
                  item.NlSisa = Math.round(item.NlSisa - item.NlSisa * tarif);
                }
              }
              item.SusutFiskal = Math.round(item.NlSisa * tarif);
            }
          }
        }
      }

      return item;
    }
  };

  useEffect(() => {
    let __data = storage.get(skey);
    let defData = handleUpdateData(__data);

    storage.update(skey, defData);
    setDefaultData(defData);
    setReady(true);
  }, []);
  const handleUploadPdf = (dataFile) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { chunkSize } = envStore.env;
        let responseUpload = null;

        let chunking = await file.chunk(dataFile.file, chunkSize, true, true);
        for (let i = 0; i < chunking.blobs.length; i++) {
          let bl = chunking.blobs[i];
          let data = {
            filename: submission.fileName,
            size: dataFile.file.size,
            offset: i,
          };
          if (responseUpload) data.uploadId = responseUpload.uploadId;
          let res = await http.upload(
            `/penyusutan/${submission.fileName}/upload/detail_lamp_khus_1a`,
            bl,
            data,
            {},
            {
              fileLabel: "csv",
            }
          );
          responseUpload = responseUpload || res.data;
        }
        resolve(responseUpload);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleCommit = async (data, callback) => {
    try {
      let resUpload = await handleUploadPdf(data);
      await http.post(`/penyusutan/${submission.fileName}/commit`, {
        uploadId: resUpload.uploadId,
      });
      DataForm.reload(baseId);
      callback("Import data Berhasil", false);
    } catch (error) {
      callback(error, true, false);
    }
  };
  return (
    ready && (
      <>
        <DataForm
          baseId={baseId}
          className="mpk-margin-C"
          hintMessage={props.errorMessage}
          asDialog={false}
          defaultData={defaultData}
          watchDefaultData={false}
          style={{ maxWidth: "unset" }}
          definitions={[
            {
              inputType: inputTypes.LIST,
              asTable: true,
              label: getLabel("__title", "DetailLampKhus1A"),
              key: "list",
              additionalAction: (
                <>
                  {(envStore.widget.active ||
                    props.authStore.user.isSupport) && (
                    <>
                      <Button
                        className="mpk-margin-S margin-right"
                        theme="primary"
                        themeType="outline"
                        onClick={() => setShowImportForm(true)}
                      >
                        <TextIconSpacing
                          icon={<FontIcon iconClassName="mdi mdi-file-excel" />}
                        >
                          {t.translate("column.import")}
                        </TextIconSpacing>
                      </Button>
                      <Button
                        className="mpk-margin-S margin-right"
                        theme="primary"
                        themeType="outline"
                        onClick={() => setShowLogImport(true)}
                      >
                        <TextIconSpacing
                          icon={<FontIcon iconClassName="mdi mdi-history" />}
                        >
                          {t.translate("column.logImport")}
                        </TextIconSpacing>
                      </Button>
                    </>
                  )}

                  <Button
                    className="mpk-margin-S margin-right"
                    theme="primary"
                    themeType="outline"
                    onClick={() => setShowMigration(true)}
                  >
                    <TextIconSpacing
                      icon={<FontIcon iconClassName="mdi mdi-content-copy" />}
                    >
                      {t.translate("column.migrate")}
                    </TextIconSpacing>
                  </Button>
                  {submission.referenceNo && (
                    <LoadingButton
                      className="mpk-margin-S margin-right"
                      theme="primary"
                      themeType="outline"
                      iconClassName="mdi mdi-download"
                      loading={isExporting}
                      onClick={async () => {
                        try {
                          setIsExporting(true);
                          let res = await http.get(
                            `/submission/${submission.fileName}/export/csv/4`
                          );
                          toast.success(res.data)
                          // await downloadSpt(
                          //   submission.fileName,
                          //   res.data,
                          //   "Lampiran 1A-1771-{fileName}-CSV.zip".replace(
                          //     `{fileName}`,
                          //     submission.fileName
                          //   )
                          // );
                          setIsExporting(false);
                        } catch (error) {
                          toast.errorRequest(error);
                          setIsExporting(false);
                        }
                      }}
                    >
                      {t.translate("column.export")}
                    </LoadingButton>
                  )}
                </>
              ),
              defaultData: {
                JnsHarta: "1",
                KelHarta:
                  envStore.env.spt.kelompokHartaPenyusutanFiskal[0].value,
                JnsKelHarta: "",
                BlnPerolehan: months[0].value,
                ThnPerolehan: String(years[0]),
                HrgPerolehan: 0,
                NlSisa: 0,
                MetSusutKom:
                  envStore.env.spt.metodePenyusutanKomersial[0].value,
                MetSusutFis: envStore.env.spt.metodePenyusutanFiskal[0].value,
                SusutFiskal: 0,
                Catatan: "",
              },
              onFetchData: (query) => {
                return new Promise(async (resolve, reject) => {
                  try {
                    let res = await http.get(
                      `/penyusutan/${submission.fileName}`,
                      query
                    );
                    // let res = await http.get(`/prepop/${submission.fileName}/detail_lamp_khus_1a`, query)
                    res.data = utils.mapListItemColumns(
                      res.data,
                      itemColumns,
                      true
                    );
                    resolve(res);
                  } catch (error) {
                    reject(error);
                  }
                });
              },
              onSubmit: (data) => {
                return new Promise(async (resolve, reject) => {
                  try {
                    let { _id } = data;
                    let __data = utils.mapColumns(data, itemColumns);
                    let res = await http[_id ? "put" : "post"](
                      `/penyusutan/${submission.fileName}${
                        _id ? `/${_id.$oid}` : ""
                      }`,
                      __data
                    );
                    await HeaderLampKhus1A.recon();
                    resolve({ _id: res.data._id });
                  } catch (error) {
                    reject(error);
                  }
                });
              },
              onDelete: (data) => {
                return new Promise(async (resolve, reject) => {
                  try {
                    let { _id } = data;
                    await http.delete(
                      `/penyusutan/${submission.fileName}/${_id.$oid}`
                    );
                    await HeaderLampKhus1A.recon();
                    resolve();
                  } catch (error) {
                    reject(error);
                  }
                });
              },
              onReset: () => {
                return new Promise(async (resolve, reject) => {
                  try {
                    await http.delete(`/penyusutan/${submission.fileName}`);
                    await HeaderLampKhus1A.recon();
                    resolve();
                  } catch (error) {
                    reject(error);
                  }
                });
              },
              definitions: [
                {
                  inputType: inputTypes.SELECT,
                  label: getLabel("JnsHarta", "DetailLampKhus1A"),
                  key: "JnsHarta",
                  options: envStore.env.spt.jenisHartaPenyusutanFiskal.filter(
                    (d) => Number(d.value) < 3
                  ),
                },
                {
                  inputType: inputTypes.SELECT,
                  label: getLabel("KelHarta", "DetailLampKhus1A"),
                  key: "KelHarta",
                  options: (data, parentKey, indexAtParent) => {
                    return envStore.env.spt.kelompokHartaPenyusutanFiskal.filter(
                      (d) =>
                        data[parentKey][indexAtParent] &&
                        d.jenisHarta === data[parentKey][indexAtParent].JnsHarta
                    );
                  },
                },
                {
                  inputType: inputTypes.INPUT,
                  label: getLabel("JnsKelHarta", "DetailLampKhus1A"),
                  key: "JnsKelHarta",
                  required: true,
                },
                {
                  inputType: inputTypes.SELECT,
                  label: getLabel("BlnPerolehan", "DetailLampKhus1A"),
                  key: "BlnPerolehan",
                  width: "50%",
                  className: "mpk-padding-S padding-right",
                  options: months,
                },
                {
                  inputType: inputTypes.SELECT,
                  label: getLabel("ThnPerolehan", "DetailLampKhus1A"),
                  key: "ThnPerolehan",
                  width: "50%",
                  options: years,
                },
                {
                  inputType: inputTypes.INPUT_MASK_NUMBER,
                  label: getLabel("HrgPerolehan", "DetailLampKhus1A"),
                  isNumericString: true,
                  decimalSeparator: ",",
                  thousandSeparator: ".",
                  key: "HrgPerolehan",
                  allowNegative: false,
                },
                {
                  inputType: inputTypes.SELECT,
                  label: getLabel("MetSusutKom", "DetailLampKhus1A"),
                  key: "MetSusutKom",
                  options: envStore.env.spt.metodePenyusutanKomersial,
                },
                {
                  inputType: inputTypes.SELECT,
                  label: getLabel("MetSusutFis", "DetailLampKhus1A"),
                  key: "MetSusutFis",
                  options: (data, parentKey, indexAtParent) =>
                    envStore.env.spt.metodePenyusutanFiskal.filter((d) =>
                      data[parentKey][indexAtParent]
                        ? String(data[parentKey][indexAtParent].JnsHarta) ===
                            "2" && d.value === "SM"
                          ? false
                          : true
                        : false
                    ),
                },
                {
                  inputType: inputTypes.INPUT_MASK_NUMBER,
                  label: getLabel("NlSisa", "DetailLampKhus1A"),
                  isNumericString: true,
                  decimalSeparator: ",",
                  thousandSeparator: ".",
                  key: "NlSisa",
                  allowNegative: false,
                },
                {
                  inputType: inputTypes.INPUT_MASK_NUMBER,
                  label: getLabel("SusutFiskal", "DetailLampKhus1A"),
                  key: "SusutFiskal",
                  isNumericString: true,
                  decimalSeparator: ",",
                  thousandSeparator: ".",
                  allowNegative: false,
                },
                {
                  inputType: inputTypes.INPUT,
                  label: getLabel("Catatan", "DetailLampKhus1A"),
                  key: "Catatan",
                  required: true,
                },
              ],
            },
          ]}
          onChange={(data, key, value, parentKey, indexAtParent) => {
            if (key === "JnsHarta" && data[parentKey][indexAtParent]) {
              data[parentKey][indexAtParent].KelHarta =
                envStore.env.spt.kelompokHartaPenyusutanFiskal.filter(
                  (d) =>
                    d.jenisHarta === data[parentKey][indexAtParent].JnsHarta
                )[0].value;
              if (value === "2")
                data[parentKey][indexAtParent].MetSusutFis = "GL";
            }

            if (
              parentKey &&
              !Number.isNaN(indexAtParent) &&
              key !== "SusutFiskal" &&
              key !== "NlSisa"
            )
              data[parentKey][indexAtParent] = handleCountPenyusutan(
                data[parentKey][indexAtParent],
                key
              );

            return data;
          }}
          // onSubmit={async (data, callback) => {
          //     storage.update(skey, data)
          //     await HeaderLampKhus1A.recon()
          //     callback(t.translate('sentence.savedItem'), false)
          // }}
        />
        {/* <ImportListForm
                visible={showImportForm}
                onRequestClose={() => setShowImportForm(false)}
                itemColumns={itemColumns}
                isBulk={true}
                onSubmitItem={(obj) => {
                    console.log("🚀 ~ file: DetailLampKhus1A.js ~ line 510 ~ obj", obj)
                    return new Promise(async (resolve, reject) => {
                        try {
                            let draf = []
                            if (obj.length > 0) {
                                obj.map((e) => {
                                    draf.push({
                                        jnsHarta: e.JnsHarta,
                                        kelHarta: e.KelHarta,
                                        jnsKelHarta: e.JnsKelHarta,
                                        blnPerolehan: e.BlnPerolehan,
                                        thnPerolehan: e.ThnPerolehan,
                                        hrgPerolehan: e.HrgPerolehan,
                                        nlSisa: e.NlSisa,
                                        metSusutKom: e.MetSusutKom,
                                        metSusutFis: e.MetSusutFis,
                                        susutFiskal: e.SusutFiskal,
                                        catatan: e.Catatan,
                                        refNo: submission.fileName
                                    })
                                })
                                let res = await http.post(`/penyusutan/${submission.fileName}/bulk`, { data: draf })
                                await HeaderLampKhus1A.recon()
                                resolve()
                            } else {
                                reject('data pertama belum valid')
                            }
                        } catch (error) {
                            reject(error)
                        }
                    })
                }}
                onFinished={() => {
                    DataForm.reload(baseId)
                }}
                onSubmit={(data) => {
                    // DataForm.get(baseId, lastData => {
                    //     lastData.list = [...lastData.list, ...data]
                    //     DataForm.update(baseId, lastData)
                    // })
                }}
                templateUri={envStore.env.template.daftarPenyusutan}
                mod="DetailLampKhus1A"
                getLabel={getLabel}
            /> */}
        <ImportUploadForm
          visible={showImportForm}
          onRequestClose={() => setShowImportForm(false)}
          title={getLabel("Import", "DetailLampKhus1A")}
          fileName={submission.fileName}
          onSubmit={async (data, callback) => {
            handleCommit(data, callback);
          }}
          onFinished={() => {
            DataForm.reload(baseId);
          }}
          templateUri={envStore.env.template.daftarPenyusutan}
          getLabel={getLabel}
        />
        <ListLogImport
          visible={showLogImport}
          onRequestClose={() => setShowLogImport(false)}
          title={getLabel("LogImport", "DetailLampKhus1A")}
          tipeForm="DetailLampKhusIA"
          filename={submission.fileName}
          getLabel={(e) => getLabel(e)}
        />
        <Migration
          visible={showMigration}
          onRequestClose={() => setShowMigration(false)}
          onSubmit={async (data, callback) => {
            try {
              if (data.fileName) {
                await http.put(
                  `/penyusutan/${submission.fileName}/migrate/async`,
                  {
                    oldRefNo: data.fileName,
                  }
                );
                callback(t.translate("sentence.migrationMessage"), false);
              } else {
                callback(
                  new Error(t.translate("sentence.migrationValidationMessage")),
                  true,
                  false
                );
              }
            } catch (error) {
              callback(error);
            }
          }}
        />
      </>
    )
  );
};

DetailLampKhus1A.generate = generate;
DetailLampKhus1A.columns = columns;
DetailLampKhus1A.itemColumns = itemColumns;

export default inject(
  "envStore",
  "temporaryStore",
  "authStore"
)(observer(DetailLampKhus1A));
