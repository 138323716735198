import React, { useEffect, useState } from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { inject, observer } from 'mobx-react'
import t from 'counterpart'
import qs from 'query-string'

const ImportFromSB = ({
    visible,
    onRequestClose,
    pasal,
    onFinished,
    ...props
}) => {
    const [log, setLog] = useState('')
    const [inProgress, setInProgress] = useState(false)
    let { submission } = props.temporaryStore.properties

    useEffect(() => {
        if(!visible){
            setTimeout(() => {
                setLog('')
                setInProgress(false)
            }, 800)
        }
    }, [visible])

    return (
        <DataForm
            title={t.translate('modules.submission.sptForm.importFromSB.title')}
            hintMessage={t.translate('modules.submission.sptForm.importFromSB.hintMessage').replace('*pasal', pasal)}
            baseId="import-from-sb"
            defaultData={{}}
            visible={visible}
            onRequestClose={onRequestClose}
            asDialog={true}
            dialogStyle={{minWidth: 480}}
            definitions={[
                {
                    render: (
                        <pre
                            className="mpk-full full-width mpk-border border-all thin solid dark mpk-padding-N padding-all"
                            style={{ background: '#eee', whiteSpace: 'break-spaces', overflow: 'auto', maxHeight: 320 }}
                        >
                            {log}
                        </pre>
                    ),
                    show: inProgress
                }
            ]}
            onSubmit={(data, callback) => {
                try {
                    setInProgress(true)
                    let { host, baseUrl } = props.envStore.widget.active 
                        ? props.envStore.env.widgetGateway
                        : props.envStore.env.apiGateway

                    let query = {
                        access_token: props.authStore.getAccessToken()
                    }

                    if(props.envStore.widget.active){
                        query.client = props.envStore.widget.client
                        query.client_id = props.envStore.widget.client
                    }

                    const evtSource = new EventSource(`${host}${baseUrl}/prepop-sb/${submission.fileName}/${pasal}?${qs.stringify(query)}`);

                    evtSource.addEventListener('ping', e => {
                        setLog(l => `${l}${e.data}\n`)
                        if(String(e.data).startsWith('[SELESAI]')){
                            callback(t.translate('sentence.finishedImportFromSB'), false)
                            onFinished()
                        }
                    });
                    evtSource.onerror = e => {
                        evtSource.close()
                        callback(null, false, false)
                        // callback()
                        // setTimeout(() => setInProgress(false), 800)
                    }
                } catch (error) {
                    callback(error, true, false)
                }
            }}
        />
    )
}

export default inject('authStore', 'envStore', 'temporaryStore')(observer(ImportFromSB))