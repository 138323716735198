import React, { useState, useEffect } from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { storage, utils, file } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import HeaderLampKhus5A from './HeaderLampKhus5A'
import { TextIconSpacing, Button, FontIcon } from 'react-md'
import { toast, http } from '../../../../../libs/react-mpk/services'
import ImportListForm from '../../ImportListForm'
import ImportUploadForm from '../../ImportListForm/ImportUploadForm'
import ListLogImport from '../../ImportListForm/ListLogImport'


const { inputTypes } = DataForm

const baseId = 'mod-submission-spt-1771-DetailLampKhus5A'
const skey = storage.keys.SPT_1771_DetailLampKhus5A
const columns = {
    list: "list",
}

const itemColumns = (profile) => ({
    NmcabUtm: {
        key: 'nmCabUtm',
        formatValue: d => String(d),
        validation: (d, obj) => {
            let isValid = d !== undefined && (String(d).length > 0 ? true : false) && !String(d).match(/[;:<>&\\"'`]/g)
            return {
                isValid,
                message: !isValid && `Harus diisi dan tidak boleh mengandung karakter spesial ([;:<>&\\"')`
            }
        }
    },
    AlmtCabUtm: {
        key: 'almtCabUtm',
        formatValue: d => String(d),
        validation: (d, obj) => {
            let isValid = d !== undefined && (String(d).length > 0 ? true : false) && !String(d).match(/[;:<>&\\"'`]/g)
            return {
                isValid,
                message: !isValid && `Harus diisi dan tidak boleh mengandung karakter spesial ([;:<>&\\"')`
            }
        }
    },
    NPWP: {
        key: 'npwp',
        formatValue: d => String(d),
        validation: d => {
            let isValid = d !== undefined
                && !String(d).match(/[^0-9]/g) && String(d).length === 15
                && (profile
                    ? profile.NPWP.substr(0, 9) === String(d).substr(0, 9)
                    : true
                )
            return {
                isValid,
                message: !isValid && (
                    <div>
                        <span>Wajib diisi dan harus 15 digit angka</span>
                        <br />dan 9 digit pertama harus sama dengan ({profile.NPWP.substr(0, 9)})
                    </div>
                )
            }
        }
    },
    JmlCabPbt: {
        key: 'jmlCabPbt',
        validation: d => {
            let isValid = ['', undefined].indexOf(d) < 0 && !String(d).match(/[^0-9]/g) && d % 1 == 0
            return {
                isValid,
                message: !isValid && `Harus berupa angka positif dan tidak boleh desimal`
            }
        }
    }
})

const generate = async () => {
    let data = await storage.generate(skey, {
        list: []
    })

    return data
}

const DetailLampKhus5A = ({
    getLabel,
    envStore,
    ...props
}) => {
    const profile = storage.get(storage.keys.SPT_1771_Profile)
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)
    const [showImportForm, setShowImportForm] = useState(false)
    const [showLogImport, setShowLogImport] = useState(false)

    const { submission } = props.temporaryStore.properties

    const handleUpdateData = (data) => {
        return data
    }

    useEffect(() => {
        let __data = storage.get(skey)
        let defData = handleUpdateData(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    const handleUploadPdf = (dataFile) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { chunkSize } = envStore.env
                let responseUpload = null

                let chunking = await file.chunk(dataFile.file, chunkSize, true, true)
                for (let i = 0; i < chunking.blobs.length; i++) {
                    let bl = chunking.blobs[i]
                    let data = {
                        filename: submission.fileName,
                        size: dataFile.file.size,
                        offset: i,
                    }
                    if (responseUpload) data.uploadId = responseUpload.uploadId
                    let res = await http.upload(`/cabang-perusahaan/${submission.fileName}/upload/detail_lamp_khus_5a`, bl, data, {}, {
                        fileLabel: 'csv'
                    })
                    responseUpload = responseUpload || res.data
                }
                resolve(responseUpload)
            } catch (error) {
                reject(error)
            }
        })
    }

    const handleCommit = async (data, callback) => {
        try {
            let resUpload = await handleUploadPdf(data)
            await http.post(`/cabang-perusahaan/${submission.fileName}/commit`, {
                uploadId: resUpload.uploadId,
                additionalData: profile.NPWP
            })
            DataForm.reload(baseId)
            callback('Import data Berhasil', false)
        } catch (error) {
            callback(error, true, false)
        }
    }

    return ready && (
        <>
            <DataForm
                baseId={baseId}
                className="mpk-margin-C"
                hintMessage={props.errorMessage}
                asDialog={false}
                defaultData={defaultData}
                watchDefaultData={false}
                style={{ maxWidth: 'unset' }}
                definitions={[
                    {
                        inputType: inputTypes.LIST,
                        asTable: true,
                        label: getLabel('__title', 'DetailLampKhus5A'),
                        key: 'list',
                        asTable: true,
                        additionalAction: (
                            <>
                                {(envStore.widget.active || props.authStore.user.isSupport) && (
                                    <>
                                        <Button
                                            className="mpk-margin-S margin-right"
                                            theme="primary"
                                            themeType="outline"
                                            onClick={() => setShowImportForm(true)}
                                        >
                                            <TextIconSpacing
                                                icon={<FontIcon iconClassName="mdi mdi-file-excel" />}
                                            >
                                                {t.translate('column.import')}
                                            </TextIconSpacing>
                                        </Button>
                                        <Button
                                            className="mpk-margin-S margin-right"
                                            theme="primary"
                                            themeType="outline"
                                            onClick={() => setShowLogImport(true)}
                                        >
                                            <TextIconSpacing
                                                icon={<FontIcon iconClassName="mdi mdi-history" />}
                                            >
                                                {t.translate('column.logImport')}
                                            </TextIconSpacing>
                                        </Button>
                                    </>
                                )}
                            </>
                        ),
                        defaultData: {
                            NmcabUtm: '',
                            AlmtCabUtm: '',
                            NPWP: profile.NPWP.substr(0, 9),
                            JmlCabPbt: 0,
                        },
                        onFetchData: (query) => {
                            return new Promise(async (resolve, reject) => {
                                try {
                                    let res = await http.get(`/cabang-perusahaan/${submission.fileName}`, query)
                                    // let res = await http.get(`/prepop/${submission.fileName}/detail_lamp_khus_1a`, query)
                                    res.data = utils.mapListItemColumns(res.data, itemColumns(), true)
                                    resolve(res)
                                } catch (error) {
                                    reject(error)
                                }
                            })
                        },
                        onSubmit: (data) => {
                            return new Promise(async (resolve, reject) => {
                                try {
                                    let { _id } = data
                                    let __data = utils.mapColumns(data, itemColumns(profile))
                                    let res = await http[_id ? 'put' : 'post'](`/cabang-perusahaan/${submission.fileName}${_id ? `/${_id.$oid}` : ''}`, __data)
                                    await HeaderLampKhus5A.recon()
                                    resolve({ _id: res.data._id })
                                } catch (error) {
                                    reject(error)
                                }
                            })
                        },
                        onDelete: data => {
                            return new Promise(async (resolve, reject) => {
                                try {
                                    let { _id } = data
                                    await http.delete(`/cabang-perusahaan/${submission.fileName}/${_id.$oid}`)
                                    await HeaderLampKhus5A.recon()
                                    resolve()
                                } catch (error) {
                                    reject(error)
                                }
                            })
                        },
                        onReset: () => {
                            return new Promise(async (resolve, reject) => {
                                try {
                                    await http.delete(`/cabang-perusahaan/${submission.fileName}`)
                                    await HeaderLampKhus5A.recon()
                                    resolve()
                                } catch (error) {
                                    reject(error)
                                }
                            })
                        },
                        definitions: [
                            {
                                inputType: inputTypes.INPUT,
                                label: getLabel('NmcabUtm', 'DetailLampKhus5A'),
                                key: 'NmcabUtm',
                                validation: 'max:200',
                                required: true
                            },
                            {
                                inputType: inputTypes.INPUT,
                                label: getLabel('AlmtCabUtm', 'DetailLampKhus5A'),
                                key: 'AlmtCabUtm',
                                validation: 'max:255',
                                required: true
                            },
                            {
                                inputType: inputTypes.INPUT,
                                label: getLabel('NPWP', 'DetailLampKhus5A'),
                                key: 'NPWP',
                                validation: 'max:255|min:15',
                                minLength: 15,
                                maxLength: 15,
                                required: true
                            },
                            {
                                inputType: inputTypes.INPUT_MASK_NUMBER,
                                label: getLabel('JmlCabPbt', 'DetailLampKhus5A'),
                                key: 'JmlCabPbt',
                                isNumericString: true,
                                decimalScale: 0
                            }
                        ]
                    }
                ]}
                onBeforeChange={(key, value) => {
                    if (key === 'NPWP' && value.length < 9 && String(value).substr(0, 9) !== profile.NPWP.substr(0, 9)) {
                        // toast.warning(t.translate('sentence.npwpNotMatch'))
                        return profile.NPWP.substr(0, 9)
                    } else return value
                }}
                // onSubmit={async (data, callback) => {
                //     storage.update(skey, data)
                //     await HeaderLampKhus5A.recon()
                //     callback(t.translate('sentence.savedItem'), false)
                // }}
                submitLabel={t.translate('column.save')}
            />
            {/* <ImportListForm
                visible={showImportForm}
                onRequestClose={() => setShowImportForm(false)}
                itemColumns={itemColumns(profile)}
                onSubmitItem={(obj) => {
                    return new Promise(async (resolve, reject) => {
                        try {
                            let __data = {
                                npwp: obj.NPWP,
                                nmCabUtm: obj.NmcabUtm,
                                almtCabUtm: obj.AlmtCabUtm,
                                jmlCabPbt: obj.JmlCabPbt
                            }
                            let res = await http.post(`/cabang-perusahaan/${submission.fileName}`, __data)
                            await HeaderLampKhus5A.recon()
                            resolve()
                        } catch (error) {
                            reject(error)
                        }
                    })
                }}
                onFinished={() => {
                    DataForm.reload(baseId)
                }}
                templateUri={envStore.env.template.daftarCabangUtamaPerusahaan}
                mod="DetailLampKhus5A"
                getLabel={getLabel}
            /> */}
            <ImportUploadForm
                visible={showImportForm}
                onRequestClose={() => setShowImportForm(false)}
                title={getLabel('Import', 'DetailLampKhus5A')}
                fileName={submission.fileName}
                onSubmit={async (data, callback) => {
                    handleCommit(data, callback)
                }}
                onFinished={() => {
                    DataForm.reload(baseId)
                }}
                templateUri={envStore.env.template.daftarCabangUtamaPerusahaan}
                getLabel={getLabel}
            />
            <ListLogImport
                visible={showLogImport}
                onRequestClose={() => setShowLogImport(false)}
                title={getLabel('LogImport', 'DetailLampKhus5A')}
                tipeForm='DetailLampKhus5A'
                filename={submission.fileName}
                getLabel={(e) => getLabel(e)}
            />
        </>
    )
}

DetailLampKhus5A.generate = generate
DetailLampKhus5A.columns = columns
DetailLampKhus5A.itemColumns = itemColumns()

export default inject('envStore', 'authStore', 'temporaryStore')(observer(DetailLampKhus5A))