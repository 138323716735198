import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { rules, storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import HeaderSSP from './HeaderSSP'
import Rekapitulasi from '../Rekapitulasi'
import moment from 'moment'
import { Button } from 'react-md'

const { inputTypes } = DataForm

const skey = storage.keys.SPT_1771_DetailSSP
const columns = {
    list:"list",
}

const itemColumns = {
    NO_SEQ: 'noSeq',
    KD_MAP: 'kdMap',
    KD_JNS_STR: 'kdJnsStr',
    TGL_SSP: 'tglSsp',
    JML_SSP: 'jmlSsp',
    NTPN: 'ntpn',
}

const getLastItem = (
    data = storage.get(skey)
) => {
    if(data.list.length > 0){
        let last = data.list.sort(function compare(a, b){
            let A = moment(b.TGL_SSP, 'YYYY-MM-DD').toDate()
            let B = moment(a.TGL_SSP, 'YYYY-MM-DD').toDate()
            return A-B
        })

        return last[0]
    } 
    
    return null
}

const generate = async () => {
    let data = await storage.generate(skey, {
        list: []
    })

    return data
}

const DetailSSP = ({
    getLabel,
    envStore,
    ...props
}) => {
    const profile = storage.get(storage.keys.SPT_1771_Profile)
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    const handleUpdateData = (data) => {
        return data
    }

    useEffect(() => {
        let __data = storage.get(skey)
        let defData = handleUpdateData(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-DetailSSP"
            className="mpk-margin-C"
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            watchDefaultData={false}
            style={{maxWidth: 'unset'}}
            definitions={[
                {
                    inputType: inputTypes.LIST,
                    asTable: false,
                    label: getLabel('__title','DetailSSP'),
                    key: 'list',
                    defaultData:{
                        NO_SEQ: 0,
                        KD_MAP: '411126',
                        KD_JNS_STR: '200',
                        TGL_SSP: '',
                        JML_SSP: 0,
                        NTPN: '',
                    },
                    definitions:[
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('KD_MAP', 'DetailSSP'),
                            key: 'KD_MAP',
                            disabled: true,
                            width: '50%',
                            required: true
                        },
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('KD_JNS_STR', 'DetailSSP'),
                            key: 'KD_JNS_STR',
                            disabled: true,
                            className:'mpk-padding-S padding-left',
                            width: '50%',
                            required: true
                        },
                        {
                            inputType: inputTypes.DATE,
                            label: getLabel('TGL_SSP', 'DetailSSP'),
                            key: 'TGL_SSP',
                            max: rules.maxTanggalPajak(profile.THN_PJK, envStore.env.timestamp),
                            required: true
                        },
                        ...['JML_SSP'].map(key => ({
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            label: getLabel(key, 'DetailSSP'),
                            key,
                            isNumericString:true,
                            decimalSeparator: ',',
                            thousandSeparator:'.',
                            allowNegative: false
                        })),
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('NTPN', 'DetailSSP'),
                            key: 'NTPN',
                            validation:'max:30',
                            required: true
                        },
                    ]
                }
            ]}
            onSubmit={async (data, callback) => {
                data.list = data.list.map((d, i) => {
                    d.NO_SEQ = i+1
                    return d
                })
                storage.update(skey, data)
                await HeaderSSP.recon()
                await Rekapitulasi.recon()
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    )
}

DetailSSP.generate = generate
DetailSSP.columns = columns
DetailSSP.itemColumns = itemColumns
DetailSSP.getLastItem = getLastItem

export default inject('envStore')(observer(DetailSSP))