import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Labarugi11
const columns = {
    ID651: 'id651',
    ID652: 'id652',
    ID653: 'id653',
    ID654: 'id654',
    ID655: 'id655',
    ID656: 'id656',
    ID657: 'id657',
    ID658: 'id658',
    ID659: 'id659',
    ID660: 'id660',
    ID661: 'id661',
    ID662: 'id662',
    ID663: 'id663',
    ID664: 'id664',
    ID665: 'id665',
    ID666: 'id666',
    ID667: 'id667',
    ID668: 'id668'
}

const recon = (
    data = storage.get(skey),
    submitting=true
) => {
    return new Promise(async (resolve) => {
        data.ID655 = data.ID652 + data.ID653 - data.ID654
        data.ID656 = data.ID651 - data.ID655
        data.ID659 = data.ID656 - data.ID657 - data.ID658
        data.ID662 = data.ID659 + data.ID660 + data.ID661
        data.ID664 = data.ID662 - data.ID663
        data.ID666 = data.ID664 + data.ID665
        data.ID668 = data.ID666 - data.ID667

         if(submitting) {   
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async (isReset) => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })(), isReset)

    return data
}

const Labarugi11 = ({
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-Labarugi11"
            className="mpk-margin-C mpk-animation slide-in"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={Object.keys(columns).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Labarugi11'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    isNumericString: true,
                    width: [
                        'ID653',
                        'ID654',
                        'ID655',
                        'ID656',
                        'ID660',
                        'ID661'
                    ].indexOf(key) >= 0 ? '50%' : '100%',
                    className: [
                        'ID653',
                        'ID655',
                        'ID660',
                    ].indexOf(key) >= 0  ? 'mpk-padding-S padding-right' : '',
                    disabled: [
                        'ID655',
                        'ID656',
                        'ID659',
                        'ID662',
                        'ID664',
                        'ID666',
                        'ID668'
                    ].indexOf(key) >= 0,
                    key
                }))
            }
            onChange={async data => {
                data = await recon(data, false)
                return data
            }}
            onSubmit={async (data, callback) => {
                await storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    ) : <LoaderInfo/>
}

Labarugi11.generate = generate
Labarugi11.columns = columns
Labarugi11.recon = recon

export default Labarugi11
