import AuthStore from './AuthStore';
import EnvStore from './EnvStore';
import ModalStore from './ModalStore';
import NavigationStore from './NavigationStore';
import TemporaryStore from './TemporaryStore';

let stores = {
    authStore       : new AuthStore(),
    envStore        : new EnvStore(),
    modalStore      : new ModalStore(),
    navigationStore : new NavigationStore(),
    temporaryStore  : new TemporaryStore()
}

export default stores;