import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'
import Induk from '../Induk'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_HeaderLampKhus7A
const columns = {
    JmlNettoRp:"jmlNettoRp",
    JmlPjkRp:"jmlPjkRp",
    JmlPjkVal:"jmlPjkVal",
    JmlKreHit:"jmlKreHit"
}

const recon = (
    data = storage.get(skey),
    detailLampKhus7A = storage.get(storage.keys.SPT_1771_DetailLampKhus7A),
    submitting=true
) => {
    return new Promise(async (resolve) => {
        data.JmlNettoRp = detailLampKhus7A.list.reduce((a, b) => (a + b.JmlNetto), 0)
        data.JmlPjkRp = detailLampKhus7A.list.reduce((a, b) => (a + b.PjkUtRp), 0)
        data.JmlPjkVal = detailLampKhus7A.list.reduce((a, b) => (a + b.PjkUtVal), 0)
        data.JmlKreHit = detailLampKhus7A.list.reduce((a, b) => (a + b.KrePjkHit), 0)
        if(submitting) {
            storage.update(skey, data)
            Induk.recon()
        }
        resolve(data)
    })
}

const generate = async () => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })())

    return data
}

const HeaderLampKhus7A = ({
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-HeaderLampKhus7A"
            className="mpk-margin-C"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={Object.keys(columns).map(key=>({
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: getLabel(key, 'HeaderLampKhus7A'),
                isNumericString:true,
                decimalSeparator: ',',
                thousandSeparator:'.',
                isNumericString: true,
                disabled: true,
                key
            }))}
            onSubmit={(data, callback) => {
                callback(t.translate('sentence.savedItem'), false)
                props.onNextTab()
            }}
            submitLabel={t.translate('column.next')}
        />
    )
}

HeaderLampKhus7A.generate = generate
HeaderLampKhus7A.columns = columns
HeaderLampKhus7A.recon = recon

export default HeaderLampKhus7A
