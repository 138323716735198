import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_HeaderLampVI

const recon = (submitting=true) => {
    return new Promise((resolve) => {
        const penyertaanModal = storage.get(storage.keys.SPT_1771_DetailLampVIPenyertaanModal)
        let update = {
            dJmlMdlStr:penyertaanModal.list.reduce((a, b) => (a+b.JmlPenyMdl), 0)
        }
        if(submitting) storage.update(skey, update)
        resolve()
    })
}

const columns = {
    dJmlMdlStr: 'dJmlMdlStr',
}

const generate = async () => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })())

    return data
}

const HeaderLampVI = ({
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    const handleUpdateData = (data) => {
        return data
    }

    useEffect(async () => {
        await recon()
        let __data = storage.get(skey)
        let defData = handleUpdateData(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-HeaderLampVI"
            className="mpk-margin-C"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={Object.keys(columns).map(key=>({
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: getLabel(key, 'HeaderLampVI'),
                isNumericString:true,
                decimalSeparator: ',',
                thousandSeparator:'.',
                isNumericString: true,
                readOnly: true,
                key
            }))}
            onSubmit={(data, callback) => {
                callback(t.translate('sentence.savedItem'), false)
                props.onNextTab()
            }}
            submitLabel={t.translate('column.next')}
        />
    )
}

HeaderLampVI.generate = generate
HeaderLampVI.columns = columns
HeaderLampVI.recon = recon

export default HeaderLampVI
