import moment from 'moment'

export const npwp = (value) => {
    value = String(value)
    if(value.length > 9 && value.substr(0,9) === '000000000') return ''
    return value
}

export const npwpOptional = (value='') => {
    if(String(value).length === 0) return '000000000000000';
    return value
}

export const maxTanggalPajak = (tahunPajak, timeStamp) => {
    let __d1 = moment(tahunPajak, 'YYYY').endOf('year')
    let __today = moment(timeStamp)
    if(__d1.isAfter(__today)){
        return __d1.format('YYYY-MM-DD')
    } else {
        return __today.format('YYYY-MM-DD')
    }
}

export const validateNamaNpwp = (data={}, key='NPWP') => {
    return data[key] !== '' && data[key] !== '000000000000000'
        ? 'required'
        : 'min:0'
}

export const validationTarif = 'max:100'
export const getValidationTahunUmum = timeStamp => {
    return `max:${moment(timeStamp).year()}`
}