import React, { useState, useEffect } from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { sso } from '../libs/react-mpk/hocs';
import { Hint, LoaderInfo } from '../libs/react-mpk/components';
import { http, toast } from '../libs/react-mpk/services'
import { Submission } from '../modules';
import t from 'counterpart'
import qs from 'query-string'
import { storage } from '../services';
import LogExport from '../modules/Submission/LogExport/LogExport'

const actions = {
	CLOSE_WINDOW: {
		resolver: 'close-window-1771'
	},
	READY: {
		initial: 'preparing-1771',
		resolver: 'on-ready-1771'
	},
	AUTH: {
		requestAccessToken: 'request-access-token',
		updateAccessToken: 'update-access-token'
	},
	ACTION: {
		reload: 'reload-1771'
	}
}

const Widget = ({
	history,
	...props
}) => {
	const [ready, setReady] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)
	const setupListener = () => {
		let widget = window
		widget.addEventListener('beforeunload', e => {
			props.envStore.widgetPost(actions.CLOSE_WINDOW.resolver, null);
		})
		widget.addEventListener('custom-message', handleMessage, false)
	}

	const handleMessage = event => {
		let { eventName, data } = event.detail
		switch (eventName) {
			case actions.READY.initial:
				console.log(`[WIDGET] ${data.id} IS READY`)
				let { organizations, spt, xbrl, id } = data
				if (organizations) organizations = organizations.sort((a, b) => (a.npwp > b.npwp))
				props.envStore.setWidget({ organizations, spt, xbrl })
				// if(spt) writeSptData(spt)
				// if(xbrl) handleXbrl(xbrl);
				setReady(true)

				props.envStore.widgetPost(actions.READY.resolver, { id })
				break;
			case 'cert-data':
				window.dispatchEvent(new CustomEvent('cert-data', { detail: data }))
				break;
			case 'reload-1771':
				console.log(eventName, data)
				window.dispatchEvent(new CustomEvent('sso-custom-message'), {
					detail: {
						eventName: 'reload',
						data
					}
				})
				break;
			default:
				return null
		}
	}

	const checkUrl = () => {
		return new Promise((resolve, reject) => {
			let parsedUrl = qs.parseUrl(window.location.href)
			let { channel, companyId } = parsedUrl.query
			if (!channel) {
				reject(new Error('Channel name is required'))
			} else {
				http.setCustomHeader('X-Channel', channel)
				resolve()
			}

			if (companyId) {
				http.get('/core-permission')
			}
		})
	}

	const writeSptData = (spt) => {
		let keys = Object.keys(spt)
		for (let k of keys) {
			let key = `SPT_1771_${k}`
			console.log(key)
			if (storage.keys[key]) {
				storage.set(key, spt[k])
			}
		}
	}

	const handleXbrl = async (xbrl) => {
		try {
			let { npwp, tahun, revNo } = xbrl
			let res = await http.get(`/submission/xbrl/prefill/${npwp}/${tahun}/${revNo}`, {}, {
				headers: {
					'accept-language': 'in'
				}
			})
			props.temporaryStore.setProperties('xbrlData', res.data)
			setReady(true)
		} catch (error) {
			toast.errorRequest(error)
			setReady(true)
		}
	}

	useEffect(async () => {
		try {
			await checkUrl()
			setupListener()
		} catch (error) {
			setErrorMessage(error.message)
			setReady(true)
		}
	}, [])

	return ready ? (
		errorMessage ? <Hint noBorder={true}>{errorMessage}</Hint> : (
			<>
				<Router history={history}>
					<Switch>
						<Route
							path='/widget/submissions'
							render={props => (
								<Submission.List {...props} />
							)}
						/>
					</Switch>
					<Switch>
						<Route
							path="/widget/submissions-log-export"
							render={(props) => <LogExport {...props} />}
						/>
					</Switch>
					<Switch>
						<Route
							path="/widget/submissions-log-export-v2/:filename/:referenceNo" // ini referenceNo
							render={(props) => <LogExport {...props} />}
						/>
					</Switch>
					<Switch>
						<Route
							path='/widget/submission/spt-1771/:tab'
							render={props => (
								<Submission.SPTForm.SPT1771 {...props} />
							)}
						/>
					</Switch>
				</Router>
			</>
		)
	) : <LoaderInfo>Getting widget parameters</LoaderInfo>
}

export default sso({
	basePath: '/widget'
})(Widget);