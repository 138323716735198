import { crud, http } from "../../libs/react-mpk/services";

let service = new crud("/submission");

service.getExportLogv2 = (query) => {
  return http.get(`/submission/log_export_v2/${query.filename}`, query);
};

service.get = (query) => {
  return http.get(`/submission/index/1771`, query);
};

export const supportGet = (query) => {
  return http.get(`/submission/support/index/1771`, query);
};

export const getByReferenceNo = (referenceNo, isSupport = false) => {
  return http.get(`/submission${isSupport ? "/support" : ""}/${referenceNo}`);
};

export const getHistory = (referenceNo, isSupport = false) => {
  return http.get(
    `/submission${isSupport ? "/support" : ""}/${referenceNo}/history`
  );
};

export const getCheckStatus = (referenceNo, isSupport = false) => {
  return http.get(
    `/submission${isSupport ? "/support" : ""}/${referenceNo}/check`
  );
};

export const getRawData = (name) => {
  return http.get(`/submission/${name}/raw`);
};

export const supportGetRawData = (name) => {
  return http.get(`/submission/support/${name}/raw`);
};

export const post1771 = (data, asWidget = false) => {
  if (asWidget) {
    data.ignoreCert = asWidget;
  }
  return http.post(`/submission${asWidget ? "/widget" : ""}/spt/1771`, data);
};

export const commitSPT = (name) => {
  return http.get(`/submission/${name}/commit`);
};

export const cancel = (name) => {
  return http.delete(`/submission/${name}`);
};

export const getPTKP = (tahun = 2015, statusKawin = 0, tanggungan = 0) => {
  return http.get(`/ptkp/${tahun}/${statusKawin}/${tanggungan}`);
};

export const getPdf = (name) => {
  return http.get(`/submission/${name}/pdf`);
};

export const uploadPDF = (name, file, data) => {
  return http.upload(`/submission/${name}/pdf`, file, data);
};

export const deletePDF = (name, uploadId) => {
  return http.delete(`/submission/${name}/pdf/${uploadId}`);
};

export const downloadBpe = (referenceNo) => {
  return http.download(
    `/bpe/${referenceNo}/download`,
    {},
    { headers: { responseType: "blob" } }
  );
};

export const getKlu = (keyword, byCode = false) => {
  return byCode
    ? http.get(`/klu/code/${keyword}`)
    : http.get(`/klu/${keyword.replace(/ /gi, "_").replace(/\//gi, "")}`);
};

export const parseFilename = (filename) => {
  let fileName = String(filename).replace(".csv", "").replace(".mpk", "");
  let splitFileName = fileName.split("F");
  let sptCode = `F${splitFileName[1]}`;
  return {
    fileName,
    npwp: splitFileName[0].substr(0, 15),
    periodBegin: splitFileName[0].substr(15, 2),
    periodEnd: splitFileName[0].substr(17, 2),
    taxYear: splitFileName[0].substr(19, 4),
    pembetulan: splitFileName[0].substr(23, 2),
    sptCode,
  };
};

export const getNpwp = (npwp) => {
  return http.get(`/npwp/${npwp}/1771`);
};

export const postCert = (data) => {
  return http.post(`/npwp/cert-meta/1771`, data);
};

export const exportSpt = (submissionName, bagian, isSupport = false, kota) => {
  return bagian.toLowerCase() === "induk"
    ? http.download(
        `/submission/${
          isSupport ? "support/" : ""
        }${submissionName}/export/${bagian}`
      )
    : http.get(
        `/submission/${submissionName}/export/spt/${bagian}?kota=${kota}`,
        null,
        { timeout: 1000 * 60 * 5 }
      );
};

export const downloadSpt = (submissionName, path, fileName) => {
  return http.download(
    `/submission/${submissionName}/download/spt`,
    { path },
    { method: "post" },
    { fileName }
  );
};

export const supportExportSpt = (submissionName, bagian) => {
  return http.download(
    `/submission/support/${submissionName}/export/${bagian}`,
    null,
    {}
  );
};
export const ExportXlsx = (q) => {
  return http.download(`/submission/export/xlsx/1771?${q}`, null, {});
};
export const getValidate = (referenceNo) => {
  return http.get(`/submission/${referenceNo}/validate`);
};

export const getSupportValidate = (referenceNo) => {
  return http.get(`/submission/support/${referenceNo}/validate`);
};

export const postXmlValidate = (referenceNo) => {
  return http.post(`/submission/${referenceNo}/xml_validate`);
};

export const postSupportXmlValidate = (referenceNo) => {
  return http.post(`/submission/support/${referenceNo}/xml_validate`);
};

export const uploadPDFBulk = (name, file, data) => {
  return http.upload(`/submission/pdf/bulk`, file, data);
};

export const postCommitZip = (data) => {
  return http.post(`/submission/commit_zip`, data);
};

export const getNpwpPemdandan = (npwp) => {
  return http.get(`/pemadanan/pemadanan/${npwp}`);
};

export const getNpwpPemdandanAp = (npwp) => {
  return http.get(`/api/pemadanan/pemadanan/${npwp}`);
};

export default service;
