import { crud, http } from "../../../libs/react-mpk/services";

let service = new crud("/log-import");
service.get = (query) => {
  return http.get(`/log-import`, query);
};
service.getDetailByReference = (category, reference, query) => {
  return http.get(`/log-import/${category}/${reference}`, query);
};

export default service;
