import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { rules, storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import HeaderLampKhus7A from './HeaderLampKhus7A'
import { round } from '../../../../../libs/react-mpk/services/number.service'

const { inputTypes } = DataForm

const skey = storage.keys.SPT_1771_DetailLampKhus7A
const columns = {
    list:"list",
}

const itemColumns = {
    NmPmtLN: 'nmPmtLn',
    AlmtPmtLN: 'almtPmtLn',
    JnsPenghasilan: 'jnsPenghasilan',
    Valas: 'valas',
    JmlNettoValas: 'jmlNettoValas',
    Tanggal: 'tanggal',
    KursValas: 'kursValas',
    JmlNetto: 'jmlNetto',
    PjkUtVal: 'pjkUtVal',
    PjkUtRp: 'pjkUtRp',
    KrePjkHit: 'krePjkHit'
}

const generate = async () => {
    let data = await storage.generate(skey, {
        list: []
    })

    return data
}

const DetailLampKhus7A = ({
    getLabel,
    envStore,
    ...props
}) => {
    const profile = storage.get(storage.keys.SPT_1771_Profile)
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    const handleUpdateData = (data) => {
        return data
    }

    useEffect(() => {
        let __data = storage.get(skey)
        let defData = handleUpdateData(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-DetailLampKhus7A"
            className="mpk-margin-C mpk-full full-width"
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            watchDefaultData={false}
            style={{
                maxWidth: 'unset'
            }}
            definitions={[
                {
                    inputType: inputTypes.LIST,
                    asTable: true,
                    label: getLabel('__title','DetailLampKhus7A'),
                    key: 'list',
                    defaultData:{
                        NmPmtLN: '',
                        AlmtPmtLN: '',
                        JnsPenghasilan: envStore.env.spt.jenisPenghasilanLuarNegeri[0].value,
                        Valas: envStore.env.spt.kodeValas[0],
                        JmlNettoValas: 0,
                        Tanggal: '',
                        KursValas: 0,
                        JmlNetto: 0,
                        PjkUtVal: 0,
                        PjkUtRp: 0,
                        KrePjkHit: 0
                    },
                    definitions:[
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('NmPmtLN', 'DetailLampKhus7A'),
                            key: 'NmPmtLN',
                            validation:'max:200',
                            className: 'mpk-max-width-XL mpk-padding-N padding-top padding-bottom',
                            required: true
                        },
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('AlmtPmtLN', 'DetailLampKhus7A'),
                            key: 'AlmtPmtLN',
                            validation: 'max:255',
                            className: 'mpk-max-width-XL mpk-padding-N padding-top padding-bottom',
                            required: true
                        },
                        {
                            inputType: inputTypes.DATE,
                            label: getLabel('Tanggal', 'DetailLampKhus7A'),
                            key: 'Tanggal',
                            max: rules.maxTanggalPajak(profile.THN_PJK, envStore.env.timestamp)
                        },
                        {
                            inputType: inputTypes.SELECT,
                            label: getLabel('JnsPenghasilan', 'DetailLampKhus7A'),
                            key: 'JnsPenghasilan',
                            options: envStore.env.spt.jenisPenghasilanLuarNegeri
                        },
                        {
                            inputType: inputTypes.SELECT,
                            label: getLabel('Valas', 'DetailLampKhus7A'),
                            key: 'Valas',
                            options: envStore.env.spt.kodeValas
                        },
                        ...['JmlNettoValas','KursValas','JmlNetto','PjkUtVal','PjkUtRp','KrePjkHit'].map(key => ({
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            label: getLabel(key, 'DetailLampKhus7A'),
                            key,
                            isNumericString:true,
                            decimalSeparator: ',',
                            thousandSeparator:'.',
                            disabled: ['JmlNetto', 'PjkUtRp'].indexOf(key) >= 0,
                            validation: d => (
                                key === 'KrePjkHit'
                                    ? `max:${d.PjkUtRp}`
                                    : key === 'PjkUtVal'
                                        ? `max:${d.JmlNettoValas}`
                                        : 'min:0'
                            )
                        }))
                    ]
                }
            ]}
            onChange={(data, key, value, parentKey, indexAtParent) => {
                if(parentKey && indexAtParent !== null){
                    let item = data[parentKey][indexAtParent]
                    if(item){
                        item.JmlNetto = round(item.JmlNettoValas*item.KursValas, 1)
                        item.PjkUtRp = round(item.PjkUtVal*item.KursValas, 1)
                    }
                }

                return data
            }}
            onSubmit={async (data, callback) => {
                storage.update(skey, data)
                await HeaderLampKhus7A.recon()
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    )
}

DetailLampKhus7A.generate = generate
DetailLampKhus7A.columns = columns
DetailLampKhus7A.itemColumns = itemColumns

export default inject('envStore')(observer(DetailLampKhus7A))