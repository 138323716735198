import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { rules, storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const { inputTypes } = DataForm
const recon = (
    data = storage.get(storage.keys.SPT_1771_Induk),
    HeaderLampIV = storage.get(storage.keys.SPT_1771_HeaderLampIV),
) => {
    return new Promise(resolve => {
        data.dF15a = HeaderLampIV ? HeaderLampIV.JBA : 0
        data.dF15b = HeaderLampIV ? HeaderLampIV.JBB : 0
        resolve(data)
    })
}

const F = ({
    data,
    handleUpdate,
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        setDefaultData(data)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-F"
            className="mpk-margin-C"
            style={{
                width: '100%',
                maxWidth: 800,
            }}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                'dF15a', 'dF15b'
            ].map(key => ({
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: getLabel(key, 'Induk'),
                isNumericString:true,
                decimalSeparator: ',',
                thousandSeparator:'.',
                isNumericString: true,
                disabled: true,
                key
            }))}
            onChange={(data, key, value) => {
                return data
            }}
        />
    )
}


F.recon = recon

export default inject('envStore')(observer(F))
