import React from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'

const WLink = ({
  className   = '',
  children    = null,
  to        = '',
  ...props
}) => {
  return (
    <Link
      to={to}
      className={`mpk-link ${className}`}
      {...props}
    >
      {children}
    </Link>
  )
}

export default WLink
