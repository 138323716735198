import React, { useState, useEffect } from "react";
import { Modal, LoaderInfo } from "..";
import { inject, observer } from "mobx-react";

const baseId = "personal-setting";
const SettingWidget = ({
  visible = false,
  setVisible,
  ListBilling,
  ...props
}) => {
  let [loading, setLoading] = useState(false);
  const renderWidget = () => {
    setLoading(true);
    console.log('Testin');

    let accessToken = props.authStore.getAccessToken();

    if (window.personalSetting) {
      console.log(window);
      window.personalSetting.render(
        "mpk-setting-widget",
        {
          accessToken,
          client: "1Vh6KS76xanIa4yASV6ubHXH6bLXbBZ7yoMRrN71",
        },
        (event) => {
          if (event.type === "restart") {
          }
        }
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    if (visible) {
      renderWidget();
    } else {
      setLoading(false);
    }
  }, [visible]);
  return (
    <Modal.Info
      baseId={baseId}
      className="mpk-padding-N padding-all mpk-paper mpk-full viewport-width viewport-height"
      style={{
        width: '60vw',
        height: '90vh'
      }}
      onRequestClose={() => {
        setVisible();
      }}
      visible={visible}
    >
      <div id="mpk-setting-widget" className="mpk-full full-width full-height">
        {loading && <LoaderInfo />}
      </div>
    </Modal.Info>
  );
};

export default inject(
  "modalStore",
  "modalStore",
  "envStore",
  "authStore",
  "temporaryStore"
)(observer(SettingWidget));
