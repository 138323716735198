import React, { useState, useEffect } from "react";
import Picture from "./Picture";
import { DropdownMenu, FontIcon } from "react-md";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { Flex } from "..";
import SettingWidget from "./SettingWidget";

const AppInfo = (props) => {
  return (
    <div>
      <div className="mpk-font size-L weight-B">
        {props.envStore.env.appInfo.name}
      </div>
      <div className="mpk-font size-NS">
        {props.envStore.env.appInfo.version}
      </div>
      {
        props.envStore.env.additionAppInfo &&
          props.envStore.env.additionAppInfo.map((item) => {
            return (
              <>
                <div className="mpk-font size-L weight-B">{item.title}</div>
                <div className="mpk-font size-NS">{item.value}</div>
              </>
            );
          })
        // (
        //   <>
        //     <div className="mpk-font size-L weight-B">Serial Number</div>
        //     <div className="mpk-font size-NS">{props.authStore.user.sn}</div>
        //   </>
        // )
      }
    </div>
  );
};

const Profile = ({ menuItems = [], ...props }) => {
  const [name, setName] = useState("...");
  const [showSetting, setShowSetting] = useState(false);

  useEffect(() => {
    if (props.authStore.isLoggedIn) {
      try {
        setName(props.authStore.user.name.split(" ")[0]);
      } catch (e) {}
    }
    if (props.username) {
      setName(props.username);
    }
  }, [props.authStore.isLoggedIn]);

  useEffect(() => {
    if (props.username) {
      setName(props.username);
    }
  }, [props.username]);

  const handleProfile = () => {
    setShowSetting(true)
  };

  const handleLogout = () => {
    if (props.handleLogout) {
      props.handleLogout();
    } else {
      props.modalStore.showConfirm({
        title: t.translate("mpk.column.logout"),
        children: t.translate("mpk.sentence.logoutMessage"),
        onSubmit: () => {
          props.authStore.logout();
        },
      });
    }
  };

  const handleApplicationInfo = () => {
    props.modalStore.showInfo({
      title: t.translate("mpk.sentence.applicationInfo"),
      children: <AppInfo {...props} />,
    });
  };

  menuItems = [
    {
      leftAddon: <FontIcon iconClassName="mdi mdi-account" />,
      children: t.translate("mpk.column.profile"),
      onClick: handleProfile,
    },
    {
      leftAddon: <FontIcon iconClassName="mdi mdi-logout" />,
      children: t.translate("mpk.column.logout"),
      onClick: handleLogout,
    },
    "separator",
    ...menuItems,
    menuItems.length > 0 ? "separator" : null,
    {
      leftAddon: <FontIcon iconClassName="mdi mdi-information" />,
      children: t.translate("mpk.column.info"),
      onClick: handleApplicationInfo,
    },
  ];

  return (
    <>
      <div className="mpk-profile">
        <DropdownMenu
          id="mpk-profile-menu"
          items={menuItems}
          disableDropdownIcon
        >
          <Flex align={Flex.properties.align.CENTER}>
            <span className="mpk-margin-N margin-right">{name}</span>
            <Picture />
          </Flex>
        </DropdownMenu>
      </div>
      <SettingWidget
        visible={showSetting}
        setVisible={() => {
          setShowSetting(false);
        }}
        {...props}
      />
    </>
  );
};

Profile.Picture = Picture;

export default inject("modalStore", "authStore", "envStore")(observer(Profile));
