import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Error from './pages/Error';
import Internal from './pages/Internal.routes';
import Product from './pages/Product/Product.routes';
import Widget from './pages/Widget.routes'

const routes = () => (
  <Switch>
    <Route path="/internal" render={(props) => (
      <Internal {...props}/>
    )}/>
    <Route path="/widget" render={(props) => (
      <Widget {...props}/>
    )}/>
    <Route path="/product" render={(props) => (
      <Product {...props}/>
    )}/>
    <Route path="/error" render={(props) => (
      <Error {...props}/>
    )}/>
  </Switch>
);

export default routes