import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Labarugi5
const columns = {
    ID295: 'id295',
    ID296: 'id296',
    ID297: 'id297',
    ID298: 'id298',
    ID299: 'id299',
    ID300: 'id300',
    ID301: 'id301',
    ID302: 'id302',
    ID303: 'id303',
    ID304: 'id304',
    ID305: 'id305',
    ID306: 'id306',
    ID307: 'id307',
    ID308: 'id308',
    ID309: 'id309',
    ID310: 'id310',
    ID311: 'id311'
}

const recon = (
    data = storage.get(skey),
    submitting=true
) => {
    return new Promise(async (resolve) => {
        data.ID297 = data.ID295 - data.ID296
        data.ID300 = data.ID298 - data.ID299
        data.ID303 = data.ID301 - data.ID302
        data.ID305 = data.ID297 + data.ID300 + data.ID303 + data.ID304
        data.ID307 = data.ID305 - data.ID306
        data.ID309 = data.ID307 + data.ID308
        data.ID311 = data.ID309 - data.ID310
         if(submitting) {   
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async (isReset) => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })(), isReset)

    return data
}

const Labarugi5 = ({
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-Labarugi5"
            className="mpk-margin-C mpk-animation slide-in"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={Object.keys(columns).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Labarugi5'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    isNumericString: true,
                    width: [
                        'ID297',
                        'ID300',
                        'ID303',
                        'ID304',
                        'ID305',
                        'ID306',
                        'ID307',
                        'ID308',
                        'ID309',
                        'ID310',
                        'ID311'
                    ].indexOf(key) < 0 ? '50%' : '100%',
                    className: [
                        'ID295',
                        'ID298',
                        'ID301'
                    ].indexOf(key) >= 0  ? 'mpk-padding-S padding-right' : '',
                    disabled: [
                        'ID297',
                        'ID300',
                        'ID303',
                        'ID305',
                        'ID307',
                        'ID309',
                        'ID311',
                    ].indexOf(key) >= 0,
                    key
                }))
            }
            onChange={async data => {
                data = await recon(data, false)
                return data
            }}
            onSubmit={async (data, callback) => {
                await storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    ) : <LoaderInfo/>
}

Labarugi5.generate = generate
Labarugi5.columns = columns
Labarugi5.recon = recon

export default Labarugi5
