import http from './http.service';
import { cloneDeep } from 'lodash'

class Crud{
  constructor(basePath='/', config={}, options={}){
    this.path = basePath
    this.config = config
    this.options = options
  }

  setPath(path){
    this.path = path
  }

  clearParams(){
    let config = cloneDeep(this.config)
    delete config.params
    return config
  }
  
  get(query={}){
    return http.request({
      method: http.methods.GET,
      url: `${this.path}`,
      query,
      options: this.options,
      config: this.config
    })
  }

  getById(id){
    return http.request({
      method: http.methods.GET,
      url: `${this.path}/${id}`,
      options: this.options,
      config: this.config
    })
  }

  post(data){
    let config = this.clearParams()
    return http.request({
      method: http.methods.POST,
      url: `${this.path}`,
      data,
      options: this.options,
      config
    })
  }

  put(id, data){
    let config = this.clearParams()
    return http.request({
      method: http.methods.PUT,
      url: `${this.path}/${id}`,
      data,
      options: this.options,
      config
    })
  }

  delete(id){
    let config = this.clearParams()
    return http.request({
      method: http.methods.DELETE,
      url: `${this.path}/${id}`,
      options: this.options,
      config
    })
  }
}

export default Crud