import { MediaContainer } from 'react-md';
import React from 'react'
import Modal from './Modal';
import Confirm from './Confirm';
import Submit from './Submit';
import Info from './Info';

export const index = ({
  baseId                = 'mpk-modal',
  title                 = null, 
  children              = null, 
  footer                = null, 
  noFooter              = false,
  disableFocusOnMount   = false,
  visible               = false,
  onRequestClose        = () => {},
  ...props 
}) => (<Modal {...{baseId, title, children, footer, noFooter, disableFocusOnMount, visible, onRequestClose, ...props}}/>)
index.Confirm = Confirm
index.Submit = Submit
index.Info    = Info

export default index;