import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Labarugi3
const columns = {
    ID175: 'id175',
    ID176: 'id176',
    ID177: 'id177',
    ID178: 'id178',
    ID179: 'id179',
    ID180: 'id180',
    ID181: 'id181',
    ID182: 'id182',
    ID183: 'id183',
    ID184: 'id184',
    ID185: 'id185',
    ID186: 'id186',
    ID187: 'id187',
    ID188: 'id188',
    ID189: 'id189',
    ID190: 'id190',
    ID191: 'id191',
    ID192: 'id192'
}

const recon = (
    data = storage.get(skey),
    submitting=true
) => {
    return new Promise(async (resolve) => {
        data.ID179 = data.ID176 + data.ID177 - data.ID178
        data.ID180 = data.ID175 - data.ID179
        data.ID183 = data.ID180 - data.ID181 - data.ID182
        data.ID186 = data.ID183 + data.ID184 + data.ID185
        data.ID188 = data.ID186 - data.ID187
        data.ID190 = data.ID188 + data.ID189
        data.ID192 = data.ID190 - data.ID191
         if(submitting) {
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async (isReset) => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })(), isReset)

    return data
}

const Labarugi3 = ({
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-Labarugi3"
            className="mpk-margin-C mpk-animation slide-in"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={Object.keys(columns).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Labarugi3'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    isNumericString: true,
                    width: [
                        'ID179',
                        'ID180',
                        'ID183',
                        'ID186',
                        'ID187',
                        'ID188',
                        'ID189',
                        'ID190',
                        'ID191',
                        'ID192'
                    ].indexOf(key) < 0 ? '50%' : '100%',
                    className: [
                        'ID175',
                        'ID177',
                        'ID181',
                        'ID184'
                    ].indexOf(key) >= 0  ? 'mpk-padding-S padding-right' : '',
                    disabled: [
                        'ID179',
                        'ID180',
                        'ID183',
                        'ID186',
                        'ID188',
                        'ID190',
                        'ID192',
                    ].indexOf(key) >= 0,
                    key
                }))
            }
            onChange={async data => {
                data = await recon(data, false)
                return data
            }}
            onSubmit={async (data, callback) => {
                await storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    ) : <LoaderInfo/>
}

Labarugi3.generate = generate
Labarugi3.columns = columns
Labarugi3.recon = recon

export default Labarugi3
