import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Neraca9
const columns = {
    ID475: 'id475',
    ID476: 'id476',
    ID477: 'id477',
    ID478: 'id478',
    ID479: 'id479',
    ID480: 'id480',
    ID481: 'id481',
    ID482: 'id482',
    ID483: 'id483',
    ID484: 'id484',
    ID485: 'id485',
    ID486: 'id486',
    ID487: 'id487',
    ID488: 'id488',
    ID489: 'id489',
    ID490: 'id490',
    ID491: 'id491',
    ID492: 'id492',
    ID493: 'id493',
    ID494: 'id494',
    ID495: 'id495',
    ID496: 'id496',
    ID497: 'id497',
    ID498: 'id498',
    ID499: 'id499',
    ID500: 'id500',
    ID501: 'id501',
    ID502: 'id502',
    ID503: 'id503',
    ID504: 'id504',
    ID505: 'id505',
    ID506: 'id506',
    ID507: 'id507',
    ID508: 'id508',
    ID509: 'id509',
    ID510: 'id510',
    ID511: 'id511',
    ID512: 'id512',
    ID513: 'id513',
    ID514: 'id514',
    ID515: 'id515',
    ID516: 'id516'
}

const recon = (
    data = storage.get(skey),
    submitting=true
) => {
    return new Promise(async (resolve) => {
        data.ID495 =    data.ID475 + data.ID476 + data.ID477 + data.ID478 +
                        data.ID479 + data.ID480 + data.ID481 + data.ID482 + data.ID483 +
                        data.ID484 + data.ID485 + data.ID486 + data.ID487 + data.ID488 +
                        data.ID489 + data.ID490 + data.ID491 - data.ID492 + data.ID493 +
                        data.ID494
        data.ID495 = data.ID495 > 0 ? data.ID495 : 0

        data.ID516 =    data.ID496 + data.ID497 + data.ID498 + data.ID499 +
                        data.ID500 + data.ID501 + data.ID502 + data.ID503 + data.ID504 +
                        data.ID505 + data.ID506 + data.ID507 + data.ID508 + data.ID509 +
                        data.ID510 + data.ID511 + data.ID512 + data.ID513 + data.ID514 +
                        data.ID515 

        if(submitting) {
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async (isReset) => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })(), isReset)

    return data
}

const Neraca9 = ({
    getLabel,
    asKewajiban,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-Neraca9"
            className="mpk-margin-C mpk-animation slide-in"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: getLabel('__aktiva', 'Neraca9')
                // },
                ...(asKewajiban ? [] : [
                    'ID475',
                    'ID476',
                    'ID477',
                    'ID478',
                    'ID479',
                    'ID480',
                    'ID481',
                    'ID482',
                    'ID483',
                    'ID484',
                    'ID485',
                    'ID486',
                    'ID487',
                    'ID488',
                    'ID489',
                    'ID490',
                    'ID491',
                    'ID492',
                    'ID493',
                    'ID494',
                    'ID495',
                ]).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Neraca9'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    isNumericString: true,
                    allowNegative: false,
                    width: ['ID495'].indexOf(key) < 0 ? '50%' : '100%',
                    className: i%2 || key === 'ID495' ? '' : 'mpk-padding-S padding-right',
                    disabled: key === 'ID495',
                    key
                })),
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: getLabel('__kewajibanDanEkuitas', 'Neraca9')
                // },
                ...(asKewajiban ? [
                    'ID496',
                    'ID497',
                    'ID498',
                    'ID499',
                    'ID500',
                    'ID501',
                    'ID502',
                    'ID503',
                    'ID504',
                    'ID505',
                    'ID506',
                    'ID507',
                    'ID508',
                    'ID509',
                    'ID510',
                    'ID511',
                    'ID512',
                    'ID513',
                    'ID514',
                    'ID515',
                    'ID516'

                ] : []).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Neraca9'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    isNumericString: true,
                    allowNegative: ['ID513','ID514','ID515'].indexOf(key) >= 0,
                    width: [
                        'ID510',
                        'ID511',
                        'ID512',
                        'ID513',
                        'ID516'
                    ].indexOf(key) >= 0 ? '100%' : '50%',
                    className: [
                        'ID496',
                        'ID498',
                        'ID500',
                        'ID502',
                        'ID504',
                        'ID506',
                        'ID508',
                        'ID514'
                    ].indexOf(key) >= 0 ? 'mpk-padding-S padding-right' : '',
                    disabled: key === 'ID516',
                    key
                }))
            ]}
            onChange={async data => {
                data = await recon(data, false)
                return data
            }}
            onSubmit={async (data, callback) => {
                await storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    ) : <LoaderInfo/>
}

Neraca9.generate = generate
Neraca9.columns = columns
Neraca9.recon = recon

export default Neraca9
