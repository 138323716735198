import __HeaderLampKhus1A from './HeaderLampKhus1A'
import __DetailLampKhus1A from './DetailLampKhus1A'
import __DetailLampKhus1A2 from './DetailLampKhus1A2'
import LampKhus1A from './LampKhusus1A'

export const HeaderLampKhus1A = __HeaderLampKhus1A
export const DetailLampKhus1A = __DetailLampKhus1A
export const DetailLampKhus1A2 = __DetailLampKhus1A2

export default LampKhus1A
