import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { rules, storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import numeral from 'numeral'
import HeaderLampIV from './HeaderLampIV'
import { round } from '../../../../../libs/react-mpk/services/number.service'

const { inputTypes } = DataForm

const skey = storage.keys.SPT_1771_DetailLampIVA
const columns = {
    list:"list",
}

const itemColumns = {
    JNS_HSL:'jnsHsl',
    DPP:'dpp',
    TARIF:'tarif',
    PPH:'pph',
}

const generate = async () => {
    let data = await storage.generate(skey, {
        list: []
    })

    return data
}

const DetailLampIVA = ({
    getLabel,
    envStore,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    const handleUpdateData = (data) => {
        return data
    }

    useEffect(() => {
        let __data = storage.get(skey)
        let defData = handleUpdateData(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-DetailLampIVA"
            className="mpk-margin-C"
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            watchDefaultData={false}
            style={{maxWidth: 'unset'}}
            definitions={[
                {
                    inputType: inputTypes.LIST,
                    asTable: true,
                    label: getLabel('__title','DetailLampIVA'),
                    key: 'list',
                    defaultData:{
                        JNS_HSL:'',
                        DPP:0,
                        TARIF:0,
                        PPH: 0
                    },
                    definitions:[
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('JNS_HSL', 'DetailLampIVA'),
                            key: 'JNS_HSL',
                            maxLength: 200,
                            required: true
                        },
                        {
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            label: getLabel('DPP', 'DetailLampIVA'),
                            isNumericString:true,
                            decimalSeparator: ',',
                            thousandSeparator:'.',
                            key: 'DPP',
                            allowNegative: false
                        },
                        {
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            label: getLabel('TARIF', 'DetailLampIVA'),
                            key:'TARIF',
                            isNumericString:true,
                            decimalSeparator: ',',
                            thousandSeparator:'.',
                            allowNegative: false,
                            decimalScale: '2',
                            fixedDecimalScale: true,
                            validation: rules.validationTarif
                        },
                        {
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            label: getLabel('PPH', 'DetailLampIVA'),
                            isNumericString:true,
                            decimalSeparator: ',',
                            thousandSeparator:'.',
                            key: 'PPH',
                            allowNegative: false,
                            readOnly: true
                        }
                    ]
                }
            ]}
            // onBeforeChange={(key, value) => {
            //     if(key === 'TARIF') return numeral(value).format(0.00)
            //     else return value
            // }}
            onChange={(data, key, value, parentKey, indexAtParent) => {
                if(parentKey && indexAtParent !== null){
                    data[parentKey][indexAtParent].PPH = round((data[parentKey][indexAtParent].DPP*data[parentKey][indexAtParent].TARIF)/100, true)
                }
                return data
            }}
            onSubmit={async (data, callback) => {
                storage.update(skey, data)
                await HeaderLampIV.recon()
                callback(t.translate('sentence.savedItem'), false)
            }}
        />
    )
}

DetailLampIVA.generate = generate
DetailLampIVA.columns = columns
DetailLampIVA.itemColumns = itemColumns

export default inject('envStore')(observer(DetailLampIVA))