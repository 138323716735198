import React, { Component } from "react";
import { TableWrapper, TabsWrapper } from "../../../libs/react-mpk/wrapper";
import moment from "moment";
import { inject, observer } from "mobx-react";
import t from "counterpart";
import service, { parseFilename, supportGet, downloadSpt } from "../Submission.service";
import { toast } from "../../../libs/react-mpk/services";
import { Button } from "react-md";

const baseId = "mod-log-export-list";

const initSubmission = (item) => {
  let meta = parseFilename(item.fileName);
  return Object.assign(item, meta);
};
const LogExport = ({ isSupport = false, ...props }) => {
  return (
    <>
      <TableWrapper
        backTo={()=> {
          if(sessionStorage.getItem('returnPath')){
            return sessionStorage.getItem('returnPath')
          } else {
            return `/${props.envStore.widget.active ? 'widget' : 'internal'}/submissions`
          }
        }}
        baseId={baseId}
        title={t.translate("modules.logExport.title")}
        className={`mpk-padding-N padding-left`}
        // hintMessage={t.translate("modules.logExport.hint.message")}
        hintIconClassName="mdi mdi-information"
        defaultData={[]}
        defaultSortBy="createdAt"
        defaultSort={TableWrapper.sorts.descending}
        defaultQuery={{ column: "category" }}
        columns={[
          {
            label: t.translate("column.category"),
            key: "category",
            searchable: true,
            render: (item) => item.category,
          },
          // {
          //   label: t.translate("column.referenceno"),
          //   key: "referenceNo",
          //   searchable: false,
          //   render: (item) => item.referenceNo,
          // },
          // {
          //   label: t.translate("column.fileName"),
          //   key: "fileName",
          //   searchable: true,
          //   render: (item) => (
          //     <div
          //       className={"mpk-link"}
          //       onClick={() => {
          //         // handleGetRawData(item);
          //       }}
          //     >
          //       {item.fileName}
          //     </div>
          //   ),
          // },
          {
            label: "Status",
            key: "status",
            searchable: false,
            render: (item) => {
              return (
                <div>
                  {/* <TableWrapper.Status type={stat.flag} onClick={stat.onClick}> */}
                  {item.status == "SUCCESS" && <Button themeType="outline" style={{ color: 'white', background: 'green' }}>{item.status}</Button>}
                  {item.status != "SUCCESS" && <Button themeType="outline" style={{ color: 'white', background: 'gray' }}>{item.status}</Button>}
                  {/* </TableWrapper.Status> */}
                </div>
              );
            },
          },
          {
            label: t.translate("column.message"),
            key: "message",
            render: (item) => item.message,
          },
          // {
          //   label: t.translate("column.createdBy"),
          //   key: "createdBy",
          //   searchable: isSupport,
          //   render: (item) => item.createdBy,
          // },
          {
            label: t.translate("column.createdAt"),
            key: "createdAt",
            searchable: isSupport,
            render: (item) => {
              try {
                return moment(new Date(Number(item.createdAt.$date.$numberLong))).format('DD/MM/YYYY hh:mm:ss A')
              } catch(e){
                return ""
              }
            }
          },
        ]}
        itemActions={[
          {
            label: "Download",
            iconClassName: "mdi mdi-download",
            onClick: (item) => {
              props.modalStore.showConfirm({
                title: "Download File",
                children: "Apa anda yakin akan mendownload file ?",
                onSubmit: async (callback) => {
                  try {
                    let res = await downloadSpt(props.match.params.filename, item.path, `${item.referenceNo}_${item.category}`)
                    callback();
                  } catch (error) {
                    callback();
                    toast.errorRequest(error);
                  }
                },
              });
            },
            show: (item) => {
              return item.status == "SUCCESS";
            },
          },
        ]}
        onFetchData={async (query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query['column'] = 'createdAt'
              query['order'] = 'DESC'
              // delete query.keyword
              delete query.columnCriteria
              delete query.sortBy
              delete query.start
              delete query.end
              delete query.startDate
              delete query.endDate
              delete query.sortCriteria
              query = {
                ...query,
                filename: props.match.params.referenceNo
              }
              if(query.keyword){
                query['field'] = 'category'
                query['query'] = query.keyword
              }
              let res = await service.getExportLogv2(query)
              resolve(res);
            } catch (error) {
              reject(error);
            }
          });
        }}
        useFilter={true}
        useCriteria={false}
        showCommandbar={true}
        showFilterPeriod={false}
        defaultSize={20}
        showActionColumn={true}
      />
    </>
  );
};

export default inject("envStore", "modalStore", "envStore", "navigationStore")(observer(LogExport));
