import React, {useState, useEffect} from 'react'
import { DataForm, LoadingButton } from '../../../../../libs/react-mpk/components'
import { rules, storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { Button } from 'react-md'
import DetailSSP from '../SSP/DetailSSP'

const { inputTypes } = DataForm
const recon = (
    data = storage.get(storage.keys.SPT_1771_Induk)
) => {
    return new Promise(resolve => {
        data.dD11 = data.dJmlC9 - data.dJmlC10
        if(data.dD11 <= 0 && data.dD11 > -1000) {
            data.dD11 = 0
        }
        data.FlgSptPPh = data.dD11 === 0 
            ? '0'
            : data.dD11 > 1000
                ? '1'
                : '2'
        if(String(data.FlgSptPPh) === '1' ){
            let lastSSP = DetailSSP.getLastItem()
            data.TglLunas = lastSSP ? lastSSP.TGL_SSP : ''
            data.OptD13 = '1'
        } else if(String(data.FlgSptPPh) === '2'){
            data.OptD13 = '1'
            data.TglLunas = ''
        } else {
            data.OptD13 = '1'
            data.TglLunas = ''
        }

        if(String(data.FlgSptPPh) != '2'){
            data.OptD13a = false
        }

        resolve(data)
    })
}

const D = ({
    data,
    handleUpdate,
    getLabel,
    ...props
}) => {
    const profile = storage.get(storage.keys.SPT_1771_Profile)
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        setDefaultData(data)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-D"
            className="mpk-margin-C"
            style={{
                width: '100%',
                maxWidth: 800,
            }}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                {
                    inputType: inputTypes.SELECT,
                    label: getLabel('FlgSptPPh', 'Induk'),
                    key: 'FlgSptPPh',
                    options: [
                        {value: '0', label: 'Nihil'},
                        {value: '1', label: 'Kurang Bayar'},
                        {value: '2', label: 'Lebih Bayar'}
                    ],
                    disabled: true
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel('dD11', 'Induk'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    disabled: true,
                    key:'dD11'
                },
                {
                    inputType: inputTypes.DATE,
                    label: getLabel('TglLunas', 'Induk'),
                    key: 'TglLunas',
                    max: rules.maxTanggalPajak(profile.THN_PJK, props.envStore.env.timestamp),
                    disabled: true,
                    required: true,
                    width: '75%',
                    show: d => (String(d.FlgSptPPh) === '1')
                },
                {
                    render: (d) => d.FlgSptPPh === '1' && (
                        <div 
                            className="mpk-flex align-center justify-end"
                            style={{
                                flex: '1',
                                height: '100%',
                            }}
                        >
                            <Button
                                onClick={() => {
                                    props.goToIndex(17)
                                }}
                                themeType="outline"
                            >
                                Daftar SSP
                            </Button>
                        </div>
                    )
                },
                {
                    inputType: inputTypes.SELECT,
                    label: getLabel('OptD13', 'Induk'),
                    key: 'OptD13',
                    options: [
                        {value: '1', label: 'Direstitusikan'},
                        // {value: '2', label: 'Diperhitungkan dengan Utang Pajak'}
                    ],
                    disabled: true,
                    show: d => (d.FlgSptPPh === '2')
                },
                {
                    inputType: inputTypes.CHECKBOX,
                    label: getLabel('OptD13a', 'Induk'),
                    key: 'OptD13a',
                    show: d => (d.FlgSptPPh === '2' && d.OptD13 === '1'),
                    disabled: false
                },
            ]}
            additionalAction={[
                {
                  show: (d) => (String(d.FlgSptPPh) === '1'),
                  render: (d) => (
                    <LoadingButton 
                      themeType="outline"
                      iconClassName="mdi mdi-credit-card-outline"
                      onClick={() => {
                        props.setSsp({
                          npwp: profile.NPWP,
                          tahunPajak: profile.THN_PJK,
                          kap: '411126',
                          kjs: '200',
                          masaAwal: '01',
                          masaAkhir: '12',
                          amount: d.dD11
                        })
                      }}
                    >
                      {t.translate('column.pay')}
                    </LoadingButton>
                  )
                }
            ]}
            onSubmit={async (__data, callback) => {
                const {
                    FlgSptPPh,
                    dD11,
                    TglLunas,
                    OptD13,
                    OptD13a
                } = __data
                
                await handleUpdate({
                    FlgSptPPh,
                    dD11,
                    TglLunas,
                    OptD13,
                    OptD13a
                })

                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    )
}

D.recon = recon

export default inject('envStore', 'navigationStore')(observer(D))
