import { merge } from 'lodash'

export const themes = {
  PAJAKKU: "ad2700c315f98a65120c4a8503860c1b",
  SOBATPAJAK: "66cebbca847243f422fde45a47cc619b",
};

export const portals = {
  ad2700c315f98a65120c4a8503860c1b: {
    name: "pajakku",
    host: "https://private.pajakku.com",
    baseUrl: "/website_cms_api",
    channel: "website_portal",
    clientId: "l9H1ginFwcDhiAMwx5k9gOKZAd89HHamoHkRwZHJ",
  },
  "66cebbca847243f422fde45a47cc619b": {
    name: "sobatpajak",
    host: "https://private.sobatpajak.com",
    baseUrl: "/website_cms_api",
    channel: "website_portal",
    clientId: "ZzUXG37XUbxE9HCSgK4Z0oIxf3XgbyzWUwTJmnMQ",
  },
};

export const dataTypes = {
  NUMBER  : 'number',
  STRING  : 'string',
  BOOLEAN : 'boolean',
  DATE    : 'date'
}

export const inputTypes = {
  DATEPICKER           : 'datepicker',
  REACT_SELECT         : 'react-select',
  AUTOCOMPLETE         : 'autocomplete',
  INPUT                : 'input',
  INPUT_MASK           : 'input-mask',
  INPUT_MASK_NUMBER    : 'input-mask-number',
  FILE_INPUT           : 'file-input',
  TEXTAREA             : 'text-area',
  SELECT               : 'select',
  DATE                 : 'date',
  DATETIME             : 'date-time',
  CHECKBOX             : 'checkbox',
  RADIO                : 'radio',
  LIST                 : 'list',
  DIVIDER              : 'divider',
  PRE_DATA             : 'pre-data',
  MULTI_CHECKBOX       : 'multi-checkbox'
}

export const formatTypes = {
  MONEY         : 'money'
}

export const statusTypes = {
  SUCCESS   : 'success',
  ERROR     : 'error',
  PROGRESS  : 'progress',
  IDLE      : 'idle'
}

export const eventNames = {
  RELOAD    : 'reload'
}

export const eventTypes = {
  RELOAD    : 'reload',
  UPDATE    : 'update',
  SUBMIT    : 'submit',
  DATA      : 'data',
  RESET     : 'reset',
  GET       : 'get'
}

export const strCriterias = [
  'contains', 'equals', 'notEquals', 'in', 'greaterThan', 'lessThan'
]

export const sorts = {
  ascending: 'ASC',
  descending: 'DESC',
  ascendingLong: 'ascending',
  descendingLong: 'descending'
}

export const defaultQueryParameter = (defaultQuery={}) => (merge({
  page: 1,
  keyword: "",
  column: "",
  columnCriteria: strCriterias[0],
  size: 20,
  sort: sorts.ascending,
  sortBy: "",
  startDate: "",
  endDate: "",
  sortCriteria: '<='
}, defaultQuery));
