import React, { useState } from "react";
import { TabsWrapper } from "../../../../libs/react-mpk/wrapper";
import { LoadingButton, Flex } from "../../../../libs/react-mpk/components";
import { toast, http } from "../../../../libs/react-mpk/services";
import { Button, FontIcon } from "react-md";
import t from "counterpart";
import sptWrapper from "../spt.wrapper";
import Profile from "./Profile";
import Induk from "./Induk";
import { storage, utils } from "../../../../services";
import { exportSpt, parseFilename } from "../../Submission.service";
import Rekapitulasi from "./Rekapitulasi";
import LampI from "./LampI";
import LampII from "./LampII";
import LampIII, {
  HeaderLampIII,
  DetailLampIIIPPh22,
  DetailLampIIIPPh23,
} from "./LampIII";
import LampIV, { HeaderLampIV, DetailLampIVA, DetailLampIVB } from "./LampIV";
import LampV, {
  HeaderLampV,
  DetailLampVPemegangSaham,
  DetailLampVPengurus,
} from "./LampV";
import LampVI, {
  HeaderLampVI,
  DetailLampVIPenyertaanModal,
  DetailLampVIUtangModal,
  DetailLampVIPiutangModal,
} from "./LampVI";
import LampKhus1A, {
  HeaderLampKhus1A,
  DetailLampKhus1A,
  DetailLampKhus1A2,
} from "./LampKhus1A";
import HeaderLampKhus2A from "./LampKhus2A";
import LampKhus3, {
  LampKhus3A2I,
  LampKhus3A1,
  LampKhus3A2II,
  LampKhus3AI,
  LampKhus3AII,
} from "./LampKhus3";
import LampKhus4A from "./LampKhus4A";
import LampKhus5A, { HeaderLampKhus5A, DetailLampKhus5A } from "./LampKhus5A";
import LampKhus6A from "./LampKhus6A";
import LampKhus7A, { HeaderLampKhus7A, DetailLampKhus7A } from "./LampKhus7A";
import LapKeu, {
  HeaderLapKeu,
  HubIstimewa1,
  HubIstimewa3,
  HubIstimewa5,
  HubIstimewa7,
  HubIstimewa9,
  HubIstimewa11,
  HubIstimewa13,
  HubIstimewa15,
  Labarugi1,
  Labarugi3,
  Labarugi5,
  Labarugi7,
  Labarugi9,
  Labarugi11,
  Labarugi13,
  Labarugi15,
  Neraca1,
  Neraca3,
  Neraca5,
  Neraca7,
  Neraca9,
  Neraca11,
  Neraca13,
  Neraca15,
} from "./LapKeu";
import SSP, { HeaderSSP, DetailSSP } from "./SSP";
import { flattenObject } from "../../../../services/utils.service";
import ExportSPT from "./ExportSPT";

const SPT1771 = ({
  className = "",
  handleBack,
  handleCancel,
  handlePembetulan,
  getIcon,
  getLabel,
  getErrorMessage,
  setSptData,
  setShowCommit,
  setSaveOnly,
  setSsp,
  setBillingCode,
  submission,
  isEditable,
  ...props
}) => {
  const headerLapKeu = storage.get(storage.keys.SPT_1771_HeaderLapKeu);
  const induk = storage.get(storage.keys.SPT_1771_Induk);
  const [inProgress, setInProgress] = useState("");
  const [idTemplate, setIdTemplate] = useState(headerLapKeu.ID_TEMPLATE);
  const [kota, setKota] = useState(induk.__kota || "");

  const handleSubmit = async (saveOnly = true) => {
    try {
      setInProgress("saving");
      await Rekapitulasi.recon();
      let data = {};

      // PROFILE
      let profile = storage.get(storage.keys.SPT_1771_Profile);
      profile = utils.mapColumns(profile, Profile.columns);

      profile.kodePembetulan = Number(profile.kodePembetulan);
      profile.tahunPajak = Number(profile.tahunPajak);
      profile.thnMulai = Number(profile.thnMulai);
      profile.thnSelesai = Number(profile.thnSelesai);
      profile.kontak = {
        alamat: profile.alamat,
        kodePos: profile.kodePos,
        kodeTelepon: profile.kodeTelepon,
        telepon: profile.telepon,
        kodeFax: profile.kodeFax,
        fax: profile.fax,
      };
      delete profile.alamat;
      delete profile.kodePos;
      delete profile.kodeTelepon;
      delete profile.telepon;
      delete profile.kodeFax;
      delete profile.fax;
      data.profile = profile;

      // if(profile.kontak.kodeFax.length === 0) delete profile.kontak.kodeFax
      // if(profile.kontak.fax.length === 0) delete profile.kontak.fax

      // REKAPITULASI
      let rekapitulasi = storage.get(storage.keys.SPT_1771_Rekapitulasi);
      data.rekapitulasi = utils.mapColumns(rekapitulasi, Rekapitulasi.columns);

      // INDUK
      let induk = storage.get(storage.keys.SPT_1771_Induk);
      induk = utils.mapColumns(induk, Induk.columns);
      data.induk = {
        audit: {
          flgAudit: Number(induk.flgAudit),
          opAkt: induk.opAkt,
          nmKAP: induk.nmKAP,
          npwpKAP: induk.npwpKAP,
          nmAP: induk.nmAP,
          npwpAP: induk.npwpAP,
          nmKKP: induk.nmKKP,
          npwpKKP: induk.npwpKKP,
          nmKP: induk.nmKP,
          npwpKP: induk.npwpKP,
        },
        da: {
          dA1: induk.dA1,
          dA2: induk.dA2,
          dA3: induk.dA3,
        },
        db: {
          optB4: Number(induk.optB4),
          dB4: induk.dB4,
          dB5: induk.dB5,
          dB6: induk.dB6,
        },
        dc: {
          dC7: induk.dC7,
          dC8a: induk.dC8a,
          dC8b: induk.dC8b,
          dJmlC8: induk.dJmlC8,
          optC9: Number(induk.optC9),
          dJmlC9: induk.dJmlC9,
          dC10a: induk.dC10a,
          dC10b: induk.dC10b,
          dC10c: induk.dC10c,
          dJmlC10: induk.dJmlC10,
        },
        dd: {
          flgSptPPh: Number(induk.flgSptPPh),
          dD11: induk.dD11,
          tglLunas: induk.tglLunas,
          optD13: Number(induk.optD13),
          optD13a: induk.optD13a,
        },
        de: {
          dE14a: induk.dE14a,
          dE14b: induk.dE14b,
          dE14c: induk.dE14c,
          dE14d: induk.dE14d,
          dE14e: induk.dE14e,
          dE14f: induk.dE14f,
          dE14g: induk.dE14g,
        },
        df: {
          dF15a: induk.dF15a,
          dF15b: induk.dF15b,
        },
        chkH: {
          optD16: Number(induk.optD16),
          chkH17a: induk.chkH17a ? 1 : 0,
          chkH17b: induk.chkH17b ? 1 : 0,
          chkH17c: induk.chkH17c ? 1 : 0,
          chkH17d: induk.chkH17d ? 1 : 0,
          chkH17e: induk.chkH17e ? 1 : 0,
          chkH17f: induk.chkH17f ? 1 : 0,
          chkH17g: induk.chkH17g ? 1 : 0,
          chkH17h: induk.chkH17h ? 1 : 0,
          chkH17i: induk.chkH17i ? 1 : 0,
          chkH17j: induk.chkH17j ? 1 : 0,
          chkH17k: induk.chkH17k ? 1 : 0,
          chkH17l: induk.chkH17l ? 1 : 0,
          chkH17m: induk.chkH17m ? 1 : 0,
          chkH17n: induk.chkH17n ? 1 : 0,
          vHl: induk.vHl,
          vHm: induk.vHm,
          vHn: induk.vHn,
        },
        flgTtdSPT: Number(profile.FlgTtdSPT),
        tglLaporSPT: induk.tglLaporSPT,
        hitungSendiri: induk.hitungSendiri ? 1 : 0,
        __kota: induk.__kota,
      };

      if (data.induk.audit.npwpAP.length === 0) delete data.induk.audit.npwpAP;
      if (data.induk.audit.npwpKP.length === 0) delete data.induk.audit.npwpKP;
      if (data.induk.audit.npwpKKP.length === 0)
        delete data.induk.audit.npwpKKP;

      // LAMPI
      let lampI = storage.get(storage.keys.SPT_1771_LampI);
      lampI = utils.mapColumns(lampI, LampI.columns);
      data.lampI = {
        d1: {
          d1A: lampI.d1A,
          d1B: lampI.d1B,
          d1C: lampI.d1C,
          d1D: lampI.d1D,
          d1E: lampI.d1E,
          d1F: lampI.d1F,
          d1G: lampI.d1G,
          d1H: lampI.d1H,
        },
        d2: lampI.d2,
        d3: lampI.d3,
        d4: lampI.d4,
        d5: {
          d5a: lampI.d5a,
          d5b: lampI.d5b,
          d5c: lampI.d5c,
          d5d: lampI.d5d,
          d5e: lampI.d5e,
          d5f: lampI.d5f,
          d5g: lampI.d5g,
          d5h: lampI.d5h,
          d5i: lampI.d5i,
          d5j: lampI.d5j,
          d5k: lampI.d5k,
          d5l: lampI.d5l,
          d5m: lampI.d5m,
        },
        d6: {
          d6a: lampI.d6a,
          d6b: lampI.d6b,
          d6c: lampI.d6c,
          d6d: lampI.d6d,
          d6e: lampI.d6e,
        },
        d7: {
          d7a: lampI.d7a,
          d7b: lampI.d7b,
        },
        d8: lampI.d8,
      };

      //LAMPII
      let lampII = storage.get(storage.keys.SPT_1771_LampII);
      lampII = utils.mapColumns(lampII, LampII.columns);
      data.lampII = {
        d1: {
          d13: lampII.d13,
          d14: lampII.d14,
          d15: lampII.d15,
          d16: lampII.d16,
        },
        d2: {
          d23: lampII.d23,
          d24: lampII.d24,
          d25: lampII.d25,
          d26: lampII.d26,
        },
        d3: {
          d33: lampII.d33,
          d34: lampII.d34,
          d35: lampII.d35,
          d36: lampII.d36,
        },
        d4: {
          d43: lampII.d43,
          d44: lampII.d44,
          d45: lampII.d45,
          d46: lampII.d46,
        },
        d5: {
          d53: lampII.d53,
          d54: lampII.d54,
          d55: lampII.d55,
          d56: lampII.d56,
        },
        d6: {
          d63: lampII.d63,
          d64: lampII.d64,
          d65: lampII.d65,
          d66: lampII.d66,
        },
        d7: {
          d73: lampII.d73,
          d74: lampII.d74,
          d75: lampII.d75,
          d76: lampII.d76,
        },
        d8: {
          d83: lampII.d83,
          d84: lampII.d84,
          d85: lampII.d85,
          d86: lampII.d86,
        },
        d9: {
          d93: lampII.d93,
          d94: lampII.d94,
          d95: lampII.d95,
          d96: lampII.d96,
        },
        d10: {
          d103: lampII.d103,
          d104: lampII.d104,
          d105: lampII.d105,
          d106: lampII.d106,
        },
        d11: {
          d113: lampII.d113,
          d114: lampII.d114,
          d115: lampII.d115,
          d116: lampII.d116,
        },
        d12: {
          d123: lampII.d123,
          d124: lampII.d124,
          d125: lampII.d125,
          d126: lampII.d126,
        },
        d13: {
          d133: lampII.d133,
          d134: lampII.d134,
          d135: lampII.d135,
          d136: lampII.d136,
        },
        dJml3: lampII.dJml3,
        dJml4: lampII.dJml4,
        dJml5: lampII.dJml5,
        dJml6: lampII.dJml6,
      };

      // LAMPIII
      let headerLampIII = storage.get(storage.keys.SPT_1771_HeaderLampIII);
      let detailPPh22 = storage.get(storage.keys.SPT_1771_DetailLampIIIPPh22);
      let detailPPh23 = storage.get(storage.keys.SPT_1771_DetailLampIIIPPh23);
      headerLampIII = utils.mapColumns(headerLampIII, HeaderLampIII.columns);
      detailPPh22 = utils.mapListItemColumns(
        detailPPh22.list,
        DetailLampIIIPPh22.itemColumns
      );
      detailPPh23 = utils.mapListItemColumns(
        detailPPh23.list,
        DetailLampIIIPPh23.itemColumns
      );
      data.lampIII = {
        header: headerLampIII,
        detailPPh22: [],
        detailPPh23: [],
        refNoPPh22: submission.fileName,
        refNoPPh23: submission.fileName,
      };

      //LAMPIV
      let headerLampIV = storage.get(storage.keys.SPT_1771_HeaderLampIV);
      let detailLampIVA = storage.get(storage.keys.SPT_1771_DetailLampIVA);
      let detailLampIVB = storage.get(storage.keys.SPT_1771_DetailLampIVB);
      headerLampIV = utils.mapColumns(headerLampIV, HeaderLampIV.columns);
      detailLampIVA = utils.mapListItemColumns(
        detailLampIVA.list,
        DetailLampIVA.itemColumns
      );
      detailLampIVB = utils.mapListItemColumns(
        detailLampIVB.list,
        DetailLampIVB.itemColumns
      );

      data.lampIV = {
        header: {
          a: {
            a1: {
              a13: headerLampIV.a13,
              a14: headerLampIV.a14,
              a15: headerLampIV.a15,
            },
            a2: {
              a23: headerLampIV.a23,
              a24: headerLampIV.a24,
              a25: headerLampIV.a25,
            },
            a3: {
              a33: headerLampIV.a33,
              a34: headerLampIV.a34,
              a35: headerLampIV.a35,
            },
            a4: {
              a43: headerLampIV.a43,
              a44: headerLampIV.a44,
              a45: headerLampIV.a45,
            },
            a5: {
              a53: headerLampIV.a53,
              a54: headerLampIV.a54,
              a55: headerLampIV.a55,
            },
            a6: {
              a63: headerLampIV.a63,
              a64: headerLampIV.a64,
              a65: headerLampIV.a65,
            },
            a7: {
              a73: headerLampIV.a73,
              a74: headerLampIV.a74,
              a75: headerLampIV.a75,
            },
            a8: {
              a8a3: headerLampIV.a8a3,
              a8a4: headerLampIV.a8a4,
              a8a5: headerLampIV.a8a5,

              a8b3: headerLampIV.a8b3,
              a8b4: headerLampIV.a8b4,
              a8b5: headerLampIV.a8b5,

              a8c3: headerLampIV.a8c3,
              a8c4: headerLampIV.a8c4,
              a8c5: headerLampIV.a8c5,
            },
            a9: {
              a93: headerLampIV.a93,
              a94: headerLampIV.a94,
              a95: headerLampIV.a95,
            },
            a10: {
              a103: headerLampIV.a103,
              a104: headerLampIV.a104,
              a105: headerLampIV.a105,
            },
            a11: {
              a113: headerLampIV.a113,
              a114: headerLampIV.a114,
              a115: headerLampIV.a115,
            },
            a12: {
              a123: headerLampIV.a123,
              a124: headerLampIV.a124,
              a125: headerLampIV.a125,
            },
            a13: {
              a133: headerLampIV.a133,
              a134: headerLampIV.a134,
              a135: headerLampIV.a135,
            },
          },
          b: {
            b13: headerLampIV.b13,
            b23: headerLampIV.b23,
            b33: headerLampIV.b33,
            b43: headerLampIV.b43,
            b53: headerLampIV.b53,
            b63: headerLampIV.b63,
          },
          jba: headerLampIV.jba,
          jbb: headerLampIV.jbb,
        },
        detail4A: detailLampIVA,
        detail4B: detailLampIVB,
      };

      //LAMPV
      let headerLampV = storage.get(storage.keys.SPT_1771_HeaderLampV);
      let detailLampVPemegangSaham = storage.get(
        storage.keys.SPT_1771_DetailLampVPemegangSaham
      );
      let detailLampVPengurus = storage.get(
        storage.keys.SPT_1771_DetailLampVPengurus
      );
      headerLampV = utils.mapColumns(headerLampV, HeaderLampV.columns);
      detailLampVPemegangSaham = utils.mapListItemColumns(
        detailLampVPemegangSaham.list,
        DetailLampVPemegangSaham.itemColumns
      );
      detailLampVPengurus = utils.mapListItemColumns(
        detailLampVPengurus.list,
        DetailLampVPengurus.itemColumns
      );
      data.lampV = {
        header: headerLampV,
        detail5A: detailLampVPemegangSaham,
        detail5B: detailLampVPengurus,
      };

      //LAMPVI
      let headerLampVI = storage.get(storage.keys.SPT_1771_HeaderLampVI);
      let detailLampVIPenyertaanModal = storage.get(
        storage.keys.SPT_1771_DetailLampVIPenyertaanModal
      );
      let detailLampVIUtangModal = storage.get(
        storage.keys.SPT_1771_DetailLampVIUtangModal
      );
      let detailLampVIPiutangModal = storage.get(
        storage.keys.SPT_1771_DetailLampVIPiutangModal
      );
      headerLampVI = utils.mapColumns(headerLampVI, HeaderLampVI.columns);
      detailLampVIPenyertaanModal = utils.mapListItemColumns(
        detailLampVIPenyertaanModal.list,
        DetailLampVIPenyertaanModal.itemColumns
      );
      detailLampVIUtangModal = utils.mapListItemColumns(
        detailLampVIUtangModal.list,
        DetailLampVIUtangModal.itemColumns
      );
      detailLampVIPiutangModal = utils.mapListItemColumns(
        detailLampVIPiutangModal.list,
        DetailLampVIPiutangModal.itemColumns
      );
      data.lampVI = {
        header: headerLampVI,
        detail6A: detailLampVIPenyertaanModal,
        detail6B: detailLampVIUtangModal,
        detail6C: detailLampVIPiutangModal,
      };

      //LAMPKHUS1A
      let headerLampKhus1A = storage.get(
        storage.keys.SPT_1771_HeaderLampKhus1A
      );
      let detailLampKhus1A = storage.get(
        storage.keys.SPT_1771_DetailLampKhus1A
      );
      let detailLampKhus1A2 = storage.get(
        storage.keys.SPT_1771_DetailLampKhus1A2
      );
      headerLampKhus1A = utils.mapColumns(
        headerLampKhus1A,
        HeaderLampKhus1A.columns
      );
      detailLampKhus1A = utils.mapListItemColumns(
        detailLampKhus1A.list,
        DetailLampKhus1A.itemColumns
      );
      detailLampKhus1A2 = utils.mapListItemColumns(
        detailLampKhus1A2.list,
        DetailLampKhus1A2.itemColumns
      );
      data.lampKhusIA = {
        header: headerLampKhus1A,
        detailKhus1A: [],
        detailKhus1A2: [],
        refNoKhus1A: submission.fileName,
        refNoKhus1A2: submission.fileName,
      };

      //LAMPKHUS2A
      let headerLampKhus2A = storage.get(
        storage.keys.SPT_1771_HeaderLampKhus2A
      );
      data.lampKhusIIA = utils.mapColumns(
        headerLampKhus2A,
        HeaderLampKhus2A.columns
      );

      //LAMPKHUSIIIA
      let lampKhus3AI = storage.get(storage.keys.SPT_1771_LampKhus3AI);
      let lampKhus3AII = storage.get(storage.keys.SPT_1771_LampKhus3AII);
      let lampKhus3A1 = storage.get(storage.keys.SPT_1771_LampKhus3A1);
      let lampKhus3A2I = storage.get(storage.keys.SPT_1771_LampKhus3A2I);
      let lampKhus3A2II = storage.get(storage.keys.SPT_1771_LampKhus3A2II);
      lampKhus3AI = utils.mapListItemColumns(
        lampKhus3AI.list,
        LampKhus3AI.itemColumns
      );
      lampKhus3AII = utils.mapListItemColumns(
        lampKhus3AII.list,
        LampKhus3AII.itemColumns
      );
      lampKhus3A1 = utils.mapColumns(lampKhus3A1, LampKhus3A1.columns);
      lampKhus3A2I = utils.mapListItemColumns(
        lampKhus3A2I.list,
        LampKhus3A2I.itemColumns
      );
      lampKhus3A2II = utils.mapColumns(lampKhus3A2II, LampKhus3A2II.columns);
      data.lampKhusIIIA = {
        aI: lampKhus3AI,
        aII: lampKhus3AII,
        a1: lampKhus3A1,
        a2I: lampKhus3A2I,
        a2II: [lampKhus3A2II],
      };

      //LAMPKHUSIVA
      let lampKhus4A = storage.get(storage.keys.SPT_1771_LampKhus4A);
      lampKhus4A = utils.mapColumns(lampKhus4A, LampKhus4A.columns);
      lampKhus4A.chkB1 = lampKhus4A.chkB1 ? 1 : 0;
      lampKhus4A.chkB2 = lampKhus4A.chkB2 ? 1 : 0;
      lampKhus4A.chkD1 = lampKhus4A.chkD1 ? 1 : 0;
      lampKhus4A.chkD2 = lampKhus4A.chkD2 ? 1 : 0;
      lampKhus4A.chkD3 = lampKhus4A.chkD3 ? 1 : 0;
      lampKhus4A.chkD4 = lampKhus4A.chkD4 ? 1 : 0;
      delete lampKhus4A.chkd1;
      delete lampKhus4A.chkd2;
      delete lampKhus4A.chkd3;
      delete lampKhus4A.chkd4;
      lampKhus4A.thNet = Number(lampKhus4A.thNet);
      data.lampKhusIVA = lampKhus4A;

      //LAMPKHUSVA
      let headerLampKhus5A = storage.get(
        storage.keys.SPT_1771_HeaderLampKhus5A
      );
      // let detailLampKhus5A = storage.get(storage.keys.SPT_1771_DetailLampKhus5A)
      headerLampKhus5A = utils.mapColumns(
        headerLampKhus5A,
        HeaderLampKhus5A.columns
      );
      // detailLampKhus5A = utils.mapListItemColumns(detailLampKhus5A.list, DetailLampKhus5A.itemColumns)
      data.lampKhusVA = {
        header: headerLampKhus5A,
        detail: [],
        refNo: submission.fileName,
      };

      //LAMPKHUSVIA
      let lampKhus6A = storage.get(storage.keys.SPT_1771_LampKhus6A);
      lampKhus6A = utils.mapColumns(lampKhus6A, LampKhus6A.columns);
      lampKhus6A.chk61 = lampKhus6A.chk61 ? 1 : 0;
      lampKhus6A.chk62 = lampKhus6A.chk62 ? 1 : 0;
      lampKhus6A.chk63 = lampKhus6A.chk63 ? 1 : 0;
      lampKhus6A.chk64 = lampKhus6A.chk64 ? 1 : 0;
      data.lampKhusVIA = lampKhus6A;

      //LAMPKHUS7A
      let headerLampKhus7A = storage.get(
        storage.keys.SPT_1771_HeaderLampKhus7A
      );
      let detailLampKhus7A = storage.get(
        storage.keys.SPT_1771_DetailLampKhus7A
      );
      headerLampKhus7A = utils.mapColumns(
        headerLampKhus7A,
        HeaderLampKhus7A.columns
      );
      detailLampKhus7A = utils.mapListItemColumns(
        detailLampKhus7A.list,
        DetailLampKhus7A.itemColumns
      );
      data.lampKhusVIIA = {
        header: headerLampKhus7A,
        detail: detailLampKhus7A,
      };

      let headerLapKeu = storage.get(storage.keys.SPT_1771_HeaderLapKeu);
      let neraca1 = storage.get(storage.keys.SPT_1771_Neraca1);
      let neraca3 = storage.get(storage.keys.SPT_1771_Neraca3);
      let neraca5 = storage.get(storage.keys.SPT_1771_Neraca5);
      let neraca7 = storage.get(storage.keys.SPT_1771_Neraca7);
      let neraca9 = storage.get(storage.keys.SPT_1771_Neraca9);
      let neraca11 = storage.get(storage.keys.SPT_1771_Neraca11);
      let neraca13 = storage.get(storage.keys.SPT_1771_Neraca13);
      let neraca15 = storage.get(storage.keys.SPT_1771_Neraca15);
      let labarugi1 = storage.get(storage.keys.SPT_1771_Labarugi1);
      let labarugi3 = storage.get(storage.keys.SPT_1771_Labarugi3);
      let labarugi5 = storage.get(storage.keys.SPT_1771_Labarugi5);
      let labarugi7 = storage.get(storage.keys.SPT_1771_Labarugi7);
      let labarugi9 = storage.get(storage.keys.SPT_1771_Labarugi9);
      let labarugi11 = storage.get(storage.keys.SPT_1771_Labarugi11);
      let labarugi13 = storage.get(storage.keys.SPT_1771_Labarugi13);
      let labarugi15 = storage.get(storage.keys.SPT_1771_Labarugi15);
      let hubIstimewa1 = storage.get(storage.keys.SPT_1771_HubIstimewa1);
      let hubIstimewa3 = storage.get(storage.keys.SPT_1771_HubIstimewa3);
      let hubIstimewa5 = storage.get(storage.keys.SPT_1771_HubIstimewa5);
      let hubIstimewa7 = storage.get(storage.keys.SPT_1771_HubIstimewa7);
      let hubIstimewa9 = storage.get(storage.keys.SPT_1771_HubIstimewa9);
      let hubIstimewa11 = storage.get(storage.keys.SPT_1771_HubIstimewa11);
      let hubIstimewa13 = storage.get(storage.keys.SPT_1771_HubIstimewa13);
      let hubIstimewa15 = storage.get(storage.keys.SPT_1771_HubIstimewa15);
      headerLapKeu = utils.mapColumns(headerLapKeu, HeaderLapKeu.columns);
      neraca1 = utils.mapColumns(neraca1, Neraca1.columns);
      neraca3 = utils.mapColumns(neraca3, Neraca3.columns);
      neraca5 = utils.mapColumns(neraca5, Neraca5.columns);
      neraca7 = utils.mapColumns(neraca7, Neraca7.columns);
      neraca9 = utils.mapColumns(neraca9, Neraca9.columns);
      neraca11 = utils.mapColumns(neraca11, Neraca11.columns);
      neraca13 = utils.mapColumns(neraca13, Neraca13.columns);
      neraca15 = utils.mapColumns(neraca15, Neraca15.columns);
      labarugi1 = utils.mapColumns(labarugi1, Labarugi1.columns);
      labarugi3 = utils.mapColumns(labarugi3, Labarugi3.columns);
      labarugi5 = utils.mapColumns(labarugi5, Labarugi5.columns);
      labarugi7 = utils.mapColumns(labarugi7, Labarugi7.columns);
      labarugi9 = utils.mapColumns(labarugi9, Labarugi9.columns);
      labarugi11 = utils.mapColumns(labarugi11, Labarugi11.columns);
      labarugi13 = utils.mapColumns(labarugi13, Labarugi13.columns);
      labarugi15 = utils.mapColumns(labarugi15, Labarugi15.columns);
      hubIstimewa1 = utils.mapListItemColumns(
        hubIstimewa1.list,
        HubIstimewa1.itemColumns
      );
      hubIstimewa3 = utils.mapListItemColumns(
        hubIstimewa3.list,
        HubIstimewa3.itemColumns
      );
      hubIstimewa5 = utils.mapListItemColumns(
        hubIstimewa5.list,
        HubIstimewa5.itemColumns
      );
      hubIstimewa7 = utils.mapListItemColumns(
        hubIstimewa7.list,
        HubIstimewa7.itemColumns
      );
      hubIstimewa9 = utils.mapListItemColumns(
        hubIstimewa9.list,
        HubIstimewa9.itemColumns
      );
      hubIstimewa11 = utils.mapListItemColumns(
        hubIstimewa11.list,
        HubIstimewa11.itemColumns
      );
      hubIstimewa13 = utils.mapListItemColumns(
        hubIstimewa13.list,
        HubIstimewa13.itemColumns
      );
      hubIstimewa15 = utils.mapListItemColumns(
        hubIstimewa15.list,
        HubIstimewa15.itemColumns
      );
      data.lapKeu = {
        header: {
          idTemplate: Number(headerLapKeu.idTemplate),
        },
        lap1: {
          neraca: neraca1,
          labarugi: labarugi1,
          hubIstimewa: hubIstimewa1,
        },
        lap3: {
          neraca: neraca3,
          labarugi: labarugi3,
          hubIstimewa: hubIstimewa3,
        },
        lap5: {
          neraca: neraca5,
          labarugi: labarugi5,
          hubIstimewa: hubIstimewa5,
        },
        lap7: {
          neraca: neraca7,
          labarugi: labarugi7,
          hubIstimewa: hubIstimewa7,
        },
        lap9: {
          neraca: neraca9,
          labarugi: labarugi9,
          hubIstimewa: hubIstimewa9,
        },
        lap11: {
          neraca: neraca11,
          labarugi: labarugi11,
          hubIstimewa: hubIstimewa11,
        },
        lap13: {
          neraca: neraca13,
          labarugi: labarugi13,
          hubIstimewa: hubIstimewa13,
        },
        lap15: {
          neraca: neraca15,
          labarugi: labarugi15,
          hubIstimewa: hubIstimewa15,
        },
      };

      //SSP
      let headerSSP = storage.get(storage.keys.SPT_1771_HeaderSSP);
      let detailSSP = storage.get(storage.keys.SPT_1771_DetailSSP);
      headerSSP = utils.mapColumns(headerSSP, HeaderSSP.columns);
      detailSSP = utils.mapListItemColumns(
        detailSSP.list,
        DetailSSP.itemColumns
      );
      data.ssp = {
        header: headerSSP,
        detail: detailSSP,
      };

      setSptData(data);
      setSaveOnly(saveOnly);
      setShowCommit(true);
      setInProgress("");
    } catch (error) {
      setInProgress("");
      toast.errorRequest(error);
    }
  };

  return (
    <TabsWrapper
      title={`SPT 1771 ${submission ? ` | ${submission.fileName}` : ""}`}
      baseId="mod-submission-spt-form"
      className={className}
      showIndex={true}
      iconAfter={true}
      defaultAsVerticalTabs={false}
      disabledToggleTabsOrientation={false}
      disabledTabsScrollControl={false}
      // backTo={`/${props.envStore.widget.active ? 'widget' : props.envStore.env.applicationType}/submissions`}
      commandbar={{
        leftCorner: (
          <Button buttonType="icon" onClick={handleBack}>
            <FontIcon iconClassName="mdi mdi-arrow-left" />
          </Button>
        ),
        rightCorner: isEditable ? (
          <Flex align={Flex.properties.align.CENTER}>
            <LoadingButton
              theme="warning"
              themeType="contained"
              onClick={handleCancel}
              className="mpk-margin-S margin-right"
              iconClassName="mdi mdi-alert"
              disabled={inProgress !== ""}
            >
              {t.translate("column.cancel")}
            </LoadingButton>
            <ExportSPT
              className="mpk-margin-S margin-right"
              submission={submission}
              idTemplate={idTemplate}
              kota={kota}
            />
            <LoadingButton
              iconClassName="mdi mdi-content-save"
              theme="outline"
              themeType="contained"
              onClick={() => handleSubmit()}
              loading={inProgress === "saving"}
              disabled={inProgress !== ""}
            >
              {t.translate("modules.submission.sptForm.save.title")}
            </LoadingButton>
          </Flex>
        ) : props.allowPembetulan ? (
          <LoadingButton
            iconClassName="mdi mdi-plus"
            theme="outline"
            themeType="contained"
            onClick={() => handlePembetulan()}
          >
            {t.translate("modules.submission.sptForm.pembetulan.title")}
          </LoadingButton>
        ) : (
          <ExportSPT
            submission={submission}
            idTemplate={idTemplate}
            kota={kota}
          />
        ),
      }}
      tabs={[
        {
          label: getLabel("__title", "Profile"),
          key: "profile",
          iconClassName: getIcon(/profile/gi),
          render: (tabProps) => (
            <Profile
              {...tabProps}
              {...{ getLabel }}
              submission={submission}
              errorMessage={getErrorMessage(["profile"])}
              setIdTemplate={setIdTemplate}
            />
          ),
        },
        {
          label: getLabel("__title", "LapKeu"),
          key: "LapKeu",
          iconClassName: getIcon(/lapKeu/gi),
          render: (tabProps) => (
            <LapKeu
              {...tabProps}
              {...{ getLabel }}
              errorMessage={getErrorMessage(["lapKeu"])}
            />
          ),
        },
        {
          label: getLabel("__title", "LampKhus5A"),
          key: "LampKhus5A",
          iconClassName: getIcon(/lampKhus5A/gi),
          render: (tabProps) => (
            <LampKhus5A
              {...tabProps}
              {...{ getLabel }}
              errorMessage={getErrorMessage(["lampKhus5A"])}
            />
          ),
        },
        {
          label: getLabel("__title", "LampKhus1A"),
          key: "LampKhus1A",
          iconClassName: getIcon(/lampKhusIA/gi),
          render: (tabProps) => (
            <LampKhus1A
              {...tabProps}
              {...{ getLabel }}
              errorMessage={getErrorMessage(["lampKhusIA"])}
            />
          ),
        },
        {
          label: getLabel("__title", "LampKhus7A"),
          key: "LampKhus7A",
          iconClassName: getIcon(/lampKhus7A/gi),
          render: (tabProps) => (
            <LampKhus7A
              {...tabProps}
              {...{ getLabel }}
              errorMessage={getErrorMessage(["lampKhus7A"])}
            />
          ),
        },
        {
          label: getLabel("__title", "LampKhus4A"),
          key: "LampKhus4A",
          iconClassName: getIcon(/lampKhusIVA/gi),
          render: (tabProps) => (
            <LampKhus4A
              {...tabProps}
              {...{ getLabel }}
              errorMessage={getErrorMessage(["lampKhusIVA"])}
            />
          ),
        },
        {
          label: getLabel("__title", "HeaderLampKhus2A"),
          key: "HeaderLampKhus2A",
          iconClassName: getIcon(/(lampKhusIIA|lampKhusIIIA|lampKhusIVA)/gi),
          render: (tabProps) => (
            <HeaderLampKhus2A
              {...tabProps}
              {...{ getLabel }}
              errorMessage={getErrorMessage(["lampKhusIIA"])}
            />
          ),
        },
        {
          label: getLabel("__title", "LampKhus3"),
          key: "LampKhus3",
          iconClassName: getIcon(/(lampKhusIIA|lampKhusIIIA|lampKhusIVA)/gi),
          render: (tabProps) => (
            <LampKhus3
              {...tabProps}
              {...{ getLabel }}
              errorMessage={getErrorMessage(["lampKhusIIIA", "lampKhusIVA"])}
            />
          ),
        },
        {
          label: getLabel("__title", "LampVI"),
          key: "LampVI",
          iconClassName: getIcon(/^lampVI$/gi),
          render: (tabProps) => (
            <LampVI
              {...tabProps}
              {...{ getLabel }}
              errorMessage={getErrorMessage(["lampVI"])}
            />
          ),
        },
        {
          label: getLabel("__title", "LampV"),
          key: "LampV",
          iconClassName: getIcon(/lampV/gi),
          render: (tabProps) => (
            <LampV
              {...tabProps}
              {...{ getLabel }}
              errorMessage={getErrorMessage(["lampV"])}
            />
          ),
        },
        {
          label: getLabel("__title", "LampIV"),
          key: "LampIV",
          iconClassName: getIcon(/lampIV/gi),
          render: (tabProps) => (
            <LampIV
              {...tabProps}
              {...{ getLabel }}
              errorMessage={getErrorMessage(["lampIV"])}
            />
          ),
        },
        {
          label: getLabel("__title", "LampIII"),
          key: "lampIII",
          iconClassName: getIcon(/lampIII/gi),
          render: (tabProps) => (
            <LampIII
              {...tabProps}
              {...{ getLabel }}
              errorMessage={getErrorMessage(["lampIII"])}
            />
          ),
        },
        {
          label: getLabel("__title", "LampII"),
          key: "lampII",
          iconClassName: getIcon(/lampII/gi),
          render: (tabProps) => (
            <LampII
              {...tabProps}
              {...{ getLabel }}
              errorMessage={getErrorMessage(["lampII"])}
            />
          ),
        },
        {
          label: getLabel("__title", "LampI"),
          key: "lampI",
          iconClassName: getIcon(/^lampI$/g),
          render: (tabProps) => (
            <LampI
              {...tabProps}
              {...{ getLabel }}
              errorMessage={getErrorMessage(["lampI"])}
            />
          ),
        },
        {
          label: t.translate("column.induk"),
          key: "induk",
          iconClassName: getIcon(/induk/gi),
          render: (tabProps) => (
            <Induk
              setSsp={setSsp}
              {...tabProps}
              {...{ getLabel }}
              setKota={setKota}
              errorMessage={getErrorMessage(["induk"])}
            />
          ),
        },
        {
          label: getLabel("__title", "LampKhus6A"),
          key: "LampKhus6A",
          iconClassName: getIcon(/lampKhus6A/gi),
          render: (tabProps) => (
            <LampKhus6A
              {...tabProps}
              {...{ getLabel }}
              errorMessage={getErrorMessage(["lampKhus6A"])}
            />
          ),
        },
        {
          label: getLabel("__title", "Rekapitulasi"),
          key: "rekapitulasi",
          iconClassName: getIcon(/rekapitulasi/gi),
          render: (tabProps) => (
            <Rekapitulasi
              {...tabProps}
              {...{ getLabel }}
              errorMessage={getErrorMessage(["rekapitulasi"])}
            />
          ),
        },
        {
          label: getLabel("__title", "SSP"),
          key: "ssp",
          iconClassName: getIcon(/ssp/gi),
          render: (tabProps) => (
            <SSP
              {...tabProps}
              {...{ getLabel }}
              errorMessage={getErrorMessage(["ssp"])}
            />
          ),
        },
      ]}
      onChange={(tab, callback) => {
        const { widget, env } = props.envStore;
        props.navigationStore.redirectTo(
          `/${
            widget.active ? "widget" : env.applicationType
          }/submission/spt-1771/${tab.key}`
        );
        callback();
      }}
    />
  );
};

const getPrefillXbrl = async (xbrl) => {
  return new Promise(async (resolve) => {
    try {
      let { npwp, tahun, revNo } = xbrl;
      let res = await http.get(
        `/submission/xbrl/prefill/${npwp}/${tahun}/${revNo}`,
        {},
        {
          headers: {
            "accept-language": "in",
          },
        }
      );
      resolve(res.data);
    } catch (error) {
      toast.errorRequest(error);
      // setReady(true)
      resolve(null);
    }
  });
};

export default sptWrapper({
  code: "1771",
  onInit: (props, submission) => {
    return new Promise(async (resolve) => {
      try {
        await Induk.generate();
        await Profile.generate(props.authStore.user, props.envStore.env);
        await Rekapitulasi.generate(props.authStore.user, props.envStore.env);
        await LampKhus4A.generate();
        await HeaderLampKhus1A.generate();
        await LampKhus1A.generate();
        await LampKhus3.generate();
        await HeaderLampKhus2A.generate();
        await HeaderLampKhus2A.recon();
        await LampKhus5A.generate();
        await LampKhus6A.generate();
        await LampKhus7A.generate();
        await LapKeu.generate();
        await LampVI.generate();
        await LampV.generate();
        await LampIV.generate();
        await LampIII.generate();
        await LampII.generate();
        await LampI.generate();
        await SSP.generate();

        // Begin Prepop XBRL
        const { xbrl } = props.envStore.widget;

        if (submission) {
          let meta = parseFilename(submission.fileName);
          if (xbrl) {
            let resPrefill = await getPrefillXbrl({
              npwp: meta.npwp,
              revNo: Number(meta.pembetulan),
              tahun: meta.taxYear,
            });
            if (resPrefill) {
              let { profile, audit, lampI, lapKeu, lampII } = resPrefill;
              let defLampI = {};
              for (let key of Object.keys(LampI.columns)) {
                defLampI[key] = 0;
              }

              lampII = flattenObject(lampII);

              let defLampII = {};
              for (let key of Object.keys(LampII.columns)) {
                defLampII[key] = 0;
              }

              await storage.update(storage.keys.SPT_1771_Profile, {
                NAMA_WP: profile.namaWP,
                JENIS_USAHA: profile.jenisUsaha,
              });

              let OpAkt = "0";
              if (audit.opAkt !== null) {
                if (audit.opAkt === "Wajar Tanpa Modifikasian / Unqualified")
                  OpAkt = "1";
                else if (audit.opAkt.match(/wajar tanpa pengecualian/gi))
                  OpAkt = "1";
                else if (audit.opAkt.match(/wajar dengan pengecualian/gi))
                  OpAkt = "2";
                else if (audit.opAkt.match(/tidak wajar/gi)) OpAkt = "3";
                else if (audit.opAkt.match(/tidak ada opini/gi)) OpAkt = "4";
              }

              await storage.update(storage.keys.SPT_1771_Induk, {
                FlgAudit: audit.flgAudit === "Diaudit / Audited" ? "1" : "0",
                NmKAP: audit.nmKAP || "",
                NmAP: audit.nmAP || "",
                OpAkt,
              });

              const { d1, d5, d6, ...otherLampI } = lampI;
              await storage.update(storage.keys.SPT_1771_LampI, {
                ...defLampI,
                ...{
                  ...d1,
                  ...d5,
                  ...d6,
                  ...otherLampI,
                },
              });

              await storage.update(storage.keys.SPT_1771_LampII, {
                ...defLampII,
                ...lampII,
              });

              const { idTemplate } = lapKeu.header;
              const columns = {
                1: {
                  neraca: Neraca1.columns,
                  labarugi: Labarugi1.columns,
                  hubIstimewa: HubIstimewa1,
                },
                3: {
                  neraca: Neraca3.columns,
                  labarugi: Labarugi3.columns,
                  hubIstimewa: HubIstimewa3,
                },
                5: {
                  neraca: Neraca5.columns,
                  labarugi: Labarugi5.columns,
                  hubIstimewa: HubIstimewa5,
                },
                7: {
                  neraca: Neraca7.columns,
                  labarugi: Labarugi7.columns,
                  hubIstimewa: HubIstimewa7,
                },
                9: {
                  neraca: Neraca9.columns,
                  labarugi: Labarugi9.columns,
                  hubIstimewa: HubIstimewa9,
                },
                11: {
                  neraca: Neraca11.columns,
                  labarugi: Labarugi11.columns,
                  hubIstimewa: HubIstimewa11,
                },
                13: {
                  neraca: Neraca13.columns,
                  labarugi: Labarugi13.columns,
                  hubIstimewa: HubIstimewa13,
                },
                15: {
                  neraca: Neraca15.columns,
                  labarugi: Labarugi15.columns,
                  hubIstimewa: HubIstimewa15,
                },
              };

              let defNeraca = {};
              let defLabarugi = {};
              for (let key of Object.keys(columns[idTemplate].neraca)) {
                defNeraca[key] = 0;
              }

              for (let key of Object.keys(columns[idTemplate].labarugi)) {
                defLabarugi[key] = 0;
              }

              let neracaData = utils.mapColumns(
                { ...defNeraca, ...lapKeu[`lap${idTemplate}`].neraca },
                columns[idTemplate].neraca,
                true
              );
              let labarugiData = utils.mapColumns(
                { ...defLabarugi, ...lapKeu[`lap${idTemplate}`].labarugi },
                columns[idTemplate].labarugi,
                true
              );
              let hubIstimewaData = utils.mapListItemColumns(
                lapKeu[`lap${idTemplate}`].hubIstimewa,
                columns[idTemplate].hubIstimewa,
                true
              );

              await storage.update(storage.keys.SPT_1771_HeaderLapKeu, {
                ID_TEMPLATE: idTemplate,
              });
              await storage.update(
                storage.keys[`SPT_1771_Neraca${idTemplate}`],
                neracaData
              );
              await storage.update(
                storage.keys[`SPT_1771_Labarugi${idTemplate}`],
                labarugiData
              );
              await storage.update(
                storage.keys[`SPT_1771_HubIstimewa${idTemplate}`],
                hubIstimewaData
              );
            }
          }
        }
        // End Prepop XBRL

        let profile = storage.get(storage.keys.SPT_1771_Profile);
        props.temporaryStore.setProperties(
          "isBut",
          profile.NEG_DOM ? true : false
        );

        setTimeout(resolve);
      } catch (error) {
        toast.errorRequest(error);
      }
    });
  },
})(SPT1771);
