import React from 'react'
import { Button, FontIcon, TextIconSpacing } from 'react-md'
import { DropdownMenu, MenuItem, MenuItemLink } from "@react-md/menu";
import Flex from '../Flex'
import { action } from '../../class'
import Link from '../Link'
import './Commandbar.scss'
import { TooltipHoverModeConfig, Tooltipped } from '@react-md/tooltip';
import {
  ArrowDropDownSVGIcon,
  HomeSVGIcon,
  InfoOutlineSVGIcon,
  MoreVertSVGIcon,
} from "@react-md/material-icons";
import { inject, observer } from 'mobx-react'

const Commandbar = ({
  actions = null,
  actionType = null,
  limitActionType = 2,
  className = '',
  rightCorner = null,
  leftCorner = null,
  title = null,
  subtitle = null,
  backTo = null,
  ...props
}) => {
  var actionsWType = []
  var actionsWNType = []
  var actionsList = []
  try {
    const resources = props.authStore.user.resources
    actions.map((d, index) => {
      if (d.resourceUri) {
        let index = resources.indexOf(d.resourceUri);
        if (resources.indexOf(d.resourceUri) >= 0) {
          actionsList.push(d)
          var no = index + 1
          if (index < limitActionType) {
            actionsWType.push(d)
          } else {
            actionsWNType.push(d)
          }
        }
      } else {
        actionsList.push(d)
        var no = index + 1
        if (index < limitActionType) {
          actionsWType.push(d)
        } else {
          actionsWNType.push(d)
        }
      }
    })
  } catch (e) { }
  return (
    <Flex
      className={`mpk-commandbar mpk-padding-N padding-left padding-right ${className}`}
      align={Flex.properties.align.CENTER}
      justify={Flex.properties.justify.BETWEEN}
      {...props}
    >
      <Flex
        fit
        align={Flex.properties.align.CENTER}
      >
        {leftCorner ?
          <div className="mpk-margin-N margin-right">
            {backTo && <Link.IconButton to={backTo} iconClassName="mdi mdi-arrow-left" />}
            {leftCorner}
          </div>
          : (
            backTo && <Link.IconButton to={backTo} iconClassName="mdi mdi-arrow-left" />
          )}
        {title && (
          <span className="mpk-font weight-B size-L">{title}</span>
        )}
        {subtitle && (
          <span className="mpk-font weight-L size-L mpk-margin-S margin-left">{subtitle}</span>
        )}
      </Flex>
      <Flex
        align={Flex.properties.align.CENTER}
      >
        {(actionsList && (actionType == 'icon' || !actionType)) && (
          <div>
            {actionsList.filter(d => (typeof d.show === 'boolean' ? d.show : true)).map((d, i) => {
              return (
                <TooltipHoverModeConfig
                  enabled={true} delayTimeout={2} defaultDelay={2}
                  key={`commandbar-action-${i}`}
                >
                  <Tooltipped
                    id={`auto-positioning-above ${d.label}`}
                    tooltip={`${d.label}`}
                    defaultPosition="below"
                  >
                    <Button
                      buttonType="icon"
                      key={`commandbar-action-${i}`}
                      onClick={d.onClick}
                    >
                      <FontIcon iconClassName={d.iconClassName} />
                    </Button>
                  </Tooltipped>
                </TooltipHoverModeConfig>
              )
            })}
          </div>
        )}
        {(actionsList && (actionType == 'button' || actionType)) && (

          <div>
            {actionsWType.filter(d => (typeof d.show === 'boolean' ? d.show : true)).map((d, i) => {
              return (
                <>
                  <Button key={`commandbar-action-${i}`} style={{ marginLeft: 4, marginRight: 4 }} onClick={d.onClick} theme="primary" themeType="outline">
                    <TextIconSpacing icon={<FontIcon iconClassName={d.iconClassName}></FontIcon>} iconAfter>
                      {d.label}
                    </TextIconSpacing>
                  </Button>
                </>
              )
            })}
            {actionsList.length > limitActionType &&

              <DropdownMenu
                style={{
                  height: 55,
                  width: 55,
                  borderRadius: 10,
                  top: 5
                }}
                id="dropdown-commandbar-action"
                buttonType="icon"
                theme="primary"
                themeType="contained"
                items={
                  actionsWNType.map((d, i) => {
                    return <MenuItem leftAddon={<FontIcon iconClassName={d.iconClassName} />} onClick={d.onClick}>{d.label}</MenuItem>
                  })
                  // [
                  // <MenuItem>asdasd</MenuItem>,
                  // <MenuItem>asdasd</MenuItem>,
                  // <MenuItem>asdasd</MenuItem>
                  // ]
                }
              >
                <MoreVertSVGIcon></MoreVertSVGIcon>
              </DropdownMenu>
            }
          </div>
        )}
        {rightCorner}
      </Flex>
    </Flex>
  )
}

Commandbar.action = action

// export default Commandbar
export default inject('authStore')(observer(Commandbar))

