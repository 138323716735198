import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const { inputTypes } = DataForm

const skey = storage.keys.SPT_1771_LampKhus3AII
const columns = {
    list:"list",
}

const itemColumns = {
    NAMA: 'nama',
    JNS_TRANS: 'jnsTrans',
    NILAI_TRANS: 'nilaiTrans',
    METODE: 'metode',
    ALASAN: 'alasan'
}

const generate = async () => {
    let data = await storage.generate(skey, {
        list: []
    })

    return data
}

const LampKhus3AII = ({
    getLabel,
    envStore,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    const handleUpdateData = (data) => {
        return data
    }

    useEffect(() => {
        let __data = storage.get(skey)
        let defData = handleUpdateData(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-LampKhus3AII"
            className="mpk-margin-C"
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            watchDefaultData={false}
            transparent={true}
            style={{maxWidth: 'unset'}}
            definitions={[
                {
                    inputType: inputTypes.LIST,
                    asTable: true,
                    label: getLabel('__title','LampKhus3AII'),
                    key: 'list',
                    defaultData:{
                        NAMA: '',
                        JNS_TRANS: String(envStore.env.spt.jenisTransaksiLampiranKhusus3A[0].value),
                        NILAI_TRANS: 0,
                        METODE: String(envStore.env.spt.metodePenetapanHarga[0]),
                        ALASAN:''
                    },
                    definitions:[
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('NAMA', 'LampKhus3AII'),
                            key: 'NAMA',
                            validation:'max:50',
                            required: true
                        },
                        {
                            inputType: inputTypes.SELECT,
                            label: getLabel('JNS_TRANS', 'LampKhus3AII'),
                            key: 'JNS_TRANS',
                            options: envStore.env.spt.jenisTransaksiLampiranKhusus3A
                        },
                        {
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            label: getLabel('NILAI_TRANS', 'LampKhus3AII'),
                            key:'NILAI_TRANS',
                            isNumericString:true,
                            decimalSeparator: ',',
                            thousandSeparator:'.',
                            isNumericString: true,
                        },
                        {
                            inputType: inputTypes.SELECT,
                            label: getLabel('METODE', 'LampKhus3AII'),
                            key: 'METODE',
                            options: envStore.env.spt.metodePenetapanHarga
                        },
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('ALASAN', 'LampKhus3AII'),
                            key: 'ALASAN',
                            valiation:'max:200'
                        },
                    ]
                }
            ]}
            onSubmit={async (data, callback) => {
                storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    )
}

LampKhus3AII.generate = generate
LampKhus3AII.columns = columns
LampKhus3AII.itemColumns = itemColumns

export default inject('envStore')(observer(LampKhus3AII))