import LampKhus3 from './LampKhus3'
import __LampKhus3A1 from './LampKhus3A1'
import __LampKhus3A2I from './LampKhus3A2I'
import __LampKhus3A2II from './LampKhus3A2II'
import __LampKhus3AI from './LampKhus3AI'
import __LampKhus3AII from './LampKhus3AII'

export const LampKhus3A1 = __LampKhus3A1
export const LampKhus3A2I = __LampKhus3A2I
export const LampKhus3A2II = __LampKhus3A2II
export const LampKhus3AI = __LampKhus3AI
export const LampKhus3AII = __LampKhus3AII

export default LampKhus3