import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { round } from '../../../../../libs/react-mpk/services/number.service'
import { rules, storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const { inputTypes } = DataForm
const recon = (
    data = storage.get(storage.keys.SPT_1771_Induk),
    LampI = storage.get(storage.keys.SPT_1771_LampI),
    HeaderLampKhus2A = storage.get(storage.keys.SPT_1771_HeaderLampKhus2A)
) => {
    return new Promise(resolve => {
        data.dA1 = LampI ? LampI.d8 : 0
        data.dA2 = HeaderLampKhus2A.JmlThPjk
        data.dA3 = data.dA1 - data.dA2
        data.dA3 = data.dA3 < 0 ? 0 : round(data.dA3, false, 1000)
        resolve(data)
    })
}

const A = ({
    data,
    handleUpdate,
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        setDefaultData(data)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-A"
            className="mpk-margin-C"
            style={{
                width: '100%',
                maxWidth: 800,
            }}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                'dA1', 'dA2', 'dA3'
            ].map(key => ({
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: getLabel(key, 'Induk'),
                isNumericString:true,
                decimalSeparator: ',',
                thousandSeparator:'.',
                isNumericString: true,
                disabled: true,
                key
            }))}
            // onSubmit={async (__data, callback) => {
            //     callback(t.translate('sentence.savedItem'), false)
            //     await handleUpdate(__data)
            // }}
            // submitLabel={t.translate('column.save')}
        />
    )
}


A.recon = recon

export default inject('envStore')(observer(A))
