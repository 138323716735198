import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import Rekapitulasi from '../Rekapitulasi'

const { inputTypes } = DataForm

const skey = storage.keys.SPT_1771_HubIstimewa3
const columns = {
    list:"list",
}

const itemColumns = {
    PIHAK:'pihak',
    JNS_TRANSAKSI:'jnsTransaksi',
    NILAI_TRANSAKSI:'nilaiTransaksi'
}

const recon = (
    data = storage.get(skey),
    submitting=true
) => {
    return new Promise(async (resolve) => {
        if(submitting) {
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async (isReset) => {
    let data = await storage.generate(skey, {
        list: []
    }, isReset)

    return data
}

const HubIstimewa3 = ({
    getLabel,
    envStore,
    ...props
}) => {
    const profile = storage.get(storage.keys.SPT_1771_Profile)
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    const handleUpdateData = (data) => {
        return data
    }

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 400)
    }, [])


    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-HubIstimewa3"
            className="mpk-margin-C"
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            watchDefaultData={false}
            style={{maxWidth: 'unset'}}
            definitions={[
                {
                    inputType: inputTypes.LIST,
                    asTable: true,
                    label: getLabel('__title','HubIstimewa3'),
                    key: 'list',
                    defaultData:{
                        PIHAK:'',
                        JNS_TRANSAKSI:'',
                        NILAI_TRANSAKSI: 0
                    },
                    definitions:[
                        {
                            inputType:inputTypes.INPUT,
                            label:getLabel('PIHAK', 'HubIstimewa3'),
                            key:'PIHAK',
                            validation:'max:500',
                            required: true
                        },
                        {
                            inputType:inputTypes.INPUT,
                            label:getLabel('JNS_TRANSAKSI', 'HubIstimewa3'),
                            key:'JNS_TRANSAKSI',
                            validation:'max:500',
                            required: true
                        },
                        {
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            label: getLabel('NILAI_TRANSAKSI', 'HubIstimewa3'),
                            isNumericString:true,
                            decimalSeparator: ',',
                            thousandSeparator:'.',
                            isNumericString: true,
                            key:'NILAI_TRANSAKSI'
                        }
                    ]
                }
            ]}
            onSubmit={async (data, callback) => {
                await storage.update(skey, data)
                Rekapitulasi.recon()
                callback(t.translate('sentence.savedItem'), false)
                props.onNextTab()
            }}
            submitLabel={t.translate('column.next')}
        />
    )
}

HubIstimewa3.generate = generate
HubIstimewa3.columns = columns
HubIstimewa3.itemColumns = itemColumns

export default inject('envStore')(observer(HubIstimewa3))