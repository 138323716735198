import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Neraca15
const columns = {
    ID823: 'id823',
    ID824: 'id824',
    ID825: 'id825',
    ID826: 'id826',
    ID827: 'id827',
    ID828: 'id828',
    ID829: 'id829',
    ID830: 'id830',
    ID831: 'id831',
    ID832: 'id832',
    ID833: 'id833',
    ID834: 'id834',
    ID835: 'id835',
    ID836: 'id836',
    ID837: 'id837',
    ID838: 'id838',
    ID839: 'id839',
    ID840: 'id840',
    ID841: 'id841',
    ID842: 'id842',
    ID843: 'id843',
    ID844: 'id844',
    ID845: 'id845',
    ID846: 'id846',
    ID847: 'id847',
    ID848: 'id848',
    ID849: 'id849',
    ID850: 'id850',
    ID851: 'id851',
    ID852: 'id852',
    ID853: 'id853',
    ID854: 'id854',
    ID855: 'id855',
    ID856: 'id856',
    ID857: 'id857'
}

const recon = (
    data = storage.get(skey),
    submitting=true
) => {
    return new Promise(async (resolve) => {
        data.ID839 =    data.ID823 + data.ID824 + data.ID825 + data.ID826 +
                        data.ID827 + data.ID828 + data.ID829 + data.ID830 + data.ID831 +
                        data.ID832 + data.ID833 + data.ID834 + data.ID835 + data.ID836 +
                        data.ID837 + data.ID838

        data.ID857 =    data.ID840 + data.ID841 + data.ID842 + data.ID843 +
                        data.ID844 + data.ID845 + data.ID846 + data.ID847 + data.ID848 +
                        data.ID849 + data.ID850 + data.ID851 + data.ID852 + data.ID853 +
                        data.ID854 + data.ID855 + data.ID856 

        if(submitting) {
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async (isReset) => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })(), isReset)

    return data
}

const Neraca15 = ({
    getLabel,
    asKewajiban,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-Neraca15"
            className="mpk-margin-C mpk-animation slide-in"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: getLabel('__aktiva', 'Neraca15')
                // },
                ...(asKewajiban ? [] : [
                    'ID823',
                    'ID824',
                    'ID825',
                    'ID826',
                    'ID827',
                    'ID828',
                    'ID829',
                    'ID830',
                    'ID831',
                    'ID832',
                    'ID833',
                    'ID834',
                    'ID835',
                    'ID836',
                    'ID837',
                    'ID838',
                    'ID839'
                ]).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Neraca15'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    isNumericString: true,
                    allowNegative: false,
                    width: [
                        'ID824',
                        'ID825',
                        'ID827',
                        'ID828',
                        'ID829',
                        'ID830',
                        'ID831',
                        'ID832',
                        'ID835',
                        'ID836',
                        'ID837',
                        'ID838',
                    ].indexOf(key) >= 0 ? '50%' : '100%',
                    className: [
                        'ID824',
                        'ID827',
                        'ID829',
                        'ID831',
                        'ID835',
                        'ID837',
                    ].indexOf(key) >= 0 ? 'mpk-padding-S padding-right' : '',
                    disabled: key === 'ID839',
                    key
                })),
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: getLabel('__kewajibanDanEkuitas', 'Neraca15')
                // },
                ...(asKewajiban ? [
                    'ID840',
                    'ID841',
                    'ID842',
                    'ID843',
                    'ID844',
                    'ID845',
                    'ID846',
                    'ID847',
                    'ID848',
                    'ID849',
                    'ID850',
                    'ID851',
                    'ID852',
                    'ID853',
                    'ID854',
                    'ID855',
                    'ID856',
                    'ID857'
                ] : []).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Neraca15'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    isNumericString: true,
                    allowNegative: ['ID855','ID856'].indexOf(key) >= 0,
                    width: [
                        'ID840',
                        'ID841',
                        'ID842',
                        'ID843',
                        'ID844',
                        'ID845',
                        'ID846',
                        'ID847',
                        'ID848',
                        'ID849',
                        'ID850',
                        'ID851',
                        'ID852',
                        'ID853',
                        'ID854',
                        'ID855',
                    ].indexOf(key) >= 0 ? '50%' : '100%',
                    className: [
                        'ID840',
                        'ID842',
                        'ID844',
                        'ID846',
                        'ID848',
                        'ID850',
                        'ID852',
                        'ID854',
                    ].indexOf(key) >= 0 ? 'mpk-padding-S padding-right' : '',
                    disabled: key === 'ID857',
                    key
                }))
            ]}
            onChange={async data => {
                data = await recon(data, false)
                return data
            }}
            onSubmit={async (data, callback) => {
                await storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    ) : <LoaderInfo/>
}

Neraca15.generate = generate
Neraca15.columns = columns
Neraca15.recon = recon

export default Neraca15
