import { find } from 'lodash';

export const formatNpwp = (npwp) => {
  let result = String(npwp).split('');
  result.splice(2, 0, '.');
  result.splice(6, 0, '.');
  result.splice(10, 0, '.');
  result.splice(12, 0, '-');
  result.splice(16, 0, '.');
  return result.toString().replace(/,/g,'');
}

export const unformatNpwp = (npwp) => {
  return npwp.replace(/\D/g, '')
}

export class Formatter{
  constructor(key='', func=() => {}){
    this.key = key 
    this.func = func
  }
}

export const dataFormatter = (data, formatters=[]) => {
  return new Promise(async resolve => {
    for(let key of Object.keys(data)){
      await fObject(data[key])
    }
    resolve()
  })
}

const fObject = (item, formatters=[]) => {
  return new Promise(async resolve => {
    for(let key of Object.keys(item)){
      let dataType = typeof item[key]
      if(dataType === 'object'){
        item[key] = Array.isArray(item[key]) 
          ? await fArray(item[key], formatters) 
          : await fObject(item[key], formatters)
      } else if(dataType === 'string'){
        item[key] = fString(key, item[key], formatters)
      }
    }
    resolve()
  })
}

const fArray = (item, formatters) => {
  return new Promise(async resolve => {
    for(let i = 0 ; i < item.length ; i++){
      let dataType = typeof item[i]
      if(dataType === 'object'){
        item[i] = Array.isArray(item[i])
          ? await fArray(item[i], formatters)
          : await fObject(item[i], formatters)
      }
    }
    resolve(item)
  })
}

const fString = (key, value, formatters) => {
  let fmt = find({key})
  if(fmt) return fmt(value)
  else return value
}

export const mapColumns = (data, columns, reverse=false) => {
  let { _id } = data
  let result = {_id}
  let __columns = reverse ? {} : {...columns}
  if(reverse){
    for(let c of Object.keys(columns)){
      if(typeof columns[c] === 'object'){
        let { key, ...oth} = columns[c]
        __columns[key] = {
          key: c,
          ...oth
        }
      } else {
        __columns[columns[c]] = c
      }
    }
  }

  for(let k of Object.keys(data)){
    let col = __columns[k]
    if(typeof col === 'object' && ((reverse && col.reverseValue) || col.value) && (typeof col.value === 'function' || typeof col.reverseValue === 'function')){
      result[col.key] = (reverse && col.reverseValue)
        ? col.reverseValue(data[k]) 
        : (col.value 
          ? col.value(data[k])
          : data[k]
        )
    } else if(col) {
      result[typeof col === 'object' ? (col.key || k) : col] = typeof col === 'object' && col.value ? col.value(data[k]) : data[k]
    } else {
      result[k] = data[k]
    }
  }

  return result
}

export const mapListItemColumns = (listData, itemColumns, reverse=false) => {
  let result = []
  for(let item of listData){
    item = mapColumns(item, itemColumns, reverse)
    result.push(item)
  }
  return result
}

export const flattenObject = (data) => {
  let result = {}
  for(let key of Object.keys(data)){
    if(typeof data[key] === 'object'){
      result = {...result, ...data[key]}
    } else {
      result[key] = data[key]
    }
  }
  return result
}

export const parseNPWP =(npwp) => {
  let result = String(npwp).split('');
  result.splice(2, 0, '.');
  result.splice(6, 0, '.');
  result.splice(10, 0, '.');
  result.splice(12, 0, '-');
  result.splice(16, 0, '.');
  return result.toString().replace(/,/g,'');
}