import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Neraca1
const columns = {
    ID1: 'id1',
    ID2: 'id2',
    ID3: 'id3',
    ID4: 'id4',
    ID5: 'id5',
    ID6: 'id6',
    ID7: 'id7',
    ID8: 'id8',
    ID9: 'id9',
    ID10: 'id10',
    ID11: 'id11',
    ID12: 'id12',
    ID13: 'id13',
    ID14: 'id14',
    ID15: 'id15',
    ID16: 'id16',
    ID17: 'id17',
    ID18: 'id18',
    ID19: 'id19',
    ID20: 'id20',
    ID21: 'id21',
    ID22: 'id22',
    ID23: 'id23',
    ID24: 'id24',
    ID25: 'id25',
    ID26: 'id26',
    ID27: 'id27',
    ID28: 'id28',
    ID29: 'id29',
    ID30: 'id30',
    ID31: 'id31',
    ID32: 'id32',
    ID33: 'id33',
    ID34: 'id34',
    ID35: 'id35',
    ID36: 'id36',
    ID37: 'id37',
    ID38: 'id38',
    ID39: 'id39',
    ID40: 'id40',
    ID41: 'id41',
    ID42: 'id42',
}

const recon = (
    data = storage.get(skey),
    submitting = true
) => {
    return new Promise(async (resolve) => {
        data.ID21 = data.ID1 + data.ID2 + data.ID3 + data.ID4 + data.ID5 + data.ID6 +
            data.ID7 + data.ID8 + data.ID9 + data.ID10 + data.ID11 + data.ID12 +
            data.ID13 + data.ID14 - data.ID15 + data.ID16 +
            data.ID17 + data.ID18 + data.ID19 + data.ID20

        data.ID42 = data.ID22 + data.ID23 + data.ID24 + data.ID25 +
            data.ID26 + data.ID27 + data.ID28 + data.ID29 +
            data.ID30 + data.ID31 + data.ID32 + data.ID33 +
            data.ID34 + data.ID35 + data.ID36 + data.ID37 +
            data.ID38 + data.ID39 + data.ID40 + data.ID41
        if (submitting) {
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async (isReset = false) => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for (let k of Object.keys(columns)) {
            result[k] = 0
        }

        return result
    })(), isReset)

    return data
}

const Neraca1Aktiva = ({
    getLabel,
    asKewajiban,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-Neraca1"
            className="mpk-margin-C mpk-animation slide-in"
            style={{ maxWidth: 'unset' }}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: getLabel('__aktiva', 'Neraca1')
                // },
                ...(asKewajiban ? [] : [
                    'ID1',
                    'ID2',
                    'ID3',
                    'ID4',
                    'ID5',
                    'ID6',
                    'ID7',
                    'ID8',
                    'ID9',
                    'ID10',
                    'ID11',
                    'ID12',
                    'ID13',
                    'ID14',
                    'ID15',
                    'ID16',
                    'ID17',
                    'ID18',
                    'ID19',
                    'ID20',
                    'ID21'
                ]).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Neraca1'),
                    decimalSeparator: ',',
                    thousandSeparator: '.',
                    isNumericString: true,
                    allowNegative: key === 'ID7' ? true : false,
                    width: key != 'ID21' ? '50%' : '100%',
                    className: i % 2 && key != 'ID21' ? '' : 'mpk-padding-S padding-right',
                    disabled: key === 'ID21',
                    key
                })),
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: getLabel('__kewajibanDanEkuitas', 'Neraca1')
                // },
                ...(asKewajiban ? [
                    'ID22',
                    'ID23',
                    'ID24',
                    'ID25',
                    'ID26',
                    'ID27',
                    'ID28',
                    'ID29',
                    'ID30',
                    'ID31',
                    'ID32',
                    'ID33',
                    'ID34',
                    'ID35',
                    'ID36',
                    'ID37',
                    'ID38',
                    'ID39',
                    'ID40',
                    'ID41',
                    'ID42',
                ] : []).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Neraca1'),
                    decimalSeparator: ',',
                    thousandSeparator: '.',
                    isNumericString: true,
                    allowNegative: ['ID39', 'ID40', 'ID41',].indexOf(key) >= 0,
                    width: ['ID22', 'ID23', 'ID28', 'ID29', 'ID34', 'ID35', 'ID42'].indexOf(key) < 0 ? '50%' : '100%',
                    className: i % 2 && ['ID22', 'ID23', 'ID28', 'ID29', 'ID34', 'ID35', 'ID42'].indexOf(key) < 0 ? '' : 'mpk-padding-S padding-right',
                    disabled: key === 'ID42',
                    key
                }))
            ]}
            onChange={async data => {
                data = await recon(data, false)
                return data
            }}
            onSubmit={async (data, callback) => {
                await storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    ) : <LoaderInfo />
}

Neraca1Aktiva.generate = generate
Neraca1Aktiva.columns = columns
Neraca1Aktiva.recon = recon

export default Neraca1Aktiva
