import react, { useEffect, useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import axios from "axios";
import { toJS } from "mobx";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  LinearProgress,
} from "react-md";
import moment from "moment";
import { toast } from "../../libs/react-mpk/services";
import { saveAs } from "file-saver";

const ListLogExport = ({ ...props }) => {
  const baseId = "mod-submission-log-export";

  useEffect(() => {
    try {
      document.getElementsByClassName("mdi-reload")[0].click();
    } catch (e) {}
  }, []);

  const [dialogItem, setDialogItem] = useState(false);
  const [downloadDialog, setDownloadDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteDialogBulk, setDeleteDialogBulk] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [dataFetch, setDataFetch] = useState([]);

  return (
    <>
      <Dialog
        visible={loadingDialog}
        style={{ height: 200, width: 200, borderRadius: 20, opacity: 1 }}
      >
        <div style={{ margin: 70 }}>
          <CircularProgress></CircularProgress>
        </div>
      </Dialog>
      <Dialog
        visible={downloadDialog}
        onRequestClose={() => setDownloadDialog(false)}
      >
        <DialogHeader>
          <b>DOWNLOAD FILE</b>
        </DialogHeader>
        <DialogContent>Apa anda yakin akan mendownload data ?</DialogContent>
        <DialogFooter>
          <Button
            onClick={() => setDownloadDialog(false)}
            style={{ marginRight: 5 }}
          >
            Batal
          </Button>
          <Button
            theme="primary"
            disabled={loadingDialog}
            themeType="contained"
            onClick={async () => {
              try {
                setLoadingDialog(true);
                setDownloadDialog(false);
                await new Promise((resolve) => setTimeout(resolve, 1000));
                var id = dialogItem._id;
                let res = await axios.get(`/api/export/download`, {
                  params: {
                    id: id,
                  },
                  responseType: "arraybuffer",
                });
                let arrayBuffer = res.data;
                let blob = new Blob([arrayBuffer], {
                  type: "application/octet-stream",
                });
                saveAs(blob, res.headers["x-filename"]);
                toast.success("Download berhasil !");
                setDialogItem(null);
                setLoadingDialog(false);
              } catch (e) {
                toast.error("Download gagal dengan pesan " + e.message);
                setDialogItem(null);
                setLoadingDialog(false);
                setDownloadDialog(false);
              }
            }}
          >
            Download
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog
        visible={deleteDialog}
        onRequestClose={() => setDeleteDialog(false)}
      >
        <DialogHeader>
          <b>HAPUS FILE</b>
        </DialogHeader>
        <DialogContent>Apa anda yakin akan menghapus data ?</DialogContent>
        <DialogFooter>
          <Button
            onClick={() => setDeleteDialog(false)}
            style={{ marginRight: 5 }}
          >
            Batal
          </Button>
          <Button
            theme="primary"
            disabled={loadingDialog}
            themeType="contained"
            onClick={async () => {
              try {
                setLoadingDialog(true);
                setDeleteDialog(false);
                await new Promise((resolve) => setTimeout(resolve, 1000));
                var id = dialogItem._id;
                let res = await axios.delete(`/api/export`, {
                  params: {
                    id: id,
                  },
                });
                toast.success("Hapus berhasil !");
                setDialogItem(null);
                setLoadingDialog(false);
                try {
                  document.getElementsByClassName("mdi-reload")[0].click();
                } catch (e) {}
              } catch (e) {
                toast.error("Hapus gagal dengan pesan " + e.message);
                setDialogItem(null);
                setLoadingDialog(false);
                setDeleteDialog(false);
              }
            }}
          >
            Hapus
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog
        visible={deleteDialogBulk}
        onRequestClose={() => setDeleteDialogBulk(false)}
      >
        <DialogHeader>
          <b>HAPUS FILE</b>
        </DialogHeader>
        <DialogContent>Apa anda yakin akan menghapus data ?</DialogContent>
        <DialogFooter>
          <Button
            onClick={() => setDeleteDialogBulk(false)}
            style={{ marginRight: 5 }}
          >
            Batal
          </Button>
          <Button
            theme="primary"
            disabled={loadingDialog}
            themeType="contained"
            onClick={async () => {
              try {
                setLoadingDialog(true);
                setDeleteDialogBulk(false);
                await new Promise((resolve) => setTimeout(resolve, 1000));
                var v_selected = JSON.parse(TableWrapper.getSelected(baseId));
                var v_selectedData = [];
                v_selected.map((d) => {
                  var item = dataFetch[d];
                  v_selectedData.push(item._id);
                });
                let res = await axios.post(`/api/export/delete`, {
                  ids: v_selectedData,
                });
                toast.success("Hapus berhasil !");
                setDialogItem(null);
                setLoadingDialog(false);
                try {
                  document.getElementsByClassName("mdi-reload")[0].click();
                } catch (e) {}
              } catch (e) {
                toast.error("Hapus gagal dengan pesan " + e.message);
                setDialogItem(null);
                setLoadingDialog(false);
                setDeleteDialogBulk(false);
              }
            }}
          >
            Hapus
          </Button>
        </DialogFooter>
      </Dialog>
      <TableWrapper
        // backTo={`/${props.envStore.widget.active
        //     ? "widget"
        //     : props.envStore.env.applicationType
        // }/submissions`}
        selectable={true}
        baseId={baseId}
        title={"Log Export"}
        className={`mpk-padding-N padding-left`}
        // hintMessage={t.translate("modules.submission.hint.message")}
        // hintIconClassName="mdi mdi-information"
        // hintMore={t.translate("modules.submission.hint.more")}
        defaultData={[]}
        useFilter={true}
        useCriteria={false}
        defaultSortBy="start_date"
        // defaultSort={TableWrapper.sorts.descending}
        // defaultQuery={{ column: "fileName" }}
        actions={[
          {
            label: "Hapus",
            iconClassName: "mdi mdi-delete",
            onClick: async () => {
              setDeleteDialogBulk(true);
            },
          },
        ]}
        itemActions={[
          {
            label: "Download",
            iconClassName: "mdi mdi-download",
            onClick: (item) => {
              setDownloadDialog(true);
              setDialogItem(item);
            },
          },
          {
            label: "Hapus",
            iconClassName: "mdi mdi-delete",
            onClick: (item) => {
              setDeleteDialog(true);
              setDialogItem(item);
            },
          },
        ]}
        columns={[
          {
            label: "Filename",
            key: "filename",
            searchable: true,
            render: (item) => item.filename,
          },
          {
            label: "Status",
            key: "status",
            searchable: true,
            render: (item) => {
              if (item.status == "FINISH") {
                return (
                  <Button
                    themeType="contained"
                    style={{ width: 150, background: "green", color: "white" }}
                  >
                    {item.status}
                  </Button>
                );
              } else {
                return (
                  <Button themeType="outline" style={{ width: 150 }}>
                    {item.status}
                  </Button>
                );
              }
            },
          },
          {
            label: "Progress",
            key: "progress",
            searchable: false,
            render: (item) => {
              if (item.total == 0) item.total = 1;
              if (item.progress > item.total) item.progress = item.total
              if (item.status == "FINISH") {
                return (
                  <LinearProgress
                    barStyle={{ background: "green" }}
                    value={item.progress}
                    min={0}
                    max={item.total}
                  />
                );
              } else {
                return (
                  <LinearProgress
                    value={item.progress}
                    min={0}
                    max={item.total}
                  />
                );
              }
              // `${item.progress}/${item.total}`,
            },
          },
          {
            label: "Total",
            key: "total",
            searchable: false,
            render: (item) => item.progress + "/" + item.total,
          },
          {
            label: "Message",
            key: "message",
            searchable: true,
            render: (item) => item.message,
          },
          {
            label: "Mulai",
            key: "mulai",
            searchable: false,
            render: (item) => {
              if (!item.start_date) {
                return "-";
              } else {
                var x = moment(item.start_date).format("DD-MM-YYYY hh:mm:ss");
                return x;
              }
            },
          },
          {
            label: "Berakhir",
            key: "bearkhir",
            searchable: false,
            render: (item) => {
              if (!item.end_date) {
                return "-";
              } else {
                var x = moment(item.end_date).format("DD-MM-YYYY hh:mm:ss");
                return x;
              }
            },
          },
          {
            label: "Dibuat Oleh",
            key: "createdBy",
            searchable: false,
            render: (item) => {
              return item.created_by;
            },
          },
          {
            label: "Diupdate Oleh",
            key: "lastModifiedBy",
            searchable: false,
            render: (item) => {
              return item.updated_by;
            },
          },
        ]}
        onFetchData={async (query) => {
          return new Promise(async (resolve, reject) => {
            var x = toJS(props.authStore);
            let res = await axios.get("/api/export", {
              params: query,
              headers: {
                authorization: `Bearer ${x.hasAccessToken}`,
              },
            });
            setDataFetch(res.data);
            resolve(res);
          });
        }}
      ></TableWrapper>
    </>
  );
};

export default inject(
  "navigationStore",
  "envStore",
  "temporaryStore",
  "modalStore",
  "authStore"
)(observer(ListLogExport));
