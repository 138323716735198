import React from 'react';
import { inject, observer } from 'mobx-react';
import { FontIcon } from 'react-md';
import './Picture.scss';

const Picture = ({
  fontIconStyle = {},
  ...props
}) => {
  const { user } = props.authStore;
  return (
    <div className="mpk-picture" {...props}>
      { user.profilePic ? (
        <img src={`${props.envStore.env.sso.host}${user.profilePic}`} alt={``}/>
      ) : (
        <div className="mpk-flex align-center justify-center mpk-full full-height">
          <FontIcon 
            className="mdi mdi-account"
            style={fontIconStyle}
          />
        </div>
      )}
    </div>
  )
}

export default inject('authStore','envStore')(observer(Picture))
