import LampVI from './LampVI'
import __HeaderLampVI from './HeaderLampVI'
import __DetailLampVIPenyertaanModal from './DetailLampVIPenyertaanModal'
import __DetailLampVIPiutangModal from './DetailLampVIPiutangModal'
import __DetailLampVIUtangModal from './DetailLampVIUtangModal'

export const HeaderLampVI = __HeaderLampVI
export const DetailLampVIPenyertaanModal = __DetailLampVIPenyertaanModal
export const DetailLampVIPiutangModal = __DetailLampVIPiutangModal
export const DetailLampVIUtangModal = __DetailLampVIUtangModal

export default LampVI