import React, {useState, useEffect} from 'react'
import { storage } from '../../../../services'
import { DataForm } from '../../../../libs/react-mpk/components'
import { http } from '../../../../libs/react-mpk/services'
import t from 'counterpart'

const skey = storage.keys.SPT_1771_Rekapitulasi
const { inputTypes } = DataForm 
const columns = {
    Jmld113231: 'jmlD113231',
    Jmld113254: 'jmlD113254',
    Jmld113232: 'jmlD113232',
    JmlAd113234: 'jmlAd113234',
    JmlBd113234: 'jmlBd113234',
    JmlAd113235: 'jmlAd113235',
    JmlBd113235: 'jmlBd113235',
    JmlAd113236: 'jmlAd113236',
    JmlBd113236: 'jmlBd113236',
    JmlCd113236: 'jmlCd113236',
    JmlLampKhs1A: 'jmlLampKhs1A',
    JmlLampKhs2A: 'jmlLampKhs2A',
    JmlLampKhs3AI: 'jmlLampKhs3AI',
    JmlLampKhs3AII: 'jmlLampKhs3AII',
    JmlLampKhs3A1: 'jmlLampKhs3A1',
    JmlLampKhs3A2I: 'jmlLampKhs3A2I',
    JmlLampKhs4A: 'jmlLampKhs4A',
    JmlLampKhs5A: 'jmlLampKhs5A',
    JmlLampKhs6A: 'jmlLampKhs6A',
    JmlLampKhs7A: 'jmlLampKhs7A',
    JmlLapKeu: 'jmlLapKeu',
    JmlItemSSP: 'jmlItemSSP'
}

const recon = (
    data = storage.get(skey),
    submitting = true
) => {
    return new Promise(async (resolve, reject) => {
        try{
            let submission = storage.get(storage.keys.SPT_SUBMISSION)
            data.Jmld113231 = 1
            data.Jmld113254 = 1
            
            // const DetailLampIIIPPh22 = storage.get(storage.keys.SPT_1771_DetailLampIIIPPh22)
            // const DetailLampIIIPPh23 = storage.get(storage.keys.SPT_1771_DetailLampIIIPPh23)
            // data.Jmld113232 = DetailLampIIIPPh22.list.length + DetailLampIIIPPh23.list.length
            let resLampIII22 = await http.get(`/kpdn/pph22/${submission.fileName}`, {page: 1, size: 1})
            let resLampIII23 = await http.get(`/kpdn/pph23/${submission.fileName}`, {page: 1, size: 1})
            let jml22 = resLampIII22.headers['x-pagination-count'] === 'undefined' ? 0 : Number(resLampIII22.headers['x-pagination-count'])
            let jml23 = resLampIII23.headers['x-pagination-count'] === 'undefined' ? 0 : Number(resLampIII23.headers['x-pagination-count'])
            data.Jmld113232 = (jml22 > 0 ? jml22 : 1) + (jml23 > 0 ? jml23 : 1)
            if(data.Jmld113232 === 0) data.Jmld113232 = 2
            
            const DetailLampIVA = storage.get(storage.keys.SPT_1771_DetailLampIVA)
            data.JmlAd113234 = DetailLampIVA.list.length
            if(data.JmlAd113234 < 1) data.JmlAd113234 = 1
            
            const DetailLampIVB = storage.get(storage.keys.SPT_1771_DetailLampIVB)
            data.JmlBd113234 = DetailLampIVB.list.length
            if(data.JmlBd113234 < 1) data.JmlBd113234 = 1

            const DetailLampVPemegangSaham = storage.get(storage.keys.SPT_1771_DetailLampVPemegangSaham)
            data.JmlAd113235 = DetailLampVPemegangSaham.list.length
            if(data.JmlAd113235 < 1) data.JmlAd113235 = 1

            const DetailLampVPengurus = storage.get(storage.keys.SPT_1771_DetailLampVPengurus)
            data.JmlBd113235 = DetailLampVPengurus.list.length
            if(data.JmlBd113235 < 1) data.JmlBd113235 = 1

            const DetailLampVIPenyertaanModal = storage.get(storage.keys.SPT_1771_DetailLampVIPenyertaanModal)
            data.JmlAd113236 = DetailLampVIPenyertaanModal.list.length
            if(data.JmlAd113236 < 1) data.JmlAd113236 = 1

            const DetailLampVIUtangModal = storage.get(storage.keys.SPT_1771_DetailLampVIUtangModal)
            data.JmlBd113236 = DetailLampVIUtangModal.list.length
            if(data.JmlBd113236 < 1) data.JmlBd113236 = 1

            const DetailLampVIPiutangModal = storage.get(storage.keys.SPT_1771_DetailLampVIPiutangModal)
            data.JmlCd113236 = DetailLampVIPiutangModal.list.length
            if(data.JmlCd113236 < 1) data.JmlCd113236 = 1

            // const DetailLampKhus1A = storage.get(storage.keys.SPT_1771_DetailLampKhus1A)
            // const DetailLampKhus1A2 = storage.get(storage.keys.SPT_1771_DetailLampKhus1A2)
            //data.JmlLampKhs1A = DetailLampKhus1A.list.length + DetailLampKhus1A2.list.length
            let resLampKhs1A = await http.get(`/penyusutan/${submission.fileName}`, {page: 1, size: 1})
            let resLampKhs1A2 = await http.get(`/amortisasi/${submission.fileName}`, {page: 1, size: 1})
            let jmlLampKhs1A = Number(resLampKhs1A.headers['x-pagination-count'] === 'undefined' ? 0 : resLampKhs1A.headers['x-pagination-count'])
            let jmlLampKhs1A2 = Number(resLampKhs1A2.headers['x-pagination-count'] === 'undefined' ? 0 : resLampKhs1A2.headers['x-pagination-count'])
            data.JmlLampKhs1A = (jmlLampKhs1A > 0 ? jmlLampKhs1A : 1) + (jmlLampKhs1A2 > 0 ? jmlLampKhs1A2 : 1)
            if(data.JmlLampKhs1A < 2) data.JmlLampKhs1A = 2
            
            data.JmlLampKhs2A = 1//HeaderLampKhus2A.list.length

            const LampKhus3AI = storage.get(storage.keys.SPT_1771_LampKhus3AI)
            data.JmlLampKhs3AI = LampKhus3AI.list.length
            if(data.JmlLampKhs3AI === 0) data.JmlLampKhs3AI = 1
            
            const LampKhus3AII = storage.get(storage.keys.SPT_1771_LampKhus3AII)
            data.JmlLampKhs3AII = LampKhus3AII.list.length
            // if(data.JmlLampKhs3AII === 0) data.JmlLampKhs3AII = 1
            
            // const LampKhus3A1 = storage.get(storage.keys.SPT_1771_LampKhus3A1)
            // data.JmlLampKhs3A1 = LampKhus3A1.list.length
            // if(data.JmlLampKhs3A1 < 1) data.JmlLampKhs3A1 = 1
            data.JmlLampKhs3A1 = 1

            const LampKhus3A2I = storage.get(storage.keys.SPT_1771_LampKhus3A2I)
            data.JmlLampKhs3A2I = LampKhus3A2I.list.length
            if(data.JmlLampKhs3A2I === 0) data.JmlLampKhs3A2I = 1

            // const LampKhus4A = storage.get(storage.keys.SPT_1771_LampKhus4A)
            // data.JmlLampKhs4A = LampKhus4A.list.length
            data.JmlLampKhs4A = 1

            //const DetailLampKhus5A = storage.get(storage.keys.SPT_1771_DetailLampKhus5A)
            let resCabangPerusahaan = await http.get(`/cabang-perusahaan/${submission.fileName}`, {page: 1, size: 1})
            data.JmlLampKhs5A = Number(resCabangPerusahaan.headers['x-pagination-count'] === 'undefined' ? 0 : resCabangPerusahaan.headers['x-pagination-count'])
            data.JmlLampKhs5A = data.JmlLampKhs5A > 0 ? data.JmlLampKhs5A : 1

            // const LampKhus6A = storage.get(storage.keys.SPT_1771_LampKhus6A)
            // data.JmlLampKhs6A = LampKhus6A.list.length
            data.JmlLampKhs6A = 1

            const LampKhus7A = storage.get(storage.keys.SPT_1771_DetailLampKhus7A)
            data.JmlLampKhs7A = LampKhus7A.list.length
            data.JmlLampKhs7A = data.JmlLampKhs7A > 0 ? data.JmlLampKhs7A : 1

            const HeaderLapKeu = storage.get(storage.keys.SPT_1771_HeaderLapKeu)
            const hubIstimewa = {}
            hubIstimewa['hub1'] = storage.get(storage.keys.SPT_1771_HubIstimewa1)
            hubIstimewa['hub3'] = storage.get(storage.keys.SPT_1771_HubIstimewa3)
            hubIstimewa['hub5'] = storage.get(storage.keys.SPT_1771_HubIstimewa5)
            hubIstimewa['hub7'] = storage.get(storage.keys.SPT_1771_HubIstimewa7)
            hubIstimewa['hub9'] = storage.get(storage.keys.SPT_1771_HubIstimewa9)
            hubIstimewa['hub11'] = storage.get(storage.keys.SPT_1771_HubIstimewa11)
            hubIstimewa['hub13'] = storage.get(storage.keys.SPT_1771_HubIstimewa13)
            hubIstimewa['hub15'] = storage.get(storage.keys.SPT_1771_HubIstimewa15)
            data.JmlLapKeu = hubIstimewa[`hub${HeaderLapKeu.ID_TEMPLATE}`].list.length
            if(data.JmlLapKeu === 0) data.JmlLapKeu = 1
            
            const DetailSSP = storage.get(storage.keys.SPT_1771_DetailSSP)
            data.JmlItemSSP = DetailSSP.list.length
            data.JmlItemSSP = data.JmlItemSSP > 0 ? data.JmlItemSSP : 1

            if(submitting){
                storage.update(skey, data)
            }

            resolve(data)
        }catch(error){
            reject(error)
        }
    })
}

const generate = async () => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = [
                'Jmld113231', 'Jmld113254', 'JmlAd113234', 'JmlBd113234'
            ].indexOf(k) >= 0 
                ? 1 
                : (['Jmld113232'].indexOf(k) >= 0 
                    ? 2
                    : 0
                )
        }
        return result
    })())

    return data
}

const Rekapitulasi = ({
    getLabel,
    ...props
}) => {
    const DetailLampIIIPPh22 = storage.get(storage.keys.SPT_1771_DetailLampIIIPPh22)
    const DetailLampIIIPPh23 = storage.get(storage.keys.SPT_1771_DetailLampIIIPPh23)
    const DetailLampIVA = storage.get(storage.keys.SPT_1771_DetailLampIVA)
    const DetailLampIVB = storage.get(storage.keys.SPT_1771_DetailLampIVB)

    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    const handleUpdateData = (data) => {
        data.Jmld113231 = 1
        data.Jmld113254 = 1

        const __lampIIIRecord = DetailLampIIIPPh22.list.length + DetailLampIIIPPh23.list.length
        data.Jmld113232 = __lampIIIRecord === 0 ? 2 : __lampIIIRecord

        const __lampIVARecord = DetailLampIVA.list.length
        data.JmlAd113234 = __lampIVARecord >= 1 ? __lampIVARecord : 1

        const __lampIVBRecord = DetailLampIVB.list.length
        data.JmlBd113234 = __lampIVBRecord >= 1 ? __lampIVBRecord : 1


        return data
    }

    useEffect(async () => {
        let defData = await recon()

        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return (
       <DataForm
            baseId="mod-submission-spt1771-rekapitulasi"
            className="mpk-margin-C"
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={Object.keys(columns).map((key,i) => ({
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: getLabel(key, 'Rekapitulasi'),
                readOnly: true,
                width: '50%',
                className: i%2 ? 'mpk-padding-S padding-left' : '',
                key
            }))}
            onSubmit={() => {
                props.onNextTab()
            }}
            submitLabel={t.translate('column.next')}
       />
    )
}

Rekapitulasi.generate = generate
Rekapitulasi.columns = columns
Rekapitulasi.recon = recon

export default Rekapitulasi
