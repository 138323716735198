import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const { inputTypes } = DataForm

const skey = storage.keys.SPT_1771_LampKhus3AI
const columns = {
    list:"list",
}

const itemColumns = {
    NAMA: 'nama',
    ALAMAT: 'alamat',
    TIN: 'tin',
    USAHA: 'usaha',
    ID_HUBUNGAN: {
        key: 'idHubungan',
        value: d => (Number(d)),
        reverseValue: d => (String(d))
    }
}

const generate = async () => {
    let data = await storage.generate(skey, {
        list: []
    })

    return data
}

const LampKhus3AI = ({
    getLabel,
    envStore,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    const handleUpdateData = (data) => {
        return data
    }

    useEffect(() => {
        let __data = storage.get(skey)
        let defData = handleUpdateData(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-LampKhus3AI"
            className="mpk-margin-C"
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            watchDefaultData={false}
            transparent={true}
            style={{maxWidth: 'unset'}}
            definitions={[
                {
                    inputType: inputTypes.LIST,
                    asTable: true,
                    label: getLabel('__title','LampKhus3AI'),
                    key: 'list',
                    defaultData:{
                        NAMA: '',
                        ALAMAT: '',
                        TIN: '',
                        USAHA: '',
                        ID_HUBUNGAN: String(envStore.env.spt.bentukHubunganDenganWP[0].value)
                    },
                    definitions:[
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('NAMA', 'LampKhus3AI'),
                            key: 'NAMA',
                            required: true
                        },
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('ALAMAT', 'LampKhus3AI'),
                            key: 'ALAMAT',
                            validation: 'max:255',
                            required: true
                        },
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('TIN', 'LampKhus3AI'),
                            key: 'TIN',
                        },
                        {
                            inputType: inputTypes.INPUT,
                            label: getLabel('USAHA', 'LampKhus3AI'),
                            key: 'USAHA',
                        },
                        {
                            inputType: inputTypes.SELECT,
                            label: getLabel('ID_HUBUNGAN', 'LampKhus3AI'),
                            key: 'ID_HUBUNGAN',
                            options: envStore.env.spt.bentukHubunganDenganWP
                        },
                    ]
                }
            ]}
            onSubmit={async (data, callback) => {
                storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    )
}

LampKhus3AI.generate = generate
LampKhus3AI.columns = columns
LampKhus3AI.itemColumns = itemColumns

export default inject('envStore')(observer(LampKhus3AI))