import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Neraca11
const columns = {
    ID609: 'id609',
    ID610: 'id610',
    ID611: 'id611',
    ID612: 'id612',
    ID613: 'id613',
    ID614: 'id614',
    ID615: 'id615',
    ID616: 'id616',
    ID617: 'id617',
    ID618: 'id618',
    ID619: 'id619',
    ID620: 'id620',
    ID621: 'id621',
    ID622: 'id622',
    ID623: 'id623',
    ID624: 'id624',
    ID625: 'id625',
    ID626: 'id626',
    ID627: 'id627',
    ID628: 'id628',
    ID629: 'id629',
    ID630: 'id630',
    ID631: 'id631',
    ID632: 'id632',
    ID633: 'id633',
    ID634: 'id634',
    ID635: 'id635',
    ID636: 'id636',
    ID637: 'id637',
    ID638: 'id638',
    ID639: 'id639',
    ID640: 'id640',
    ID641: 'id641',
    ID642: 'id642',
    ID643: 'id643',
    ID644: 'id644',
    ID645: 'id645',
    ID646: 'id646',
    ID647: 'id647',
    ID648: 'id648',
    ID649: 'id649',
    ID650: 'id650'
}

const recon = (
    data = storage.get(skey),
    submitting = true
) => {
    return new Promise(async (resolve) => {
        data.ID629 = data.ID609 + data.ID610 + data.ID611 + data.ID612 +
            data.ID613 + data.ID614 + data.ID615 + data.ID616 + data.ID617 +
            data.ID618 + data.ID619 + data.ID620 + data.ID621 + data.ID622 -
            data.ID623 + data.ID624 + data.ID625 + data.ID626 + data.ID627 +
            data.ID628

        data.ID650 = data.ID630 + data.ID631 + data.ID632 + data.ID633 +
            data.ID634 + data.ID635 + data.ID636 + data.ID637 + data.ID638 +
            data.ID639 + data.ID640 + data.ID641 + data.ID642 + data.ID643 +
            data.ID644 + data.ID645 + data.ID646 + data.ID647 + data.ID648 +
            data.ID649

        if (submitting) {
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async (isReset) => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for (let k of Object.keys(columns)) {
            result[k] = 0
        }

        return result
    })(), isReset)

    return data
}

const Neraca11 = ({
    getLabel,
    asKewajiban,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-Neraca11"
            className="mpk-margin-C mpk-animation slide-in"
            style={{ maxWidth: 'unset' }}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: getLabel('__aktiva', 'Neraca11')
                // },
                ...(asKewajiban ? [] : [
                    'ID609',
                    'ID610',
                    'ID611',
                    'ID612',
                    'ID613',
                    'ID614',
                    'ID615',
                    'ID616',
                    'ID617',
                    'ID618',
                    'ID619',
                    'ID620',
                    'ID621',
                    'ID622',
                    'ID623',
                    'ID624',
                    'ID625',
                    'ID626',
                    'ID627',
                    'ID628',
                    'ID629',
                ]).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Neraca11'),
                    isNumericString: true,
                    decimalSeparator: ',',
                    thousandSeparator: '.',
                    width: ['ID629'].indexOf(key) < 0 ? '50%' : '100%',
                    className: i % 2 || key === 'ID629' ? '' : 'mpk-padding-S padding-right',
                    disabled: key === 'ID629',
                    key
                })),
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: getLabel('__kewajibanDanEkuitas', 'Neraca11')
                // },
                ...(asKewajiban ? [
                    'ID630',
                    'ID631',
                    'ID632',
                    'ID633',
                    'ID634',
                    'ID635',
                    'ID636',
                    'ID637',
                    'ID638',
                    'ID639',
                    'ID640',
                    'ID641',
                    'ID642',
                    'ID643',
                    'ID644',
                    'ID645',
                    'ID646',
                    'ID647',
                    'ID648',
                    'ID649',
                    'ID650'
                ] : []).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Neraca11'),
                    isNumericString: true,
                    decimalSeparator: ',',
                    thousandSeparator: '.',
                    isNumericString: true,
                    allowNegative: ['ID647', 'ID648', 'ID649'].indexOf(key) >= 0,
                    width: [
                        'ID632',
                        'ID633',
                        'ID634',
                        'ID635',
                        'ID638',
                        'ID639',
                        'ID640',
                        'ID641',
                        'ID643',
                        'ID644',
                        'ID645',
                        'ID646',
                        'ID647',
                        'ID648',
                    ].indexOf(key) >= 0 ? '50%' : '100%',
                    className: [
                        'ID632',
                        'ID634',
                        'ID638',
                        'ID640',
                        'ID643',
                        'ID645',
                        'ID647',
                    ].indexOf(key) >= 0 ? 'mpk-padding-S padding-right' : '',
                    disabled: key === 'ID650',
                    key
                }))
            ]}
            onChange={async data => {
                data = await recon(data, false)
                return data
            }}
            onSubmit={async (data, callback) => {
                await storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    ) : <LoaderInfo />
}

Neraca11.generate = generate
Neraca11.columns = columns
Neraca11.recon = recon

export default Neraca11
