import React, { useState, useEffect } from 'react';
import { DataForm, DividerLabel } from '../../../../libs/react-mpk/components';
import { http, toast } from '../../../../libs/react-mpk/services';
import { rules, options, storage, utils } from '../../../../services'
import { inject, observer } from 'mobx-react'
import t from 'counterpart'
import moment from 'moment'
import { find } from 'lodash'
import * as service from '../../Submission.service'
import numeral from 'numeral'
import { Upload } from '../../../Certificate'
import { Button } from 'react-md';
import DetailLampIIIPPh22 from '../SPT1771/LampIII/DetailLampIIIPPh22';
import DetailLampIIIPPh23 from '../SPT1771/LampIII/DetailLampIIIPPh23';
import DetailLampKhus1A from '../SPT1771/LampKhus1A/DetailLampKhus1A';
import DetailLampKhus1A2 from '../SPT1771/LampKhus1A/DetailLampKhus1A2';
import iziToast from 'izitoast';
import { toJS } from 'mobx';

const { inputTypes } = DataForm

const minAutocompleteNpwp = 20
const CreateForm = ({
    visible,
    item,
    onRequestClose,
    onFinished,
    ...props
}) => {
    const { widget } = props.envStore
    const defaultNpwp = widget.active && (
        widget.xbrl
            ? widget.xbrl
            : widget.organizations && widget.organizations[0]
    )

    const currentYear = String(Number(moment(props.envStore.env.timestamp).year()) - 1)
    const [months] = useState(options.getMonths())
    const [years] = useState(options.getYears(props.envStore.env.timestamp, props.envStore.env.spt.minYear))
    const [pembetulans, setPembetulans] = useState(options.getPembetulans())
    const [tahunBuku] = useState(options.getTahunBuku(props.envStore.env.timestamp))
    const [isShowCertPassword, setIsShowCertPassword] = useState(false)
    const [isShowCertUpload, setIsShowCertUpload] = useState(false)
    const [selectedNpwp, setSelectedNpwp] = useState(null)
    const [refData, setRefData] = useState(null)
    const [tahunBukuSelesai] = useState([...tahunBuku, {
        label: String(Number(tahunBuku[0].label) + 1),
        value: String(Number(tahunBuku[0].value) + 1)
    }])
    const [organizationView, setOrganizationView] = useState([])

    const getLabel = (key, mod, code = '1771') => {
        return `${t.translate(`modules.submission.sptForm.${code}.${mod}.${key}`)}`
    }

    const handleUpdateData = data => {
        data.THN_MULAI = String(data.THN_PJK).substr(2, 2)
        const startDate = moment(`${data.BULAN_MULAI}-20${data.THN_MULAI}`, 'MM-YYYY')
        const endDate = moment(startDate).add(11, 'month')

        data.npwp16 = npwp16
        data.nitku = nitku

        // data.BULAN_SELESAI = months[endDate.month()].value
        data.THN_SELESAI = String(endDate.year()).substr(2, 2)
        return data
    }

    useEffect(() => {
        if (item) {
            let meta = service.parseFilename(item.fileName)
            setRefData(handleUpdateData({
                NPWP: meta.npwp,
                THN_PJK: meta.taxYear,
                KD_PEMBETULAN_KE: String(Number(meta.pembetulan) + 1),
                BULAN_MULAI: numeral(meta.periodBegin).format('00'),
            }))
        } else setRefData(null)
    }, [item])

    useEffect(()=> {
    }, [item, refData])

    const [npwp16, setNpwp16] = useState('')
    const [nitku, setNitku] = useState('')

    const visibleFun = async () => {
        setIsShowCertPassword(false)
        if (!item && widget.active && widget.spt && widget.spt.Profile) {
            let {
                NPWP,
                THN_PJK = currentYear,
                KD_PEMBETULAN_KE = '0',
                BULAN_MULAI = '01',
                THN_MULAI = currentYear,
                BULAN_SELESAI = '12',
                THN_SELESAI = currentYear
            } = widget.spt.Profile
            setRefData({
                NPWP: NPWP
                    ? String(NPWP)
                    : defaultNpwp
                        ? defaultNpwp.npwp
                        : props.temporaryStore.properties.npwp
                            ? props.temporaryStore.properties.npwp.npwp
                            : ''
                ,
                THN_PJK: String(THN_PJK),
                KD_PEMBETULAN_KE: String(KD_PEMBETULAN_KE),
                BULAN_MULAI: String(BULAN_MULAI),
                THN_MULAI: String(THN_MULAI),
                BULAN_SELESAI: String(BULAN_SELESAI),
                THN_SELESAI: String(THN_SELESAI),
                // npwp16: String(NPWP16),
                // nitku: String(NITKU)
            })
        }

        // setTimeout(async ()=> {
        //     try {
        //         var x = toJS(props.temporaryStore)
        //         if(x.properties.npwp.nitku == null){
        //             var inputNpwp = document.getElementsByClassName('rmd-text-field')[1].value
        //             var npwp = await service.getNpwpPemdandan(inputNpwp)
        //             var refData = {
        //                 ...refData,
        //                 npwp16: String(npwp.data.npwp16),
        //                 nitku: String(npwp.data.nitku)
        //             }
        //             var x = toJS(props.temporaryStore)
        //             props.temporaryStore.setProperties("npwp", {
        //                 ...x.properties.npwp,
        //                 npwp16: npwp.data.npwp16,
        //                 nitku: npwp.data.nitku
        //             })
        //             setNpwp16(npwp.data.npwp16)
        //             setNitku(npwp.data.nitku)
        //             // document.getElementsByClassName('rmd-text-field')[2].value = npwp.data.npwp16
        //             // document.getElementsByClassName('rmd-text-field')[3].value = npwp.data.nitku
        //             // setRefData({
        //             //     ...refData,
        //             //     npwp16: npwp.data.npwp16,
        //             //     nitku: npwp.data.nitku,
        //             //     npwp22: npwp.data.nitku
        //             // })
        //             // iziToast.success({
        //             //     title: "Tarik NPWP 16 & NITKU",
        //             //     message: "Berhasil"
        //             // })
        //         }
        //     } catch(e){
        //         iziToast.info({
        //             title: "Tarik NPWP 16 & NITKU Gagal",
        //             message: e.message
        //         })
        //     }
            
        // }, 100)
    }


    useEffect(() => {
        if (visible) {
            visibleFun()
        }
    }, [visible])

    const writeSptData = (submission, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (widget.active && widget.spt) {
                    let { spt } = widget
                    let keys = Object.keys(spt)
                    for (let k of keys) {
                        let key = `SPT_1771_${k}`
                        if (['DetailLampKhus1A', 'DetailLampKhus1A2', 'DetailLampIIIPPh22', 'DetailLampIIIPPh23'].indexOf(k) >= 0) {
                            if ((k === 'DetailLampKhus1A' || k === 'DetailLampKhus1A2') && spt[k].list) {
                                let c = {
                                    DetailLampKhus1A: {
                                        co: DetailLampKhus1A,
                                        path: 'penyusutan'
                                    },
                                    DetailLampKhus1A2: {
                                        co: DetailLampKhus1A2,
                                        path: 'amortisasi'
                                    }
                                }
                                for (let d of spt[k].list) {
                                    d = utils.mapColumns(d, c[k].co.itemColumns)
                                    await http.post(`/${c[k].path}/${submission.fileName}`, { ...d, referenceNo: submission.fileName })
                                }
                            } else if ((k === 'DetailLampIIIPPh22' || k === 'DetailLampIIIPPh23') && spt[k].list) {
                                let c = { DetailLampIIIPPh22, DetailLampIIIPPh23 }
                                let pasal = String(k).substr(16, 2)
                                for (let d of spt[k].list) {
                                    d = utils.mapColumns(d, c[k].itemColumns)
                                    await http.post(`/kpdn/pph${pasal}/${submission.fileName}`, { ...d, referenceNo: submission.fileName })
                                }
                            }
                        } else if (storage.keys[key]) {
                            storage.set(`__${key}`, key == 'Profile' ? { ...spt[k], ...data } : spt[k])
                        }
                    }
                }
                resolve()
            } catch (error) {
                reject(error)
            }
        })
    }

    return (
        <>
            <DataForm
                baseId="spt-create-form"
                title={t.translate(item ? 'column.pembetulan' : 'column.createNew')}
                visible={visible}
                onRequestClose={onRequestClose}
                hintMessage={item
                    ? t.translate('sentence.hintPembetulan').replace('*submissionName', item.fileName)
                    : t.translate('sentence.hintCreateForm')
                }
                asDialog={true}
                // watchDefaultData={item || nitku || (widget.active && widget.spt && widget.spt.Profile) ? true : false}
                watchDefaultData={item || (widget.active && widget.spt && widget.spt.Profile) ? true : false}
                defaultData={refData || {
                    NPWP: defaultNpwp
                        ? defaultNpwp.npwp
                        : (
                            props.temporaryStore.properties.npwp ? props.temporaryStore.properties.npwp.npwp : ''
                        ),
                    // DJP PERUBAHAN NPWP_16+nitku
                    npwp16: npwp16,
                    nitku: nitku,
                    // DJP PERUBAHAN NPWP_16+nitku
                    THN_PJK: widget.xbrl && widget.xbrl.tahun ? String(widget.xbrl.tahun) : currentYear,
                    KD_PEMBETULAN_KE: widget.xbrl && widget.xbrl.revNo ? String(widget.xbrl.revNo) : '0',
                    BULAN_MULAI: '01',
                    THN_MULAI: String(widget.xbrl && widget.xbrl.tahun ? String(widget.xbrl.tahun) : currentYear).substr(2, 2),
                    BULAN_SELESAI: '12',
                    THN_SELESAI: String(widget.xbrl && widget.xbrl.tahun ? String(widget.xbrl.tahun) : currentYear).substr(2, 2),
                    certPassword: defaultNpwp ? defaultNpwp.certPassword : ''
                }}
                definitions={[
                    {
                        inputType: inputTypes.INPUT,
                        label: getLabel('NPWP', 'Profile'),
                        key: 'NPWP',
                        required: true,
                        disabled: true,
                        show: !widget.active || (widget.active && widget.xbrl ? true : false)
                    },
                    // DJP PERUBAHAN NPWP_16+nitku
                    {
                        inputType: inputTypes.INPUT,
                        label: getLabel('npwp16', 'Profile'),
                        key: 'npwp16',
                        validation: 'min:16|max:16',
                        maxLength: 16,
                        minLength: 16,
                        disabled: false,
                        show: !widget.active || (widget.active && widget.xbrl ? true : false),
                        width: '50%',
                    },
                    {
                        inputType: inputTypes.INPUT,
                        label: getLabel('nitku', 'Profile'),
                        key: 'nitku',
                        validation: 'min:22|max:22',
                        maxLength: 22,
                        minLength: 22,
                        disabled: false,
                        className: 'mpk-padding-N padding-left',
                        show: !widget.active || (widget.active && widget.xbrl ? true : false),
                        width: '50%',
                    },
                    {
                        inputType: inputTypes.INPUT,
                        label: getLabel('npwp16', 'Profile'),
                        key: 'npwp16',
                        validation: 'min:16|max:16',
                        maxLength: 16,
                        minLength: 16,
                        disabled: false,
                        show: widget.active,
                        width: '50%',
                    },
                    {
                        inputType: inputTypes.INPUT,
                        label: getLabel('nitku', 'Profile'),
                        key: 'nitku',
                        validation: 'min:22|max:22',
                        maxLength: 22,
                        minLength: 22,
                        disabled: false,
                        className: 'mpk-padding-N padding-left',
                        show: widget.active,
                        width: '50%',
                    },
                    // DJP PERUBAHAN NPWP_16+nitku
                    {
                        inputType: inputTypes.INPUT,
                        label: getLabel('NPWP', 'Profile'),
                        key: 'NPWP',
                        validation: 'min:15|max:15',
                        maxLength: 15,
                        required: true,
                        disabled: false,
                        show: widget.active
                    },
                    // {
                    //     inputType: inputTypes.SELECT,
                    //     label: getLabel('NPWP', 'Profile'),
                    //     key: 'NPWP',
                    //     required: true,
                    //     options: widget.organizations ? widget.organizations.map(d => ({
                    //         label: d.npwp,
                    //         value: d.npwp
                    //     })) : [],
                    //     show: widget.active && widget.organizations && widget.organizations.length < minAutocompleteNpwp && (widget.xbrl ? false : true),
                    //     disabled: item ? true : false
                    // },
                    // {
                    //     inputType: inputTypes.AUTOCOMPLETE,
                    //     label: getLabel('NPWP', 'Profile'),
                    //     key: 'NPWP',
                    //     required: true,
                    //     validation: 'min:15|max:15',
                    //     data: organizationView.map(d => ({
                    //         children:<div style={{whiteSpace: 'wrap'}}>{d.npwp}</div>, 
                    //         value: d.npwp
                    //     })),
                    //     disabled: item ? true : false,
                    //     show: widget.active && widget.organizations && widget.organizations.length >= minAutocompleteNpwp && (widget.xbrl ? false : true)
                    // },
                    {
                        inputType: inputTypes.SELECT,
                        label: getLabel('THN_PJK', 'Profile'),
                        key: 'THN_PJK',
                        width: '50%',
                        options: years
                    },
                    {
                        inputType: inputTypes.SELECT,
                        label: getLabel('KD_PEMBETULAN_KE', 'Profile'),
                        key: 'KD_PEMBETULAN_KE',
                        className: 'mpk-padding-N padding-left',
                        width: '50%',
                        options: pembetulans
                    },
                    {
                        inputType: inputTypes.SELECT,
                        label: getLabel('BULAN_MULAI', 'Profile'),
                        key: 'BULAN_MULAI',
                        width: '50%',
                        options: months,//months.filter((d, i) => (i < 7)),
                        disabled: false
                    },
                    {
                        inputType: inputTypes.SELECT,
                        className: 'mpk-padding-N padding-left',
                        label: getLabel('THN_MULAI', 'Profile'),
                        key: 'THN_MULAI',
                        width: '50%',
                        options: [{
                            label: '2014',
                            value: '14'
                        }, ...tahunBuku],
                        disabled: true
                    },
                    {
                        inputType: inputTypes.SELECT,
                        label: getLabel('BULAN_SELESAI', 'Profile'),
                        key: 'BULAN_SELESAI',
                        width: '50%',
                        options: months,
                        // disabled: true
                    },
                    {
                        inputType: inputTypes.SELECT,
                        className: 'mpk-padding-N padding-left',
                        label: getLabel('THN_SELESAI', 'Profile'),
                        key: 'THN_SELESAI',
                        width: '50%',
                        options: tahunBukuSelesai,
                        disabled: true
                    },
                    {
                        render: (
                            <DividerLabel className='mpk-margin-N margin-top margin-bottom'>{t.translate('modules.certificate.upload.sertelRegistered')}</DividerLabel>
                        ),
                        show: isShowCertPassword
                    },
                    {
                        inputType: inputTypes.INPUT,
                        label: t.translate('modules.certificate.upload.entriPassword'),
                        key: 'certPassword',
                        type: 'password',
                        required: true,
                        helpText: t.translate('modules.certificate.upload.passwordHint'),
                        show: isShowCertPassword
                    },
                    {
                        render: (
                            <Button
                                themeType="outline"
                                theme="primary"
                                onClick={() => setIsShowCertUpload(true)}
                            >
                                {t.translate('modules.certificate.upload.orUploadFile')}
                            </Button>
                        ),
                        show: isShowCertPassword
                    }
                ]}
                onBeforeChange={(key, value) => {
                    { }
                    if (key === 'NPWP') {
                        if (widget.organizations.length >= minAutocompleteNpwp) {
                            setOrganizationView(() => {
                                return widget.organizations.filter(d => d.npwp.match(new RegExp(value)) ? true : false)
                            })
                        } else return rules.npwp(value)
                    }
                    // if (['NPWP'].indexOf(key) >= 0) return rules.npwp(value)
                    return value
                }}
                onChange={(data, key, value) => {
                    if (props.envStore.widget.active && key === 'NPWP') {
                        let cert = find(props.envStore.widget.organizations, { npwp: data.NPWP })
                        if (cert) {
                            let { name, infoAddress, infoPostal, certPassword = '' } = cert
                            data.NAMA_WP = name
                            data.ALAMAT = infoAddress
                            data.KODE_POS = infoPostal
                            data.certPassword = certPassword
                        }
                    }

                    return handleUpdateData(data)
                }}
                onSubmit={async (data, callback) => {
                    try {
                        let { NPWP, KD_PEMBETULAN_KE, BULAN_MULAI, BULAN_SELESAI, THN_PJK } = data

                        if (widget.active && widget.organizations.length >= minAutocompleteNpwp) {
                            let _npwp = find(widget.organizations, { npwp: NPWP })
                            if (!_npwp) throw new Error(t.translate('sentence.npwpNotFound').replace('*npwp', NPWP))
                        }

                        // if (widget.active && !widget.xbrl) {
                        //     let resCert = await service.getNpwp(NPWP)
                        //     if (!resCert.data) {
                        //         await new Promise(async(resolve, reject) => {
                        //             try {
                        //                 await service.postCert({
                        //                     npwp: data.NPWP,
                        //                     certPassword: data.certPassword
                        //                 })
                        //                 setIsShowCertPassword(false)
                        //                 resolve()
                        //             } catch (error) {
                        //                 if(error.response.status === 404){
                        //                     setSelectedNpwp(NPWP)
                        //                     setIsShowCertUpload(true)
                        //                 } else {
                        //                     setIsShowCertPassword(true)
                        //                 }
                        //                 reject(error)
                        //             }
                        //         })

                        //     }
                        // }

                        let res = await (item
                            ? http.get(`/submission/${item.fileName}/pembetulan/${KD_PEMBETULAN_KE}`)
                            : http.post(`/submission/init/1771`, {
                                npwp: NPWP,
                                // DJP PERUBAHAN NPWP_16+nitku
                                npwp16: data.npwp16,
                                nitku: data.nitku,
                                // DJP PERUBAHAN NPWP_16+nitku
                                kodePembetulan: KD_PEMBETULAN_KE,
                                tahunPajak: THN_PJK,
                                masaPajak: BULAN_MULAI,
                                masaPajakAkhir: BULAN_SELESAI,
                                ignoreCert: widget.active
                            })
                        )

                        storage.clearAll()
                        await writeSptData(res.data, data)
                        storage.set(storage.keys.SPT_SUBMISSION, res.data)

                        if (onFinished) {
                            onFinished(res.data, callback)
                        } else {
                            callback()
                            props.navigationStore.redirectTo(`/${props.envStore.widget.active ? 'widget' : props.envStore.env.applicationType}/submission/spt-1771/profile`)
                        }
                    } catch (error) {
                        callback(error, true, false)
                    }
                }}
            />
            <Upload
                visible={isShowCertUpload}
                onRequestClose={() => setIsShowCertUpload(false)}
                npwp={selectedNpwp}
                onFinished={cert => {
                    toast.success(t.translate('modules.submission.sptForm.certificateUploaded'))
                    setIsShowCertPassword(false)
                }}
            />
        </>
    )
};

export default inject('envStore', 'temporaryStore', 'navigationStore')(observer(CreateForm));
