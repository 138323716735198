import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Labarugi1
const columns = {
    ID43: 'id43',
    ID44: 'id44',
    ID45: 'id45',
    ID46: 'id46',
    ID47: 'id47',
    ID48: 'id48',
    ID49: 'id49',
    ID50: 'id50',
    ID51: 'id51',
    ID52: 'id52',
    ID53: 'id53',
    ID54: 'id54',
    ID55: 'id55',
    ID56: 'id56',
    ID57: 'id57',
    ID58: 'id58',
    ID59: 'id59',
    ID60: 'id60',
    ID61: 'id61',
    ID62: 'id62',
    ID63: 'id63',
    ID64: 'id64',
    ID65: 'id65',
    ID66: 'id66'
}

const recon = (
    data = storage.get(skey),
    submitting=true
) => {
    return new Promise(async (resolve) => {
        data.ID47 = data.ID44 + data.ID45 + data.ID46
        data.ID50 = data.ID47 + data.ID48 - data.ID49
        data.ID53 =  data.ID50 + data.ID51 - data.ID52 
        data.ID54 = data.ID43 - data.ID53 
        data.ID57 = data.ID54 - data.ID55 - data.ID56
        data.ID60 = data.ID57 + data.ID58 + data.ID59
        data.ID62 = data.ID60 - data.ID61
        data.ID64 = data.ID62 + data.ID63
        data.ID66 = data.ID64 - data.ID65

         if(submitting) {
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async (isReset) => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })(), isReset)

    return data
}

const Labarugi1 = ({
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-Labarugi1"
            className="mpk-margin-C mpk-animation slide-in"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={Object.keys(columns).map((key, i) => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'Labarugi1'),
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    isNumericString: true,
                    width: ['ID47','ID50','ID53','ID54','ID57','ID60','ID61','ID62','ID63','ID64','ID65','ID66'].indexOf(key) < 0 ? '50%' : '100%',
                    className: ['ID43','ID45', 'ID48', 'ID51', 'ID55', 'ID58'].indexOf(key) >= 0  ? 'mpk-padding-S padding-right' : '',
                    disabled: [
                        'ID47',
                        'ID50',
                        'ID53',
                        'ID54',
                        'ID57',
                        'ID60',
                        'ID62',
                        'ID64',
                        'ID66'
                    ].indexOf(key) >= 0,
                    key
                }))
            }
            onChange={async data => {
                data = await recon(data, false)
                return data
            }}
            onSubmit={async (data, callback) => {
                await storage.update(skey, data)
                callback(t.translate('sentence.savedItem'), false)
            }}
            submitLabel={t.translate('column.save')}
        />
    ) : <LoaderInfo/>
}

Labarugi1.generate = generate
Labarugi1.columns = columns
Labarugi1.recon = recon

export default Labarugi1
