import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { http } from '../../../../../libs/react-mpk/services'
import { storage } from '../../../../../services'
import t from 'counterpart'
import Induk from '../Induk'

const { inputTypes } = DataForm

const skey = storage.keys.SPT_1771_HeaderLampIII
const columns = {
    JmlObjPmt:"jmlObjPmt",
    JmlPs22:"jmlPs22",
    JmlPs23:"jmlPs23",
    JmlPs222326:"jmlPs222326"
}

const generate = async () => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })())

    return data
}

const recon = (
    data=storage.get(skey), 
    submitting=true
) => {
    return new Promise(async (resolve, reject) => {
        try{
            // let __pph22 = {ObjPmt: 0, PPhDPT: 0}
            // let __pph23 = {ObjPmt: 0, PPhDPT: 0}
            // for(let item of pph22.list){
            //     __pph22.ObjPmt += item.ObjPmt
            //     __pph22.PPhDPT += item.PPhDPT
            // }
            // for(let item of pph23.list){
            //     __pph23.ObjPmt += item.ObjPmt
            //     __pph23.PPhDPT += item.PPhDPT
            // }
            let submission = storage.get(storage.keys.SPT_SUBMISSION)
            let res22ObjPmt = await http.get(`/kpdn/pph22/${submission.fileName}/summary?field=objPmt`)
            let res22OPphDPT = await http.get(`/kpdn/pph22/${submission.fileName}/summary?field=pphDPT`)
            let res23ObjPmt = await http.get(`/kpdn/pph23/${submission.fileName}/summary?field=objPmt`)
            let res23OPphDPT = await http.get(`/kpdn/pph23/${submission.fileName}/summary?field=pphDPT`)

            let __pph22ObjPmt = res22ObjPmt.data[0] || {sum:0}
            let __pph23ObjPmt = res23ObjPmt.data[0] || {sum:0}
            let __pph22PphDPT = res22OPphDPT.data[0] || {sum:0}
            let __pph23PphDPT = res23OPphDPT.data[0] || {sum:0}

            __pph22ObjPmt = typeof __pph22ObjPmt.sum === 'object' ? Number(__pph22ObjPmt.sum.$long) : __pph22ObjPmt.sum
            __pph23ObjPmt = typeof __pph23ObjPmt.sum === 'object' ? Number(__pph23ObjPmt.sum.$long) : __pph23ObjPmt.sum
            __pph22PphDPT = typeof __pph22PphDPT.sum === 'object' ? Number(__pph22PphDPT.sum.$long) : __pph22PphDPT.sum
            __pph23PphDPT = typeof __pph23PphDPT.sum === 'object' ? Number(__pph23PphDPT.sum.$long) : __pph23PphDPT.sum

            data.JmlObjPmt = __pph22ObjPmt + __pph23ObjPmt
            data.JmlPs22 = __pph22PphDPT
            data.JmlPs23 = __pph23PphDPT
            data.JmlPs222326 = data.JmlPs22 + data.JmlPs23
            if(submitting) {
                storage.update(skey, data)
                Induk.recon()
            }
            resolve(data)
        }catch(error){
            reject(error)
        }
    })
}

const HeaderLampIII = ({
    getLabel,
    ...props
}) => {
    const pph22 = storage.get(storage.keys.SPT_1771_DetailLampIIIPPh22)
    const pph23 = storage.get(storage.keys.SPT_1771_DetailLampIIIPPh23)
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    

    useEffect(async () => {
        let __data = storage.get(skey)
        let defData = await recon(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-headerLampIII"
            className="mpk-margin-C"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={Object.keys(columns).map(key=>({
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: getLabel(key, 'HeaderLampIII'),
                isNumericString:true,
                decimalSeparator: ',',
                thousandSeparator:'.',
                isNumericString: true,
                readOnly: true,
                key
            }))}
            onSubmit={(data, callback) => {
                callback(t.translate('sentence.savedItem'), false)
                props.onNextTab()
            }}
            submitLabel={t.translate('column.next')}
        />
    ) : <LoaderInfo/>
}

HeaderLampIII.generate = generate
HeaderLampIII.columns = columns
HeaderLampIII.recon = recon

export default HeaderLampIII
