import Link from './Link'
import IconButton from './IconButton'

export const index = ({
    to,
    ...props
}) => (<Link to={to} {...props}/>)

index.IconButton = IconButton

export default index;