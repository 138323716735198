import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'
import Induk from '../Induk'
import { cloneDeep } from 'lodash'

const skey = storage.keys.SPT_1771_HeaderLampKhus2A
const calculate = (
    data,
    lampI = storage.get(storage.keys.SPT_1771_LampI),
    lampKhus4A = storage.get(storage.keys.SPT_1771_LampKhus4A),
) => {
    data = cloneDeep(data)
    data.RL11 = lampI ? lampI.d8 : 0
    // if(key && key.startsWith('RL') && data[key] > 0){
        if(!data.isiManual){

            let lastIndex = 11//Number(key.replace(`RL`, ''))
            let firstIndex = 1
            let minIndex = 11 - Number(lampKhus4A.ThRugi)
            for(let i = firstIndex ; i <= lastIndex ; i++){
                let currentKey = `RL${i}`
                if(i < minIndex){
                    // data[currentKey] = 0
                    data[`S${i}`] = 0
                    for(let il = i+1 ; il <= 11 ; il++){
                        let lKey = `L${i}${il}`
                        data[lKey] = 0
                    }
                } else {
                    let isUntung = data[currentKey] >= 0
                    if(isUntung){
                        let budgetKompensasi = data[currentKey]
                        for(let ii = firstIndex ; ii <= lastIndex ; ii++){
                        // for(let ii = firstIndex ; ii <= i ; ii++){
                        // for(let ii = firstIndex ; ii < i ; ii++){
                            let rKey = `RL${ii}`
                            let isRugi = data[rKey] < 0
                            if(isRugi){
                                let sisa = data[rKey]
                                for(let il = ii+1 ; il <= i ; il++){
                                    let lKey = `L${ii}${il}`
    
                                    if(il == i){
                                        data[lKey] = Math.abs(sisa) > budgetKompensasi ? budgetKompensasi : Math.abs(sisa)
                                        budgetKompensasi -= data[lKey]
                                    }
                                    sisa += data[lKey]
                                }
                                data[`S${ii}`] = sisa < 0 ? Math.abs(sisa) : 0
                            } else {
                                for(let il = i+1 ; il <= lastIndex ; il++){
                                    let lKey = `L${i}${il}`
                                    data[lKey] = 0
                                }
                                data[`S${ii}`] = 0
                            }
                        }
                    } else {
                        for(let il = 1 ; il <= i ; il++){
                            let lKey = `L${il}${i}`
                            data[lKey] = 0
                        }
                    }
                }
            }
        }
    // }

    data.JmlTh2 = data.L12
    data.JmlTh3 = data.L13 + data.L23
    data.JmlTh4 = data.L14 + data.L24 + data.L34
    data.JmlTh5 = data.L15 + data.L25 + data.L35 + data.L45
    data.JmlTh6 = data.L16 + data.L26 + data.L36 + data.L46 + data.L56
    data.JmlTh7 = data.L17 + data.L27 + data.L37 + data.L47 + data.L57 + data.L67
    data.JmlTh8 = data.L18 + data.L28 + data.L38 + data.L48     + data.L58 + data.L68 + data.L78
    data.JmlTh9 = data.L19 + data.L29 + data.L39 + data.L49 + data.L59 + data.L69 + data.L79 + data.L89
    data.JmlTh10 = data.L110 + data.L210 + data.L310 + data.L410 + data.L510 + data.L610 + data.L710 + data.L810 + data.L910
    data.JmlThPjk = data.L111 + data.L211 + data.L311 + data.L411 + data.L511 + data.L611 + data.L711 + data.L811 + data.L911 + data.L1011
    if(data.RL11 < 0){
        data.JmlSisa = data.S1 + data.S2 + data.S3 + data.S4 + data.S5 + data.S6 + data.S7 + data.S8 + data.S9 + data.S10 - data.RL11
    }else{
        data.JmlSisa = data.S1 + data.S2 + data.S3 + data.S4 + data.S5 + data.S6 + data.S7 + data.S8 + data.S9 + data.S10
    }
    delete data.R11
    return data
}

const recon = (
    data = storage.get(skey),
    profile = storage.get(storage.keys.SPT_1771_Profile),
    submitting=true
) => {
    return new Promise((resolve) => {
        for(let k of [
            'ThPjk1',
            'ThPjk2',
            'ThPjk3',
            'ThPjk4',
            'ThPjk5',
            'ThPjk6',
            'ThPjk7',
            'ThPjk8',
            'ThPjk9',
            'ThPjk10',
            'ThPjk11',
            'ThPjkJln'
        ]){
            if(k === 'ThPjkJln' ) data[k] = Number(profile.THN_PJK) + 1;
            else data[k] = Number(profile.THN_PJK) - (11 - Number(k.substr(5)))
        }
        
        data = calculate(data)
        if(submitting) {
            storage.update(storage.keys.SPT_1771_HeaderLampKhus2A, data)
            Induk.recon()
        }
        resolve(data)
    })
}

const { inputTypes } = DataForm

const columns = {
    ThPjk1: 'thPjk1',
    ThPjk2: 'thPjk2',
    ThPjk3: 'thPjk3',
    ThPjk4: 'thPjk4',
    ThPjk5: 'thPjk5',
    ThPjk6: 'thPjk6',
    ThPjk7: 'thPjk7',
    ThPjk8: 'thPjk8',
    ThPjk9: 'thPjk9',
    ThPjk10: 'thPjk10',
    ThPjk11: 'thPjk11',
    ThPjkJln: 'thPjkJln',
    RL1: 'rL1',
    RL2: 'rL2',
    RL3: 'rL3',
    RL4: 'rL4',
    RL5: 'rL5',
    RL6: 'rL6',
    RL7: 'rL7',
    RL8: 'rL8',
    RL9: 'rL9',
    RL10: 'rL10',
    RL11: 'rL11',
    L12: 'l12',
    L13: 'l13',
    L14: 'l14',
    L15: 'l15',
    L16: 'l16',
    L17: 'l17',
    L18: 'l18',
    L19: 'l19',
    L110: 'l110',
    L111: 'l111',
    S1: 's1',
    L23: 'l23',
    L24: 'l24',
    L25: 'l25',
    L26: 'l26',
    L27: 'l27',
    L28: 'l28',
    L29: 'l29',
    L210: 'l210',
    L211: 'l211',
    S2: 's2',
    L34: 'l34',
    L35: 'l35',
    L36: 'l36',
    L37: 'l37',
    L38: 'l38',
    L39: 'l39',
    L310: 'l310',
    L311: 'l311',
    S3: 's3',
    L45: 'l45',
    L46: 'l46',
    L47: 'l47',
    L48: 'l48',
    L49: 'l49',
    L410: 'l410',
    L411: 'l411',
    S4: 's4',
    L56: 'l56',
    L57: 'l57',
    L58: 'l58',
    L59: 'l59',
    L510: 'l510',
    L511: 'l511',
    S5: 's5',
    L67: 'l67',
    L68: 'l68',
    L69: 'l69',
    L610: 'l610',
    L611: 'l611',
    S6: 's6',
    L78: 'l78',
    L79: 'l79',
    L710: 'l710',
    L711: 'l711',
    S7: 's7',
    L89: 'l89',
    L810: 'l810',
    L811: 'l811',
    S8: 's8',
    L910: 'l910',
    L911: 'l911',
    S9: 's9',
    L1011: 'l1011',
    S10: 's10',
    JmlTh2: 'jmlTh2',
    JmlTh3: 'jmlTh3',
    JmlTh4: 'jmlTh4',
    JmlTh5: 'jmlTh5',
    JmlTh6: 'jmlTh6',
    JmlTh7: 'jmlTh7',
    JmlTh8: 'jmlTh8',
    JmlTh9: 'jmlTh9',
    JmlTh10: 'jmlTh10',
    JmlThPjk: 'jmlThPjk',
    JmlSisa: 'jmlSisa'
}

const generate = async () => {
    let profile = await storage.get(storage.keys.SPT_1771_Profile)
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            let val = 0

            if(k.startsWith('ThPjkJln')) val = Number(profile.THN_PJK)
            else if(k.startsWith('ThPjk')) {
                val = Number(profile.THN_PJK) - (11 - Number(k.substr(5)))
            }

            result[k] = val
        }

        return result
    })())

    return data
}

const HeaderLampKhus2A = ({
    getLabel,
    ...props
}) => {
    let profile = storage.get(storage.keys.SPT_1771_Profile)
    let lampI = storage.get(storage.keys.SPT_1771_LampI)
    let lampKhus4A = storage.get(storage.keys.SPT_1771_LampKhus4A)

    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)
    const [isHitungSendiri, setIsisiManual] = useState(false)
    const [definitions, setDefinitions] = useState([])
    console.log("🚀 ~ file: HeaderLampKhus2A.js:260 ~ definitions:", definitions)

    const handleUpdateData = (data, key) => {
        /*
        data = cloneDeep(data)
        data.RL11 = lampI ? lampI.d8 : 0
        // if(key && key.startsWith('RL') && data[key] > 0){
            let lastIndex = 11//Number(key.replace(`RL`, ''))
            let firstIndex = 1
            let minIndex = 11 - Number(data.ThRugi)
            for(let i = firstIndex ; i <= lastIndex ; i++){
                let currentKey = `RL${i}`
                if(i < minIndex){
                    data[currentKey] = 0
                    data[`S${i}`] = 0
                    for(let il = i+1 ; il <= 11 ; il++){
                        let lKey = `L${i}${il}`
                        data[lKey] = 0
                    }
                } else {
                    let isUntung = data[currentKey] > 0
                    if(isUntung){
                        let budgetKompensasi = data[currentKey]
                        for(let ii = firstIndex ; ii <= i ; ii++){
                            let rKey = `RL${ii}`
                            let isRugi = data[rKey] < 0
                            if(isRugi){
                                let sisa = data[rKey]
                                for(let il = ii+1 ; il <= i ; il++){
                                    let lKey = `L${ii}${il}`

                                    if(il == i){
                                        data[lKey] = Math.abs(sisa) > budgetKompensasi ? budgetKompensasi : Math.abs(sisa)
                                        budgetKompensasi -= data[lKey]
                                    }
                                    sisa += data[lKey]
                                }
                                data[`S${ii}`] = sisa < 0 ? sisa : 0
                            }
                        }
                    } else {
                        for(let il = 1 ; il < i ; il++){
                            let lKey = `L${il}${i}`
                            data[lKey] = 0
                        }
                    }
                }
            }
        // }

        data.JmlTh2 = data.L12
        data.JmlTh3 = data.L13 + data.L23
        data.JmlTh4 = data.L14 + data.L24 + data.L34
        data.JmlTh5 = data.L15 + data.L25 + data.L35 + data.L45
        data.JmlTh6 = data.L16 + data.L26 + data.L36 + data.L46 + data.L56
        data.JmlTh7 = data.L17 + data.L27 + data.L37 + data.L47 + data.L57 + data.L67
        data.JmlTh8 = data.L18 + data.L28 + data.L38 + data.L48     + data.L58 + data.L68 + data.L78
        data.JmlTh9 = data.L19 + data.L29 + data.L39 + data.L49 + data.L59 + data.L69 + data.L79 + data.L89
        data.JmlTh10 = data.L110 + data.L210 + data.L310 + data.L410 + data.L510 + data.L610 + data.L710 + data.L810 + data.L910
        data.JmlThPjk = data.L111 + data.L211 + data.L311 + data.L411 + data.L511 + data.L611 + data.L711 + data.L811 + data.L911 + data.L1011
        data.JmlSisa = data.S1 + data.S2 + data.S3 + data.S4 + data.S5 + data.S6 + data.S7 + data.S8 + data.S9 + data.S10
        delete data.R11
        return data
        */
    }

    const matrix = [
        ['__tahun'      ,'__labaRugi','ThPjk2'   ,'ThPjk3'   ,'ThPjk4'   ,'ThPjk5'   ,'ThPjk6'   ,'ThPjk7'   ,'ThPjk8'   ,'ThPjk9'   ,'ThPjk10'  ,'__tahunPajak',  'ThPjkJln'],
        ['ThPjk1'       ,'RL1'      ,'L12'      ,'L13'      ,'L14'      ,'L15'      ,'L16'      ,'L17'      ,'L18'      ,'L19'      ,'L110'     ,'L111'     ,'S1'],
        ['ThPjk2'       ,'RL2'      ,'-'        ,'L23'      ,'L24'      ,'L25'      ,'L26'      ,'L27'      ,'L28'      ,'L29'      ,'L210'     ,'L211'     ,'S2'],
        ['ThPjk3'       ,'RL3'      ,'-'        ,'-'        ,'L34'      ,'L35'      ,'L36'      ,'L37'      ,'L38'      ,'L39'      ,'L310'     ,'L311'     ,'S3'],
        ['ThPjk4'       ,'RL4'      ,'-'        ,'-'        ,'-'        ,'L45'      ,'L46'      ,'L47'      ,'L48'      ,'L49'      ,'L410'     ,'L411'     ,'S4'],
        ['ThPjk5'       ,'RL5'      ,'-'        ,'-'        ,'-'        ,'-'        ,'L56'      ,'L57'      ,'L58'      ,'L59'      ,'L510'     ,'L511'     ,'S5'],
        ['ThPjk6'       ,'RL6'      ,'-'        ,'-'        ,'-'        ,'-'        ,'-'        ,'L67'      ,'L68'      ,'L69'      ,'L610'     ,'L611'     ,'S6'],
        ['ThPjk7'       ,'RL7'      ,'-'        ,'-'        ,'-'        ,'-'        ,'-'        ,'-'        ,'L78'      ,'L79'      ,'L710'     ,'L711'     ,'S7'],
        ['ThPjk8'       ,'RL8'      ,'-'        ,'-'        ,'-'        ,'-'        ,'-'        ,'-'        ,'-'        ,'L89'      ,'L810'     ,'L811'     ,'S8'],
        ['ThPjk9'       ,'RL9'      ,'-'        ,'-'        ,'-'        ,'-'        ,'-'        ,'-'        ,'-'        ,'-'        ,'L910'     ,'L911'     ,'S9'],
        ['ThPjk10'      ,'RL10'      ,'-'        ,'-'        ,'-'        ,'-'        ,'-'        ,'-'        ,'-'        ,'-'        ,'-'        ,'L1011'   ,'S10'],
        ['ThPjk11'      ,'RL11'       ,'JmlTh2'    ,'JmlTh3'   ,'JmlTh4'   ,'JmlTh5'   ,'JmlTh6',  'JmlTh7'    ,'JmlTh8'   ,'JmlTh9'   ,'JmlTh10'  ,'JmlThPjk' , 'JmlSisa']
    ]

    const getDefinitions = () => {
        return new Promise(resolve => {
            let result = []
            for(let i = 2 ; i <=11 ; i++){
                result.push({
                    inputType: inputTypes.DIVIDER,
                    label: String(profile.THN_PJK - (12 - i))
                })

                result.push({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(`RL${i-1}`, 'HeaderLampKhus2A'),
                    key: `RL${i-1}`,
                    isNumericString: true,
                    decimalSeparator: '.',
                    thousandSeparator: ',',
                    helpText: `RL${i-1}`
                })

                for(let ia = i ; ia <= 11 ; ia++){
                    let keyL = `L${i-1}${ia}`
                    result.push({
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: getLabel(keyL, 'HeaderLampKhus2A'),
                        key: keyL,
                        isNumericString: true,
                        decimalSeparator: '.',
                        thousandSeparator: ',',
                        disabled: [
                            'L12', 'L13', 'L14', 'L15', 'L16',
                            'L23', 'L24', 'L25', 'L26',
                            'L34', 'L35', 'L36',
                            'L45', 'L46',
                            'L56', 'JmlSisa'
                        ].indexOf(keyL) >= 0,
                        helpText: keyL
                    })
                }

                if(i > 2){
                    let keyJml = `JmlTh${(i-1)}`
                    result.push({
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: getLabel(keyJml, 'HeaderLampKhus2A'),
                        disabled: true,
                        key: keyJml,
                        isNumericString: true,
                        decimalSeparator: '.',
                        thousandSeparator: ','
                    })
                }

                if(i <= 11){
                    let keySisa = `S${i-1}`
                    result.push({
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        label: getLabel(keySisa, 'HeaderLampKhus2A'),
                        key: keySisa,
                        isNumericString: true,
                        decimalSeparator: '.',
                        thousandSeparator: ','
                    })
                }
            }

            result.push({
                inputType: inputTypes.DIVIDER,
                label: getLabel('__jumlah', 'HeaderLampKhus2A')
            })

            result.push({
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: getLabel('JmlThPjk', 'HeaderLampKhus2A'),
                key: 'JmlThPjk',
                isNumericString: true,
                decimalSeparator: '.',
                thousandSeparator: ',',
                disabled: true
            })

            result.push({
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: getLabel('JmlSisa', 'HeaderLampKhus2A'),
                key: 'JmlSisa',
                isNumericString: true,
                decimalSeparator: '.',
                thousandSeparator: ',',
                disabled: true
            })

            setDefinitions(result)
            resolve()
        })
    }

    const getMatrixDefinition = (isManual=false) => {
        return new Promise(resolve => {
            let result = []
            let totalColumn = matrix[0].length
            let width = `${100/totalColumn}%`
            for(let i = 0 ; i < matrix.length ; i++){
                for(let m of matrix[i]){
                    if(m === '-'){
                        result.push({
                            render: (
                                <div style={{width}}></div>
                            )
                        })
                    } else {
                        if(m.includes('ThPjk') && m !== 'JmlThPjk'){
                            result.push({
                                inputType: inputTypes.INPUT,
                                // label: getLabel(m, 'HeaderLampKhus2A'),
                                // label: m,
                                key: m,
                                readOnly: true,
                                className: 'mpk-padding-S padding-right',
                                submitOnBlur: true,
                                style: i > 0 && {
                                    position: 'sticky',
                                    left: 0,
                                    background: 'white',
                                    zIndex: matrix.length
                                },
                                theme: 'none',
                                width
                            })
                        } else if(m.includes('__')){
                            result.push({
                                render: (
                                    <div
                                        style={{
                                            width, 
                                            paddingTop: 11,
                                            position: (m === '__tahun' || m === '__labaRugi') && 'sticky',
                                            left: m === '__tahun' ? 0 : 148,
                                            background: 'white',
                                            zIndex: (m ==='__tahun' || m === '__labaRugi') && (matrix.length+matrix[i].length)
                                        }}
                                    >
                                        {m === '__tahunPajak' ? profile.THN_PJK : t.translate(`column.${m.replace('__', '')}`)}
                                    </div>
                                )
                            })
                        } else {
                            result.push({
                                inputType: inputTypes.INPUT_MASK_NUMBER,
                                // label: getLabel(m, 'HeaderLampKhus2A'),
                                label: m,
                                helpText: m.includes('Jml') && getLabel(m, 'HeaderLampKhus2A'),
                                key: m,
                                isNumericString: true,
                                decimalSeparator: '.',
                                thousandSeparator: ',',
                                disabled: isManual ? false :(!m.startsWith('RL') || m.startsWith('RL11') || (i <= ((10 + (lampKhus4A.Chkd3 ? 0 : 1)) - Number(lampKhus4A.ThRugi)) && !m.startsWith('RL'))),
                                submitOnBlur: true,
                                defaultValue: 1000,
                                width,
                                className: 'mpk-padding-S padding-right',
                                style: m.startsWith('RL') ? {
                                    position: 'sticky',
                                    left: 148,
                                    background: 'white',
                                    zIndex: matrix.length+matrix[i].length
                                } : null,
                                theme: 'underline'
                            })
                        }
                    }
                }
            }
            setDefinitions(result)
            resolve()
        })
    }

    useEffect(async () => {
        // await getDefinitions()
        let __data = await recon()
        let defData = calculate(__data, lampI, lampKhus4A)
        await getMatrixDefinition(defData.isiManual)
        if(defData.isHitungSendiri){
            setIsisiManual(true)
        }else{
            setIsisiManual(false)
        }
        storage.update(skey, defData)

        /*
        let lampKhus4A = storage.get(storage.keys.SPT_1771_LampKhus4A)
        defData.Chkd3 = lampKhus4A.Chkd3
        defData.ThRugi = defData.Chkd3 ? String(lampKhus4A.ThRugi) : '5'
        */

        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <div className="mpk-scrollable mpk-full full-width">
            <DataForm
                baseId="mod-submission-spt-1771-headerLampKhus2A"
                className="mpk-margin-C mpk-animation slide-in"
                style={{
                    maxWidth: 'unset',
                    width: 2000
                }}
                useForm={false}
                hintMessage={props.errorMessage}
                asDialog={false}
                defaultData={defaultData}
                definitions={[
                    {
                        inputType: inputTypes.CHECKBOX,
                        label: 'Isi Manual',
                        key:'isiManual'
                    },
                    // {
                    //     inputType: inputTypes.SELECT,
                    //     label: getLabel('ThRugi', 'LampKhus4A'),
                    //     key:'ThRugi',
                    //     options: ['5', '6','7','8','9','10'],
                    //     width: 480,
                    //     className: 'mpk-margin-N margin-bottom',
                    //     style:{
                    //         position: 'sticky',
                    //         left: 0
                    //     }
                    // },
                    {
                        render:(<div className="mpk-full full-width"/>)
                    },
                    ...definitions
                ]}
                onChange={(data, key, value) => {
                    if(key==='isiManual'){
                        if(value){
                            setIsisiManual(true)
                            getMatrixDefinition(value)
                        }else{
                            setIsisiManual(false)
                            getMatrixDefinition(value)
                        }
                        // setIsisiManual(value)
                    }
                    data = calculate(data, lampI, lampKhus4A)
                    return data
                }}
                onSubmit={async (data, callback) => {
                    await recon(data)
                    await Induk.recon()
                    // storage.update(storage.keys.SPT_1771_LampKhus4A, {
                    //     ThRugi: ThRugi === '5' ? '6' : ThRugi,
                    //     Chkd3: ThRugi === '5' ? 0 : 1
                    // })
                    callback(t.translate('sentence.savedItem'), false) 
                    props.onNextTab()
                }}
                submitLabel={t.translate('column.next')}
            />
        </div>
    ) : <LoaderInfo>Loading data...</LoaderInfo>
}

HeaderLampKhus2A.generate = generate
HeaderLampKhus2A.columns = columns
HeaderLampKhus2A.recon = recon

export default HeaderLampKhus2A
