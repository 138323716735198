import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_HeaderLapKeu
const columns = {
    ID_TEMPLATE:{
        key: 'idTemplate',
        reverseValue: d => String(d)
    }
}

const recon = (
    data = storage.get(skey),
    submitting=true
) => {
    return new Promise(async (resolve) => {
        if(submitting) {
            storage.update(skey, data)
        }
        resolve(data)
    })
}

const generate = async () => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = '1'
        }

        return result
    })())

    return data
}

const HeaderLapKeu = ({
    getLabel,
    onChangeTemplateId,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon()
        
        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-HeaderLapKeu"
            className="mpk-margin-C"
            style={{
                width: '100%',
                maxWidth: 800,
            }}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={Object.keys(columns).map(key=>({
                inputType: inputTypes.SELECT,
                label: getLabel(key, 'HeaderLapKeu'),
                options: props.envStore.env.spt.templateLapKeu,
                key
            }))}
            onSubmit={async (data, callback) => {
                await storage.update(skey,data)
                callback(t.translate('sentence.savedItem'), false)
                onChangeTemplateId(data.ID_TEMPLATE)
            }}
            submitLabel={t.translate('column.save')}
        />
    )
}

HeaderLapKeu.generate = generate
HeaderLapKeu.columns = columns
HeaderLapKeu.recon = recon

export default inject('envStore')(observer(HeaderLapKeu))
