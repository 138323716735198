import React, { useState, useEffect } from "react";
import { DataForm, LoaderInfo } from "../../../libs/react-mpk/components";
import t from "counterpart";
import ProgressBar from "@ramonak/react-progress-bar";
import "./styleLoadingBar.scss";

import * as serviceSubmission from "../Submission.service";
import * as serviceCertificate from "../../Certificate/Certificate.service";

import { inject, observer } from "mobx-react";
import { toast } from "../../../libs/react-mpk/services";
import { find } from "lodash";
import { file, storage } from "../../../services";
import { uid } from "uid";
import moment from "moment";
import renderHTML from "react-render-html";
import { Upload } from "../../Certificate";

const { inputTypes } = DataForm;
const ImportForm = ({
  visible = false,
  onRequestClose = () => {},
  onError = () => {},
  submission = null,
  ...props
}) => {
  const handleSetPdfDefinitions = (code, profile) => {
    profile = profile || storage.get(storage.keys[`SPT_${code}_Profile`]);

    if (profile) {
      switch (code) {
        default:
          return props.envStore.env.pdf[code];
      }
    } else return [];
  };

  const [datas, setDatas] = useState({
    file: null,
    agree: null,
    importOptions: "Lapor SPT",
  });
  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [totalChunk, setTotalChunk] = useState(100);
  const [progressChunk, setProgressChunk] = useState(0);
  const [importOption, setImportOption] = useState("Lapor SPT");

  const onSubmitCommitSpt = async (data, callback) => {
    if (data.agree) {
      setLoadingUpload(true);
      const f = data.file_lapor;
      try {
        const { chunkSize } = props.envStore.env;
        let responseUpload = null;

        setStatusMessage(
          t
            .translate(
              `modules.submission.importForm.commitBulk.statusMessagePdf`
            )
            .replace("*name", f.name)
        );
        let chunking = await file.chunk(f, chunkSize, true, true);
        setTotalChunk(chunking.blobs.length);
        let id = `${moment(props.envStore.env.timestamp).format(
          "YYYYMMDDHHmmss"
        )}${uid(2)}`;
        let uploadId = null;
        for (let i = 0; i < chunking.blobs.length; i++) {
          let bl = chunking.blobs[i];
          let dataSubmit = {
            offset: i,
            size: f.size,
            name: f.name,
          };
          if (i > 0) {
            dataSubmit = { ...dataSubmit, uploadId: uploadId };
          }
          let res = await serviceSubmission.uploadPDFBulk(
            f.name,
            bl,
            dataSubmit
          );
          uploadId = res.data.uploadId;
          setProgressChunk(i + 1);
        }

        let resCommit = await serviceSubmission.postCommitZip({
          name: f.name,
          uploadId: uploadId,
        });
        setTimeout(() => {
          setLoadingUpload(false);
          setTotalChunk(100);
          setProgressChunk(0);
          callback(
            t.translate("modules.submission.importForm.commitBulk.success"),
            false
          );
        }, 1000);
      } catch (error) {
        setLoadingUpload(false);
        callback(error, true, false);
      }
    } else {
      callback(
        new Error(t.translate("modules.submission.importForm.notAgreeAlert")),
        true,
        false
      );
    }
  };

  const onSubmitCertificate = async (data, callback) => {
    setLoadingUpload(true);
    const file_certificate = data.file_certificate;
    const file_password = data.file_password;
    try {
      const { chunkSize } = props.envStore.env;

      //UPLOAD CERT
      setStatusMessage(
        t
          .translate(`modules.submission.importForm.certificate.statusMessage`)
          .replace("*name", file_certificate.name)
      );
      let chunkingCert = await file.chunk(
        file_certificate,
        chunkSize,
        true,
        true
      );
      setTotalChunk(chunkingCert.blobs.length);
      let uploadIdSertel = null;
      for (let i = 0; i < chunkingCert.blobs.length; i++) {
        let bl = chunkingCert.blobs[i];
        let dataSubmit = {
          offset: i,
          size: file_certificate.size,
          name: file_certificate.name,
        };
        if (i > 0) {
          dataSubmit = { ...dataSubmit, uploadId: uploadIdSertel };
        }
        let res = await serviceCertificate.uploadCertificateBulk(
          file_certificate.name,
          bl,
          dataSubmit
        );
        uploadIdSertel = res.data.uploadId;
        setProgressChunk(i + 1);
      }

      setTimeout(() => {
        setTotalChunk(100);
        setProgressChunk(0);
      }, 500);
      //UPLOAD CSV
      setStatusMessage(
        t
          .translate(`modules.submission.importForm.certificate.statusMessage`)
          .replace("*name", file_password.name)
      );
      let chunkingPassword = await file.chunk(
        file_password,
        1024 * 1000 * 100, // 100mb
        true,
        true
      );
      setTotalChunk(chunkingPassword.blobs.length);
      let referenceNoPassphrase = null;
      for (let i = 0; i < chunkingPassword.blobs.length; i++) {
        let bl = chunkingPassword.blobs[i];
        let dataSubmit = {
          offset: i,
          size: file_password.size,
          name: file_password.name,
        };
        if (i > 0) {
          dataSubmit = { ...dataSubmit };
        }
        let res = await serviceCertificate.uploadPassBulk(
          file_password.name,
          bl,
          dataSubmit
        );
        referenceNoPassphrase = res.data.referenceNo;
        setProgressChunk(i + 1);
      }

      let resCommit = await serviceCertificate.postCommitZip({
        uploadIdSertel: uploadIdSertel,
        zipName: file_certificate.name,
        referenceNoPassphrase: referenceNoPassphrase,
      });
      setTimeout(() => {
        setLoadingUpload(false);
        setTotalChunk(100);
        setProgressChunk(0);
        callback(
          t.translate("modules.submission.importForm.certificate.success"),
          false
        );
      }, 1000);
    } catch (error) {
      setLoadingUpload(false);
      callback(error, true, false);
    }
  };

  useEffect(async () => {
    setLoading(true);
    if (visible) {
      try {
        setLoading(false);
        setDatas({
          file: null,
          agree: null,
          importOptions: "Lapor SPT",
        });
      } catch (error) {
        toast.errorRequest(error);
        onRequestClose();
      }
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [visible]);

  return (
    <>
      <DataForm
        baseId="mod-submission-import-form"
        title={t.translate("modules.submission.importForm.title")}
        hintMessage={t.translate("modules.submission.importForm.hint")}
        asDialog={true}
        visible={visible}
        onRequestClose={onRequestClose}
        defaultData={datas}
        style={{ width: "100%", maxWidth: 1024 }}
        definitions={
          loading
            ? [
                {
                  render: (
                    <LoaderInfo>
                      {t.translate("sentence.loadingData")}
                    </LoaderInfo>
                  ),
                },
              ]
            : [
                {
                  inputType: inputTypes.SELECT,
                  label: "Pilih Import",
                  key: "importOptions",
                  options: [
                    { label: "Lapor SPT", value: "Lapor SPT" },
                    { label: "Certificate", value: "Certificate" },
                  ],
                  required: true,
                },
                {
                  inputType: inputTypes.FILE_INPUT,
                  label: "File Lapor",
                  key: "file_lapor",
                  // value: defaultValue,
                  required: (item) => item.importOptions === "Lapor SPT",
                  placeholder: "Upload .zip file",
                  accept: ".zip",
                  style: { width: "20%" },
                  show: (item) => item.importOptions === "Lapor SPT",
                },
                {
                  inputType: inputTypes.CHECKBOX,
                  label: t.translate(
                    "modules.submission.importForm.commitBulk.submitHint"
                  ),
                  className: "mpk-margin-N margin-top",
                  key: "agree",
                  show: (item) => item.importOptions === "Lapor SPT",
                },
                {
                  inputType: inputTypes.FILE_INPUT,
                  label: "File Certificate",
                  key: "file_certificate",
                  required: (item) => item.importOptions !== "Lapor SPT",
                  placeholder: "Upload .zip file",
                  accept: ".zip",
                  style: { width: "20%" },
                  show: (item) => item.importOptions !== "Lapor SPT",
                },
                {
                  inputType: inputTypes.FILE_INPUT,
                  label: "File Password",
                  key: "file_password",
                  required: (item) => item.importOptions !== "Lapor SPT",
                  placeholder: "Upload .csv file",
                  accept: ".csv",
                  style: { width: "20%" },
                  show: (item) => item.importOptions !== "Lapor SPT",
                },
                {
                  render: loadingUpload ? (
                    <div style={{ width: "100%" }}>
                      <ProgressBar
                        completed={parseInt((progressChunk / totalChunk) * 100)}
                        maxCompleted={100}
                        bgColor={"#c3362b"}
                      />
                    </div>
                  ) : null,
                },
                {
                  render: loadingUpload ? (
                    <div style={{ width: "100%" }}>{statusMessage}</div>
                  ) : null,
                },
              ]
        }
        onBeforeChange={(key, value) => {
          return new Promise(async (resolve, reject) => {
            try {
              if (value) {
                if (key === "importOptions") {
                  setImportOption(value);
                }
                resolve(value);
              } else {
                resolve(value);
              }
            } catch (error) {
              toast.errorRequest(error);
            }
          });
        }}
        onChange={(data, key) => {
          setDatas(data);
          return data;
        }}
        onSubmit={
          importOption === "Lapor SPT" ? onSubmitCommitSpt : onSubmitCertificate
        }
        submitLabel={t.translate("column.submit")}
      />
    </>
  );
};

export default inject(
  "envStore",
  "navigationStore",
  "temporaryStore",
  "modalStore",
  "authStore"
)(observer(ImportForm));
