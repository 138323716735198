import React, { useState, useEffect } from "react";
import { TableWrapper, TabsWrapper } from "../../../libs/react-mpk/wrapper";
import { DataForm, LoaderInfo } from "../../../libs/react-mpk/components";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  List,
  ListItem,
} from "react-md";
import moment from "moment";
import t from "counterpart";

import { inject, observer } from "mobx-react";
import { toast } from "../../../libs/react-mpk/services";
import service from "./LogImport.service";

const baseId = "mod-log-import-list-detail";
const statusTypes = TableWrapper.Status.types;

const { inputTypes } = DataForm;
const LogImportDetail = ({
  visible = false,
  onRequestClose = () => {},
  onError = () => {},
  item = null,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    if (visible) {
      try {
        TableWrapper.reload(baseId);
        setLoading(false);
      } catch (error) {
        toast.errorRequest(error);
        onRequestClose();
      }
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [visible]);

  return !loading && item ? (
    <>
      <Dialog
        visible={visible}
        onRequestClose={() => {}}
        style={{
          width: 1000,
          height: 1000,
        }}
      >
        <DialogContent style={{ marginTop: 0 }}>
          <div style={{ fontSize: 21, fontWeight: "bold" }}>Detail</div>
          <List>
            <ListItem primaryText="Reference" secondaryText={item.reference} />
            <ListItem primaryText="Category" secondaryText={item.category} />
            <ListItem primaryText="Status" secondaryText={item.status} />
            {item.errorMessage != null && (
              <ListItem
                primaryText="Pesan Error"
                secondaryText={item.errorMessage}
              />
            )}

            <ListItem
              primaryText="Start At"
              secondaryText={moment(item.startAt).format("lll")}
            />
            <ListItem
              primaryText="End At"
              secondaryText={
                item.endAt ? moment(item.endAt).format("lll") : "-"
              }
            />
            <ListItem primaryText="Created By" secondaryText={item.createdBy} />
            <ListItem
              primaryText="Created Date"
              secondaryText={moment(item.createdAt).format("lll")}
            />
          </List>
          <TableWrapper
            baseId={baseId}
            title={t.translate("modules.logImport.title")}
            className={`mpk-padding-N padding-top padding-right`}
            // hintMessage={t.translate("modules.logImport.hint.message")}
            hintIconClassName="mdi mdi-information"
            defaultData={[]}
            defaultSortBy="createdAt"
            defaultSort={TableWrapper.sorts.descending}
            defaultQuery={{ column: "fileName" }}
            columns={[
              {
                label: t.translate("column.fileName"),
                key: "fileName",
                searchable: true,
                render: (item) => item.fileName,
              },
              {
                label: "Status",
                key: "status",
                searchable: true,
                render: (item) => {
                  return (
                    <div>
                      <TableWrapper.Status
                        type={
                          item.status === "ERROR"
                            ? statusTypes.ERROR
                            : item.status === "READY"
                            ? statusTypes.SUCCESS
                            : statusTypes.PROGRESS
                        }
                        // onClick={stat.onClick}
                      >
                        {item.status}
                      </TableWrapper.Status>
                    </div>
                  );
                },
              },
              {
                label: "Pesan Error",
                key: "errorMessage",
                searchable: true,
                render: (item) => (item.errorMessage ? item.errorMessage : "-"),
              },
              {
                label: "Reference No",
                key: "referenceNo",
                searchable: false,
                render: (item) => (item.referenceNo ? item.referenceNo : "-"),
              },
            ]}
            // itemActions={itemActions}
            onFetchData={async (query) => {
              return new Promise(async (resolve, reject) => {
                try {
                  let res = await service.getDetailByReference(
                    item.category,
                    item.reference,
                    query
                  );
                  resolve(res);
                } catch (error) {
                  reject(error);
                }
              });
              return service.get(query);
            }}
            useFilter={false}
            useCriteria={false}
            showCommandbar={true}
            showFilterPeriod={false}
            defaultSize={10}
            showActionColumn={false}
          />
        </DialogContent>
        <DialogFooter>
          <Button
            id="dialog-close"
            onClick={onRequestClose}
            style={{ marginRight: 5 }}
          >
            Close
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  ) : null;
};

export default inject(
  "envStore",
  "navigationStore",
  "temporaryStore",
  "modalStore",
  "authStore"
)(observer(LogImportDetail));
