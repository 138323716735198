import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const { inputTypes } = DataForm
const columns = {
    TglLaporSPT:'tglLaporSpt'
}

const Footer = ({
    data,
    handleUpdate,
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        setDefaultData(data)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-HeaderLapKeu"
            className="mpk-margin-C"
            style={{
                width: '100%',
                maxWidth: 800,
            }}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                {
                    inputType: inputTypes.INPUT,
                    label: getLabel('__kota', 'Induk'),
                    key: '__kota',
                    required: false,
                    width: '50%',
                },
                {
                    inputType: inputTypes.DATE,
                    label: getLabel('TglLaporSPT', 'Induk'),
                    key: 'TglLaporSPT',
                    required: true,
                    width: '50%',
                    className:'mpk-padding-N padding-left'
                }
            ]}
            onSubmit={async (__data, callback) => {
                handleUpdate({
                    TglLaporSPT: __data.TglLaporSPT,
                    __kota: __data.__kota
                })
                props.onNextTab()
            }}
            submitLabel={t.translate('column.next')}
        />
    )
}

export default inject('envStore')(observer(Footer))
