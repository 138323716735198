import React, {useState, useEffect} from 'react'
import { ExpansionList, ExpansionPanel, Label, usePanels } from 'react-md'
import { Hint } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import HeaderLapKeu from './HeaderLapKeu'
import HubIstimewa1 from './HubIstimewa1'
import HubIstimewa11 from './HubIstimewa11'
import HubIstimewa13 from './HubIstimewa13'
import HubIstimewa15 from './HubIstimewa15'
import HubIstimewa3 from './HubIstimewa3'
import HubIstimewa5 from './HubIstimewa5'
import HubIstimewa7 from './HubIstimewa7'
import HubIstimewa9 from './HubIstimewa9'
import Labarugi1 from './Labarugi1'
import Labarugi11 from './Labarugi11'
import Labarugi13 from './Labarugi13'
import Labarugi15 from './Labarugi15'
import Labarugi3 from './Labarugi3'
import Labarugi5 from './Labarugi5'
import Labarugi7 from './Labarugi7'
import Labarugi9 from './Labarugi9'
import Neraca1 from './Neraca1'
import Neraca11 from './Neraca11'
import Neraca13 from './Neraca13'
import Neraca15 from './Neraca15'
import Neraca3 from './Neraca3'
import Neraca5 from './Neraca5'
import Neraca7 from './Neraca7'
import Neraca9 from './Neraca9'

const generate = (isReset=false) => (new Promise(async resolve => {
    await Neraca1.generate(isReset)
    await Neraca3.generate(isReset)
    await Neraca5.generate(isReset)
    await Neraca7.generate(isReset)
    await Neraca9.generate(isReset)
    await Neraca11.generate(isReset)
    await Neraca13.generate(isReset)
    await Neraca15.generate(isReset)
    
    await Labarugi1.generate(isReset)
    await Labarugi3.generate(isReset)
    await Labarugi5.generate(isReset)
    await Labarugi7.generate(isReset)
    await Labarugi9.generate(isReset)
    await Labarugi11.generate(isReset)
    await Labarugi13.generate(isReset)
    await Labarugi15.generate(isReset)

    await HubIstimewa1.generate(isReset)
    await HubIstimewa3.generate(isReset)
    await HubIstimewa5.generate(isReset)
    await HubIstimewa7.generate(isReset)
    await HubIstimewa9.generate(isReset)
    await HubIstimewa11.generate(isReset)
    await HubIstimewa13.generate(isReset)
    await HubIstimewa15.generate(isReset)
    
    await HeaderLapKeu.generate()
    await HeaderLapKeu.recon()
    resolve()
}))

const LapKeu = ({
    getLabel,
    onNextTab,
    ...props
}) => {
    const headerLapKeu = storage.get(storage.keys.SPT_1771_HeaderLapKeu)
    const [ready, setReady] = useState(false)
    const [currentTemplateId, setCurrentTemplateId] = useState(headerLapKeu.ID_TEMPLATE)
    const [panels, onKeyDown1] = usePanels({
        idPrefix: 'spt-1771-LapKeu-1',
        count:4,
        // defaultExpandedIndex: 0
    })
    const [panels3, onKeyDown3] = usePanels({
        idPrefix: 'spt-1771-LapKeu-3',
        count:3,
        // defaultExpandedIndex: 0
    })
    const [panels5, onKeyDown5] = usePanels({
        idPrefix: 'spt-1771-LapKeu-5',
        count:3,
        // defaultExpandedIndex: 0
    })
    const [panels7, onKeyDown7] = usePanels({
        idPrefix: 'spt-1771-LapKeu-7',
        count:3,
        // defaultExpandedIndex: 0
    })
    const [panels9, onKeyDown9] = usePanels({
        idPrefix: 'spt-1771-LapKeu-9',
        count:3,
        // defaultExpandedIndex: 0
    })
    const [panels11, onKeyDown11] = usePanels({
        idPrefix: 'spt-1771-LapKeu-11',
        count:3,
        // defaultExpandedIndex: 0
    })
    const [panels13, onKeyDown13] = usePanels({
        idPrefix: 'spt-1771-LapKeu-13',
        count:3,
        // defaultExpandedIndex: 0
    })
    const [panels15, onKeyDown15] = usePanels({
        idPrefix: 'spt-1771-LapKeu-15',
        count:3,
        // defaultExpandedIndex: 0
    })

    useEffect(() => {
        setReady(true)
    }, [])

    const [panel1Props, panel2Props, panel3Props, panel4Props] = panels;

    return ready && (
        <div
            className="mpk-margin-C mpk-animation slide-in mpk-padding-N padding-bottom"
            style={{
                width: '100%',
                maxWidth: 1024
            }}
        >
            {props.errorMessage && 
                <div className="mpk-paper mpk-padding-N padding-all">
                    <Hint>{props.errorMessage}</Hint>
                </div>
            }
            {/* <HeaderLapKeu 
                {...{getLabel, onNextTab}}
                onChangeTemplateId={(val) => setCurrentTemplateId(val)}
            /> */}
            <ExpansionList 
                onKeyDown={onKeyDown1}
                className="mpk-margin-N margin-top"
            >   
                {(() => {
                    switch(Number(currentTemplateId)){
                        case 1:
                            return [
                                {
                                    label: getLabel('__titleActiva', 'LapKeu'),
                                    render: <Neraca1 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel1Props
                                },
                                {
                                    label: getLabel('__titleKewajiban', 'LapKeu'),
                                    render: <Neraca1 {...{getLabel, onNextTab}} asKewajiban={true}/>,
                                    panelProps: panel2Props
                                },
                                {
                                    label: getLabel('__titleLabaRugi', 'LapKeu'),
                                    render: <Labarugi1 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel3Props
                                },
                                {
                                    label: getLabel('__titleHubIstimewa', 'LapKeu'),
                                    render: <HubIstimewa1 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel4Props
                                }
                            ]
                        case 3:
                            return [
                                {
                                    label: getLabel('__titleActiva', 'LapKeu'),
                                    render: <Neraca3 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel1Props
                                },
                                {
                                    label: getLabel('__titleKewajiban', 'LapKeu'),
                                    render: <Neraca3 {...{getLabel, onNextTab}} asKewajiban={true}/>,
                                    panelProps: panel2Props
                                },
                                {
                                    label: getLabel('__titleLabaRugi', 'LapKeu'),
                                    render: <Labarugi3 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel3Props
                                },
                                {
                                    label: getLabel('__titleHubIstimewa', 'LapKeu'),
                                    render: <HubIstimewa3 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel4Props
                                },
                            ]
                        case 5:
                            return [
                                {
                                    label: getLabel('__titleActiva', 'LapKeu'),
                                    render: <Neraca5 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel1Props
                                },
                                {
                                    label: getLabel('__titleKewajiban', 'LapKeu'),
                                    render: <Neraca5 {...{getLabel, onNextTab}} asKewajiban={true}/>,
                                    panelProps: panel2Props
                                },
                                {
                                    label: getLabel('__titleLabaRugi', 'LapKeu'),
                                    render: <Labarugi5 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel3Props
                                },
                                {
                                    label: getLabel('__titleHubIstimewa', 'LapKeu'),
                                    render: <HubIstimewa5 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel4Props
                                },
                            ]
                        case 7:
                            return [
                                {
                                    label: getLabel('__titleActiva', 'LapKeu'),
                                    render: <Neraca7 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel1Props
                                },
                                {
                                    label: getLabel('__titleKewajiban', 'LapKeu'),
                                    render: <Neraca7 {...{getLabel, onNextTab}} asKewajiban={true}/>,
                                    panelProps: panel2Props
                                },
                                {
                                    label: getLabel('__titleLabaRugi', 'LapKeu'),
                                    render: <Labarugi7 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel3Props
                                },
                                {
                                    label: getLabel('__titleHubIstimewa', 'LapKeu'),
                                    render: <HubIstimewa7 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel4Props
                                },
                            ]
                        case 9:
                            return [
                                {
                                    label: getLabel('__titleActiva', 'LapKeu'),
                                    render: <Neraca9 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel1Props
                                },
                                {
                                    label: getLabel('__titleKewajiban', 'LapKeu'),
                                    render: <Neraca9 {...{getLabel, onNextTab}} asKewajiban={true}/>,
                                    panelProps: panel2Props
                                },
                                {
                                    label: getLabel('__titleLabaRugi', 'LapKeu'),
                                    render: <Labarugi9 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel3Props
                                },
                                {
                                    label: getLabel('__titleHubIstimewa', 'LapKeu'),
                                    render: <HubIstimewa9 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel4Props
                                },
                            ]
                        case 11:
                            return [
                                {
                                    label: getLabel('__titleActiva', 'LapKeu'),
                                    render: <Neraca11 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel1Props
                                },
                                {
                                    label: getLabel('__titleKewajiban', 'LapKeu'),
                                    render: <Neraca11 {...{getLabel, onNextTab}} asKewajiban={true}/>,
                                    panelProps: panel2Props
                                },
                                {
                                    label: getLabel('__titleLabaRugi', 'LapKeu'),
                                    render: <Labarugi11 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel3Props
                                },
                                {
                                    label: getLabel('__titleHubIstimewa', 'LapKeu'),
                                    render: <HubIstimewa11 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel4Props
                                },
                            ]
                        case 13:
                            return [
                                {
                                    label: getLabel('__titleActiva', 'LapKeu'),
                                    render: <Neraca13 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel1Props
                                },
                                {
                                    label: getLabel('__titleKewajiban', 'LapKeu'),
                                    render: <Neraca13 {...{getLabel, onNextTab}} asKewajiban={true}/>,
                                    panelProps: panel2Props
                                },
                                {
                                    label: getLabel('__titleLabaRugi', 'LapKeu'),
                                    render: <Labarugi13 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel3Props
                                },
                                {
                                    label: getLabel('__titleHubIstimewa', 'LapKeu'),
                                    render: <HubIstimewa13 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel4Props
                                },
                            ]
                        case 15:
                            return [
                                {
                                    label: getLabel('__titleActiva', 'LapKeu'),
                                    render: <Neraca15 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel1Props
                                },
                                {
                                    label: getLabel('__titleKewajiban', 'LapKeu'),
                                    render: <Neraca15 {...{getLabel, onNextTab}} asKewajiban={true}/>,
                                    panelProps: panel2Props
                                },
                                {
                                    label: getLabel('__titleLabaRugi', 'LapKeu'),
                                    render: <Labarugi15 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel3Props
                                },
                                {
                                    label: getLabel('__titleHubIstimewa', 'LapKeu'),
                                    render: <HubIstimewa15 {...{getLabel, onNextTab}}/>,
                                    panelProps: panel4Props
                                },
                            ]
                        default:
                            return []
                    }
                })().map((p, i) => (
                    <ExpansionPanel 
                        key={`spt-1771-LapKeu-panel-${i}`}
                        {...p.panelProps}
                        header={<div className="mpk-font size-M weight-M">{p.label}</div>}
                    >
                        {p.render}
                    </ExpansionPanel>
                ))}
            </ExpansionList>


            {/* {[
                {
                    onKeyDown: onKeyDown1,
                    title:getLabel('__title1','LapKeu'),
                    components:[
                        {
                            label: getLabel('__title', 'Neraca1'),
                            render: <Neraca1 {...{getLabel, onNextTab}}/>,
                            panelProps: panel1Props1
                        },
                        {
                            label: getLabel('__title', 'Labarugi1'),
                            render: <Labarugi1 {...{getLabel, onNextTab}}/>,
                            panelProps: panel2Props1
                        },
                        {
                            label: getLabel('__title', 'HubIstimewa1'),
                            render: <HubIstimewa1 {...{getLabel, onNextTab}}/>,
                            panelProps: panel3Props1
                        }
                    ]
                },
                {
                    onKeyDown: onKeyDown3,
                    title:getLabel('__title3','LapKeu'),
                    components:[
                        {
                            label: getLabel('__title', 'Neraca3'),
                            render: <Neraca3 {...{getLabel, onNextTab}}/>,
                            panelProps: panel1Props3
                        },
                        {
                            label: getLabel('__title', 'Labarugi3'),
                            render: <Labarugi3 {...{getLabel, onNextTab}}/>,
                            panelProps: panel2Props3
                        },
                        {
                            label: getLabel('__title', 'HubIstimewa3'),
                            render: <HubIstimewa3 {...{getLabel, onNextTab}}/>,
                            panelProps: panel3Props3
                        },
                    ]
                },
                {
                    onKeyDown: onKeyDown5,
                    title:getLabel('__title5','LapKeu'),
                    components:[
                        {
                            label: getLabel('__title', 'Neraca5'),
                            render: <Neraca5 {...{getLabel, onNextTab}}/>,
                            panelProps: panel1Props5
                        },
                        {
                            label: getLabel('__title', 'Labarugi5'),
                            render: <Labarugi5 {...{getLabel, onNextTab}}/>,
                            panelProps: panel2Props5
                        },
                        {
                            label: getLabel('__title', 'HubIstimewa5'),
                            render: <HubIstimewa5 {...{getLabel, onNextTab}}/>,
                            panelProps: panel3Props5
                        },
                    ]
                },
                {
                    onKeyDown: onKeyDown7,
                    title:getLabel('__title7','LapKeu'),
                    components:[
                        {
                            label: getLabel('__title', 'Neraca7'),
                            render: <Neraca7 {...{getLabel, onNextTab}}/>,
                            panelProps: panel1Props7
                        },
                        {
                            label: getLabel('__title', 'Labarugi7'),
                            render: <Labarugi7 {...{getLabel, onNextTab}}/>,
                            panelProps: panel2Props7
                        },
                        {
                            label: getLabel('__title', 'HubIstimewa7'),
                            render: <HubIstimewa7 {...{getLabel, onNextTab}}/>,
                            panelProps: panel3Props7
                        },
                    ]
                },
                {
                    onKeyDown: onKeyDown9,
                    title:getLabel('__title9','LapKeu'),
                    components:[
                        {
                            label: getLabel('__title', 'Neraca9'),
                            render: <Neraca9 {...{getLabel, onNextTab}}/>,
                            panelProps: panel1Props9
                        },
                        {
                            label: getLabel('__title', 'Labarugi9'),
                            render: <Labarugi9 {...{getLabel, onNextTab}}/>,
                            panelProps: panel2Props9
                        },
                        {
                            label: getLabel('__title', 'HubIstimewa9'),
                            render: <HubIstimewa9 {...{getLabel, onNextTab}}/>,
                            panelProps: panel3Props9
                        },
                    ]
                },
                {
                    onKeyDown: onKeyDown11,
                    title:getLabel('__title11','LapKeu'),
                    components:[
                        {
                            label: getLabel('__title', 'Neraca11'),
                            render: <Neraca11 {...{getLabel, onNextTab}}/>,
                            panelProps: panel1Props11
                        },
                        {
                            label: getLabel('__title', 'Labarugi11'),
                            render: <Labarugi11 {...{getLabel, onNextTab}}/>,
                            panelProps: panel2Props11
                        },
                        {
                            label: getLabel('__title', 'HubIstimewa11'),
                            render: <HubIstimewa11 {...{getLabel, onNextTab}}/>,
                            panelProps: panel3Props11
                        },
                    ]
                },
                {
                    onKeyDown: onKeyDown13,
                    title:getLabel('__title13','LapKeu'),
                    components:[
                        {
                            label: getLabel('__title', 'Neraca13'),
                            render: <Neraca13 {...{getLabel, onNextTab}}/>,
                            panelProps: panel1Props13
                        },
                        {
                            label: getLabel('__title', 'Labarugi13'),
                            render: <Labarugi13 {...{getLabel, onNextTab}}/>,
                            panelProps: panel2Props13
                        },
                        {
                            label: getLabel('__title', 'HubIstimewa13'),
                            render: <HubIstimewa13 {...{getLabel, onNextTab}}/>,
                            panelProps: panel3Props13
                        },
                    ]
                },
                {
                    onKeyDown: onKeyDown15,
                    title:getLabel('__title15','LapKeu'),
                    components:[
                        {
                            label: getLabel('__title', 'Neraca15'),
                            render: <Neraca15 {...{getLabel, onNextTab}}/>,
                            panelProps: panel1Props15
                        },
                        {
                            label: getLabel('__title', 'Labarugi15'),
                            render: <Labarugi15 {...{getLabel, onNextTab}}/>,
                            panelProps: panel2Props15
                        },
                        {
                            label: getLabel('__title', 'HubIstimewa15'),
                            render: <HubIstimewa15 {...{getLabel, onNextTab}}/>,
                            panelProps: panel3Props15
                        },
                    ]
                }
            ].map((d, i) => (
                <div>
                    <Label className="mpk-margin-N margin-top">{d.title}</Label>
                    <ExpansionList 
                        onKeyDown={onKeyDown1}
                        className="mpk-margin-N margin-top"
                        key={`lap-keu-index-${i}`}
                    >   
                        {d.components.map((p, i) => (
                            <ExpansionPanel 
                                key={`spt-1771-LapKeu-panel-${i}`}
                                {...p.panelProps}
                                header={<div className="mpk-font size-M weight-M">{p.label}</div>}
                            >
                                {p.render}
                            </ExpansionPanel>
                        ))}
                    </ExpansionList>
                </div>
            ))} */}
        </div>
    )
}

LapKeu.generate = generate

export default LapKeu
