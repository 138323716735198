import React, { Component } from 'react';
import { ErrorPage } from '../libs/react-mpk/components';
import queryString from 'query-string';

class Error extends Component{
  constructor(){
    super();
    this.state = {}
  }

  componentDidMount(){
    let parsedUrl = queryString.parseUrl(window.location.href);
    this.setState(parsedUrl.query);
  }

  render(){
    let { status, statusText, errorMessage, redirectUri } = this.state;
    return(
      <ErrorPage
        status={status}
        statusText={statusText}
        errorMessage={errorMessage}
        redirectUri={redirectUri}
      />
    )
  }
}

export default Error;