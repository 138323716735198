import React from 'react'
import './DividerLabel.scss'

const DividerLabel = ({
    className       = '',
    children        = null,
    ...props
}) => {
    return (
        <div
            className={`mpk-divider-label mpk-margin-N margin-bottom mpk-font weight-B ${className}`}
            {...props}
        >
            {children}
        </div>
    )
}

export default DividerLabel
