import React from "react";
import { Button, DropdownMenu, FontIcon } from "react-md";
import Flex from "../Flex";

const Pagination = ({
  baseId = "mpk-pagination-id",
  page = 1,
  size = 20,
  sizeOptions = [5, 10, 20, 50, 100],
  totalData = 0,
  justify = Flex.properties.justify.END,
  onChange = () =>
    console.log(
      "[react-mpk][component][pagination] - The onChange props has not been defined yet"
    ),
  showSizeOptions = true,
  showFullPagination = true,
  ...props
}) => {
  page = Number(page);
  size = Number(size);
  const offset = (page - 1) * size;

  const start = offset + 1;
  let end = offset + size;

  end = end > totalData ? totalData : end;
  const totalPage = Math.ceil(totalData / size);

  const listPage = new Array(totalPage || 1).fill(true).map((e, i) => i + 1);

  return (
    <Flex
      className="mpk-pagination weight-B"
      align={Flex.properties.align.CENTER}
      justify={justify}
      {...props}
    >
      <div className="mpk-margin-N margin-right">
        Halaman :
        <DropdownMenu
          id={`${baseId}-select-page-menu`}
          items={listPage.map((d) => ({
            children: d,
            onClick: () => onChange({ page: d }),
          }))}
          theme="primary"
          style={{ width: "20px" }}
          buttonType="text"
          menuStyle={{ maxHeight: "30vh" }}
        >
          {`${page}`}
        </DropdownMenu>
      </div>
      <div className="mpk-margin-N margin-right">{`${start} - ${end} of ${totalData}`}</div>
      {showSizeOptions && (
        <DropdownMenu
          id={`${baseId}-size-menu`}
          disableDropdownIcon
          items={sizeOptions.map((d) => ({
            children: d,
            onClick: () => onChange({ page: 1, size: d }),
          }))}
          theme="primary"
        >
          {`${size} rows/page`}
        </DropdownMenu>
      )}
      {showFullPagination && (
        <Button
          theme="primary"
          disabled={page === 1}
          onClick={() => onChange({ page: 1 })}
        >
          <FontIcon iconClassName="mdi mdi-arrow-collapse-left" />
        </Button>
      )}

      <Button
        theme="primary"
        disabled={page === 1}
        onClick={() => onChange({ page: page - 1, size })}
      >
        <FontIcon iconClassName="mdi mdi-arrow-left" />
      </Button>
      <Button
        theme="primary"
        disabled={totalPage <= 1 || page === totalPage}
        onClick={() => onChange({ page: page + 1, size })}
      >
        <FontIcon iconClassName="mdi mdi-arrow-right" />
      </Button>
      {showFullPagination && (
        <Button
          theme="primary"
          disabled={totalPage <= 1 || page === totalPage}
          onClick={() => onChange({ page: totalPage })}
        >
          <FontIcon iconClassName="mdi mdi-arrow-collapse-right" />
        </Button>
      )}
    </Flex>
  );
};

Pagination.justifies = Flex.properties.justify;

export default Pagination;
