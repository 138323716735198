import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { rules, storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const { inputTypes } = DataForm
const recon = (
    data = storage.get(storage.keys.SPT_1771_Induk)
) => {
    return new Promise(resolve => {
        resolve(data)
    })
}

const Checklist = ({
    data,
    handleUpdate,
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        setDefaultData(data)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-Checklist"
            className="mpk-margin-C"
            style={{
                width: '100%',
                maxWidth: 800,
            }}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                ...[
                    "OptD16",
                    "chkH17a",
                    "chkH17b",
                    "chkH17c",
                    "chkH17d",
                    "chkH17e",
                    "chkH17f",
                    "chkH17g",
                    "chkH17h",
                    "chkH17i",
                    "chkH17j",
                    "chkH17k",
                ].map(key => ({
                    inputType: inputTypes.CHECKBOX,
                    label: getLabel(key, 'Induk'),
                    key
                })),
                {
                    inputType: inputTypes.CHECKBOX,
                    label: getLabel('chkH17l', 'Induk'),
                    key: 'chkH17l',
                    width: '25%'
                },
                {
                    inputType: inputTypes.INPUT,
                    label: getLabel('vHl', 'Induk'),
                    key: 'vHl',
                    width: '75%',
                    validation: d => (d.chkH17l ? 'required' : 'min:0')
                },
                {
                    inputType: inputTypes.CHECKBOX,
                    label: getLabel('chkH17m', 'Induk'),
                    key: 'chkH17m',
                    width: '25%'
                },
                {
                    inputType: inputTypes.INPUT,
                    label: getLabel('vHm', 'Induk'),
                    key: 'vHm',
                    width: '75%',
                    validation: d => (d.chkH17m ? 'required' : 'min:0')
                },
                {
                    inputType: inputTypes.CHECKBOX,
                    label: getLabel('chkH17n', 'Induk'),
                    key: 'chkH17n',
                    width: '25%'
                },
                {
                    inputType: inputTypes.INPUT,
                    label: getLabel('vHn', 'Induk'),
                    key: 'vHn',
                    width: '75%',
                    validation: d => (d.chkH17n ? 'required' : 'min:0')
                },
                // {
                //     inputType: inputTypes.SELECT,
                //     label: getLabel('FlgTtdSPT', 'Induk'),
                //     key: 'FlgTtdSPT',
                //     options:[
                //         {label: 'PKP', value:'1'},
                //         {label: 'Kuasa', value: '2'}
                //     ]
                // }
            ]}
            onSubmit={async (__data, callback) => {
                const {
                    OptD16,
                    chkH17a,
                    chkH17b,
                    chkH17c,
                    chkH17d,
                    chkH17e,
                    chkH17f,
                    chkH17g,
                    chkH17h,
                    chkH17i,
                    chkH17j,
                    chkH17k,
                    chkH17l,
                    chkH17m,
                    chkH17n,
                    vHl,
                    vHm,
                    vHn
                } = __data
                
                await handleUpdate({
                    OptD16,
                    chkH17a,
                    chkH17b,
                    chkH17c,
                    chkH17d,
                    chkH17e,
                    chkH17f,
                    chkH17g,
                    chkH17h,
                    chkH17i,
                    chkH17j,
                    chkH17k,
                    chkH17l,
                    chkH17m,
                    chkH17n,
                    vHl,
                    vHm,
                    vHn
                })

                callback(t.translate('sentence.savedItem'), false)
                // props.onNextTab()
            }}
            // submitLabel={t.translate('column.next')}
        />
    )
}


Checklist.recon = recon

export default inject('envStore')(observer(Checklist))
