import React from 'react';
import { CircularProgress } from 'react-md';
import Flex from '../Flex';

const LoaderInfo = ({
  loaderId     = 'mpk-loader-info',
  className    = '',
  style        = {},
  children     = null
}) => (
  <Flex 
    className={`${className} mpk-padding-N padding-all`}
    align={Flex.properties.align.CENTER}
    style={style}
  >
    <div className="mpk-margin-N margin-right">
      <CircularProgress small id={loaderId}/>
    </div>
    {children}
  </Flex>
)

export default LoaderInfo;
