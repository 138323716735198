import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const skey = storage.keys.SPT_1771_LampKhus3A1

const { inputTypes } = DataForm

const columns = {
    IDI1A: 'idi1a',
    IDI1B: 'idi1b',
    IDI1C: 'idi1c',
    IDI1D: 'idi1d',
    IDI2A: 'idi2a',
    IDI2B: 'idi2b',
    IDI2C: 'idi2c',
    IDI3A: 'idi3a',
    IDI3B: 'idi3b',
    IDI3C: 'idi3c',
    IDI3D: 'idi3d',
    IDI3E: 'idi3e',
    IDI4A: 'idi4a',
    IDI4B: 'idi4b',
    IDI4C: 'idi4c'
}

const generate = async () => {
    let data = await storage.generate(skey, (() => {
        let result = {}
        for(let k of Object.keys(columns)){
            result[k] = 0
        }

        return result
    })())

    return data
}

const LampKhus3A1 = ({
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)
    const [definitions, setDefinitions] = useState([])

    const handleUpdateData = (data, key) => {
        return data
    }

    useEffect(async () => {
        let __data = await storage.get(skey)
        let defData = handleUpdateData(__data)

        storage.update(skey, defData)
        setDefaultData(defData)
        setTimeout(() => setReady(true), 500)
    }, [])

    return ready ? (
        <DataForm
            baseId="mod-submission-spt-1771-LampKhus3A1"
            className="mpk-margin-C mpk-animation slide-in"
            style={{maxWidth: 'unset'}}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                {
                    render: () => (
                        <div className="mpk-margin-M margin-bottom">
                            <h3>
                                {getLabel('__H00', 'LampKhus3A1')}
                            </h3>
                            <p>{getLabel('__H01', 'LampKhus3A1')}</p>
                        </div>
                    )
                },
                {
                    inputType: inputTypes.DIVIDER,
                    label: getLabel('__H1', 'LampKhus3A1')
                },
                ...[
                    'IDI1A',
                    'IDI1B',
                    'IDI1C',
                    'IDI1D'
                ].map(key=>({
                    inputType: inputTypes.CHECKBOX,
                    label: getLabel(key, 'LampKhus3A1'),
                    key
                })),
                {
                    inputType: inputTypes.DIVIDER,
                    label: getLabel('__H2', 'LampKhus3A1')
                },
                ...[
                    'IDI2A',
                    'IDI2B',
                    'IDI2C'
                ].map(key=>({
                    inputType: inputTypes.CHECKBOX,
                    label: getLabel(key, 'LampKhus3A1'),
                    key
                })),
                {
                    inputType: inputTypes.DIVIDER,
                    label: getLabel('__H3', 'LampKhus3A1')
                },
                ...[
                    'IDI3A',
                    'IDI3B',
                    'IDI3C',
                    'IDI3D',
                    'IDI3E'
                ].map(key=>({
                    inputType: inputTypes.CHECKBOX,
                    label: getLabel(key, 'LampKhus3A1'),
                    key
                })),
                {
                    inputType: inputTypes.DIVIDER,
                    label: getLabel('__H4', 'LampKhus3A1')
                },
                ...[
                    'IDI4A',
                    'IDI4B',
                    'IDI4C'
                ].map(key=>({
                    inputType: inputTypes.CHECKBOX,
                    label: getLabel(key, 'LampKhus3A1'),
                    key
                }))
            ]}
            onBeforeChange={(key, value) => (value ? 1 : 0)}
            onSubmit={(data, callback) => {
                callback(t.translate('sentence.savedItem'), false)
                storage.update(skey, data)
            }}
            submitLabel={t.translate('column.save')}
            transparent={true}
        />
    ) : <LoaderInfo>Loading data...</LoaderInfo>
}

LampKhus3A1.generate = generate
LampKhus3A1.columns = columns

export default LampKhus3A1
