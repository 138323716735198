import React, {useState, useEffect} from 'react'
import { rules, storage } from '../../../../../services'
import { DataForm } from '../../../../../libs/react-mpk/components'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { round } from '../../../../../libs/react-mpk/services/number.service'

const skey = storage.keys.SPT_1771_LampKhus6A
const { inputTypes } = DataForm
const columns = {
    d1: 'd1',
    d2a: 'd2a',
    d2b: 'd2b',
    d2c: 'd2c',
    d3: 'd3',
    d4: 'd4',
    d5: 'd5',
    d6: 'd6',
    Trf6: 'trf6',
    Chk61: 'chk61',
    Chk62: 'chk62',
    Chk63: 'chk63',
    Chk64: 'chk64',
    NPWPHit: 'npwpHit',
    NmHit: 'nmHit',
    AlmtHit: 'almtHit',
    KotaHit: 'kotaHit',
    KdPosHit: 'kdPosHit',
    Opt: 'opt',
    KdNegaraP3B: 'kdNegaraP3B'
}

const generate = async () => {
    let data = await storage.generate(skey, {
        d1: 0,
        d2a: 0,
        d2b: 0,
        d2c: 0,
        d3: 0,
        d4: 0,
        d5: 0,
        d6: 0,
        Trf6: 0,
        Chk61: true,
        Chk62: false,
        Chk63: false,
        Chk64: false,
        NPWPHit: '',
        NmHit: '',
        AlmtHit: '',
        KotaHit: '',
        KdPosHit: '',
        Opt: '1',
        KdNegaraP3B: 'Algeria'
    })

    return data
}

const recon = (
    isBut = false,
    data = storage.get(skey),
    induk = storage.get(storage.keys.SPT_1771_Induk),
    lampI = storage.get(storage.keys.SPT_1771_LampI),
    submitting = true
) => {
    return new Promise((resolve) => {
        data.d1 = isBut ? lampI.d3 : 0
        data.d2a = isBut ? lampI.d5m : 0
        data.d2b = isBut ? lampI.d6e : 0
        data.d2c = data.d2a - data.d2b 
        data.d3 = data.d1 + data.d2c
        data.d4 = isBut ? induk.dB6 : 0
        data.d5 = data.d3 - data.d4
        data.Trf6 = data.Chk61 ? data.Trf6 : 0
        data.d6 = round((data.Trf6 * data.d5)/100)

        if(!data.Chk64) {
            data.NPWPHit = ''
            data.NmHit = ''
            data.AlmtHit = ''
            data.KotaHit = ''
            data.KdPosHit = ''
        }

        if(!data.Chk63){
            data.KdNegaraP3B = ''
        }

        data.Opt = data.Chk61 
            ? '1' 
            : data.Chk62 
                ? '2'
                : '1'

        if(submitting) storage.update(skey, data)
        resolve(data)
    })
}

const LampKhus6A = ({
    getLabel,
    envStore,
    temporaryStore,
    ...props
}) => {
    const induk = storage.get(storage.keys.SPT_1771_Induk)
    const lampI = storage.get(storage.keys.SPT_1771_LampI)
    const profile = storage.get(storage.keys.SPT_1771_Profile)
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        let defData = await recon(temporaryStore.properties.isBut)
        storage.update(skey, defData)
        setDefaultData(defData)
        setReady(true)
    }, [])

    return ready && (
       <DataForm
            baseId="mod-submission-spt1771-LampKhus6A"
            className="mpk-margin-C"
            hintMessage={temporaryStore.properties.isBut ? props.errorMessage : t.translate('sentence.khususBUT')}
            asDialog={false}
            defaultData={defaultData}
            definitions={temporaryStore.properties.isBut ? [
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: t.translate('column.checklist')
                // },
                ...['d1','d2a','d2b','d2c','d3','d4','d5'].map( key => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'LampKhus6A'),
                    key,
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    decimalScale: key === 'Trf6' ? 2 : null,
                    disabled: key === 'Trf6' ? false : true
                })),
                {
                    inputType: inputTypes.SELECT,
                    label: getLabel('Opt', 'LampKhus6A'),
                    key:'Opt',
                    options: envStore.env.spt.kodeOPT,
                    // disabled: true,
                },
                {
                    inputType: inputTypes.CHECKBOX,
                    label: getLabel('Chk61', 'LampKhus6A'),
                    disabled: true,
                    key:'Chk61',
                    show: false
                },
                // {
                //     inputType: inputTypes.DIVIDER,
                //     label: 'PPh'
                // },
                ...['Trf6', 'd6'].map( key => ({
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    label: getLabel(key, 'LampKhus6A'),
                    key,
                    isNumericString:true,
                    decimalSeparator: ',',
                    thousandSeparator:'.',
                    validation: key === 'Trf6' ? rules.validationTarif : 'min:0',
                    decimalScale: key === 'Trf6' ? 2 : null,
                    disabled: key === 'Trf6' ? false : true,
                    show: d => (d.Chk61),
                })),
                // ...['Chk62','Chk63'].map(key => ({
                //     inputType: inputTypes.CHECKBOX,
                //     label: getLabel(key, 'LampKhus6A'),
                //     show: d => (key === '' ? d.Chk64),
                //     key,
                // })),
                {
                    inputType: inputTypes.CHECKBOX,
                    label: getLabel('Chk62', 'LampKhus6A'),
                    key: 'Chk62',
                    disabled: true,
                    show: false
                },
                {
                    inputType: inputTypes.CHECKBOX,
                    label: getLabel('Chk63', 'LampKhus6A'),
                    key: 'Chk63',
                    show: d => (d.Chk62),
                },
                {
                    inputType: inputTypes.SELECT,
                    label: getLabel('KdNegaraP3B', 'LampKhus6A'),
                    key:'KdNegaraP3B',
                    options: envStore.env.spt.kodeListNegaraP3B,
                    show: d => (d.Chk63)
                },
                ...['Chk64'].map(key => ({
                    inputType: inputTypes.CHECKBOX,
                    label: getLabel(key, 'LampKhus6A'),
                    show: d => (d.Chk62),
                    key,
                })),
                {
                    inputType: inputTypes.DIVIDER,
                    label: t.translate('column.newProfile'),
                    show: d => (d.Chk64),
                },
                ...['NPWPHit','NmHit','AlmtHit','KotaHit'].map(key => ({
                    inputType: inputTypes.INPUT,
                    label: getLabel(key, 'LampKhus6A'),
                    validation: d => (d.Chk64 ? 'required': 'min:0'),
                    show: d => (d.Chk64),
                    key,
                })),
                {
                    inputType: inputTypes.INPUT,
                    label: getLabel('KdPosHit', 'LampKhus6A'),
                    key:'KdPosHit',
                    isNumericString: false,
                    validation: d => (d.Chk64 ? 'required|digits:5' : 'min:0'),
                    show: d => (d.Chk64),
                },
            ] : []}
            onChange={async (data, key, value) => {
                if(key === 'Chk61') data.Chk62 = !value
                else if(key === 'Chk62') {
                    data.Chk61 = !value
                    data.Chk63 = value
                    data.Chk64 = !value
                }
                if(key === 'Opt'){
                    data.Chk61 = value === '1'
                    data.Chk62 = value === '2'
                    if(value === '2'){
                        data.Chk63 = true
                        data.Chk64 = false
                    } else if(value === '1'){
                        data.Chk63 = false
                        data.Chk64 = false
                    }
                }
                if(key === 'Chk63') data.Chk64 = !value
                else if(key === 'Chk64') {
                    data.Chk63 = !value
                }
                data = await recon(temporaryStore.properties.isBut, data, induk, lampI, false)
                return data
            }}
            onSubmit={(data, callback) => {
                if(data.Chk62 && !data.Chk63 && !data.Chk64){
                    callback(t.translate('sentence.lamp6AChk62Alert'), true)
                } else {
                    storage.update(skey, data)
                    callback(t.translate('sentence.savedItem'), false)
                    props.onNextTab()
                }
            }}
            submitLabel={t.translate('column.next')}
       />
    )
}

LampKhus6A.generate = generate
LampKhus6A.columns = columns
LampKhus6A.recon = recon

export default inject('envStore', 'temporaryStore')(observer(LampKhus6A))
